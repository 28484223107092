/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * InputPhoneNo Component
 *
 * @component
 *
 * @description
 * An input component for handling phone number input using react-phone-input-2 library.
 *
 * @param {Object} props - The properties of the component.
 * @param {string} props.label - The label for the phone number input field.
 * @param {Function} props.setFileValue - Function to set the phone number value.
 * @param {boolean} props.required - Indicates whether the input is required.
 * @param {string} props.placeholder - Placeholder text for the phone number input field.
 * @param {string} props.value - The value of the phone number input field.
 * @param {boolean} props.disabled - Indicates whether the input is disabled.
 * @param {Object} props.error - Error object for validation feedback.
 * @param {string} props.errorMsg - Error message for validation feedback.
 * @param {string} props.name - The name of the phone number input field.
 * @param {Function} props.register - Function to register the phone number input field with a form.
 * @param {Function} props.handleChange - Function to handle changes in the phone number input field.
 *
 */

import styled from 'styled-components'
import {palette} from '../../styled/colors'
import {Text} from '../../styled/shared'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {useSelector} from 'react-redux'

const InputPhoneNo = ({label, setFileValue, required, placeholder, value, disabled, error, errorMsg, name, register, handleChange}: any) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)

	return (
		<>
			<Label type="normal" margin="0rem 0rem 0.19rem 0rem" isDarkTheme={_isDarkTheme}>
				{label} {required ? <Mandatory>*</Mandatory> : ''}
			</Label>
			<div id={`${_isDarkTheme ? 'phoneInput_dark' : 'phoneInput'}`}>
				<PhoneInput
					inputClass="phoneInputFields"
					country={'us'}
					enableAreaCodes={true}
					value={value}
					inputProps={{
						name: 'phone',
						country: 'us',
						required: true,
						autoFocus: false
					}}
					onChange={(phone) => handleChange({name: name, value: phone})}
				/>
			</div>
			{required && error && !disabled && (
				<ErrorText fontSize={0.625} type="small" color="danger">
					{error?.message ? error?.message : error}
				</ErrorText>
			)}
		</>
	)
}

const Label = styled(Text)`
	font-weight: 500;
	text-transform: capitalize;
`

const Mandatory = styled.span`
	color: ${palette.danger};
`

const ErrorText = styled(Text)`
	text-transform: capitalize;
	font-weight: 400;
`

export default InputPhoneNo
