/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * DisconnectSocialAccounts Component
 *
 * @component
 * @description Modal for unlinking social accounts.
 *
 * @param {Object} props - Component props.
 * @param {boolean} props.open - Flag indicating whether the modal is open.
 * @param {function} props.onCloseModal - Callback function to handle modal close.
 * @param {function} props.unlink - Callback function to handle account unlinking.
 * @param {function} props.unlinkAndRemoveData - Callback function to handle account unlinking and data removal.
 */

import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {Text, Spacer, Flexed} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {BsXLg} from 'react-icons/bs'
import {useSelector} from 'react-redux'
import {media} from 'styled-bootstrap-grid'
import {CommonEnum} from '../../utils'

interface ICategoriesModalProps {
	open: boolean
	onCloseModal: any
	unlink: any
	unlinkAndRemoveData: any
}

interface IProps {
	isDarkTheme: boolean
}

const DisconnectSocialAccounts = ({open, onCloseModal, unlink, unlinkAndRemoveData}: ICategoriesModalProps) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	return (
		<>
			<Modal
				open={open}
				center
				onClose={() => {
					onCloseModal()
				}}
				classNames={{
					overlay: 'customOverlay',
					modal: 'blockModal'
				}}
				showCloseIcon={false}
				// @ts-ignore
				styles={{zIndex: '2'}}>
				<ModalWrapper isDarkTheme={_isDarkTheme}>
					<Head direction="row" align="center" justify="space-between">
						<Text type="large" lineHeight="1.438" fontWeight="700" isDarkTheme={_isDarkTheme}>
							Unlink Account?
						</Text>
						<CrossIcon
							isDarkTheme={_isDarkTheme}
							onClick={() => {
								onCloseModal()
							}}
						/>
					</Head>
					<Body>
						<Spacer height={0.5} />
						<Text type="normal" fontWeight={500} isCentered isDarkTheme={_isDarkTheme}>
							You will loose access to {CommonEnum.AppName} using this account! If this is you're only way to login, you will lose access to your ListingApp account!
						</Text>
						<Spacer height={2} />
						<Action justify="space-between" gap={1}>
							<Button
								bgColor={_isDarkTheme ? palette.dark_gray : palette.black}
								isDarkTheme={_isDarkTheme}
								onClick={() => {
									unlink()
								}}>
								Unlink
							</Button>
							<Button
								bgColor={palette.danger}
								isDarkTheme={_isDarkTheme}
								onClick={() => {
									unlinkAndRemoveData()
								}}>
								Unlink and remove associated data
							</Button>
						</Action>
					</Body>
				</ModalWrapper>
			</Modal>
		</>
	)
}

const ModalWrapper = styled.div<IProps>`
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	padding: 1rem;
`

const Head = styled(Flexed)`
	padding: 1rem;
	${media.sm`
    width: 400px;
	`}
	${media.md`
    width: 400px;
	`}
`

const Body = styled.div`
	padding: 0rem 1rem 1rem;
	${media.sm`
    width: 400px;
	`}
	${media.md`
    width: 400px;
	`}
`

const CrossIcon = styled(BsXLg)<IProps>`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 1.3rem;
`

const Button = styled.div<any>`
	background: ${({bgColor}) => bgColor};
	border-radius: 0.3rem;
	padding: 0.5rem 0.8rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	justify-content: center;
	font-size: 0.75rem;
	font-weight: bold;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.white : palette.white)};
	cursor: pointer;
	width: 100%;
	text-transform: uppercase;
	text-align: center;
`

const Action = styled(Flexed)`
	${media.xs`
        flex-direction: column;
	`}
	${media.sm`
        flex-direction: row;
	`}
    ${media.md`
        flex-direction: row;
	`}
`

export default DisconnectSocialAccounts
