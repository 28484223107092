/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Commonly reuseable Styled components for headings in the application, providing consistent styling and customization options.
 *
 * Heading Components
 * Description: Styled components for headings in the application, providing consistent styling and customization options.
 *
 * StyledHeading
 * Description: Styled component for general headings.
 *
 * Props:
 * - font: string - Font family for the heading.
 * - level: number - Heading level (1 to 5).
 * - supersize: boolean - Flag for supersizing the heading.
 * - color: string - Color of the heading.
 * - margin: string - Margin around the heading.
 * - isCentered: boolean - Flag for centering the heading.
 * - bold: boolean - Flag for bold text.
 * - hasShadow: boolean - Flag for adding a text shadow.
 * - isLink: boolean - Flag for indicating a link style.
 *
 * BoxedHeading
 * Description: Styled component for headings with a boxed background.
 *
 * Props:
 * - font: string - Font family for the heading.
 * - level: number - Heading level (1 to 5).
 * - supersize: boolean - Flag for supersizing the heading.
 * - color: string - Color of the heading.
 * - margin: string - Margin around the heading.
 * - isCentered: boolean - Flag for centering the heading.
 * - weight: string - Font weight of the heading.
 * - hasShadow: boolean - Flag for adding a text shadow.
 * - isLink: boolean - Flag for indicating a link style.
 */

import styled from 'styled-components'
import {palette} from './colors'

interface DividerProps {
	isDarkTheme?: boolean
	margin?: string
}

const handleHeadingFontSize = (level: any) => {
	switch (level) {
		case 1:
			return '3.5rem'
		case 2:
			return '2.25rem'
		case 3:
			return '1.625rem'
		case 4:
			return '1.375rem'
		case 5:
			return '1.125rem'
		default:
			return '1rem'
	}
}

const handleHeadingLineHeight = (level: any) => {
	switch (level) {
		case 1:
			return '2.5rem'
		case 2:
			return '2.5rem'
		case 3:
			return 'normal'
		case 4:
			return '2rem'
		case 5:
			return '2rem'
		default:
			return 'normal'
	}
}

// Heading Levels
//             fontSize              LineHight
// level 1 56px || 3.5rem       .      	40px || 2.5rem
// level 2 36px || 2.25rem      .       40px || 2.5rem
// level 3 26px || 1.625rem     .            || normal
// level 4 22px || 1.375rem     .       32px || 2rem
// level 5 18px || 1.125rem     .       32px || 2rem
// level 6 16px || 1rem         .       	 || normal

export const Heading = styled.div<any>`
	text-transform: ${({textTransform}) => (textTransform ? textTransform : 'capitalize')};
	font-size: ${({level, fontSize}) => (fontSize ? `${fontSize}rem` : handleHeadingFontSize(level))};
	line-height: ${({level, lineHeight}) => (lineHeight ? `${lineHeight}rem` : handleHeadingLineHeight(level))};
	color: ${({color, isDarkTheme}) => (color ? palette[color] : isDarkTheme ? palette.white : palette.black)};
	margin: ${({margin}) => `${margin}`};
	text-align: ${({isCentered}) => (isCentered ? `center` : 'inherit')};
	font-weight: ${({fontWeight}) => (fontWeight ? fontWeight : 600)};
	cursor: ${({pointer}) => (pointer ? `pointer` : '')};
	opacity: ${({opacity}) => (opacity ? opacity : '1')};
	letter-spacing: 0.02em;
`

const handleParagraphFontSize = (type: any) => {
	switch (type) {
		case 'large':
			return '1.25rem'
		case 'medium':
			return '1.125rem'
		case 'normal':
			return '1rem'
		case 'small':
			return '0.875rem'
		case 'xsmall':
			return '0.75rem'
		default:
			return '0.875rem'
	}
}

const handleParagraphLineHeight = (type: any) => {
	switch (type) {
		case 'large':
			return '1.688rem'
		case 'medium':
			return '1.563rem'
		case 'normal':
			return '1.5rem'
		case 'small':
			return '1.125rem'
		case 'xsmall':
			return '1rem'
		default:
			return '1.125rem'
	}
}

// Paragraph Levels
//             fontSize              LineHight
// large    20px || 1.25rem     .      27px || 1.688rem
// medium   18px || 1.125rem    .      25px || 1.563rem
// normal   16px || 1rem        .      24px || 1.5rem
// small    14px || 0.875rem    .      18px || 1.125rem
// xsmall   12px || 0.75rem     .      16px || 1rem

export const Text = styled.div<any>`
	font-weight: ${({fontWeight}) => (fontWeight ? fontWeight : '400')};
	font-size: ${({type, fontSize}) => (fontSize ? `${fontSize}rem` : handleParagraphFontSize(type))};
	line-height: ${({type, lineHeight}) => (lineHeight ? `${lineHeight}rem` : handleParagraphLineHeight(type))};
	color: ${({color, isDarkTheme}) => (color ? palette[color] : isDarkTheme ? palette.light_gray : palette.dark_gray)};
	margin: ${({margin}) => `${margin}`};
	text-align: ${({isCentered}) => (isCentered ? `center` : 'left')};
	cursor: ${({pointer}) => (pointer ? `pointer` : '')};
	opacity: ${({opacity}) => (opacity ? opacity : '1')};
	text-transform: ${({textTransform}) => (textTransform ? textTransform : '')};
	letter-spacing: 0.02em;
`

export const Divider = styled.div<DividerProps>`
	height: 1px;
	width: 100%;
	background: ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.silver)};
	margin: ${({margin}) => `${margin}`};
`

export const Spacer = styled.div<any>`
	height: ${({height}) => `${height}rem`};
	border-bottom: ${({border}) => (border ? '1px solid #E1E1E1' : 'none')};
`

export const VerticalSpacer = styled.span<any>`
	width: ${({width}) => `${width}rem`};
	display: inline-block;
`

export const Flexed = styled.div<any>`
	display: ${({inline}) => (inline ? 'inline-flex' : 'flex')};
	flex-direction: column;
	flex-direction: ${({direction}) => direction};
	align-items: ${({align}) => align};
	justify-content: ${({justify}) => justify};
	margin: ${({margin}) => `${margin}`};
	gap: ${({gap}) => `${gap}rem`};
	cursor: ${({pointer}) => (pointer ? `pointer` : '')};
	flex-wrap: ${({flexWrap}) => `${flexWrap}`};
`

export const Loading = styled.div<any>`
	position: ${({position}) => (position ? position : 'fixed')};
	background: rgb(0 0 0 / 36%);
	width: 100%;
	height: 100%;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: ${({left}) => left};
`

export const DletelTag = styled.del<any>`
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.light_silver)};
`

export const DisplayItemBaner = styled(Text)<any>`
	position: absolute;
	width: fit-content;
	border-radius: 0.2rem;
	background: ${palette.danger};
	color: ${palette.white};
	font-size: 14px;
	padding: 0.2rem 0.3rem;
	display: flex;
	align-items: center;
	gap: 0.2rem;
	top: ${({top}) => `${top}rem`};
	right: ${({right}) => `${right}rem`};
	left: ${({left}) => `${left}rem`};
	bottom: ${({bottom}) => `${bottom}rem`};
	margin: ${({margin}) => `${margin}`};
	z-index: ${({zIndex}) => `${zIndex}`};
`

export const Tag = styled(Text)<any>`
	width: fit-content;
	border-radius: 0.2rem;
	background: ${palette.blue};
	color: ${palette.white};
	font-size: 14px;
	padding: 0.2rem 0.3rem;
	display: flex;
	align-items: center;
	gap: 0.2rem;
	margin: ${({margin}) => `${margin}`};
`

export const FeaturedAddTag = styled.div`
	position: absolute;
	background: ${palette.yellow};
	padding: 0.3rem 0.6rem;
	border-radius: 0.2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	font-size: 0.7rem;
	top: 0.5rem;
	left: 0.5rem;
	z-index: 2;
`
export const SoldAddTag = styled(FeaturedAddTag)`
	background: ${palette.black};
	color: ${palette.white};
`
