/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * PropertyDetails Component
 *
 * This component is responsible for displaying detailed information about a property ad.
 * It includes features such as price, location, contact details, and related ads.
 * Users can interact with the ad, view the owner's profile, contact the seller, and more.
 *
 * @component
 */

import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {Divider, Flexed, Heading, Spacer, Text, Loading, FeaturedAddTag} from '../../styled/shared'
import {useSelector} from 'react-redux'
import {palette} from '../../styled/colors'
import {AiOutlineHeart, AiFillHeart, AiOutlineEye} from 'react-icons/ai'
import {BiShareAlt} from 'react-icons/bi'
import {FiPhone} from 'react-icons/fi'
import {HiOutlineLocationMarker} from 'react-icons/hi'
import {RiChat1Line} from 'react-icons/ri'
import {RiFlag2Fill} from 'react-icons/ri'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {Carousel} from 'react-responsive-carousel'
import {FaAngleRight, FaEdit} from 'react-icons/fa'
import AdsReport from '../../components/modals/AdsReport'
import {useSearchParams, useNavigate, Link, createSearchParams} from 'react-router-dom'
import {api, checkAdvertisementStatus, checkFeaturedPostStatus} from '../../components/api/callAxios'
import {verifyJSON} from '../../constants/commonFunctions'
import moment from 'moment'
import PropertyRelatedAds from '../../components/products/PropertyRalatedAds'
import useRouter from '../../components/common/UserRouterHook'
import AuthModal from '../../components/modals/AuthModal'
import Button from '../../components/common/Button'
import {toast} from 'react-toastify'
import {GoBlocked} from 'react-icons/go'
import SocialShareModal from '../../components/modals/SocialShareModal'
import {BsDash, BsDot} from 'react-icons/bs'
import useWindowSize from '../../hooks/useWindowSize'
import TopBottomAdvertisement from '../../components/advertisement/TopBottomAdvertisement'
import RightAdvertisement from '../../components/advertisement/RightAdvertisement'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import PotentialBuyersModel from '../../components/modals/PotentialBuyersModel'
import {formatNumber} from '../../utils'

const PropertyDetails = () => {
	const _navigate = useNavigate()
	const windowSize = useWindowSize()
	const [searchParams] = useSearchParams()
	const _id: any = searchParams.get('id')
	const _isDarkTheme = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	const [addFav, setAddFav] = useState(false)
	const [postFullDetails, setPostFullDetails] = useState<any>('')
	const [relatedAds, setRelatedAds] = useState<any>([])
	const [address, setAddress] = useState<any>('')
	const [reportAds, setReportAds] = useState(false)
	const [isLoading, setIsLoading] = useState<any>(false)
	const [postImage, setPostImage] = useState<any>([])
	const [features, setFeatures] = useState([])
	const [showPhone, setShowPhone] = useState(false)
	const [loadingPhone, setLoadingPhone] = useState(false)
	const [openAuthModal, setOpenAuthModal] = useState(false)
	const [openShareModal, setOpenShareModal] = useState(false)
	const [clickOnChat, setClickOnChat] = useState(false)
	const router: any = useRouter()
	const [scrollPosition, setScrollPosition] = useState(0)
	const [paidAdvertisement, setPaidAdvertisement] = useState<any>([])
	const [lightBoxOpen, setLightBoxOpen] = useState(false)
	const [photoIndex, setPhotoIndex] = useState<any>(0)
	const [selectedIndex, setSelectedIndex] = useState<any>(0)
	const [potentialBuyers, setPotentialBuyers] = useState<any>([])
	const [potentialBuyerModel, setPotentialBuyerModel] = useState<any>(false)

	const handleScroll = () => {
		const _position = window.pageYOffset
		setScrollPosition(_position)
	}

	useEffect(() => {
		checkFeaturedPostStatus()
		checkAdvertisementStatus()
		window.addEventListener('scroll', handleScroll, {passive: true})
	}, [])

	useEffect(() => {
		getPostDetails(_id)
	}, [_id, _loginUserDetails])

	const getPostDetails = async (postId: string) => {
		setIsLoading(true)
		await api
			.post(`/ads/ad_detail`, {
				adsId: postId,
				user: _loginUserDetails
			})
			.then(async (response: any) => {
				setPostFullDetails(response?.data?.ads)
				setRelatedAds(response?.data?.allRelatedAds)
				setAddress(response?.data?.ads?.location)
				setAddFav(response?.data?.ads?.isFav)
				if (response?.data?.ads?.photos?.length > 0) {
					let _photos: any = verifyJSON(response?.data?.ads?.photos) ? JSON.parse(response?.data?.ads?.photos) : response?.data?.ads?.photos
					setPostImage(_photos)
				}
				let _postFeatures: any = verifyJSON(response?.data?.ads?.features) ? JSON.parse(response?.data?.ads?.features) : response?.data?.ads?.features
				setFeatures(_postFeatures)
				adsStatics(response?.data?.ads, true, false, false)
				getPaidAdvertisement()
				if (response?.data?.potentialBuyers?.length) {
					getPotentialBuyerUsers(response?.data?.potentialBuyers)
				}

				setIsLoading(false)
			})
			.catch(function (error: any) {
				if (error?.response) {
					toast.error(error?.response?.data?.message)
				}
				setPostFullDetails('')
				setRelatedAds([])
				setAddress('')
				setIsLoading(false)
				setAddFav(false)
				setFeatures([])
			})
	}

	const goToProfile = () => {
		_navigate(`/dealer-profile?id=${postFullDetails?.customerIdFkey?.id}`)
	}

	const addFavPost = async () => {
		setIsLoading(true)
		await api
			.post(`favoriteAds/add_fav`, {addId: postFullDetails?.id})
			.then(async (response) => {
				if (response) {
					setAddFav(!addFav)
				}
				setIsLoading(false)
			})
			.catch(function (error) {
				if (error?.response) {
					toast.error(error?.response?.data?.message)
				}
				setIsLoading(false)
			})
	}
	const removeFavPost = async () => {
		setIsLoading(true)
		await api
			.post(`favoriteAds/remove_fav`, {addId: postFullDetails?.id})
			.then(async (response) => {
				if (response) {
					setAddFav(!addFav)
					setIsLoading(false)
				}
			})
			.catch(function (error) {
				if (error?.response) {
					toast.error(error?.response?.data?.message)
				}
				setIsLoading(false)
			})
	}

	const handleCall = (phoneNumber: any) => {
		const telLink = `tel:${phoneNumber}`
		window.location.href = telLink
	}

	const adsStatics = async (data: any, view: any, tel: any, chats: any) => {
		let checkToken = localStorage.getItem('authorization')
		if (checkToken && _loginUserDetails?.id) {
			await api
				.post(`/adsStatics/create_adsStatics`, {
					ownerId: data?.customerIdFkey?.id,
					adsId: data?.id,
					userId: _loginUserDetails?.id,
					viewAd: view,
					tel: tel,
					chat: chats
				})
				.then(async (response) => {})
				.catch(function (error) {
					if (error?.response) {
						toast.error(error?.response?.data?.message)
					}
				})
		} else if (!checkToken) {
			await api
				.post(`/adsStatics/create_adsStatics`, {
					ownerId: data?.customerIdFkey?.id,
					adsId: data?.id,
					userId: _loginUserDetails?.id,
					viewAd: view,
					tel: tel,
					chat: chats
				})
				.then(async (response) => {})
				.catch(function (error) {
					if (error?.response) {
						toast.error(error?.response?.data?.message)
					}
				})
		}
	}

	const activateAndDeactivatePost = (status: any, id: any) => {
		setIsLoading(true)
		api.post(`ads/adsStatus/${id}`, {
			activate: status
		})
			.then((res) => {
				setIsLoading(false)
				toast.success(`Your ad ${res?.data?.activate ? 'is activated' : 'is deactivated'} successfully.`)
				getPostDetails(_id)
			})
			.catch((e) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setIsLoading(false)
				}
			})
	}

	const getPaidAdvertisement = async () => {
		await api
			.get(`paidAds/all_ads_client`)
			.then(async (response) => {
				setPaidAdvertisement(response.data.data)
			})
			.catch(function (error) {
				if (error?.response) {
					toast.error(error?.response?.data?.message)
				}
			})
	}

	const getPotentialBuyerUsers = async (userIds: any) => {
		try {
			await api
				.post(`customers/view_all_user`, {userIds})
				.then(async (response) => {
					setPotentialBuyers(response?.data)
				})
				.catch(function (error) {
					console.log(error)
				})
		} catch (error) {
			console.log('===error', error)
		}
	}

	return (
		<>
			<StickyWrapper scroll={scrollPosition} isDarkTheme={_isDarkTheme}>
				<Container>
					<Row justifyContent="between">
						<Col lg={6} md={6} xsOrder={2} smOrder={2} mdOrder={1}>
							{postFullDetails?.typeCategory != 'Cars on Installments' ? (
								<Heading margin="0rem 0rem 0.5rem 0rem" level={3} fontWeight={700} isDarkTheme={_isDarkTheme}>
									${postFullDetails?.price?.toLocaleString()}
								</Heading>
							) : (
								<Heading margin="0rem 0rem 0.5rem 0rem" level={3} fontWeight={700} isDarkTheme={_isDarkTheme}>
									${postFullDetails?.downPayment?.toLocaleString()}
									<BsDash />
									{postFullDetails?.installmentPlan}
								</Heading>
							)}
							<Flexed margin="0rem 0rem 0.5rem 0rem" direction="row" align="center" gap={1} flexWrap="wrap">
								{postFullDetails?.bedRooms != '' && (
									<Flexed direction="row" align="center" gap={0.3}>
										<Icons isDarkTheme={_isDarkTheme} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 12" className="_4225178d">
											<path fill-rule="evenodd" d="M1 .3L.3 1v10.7h1.4v-1.4h10.6v1.4h1.4v-6L13 5H6.3V3.7L5.7 3h-4V1L1 .3zM1.7 5H5v-.7H1.7V5zm0 3.3v-2h10.6V9H1.7v-.7z"></path>
										</Icons>
										<Text type="normal" fontWeight={600} isDarkTheme={_isDarkTheme}>
											{postFullDetails?.bedRooms} beds
										</Text>
									</Flexed>
								)}
								{postFullDetails?.bathRooms != '' && (
									<Flexed direction="row" align="center" gap={0.3}>
										<Icons isDarkTheme={_isDarkTheme} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 12" className="_4225178d">
											<path fill-rule="evenodd" d="M1.7 10h10.6V6.7H1.7V10zM13 5.3H3v-3A1 1 0 1 1 5 2h-.7v1.3H7V2h-.7a2.3 2.3 0 0 0-4.6.3v3H1L.3 6v4.7l.7.6h.7v.7H3v-.7h8v.7h1.3v-.7h.7l.7-.6V6l-.7-.7z"></path>
										</Icons>
										<Text type="normal" fontWeight={600} isDarkTheme={_isDarkTheme}>
											{postFullDetails?.bathRooms} baths
										</Text>
									</Flexed>
								)}
								{postFullDetails?.areaUnit && (
									<Flexed direction="row" align="center" gap={0.3}>
										<Icons isDarkTheme={_isDarkTheme} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="_4225178d">
											<path fill-rule="evenodd" d="M3 3L2 4v16l1 1h18l1-1V4l-1-1H3zm8 10v6H4v-6h7zm0-8v6H4V5h7zm9 8v6h-7v-6h7zm0-8v6h-7V5h7z"></path>
										</Icons>
										<Text type="normal" fontWeight={600} isDarkTheme={_isDarkTheme}>
											{postFullDetails?.area}
											{postFullDetails?.areaUnit === 'Square Feet' ? 'sqft' : postFullDetails?.areaUnit === 'Square Yards' ? 'sqyd' : postFullDetails?.areaUnit === 'Square Meter' ? 'sqm' : postFullDetails?.areaUnit}
										</Text>
									</Flexed>
								)}
								{postFullDetails?.kmDriven && (
									<Flexed direction="row" align="center" gap={0.3}>
										<Icons isDarkTheme={_isDarkTheme} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 12" className="_4225178d">
											<path
												fill-rule="evenodd"
												d="M7 .3c3.7 0 6.7 3 6.7 6.7 0 1.5-.2 3.1-1.6 4.5l-.4.2H2.3l-.4-.2C.5 10 .3 8.5.3 7 .3 3.3 3.3.3 7 .3zm0 1.4C4 1.7 1.7 4 1.7 7c0 1.6.2 2.6 1 3.3h8.7c.7-.7 1-1.7 1-3.3 0-3-2.5-5.3-5.4-5.3zm3.3 2v1l-.8.8-.7.6.2 1A2 2 0 1 1 8 5l1.5-1.4h1zM7 6.3a.7.7 0 1 0 0 1.4.7.7 0 0 0 0-1.4z"></path>
										</Icons>
										<Text type="normal" fontWeight={600} isDarkTheme={_isDarkTheme}>
											{postFullDetails?.kmDriven} km
											<BsDot fontSize={18} />
											{postFullDetails?.year}
										</Text>
									</Flexed>
								)}
							</Flexed>
							<Flexed direction="row" gap={0.5}>
								<div>
									<HiOutlineLocationMarker cursor="pointer" fontSize={18} color={_isDarkTheme ? palette.light_gray : palette.dark_gray} />
								</div>
								<LocationText type="normal" isDarkTheme={_isDarkTheme}>
									{address}
								</LocationText>
							</Flexed>
						</Col>
						<Col lg={6} md={6} xsOrder={1} smOrder={1} mdOrder={2}>
							{postFullDetails != '' && (
								<StickyDetailFlex direction="row" gap={1}>
									<div>
										{_loginUserDetails?.id != postFullDetails?.customerId ? (
											<>
												<Flexed direction="row" gap={1}>
													<SeeProfile width={3} height={3} onClick={goToProfile}>
														{postFullDetails?.userPhoto ? (
															<Profile width={3} height={3} src={process.env.REACT_APP_IMAGE_URL + postFullDetails?.userPhoto} />
														) : (
															<Text fontSize={1.8} color="white" fontWeight={700}>
																{postFullDetails?.userName && postFullDetails?.userName[0].toUpperCase()}
															</Text>
														)}
													</SeeProfile>
													<Flexed onClick={goToProfile} pointer>
														<Text type="normal" fontWeight="bold" isDarkTheme={_isDarkTheme}>
															{postFullDetails?.userName}
														</Text>

														<Flexed direction="row" pointer align="center" margin="0.2rem 0rem 0rem 0rem">
															<Text type="normal" lineHeight="0" color={'red'} fontWeight="500">
																See profile
															</Text>
															<FaAngleRight color={palette.red} />
														</Flexed>
													</Flexed>
												</Flexed>
												<Spacer height={1} />
												<Flexed direction="row" gap={1}>
													{postFullDetails?.showNoInAds && (
														<>
															<Phone
																isDarkTheme={_isDarkTheme}
																onClick={() => {
																	if (_loginUserDetails?.id) {
																		if (!showPhone) {
																			setLoadingPhone(true)
																			setShowPhone(true)
																			setTimeout(() => {
																				setLoadingPhone(false)
																			}, 300)
																			adsStatics(postFullDetails, false, true, false)
																		} else if (showPhone) {
																			handleCall(postFullDetails?.phone)
																		}
																	} else {
																		setOpenAuthModal(true)
																	}
																}}>
																<FiPhone color={palette.red} />
																{showPhone ? <> {loadingPhone ? <LoadingSpinner onLoad={() => setLoadingPhone(false)} /> : postFullDetails?.phone}</> : 'Show phone number'}
															</Phone>
														</>
													)}

													<Chat
														isDarkTheme={_isDarkTheme}
														onClick={() => {
															if (_loginUserDetails?.id) {
																setClickOnChat(true)
																adsStatics(postFullDetails, false, false, true)
																router.navigate(
																	_loginUserDetails?.id && _loginUserDetails?.id == postFullDetails?.customerId
																		? `/chat`
																		: `/chat?id=${postFullDetails?.customerId}&isBlocked=${postFullDetails?.isBlocked}&addId=${postFullDetails?.id}`
																)
															} else {
																setOpenAuthModal(true)
															}
														}}>
														<RiChat1Line fontSize={20} />
														Chat
													</Chat>
												</Flexed>
											</>
										) : (
											<>
												<Flexed flexWrap="wrap" direction="row" gap={0.8} align="center" margin="0rem 0rem 0.5rem 0rem">
													<Flexed direction="row" gap={0.3} align="center">
														<AiOutlineEye color={_isDarkTheme ? palette.light_gray : palette.dark_gray} />
														<Text type="small" fontWeight={700} isDarkTheme={_isDarkTheme}>
															Views: {postFullDetails?.views}
														</Text>
													</Flexed>
													<Flexed direction="row" gap={0.3} align="center">
														<AiOutlineEye color={_isDarkTheme ? palette.light_gray : palette.dark_gray} />
														<Text type="small" fontWeight={700} isDarkTheme={_isDarkTheme}>
															Estimated Views: {postFullDetails?.estimatedViews ? formatNumber(postFullDetails?.estimatedViews) : 0}
														</Text>
													</Flexed>

													<Flexed direction="row" gap={0.3} align="center">
														<FiPhone color={_isDarkTheme ? palette.light_gray : palette.dark_gray} />
														<Text type="small" fontWeight={700} isDarkTheme={_isDarkTheme}>
															Tel: {postFullDetails?.tellUsers}
														</Text>
													</Flexed>

													<Flexed direction="row" gap={0.3} align="center">
														<AiFillHeart color={_isDarkTheme ? palette.light_gray : palette.dark_gray} />
														<Text type="small" fontWeight={700} isDarkTheme={_isDarkTheme}>
															Likes: {postFullDetails?.likes}
														</Text>
													</Flexed>

													<Flexed direction="row" gap={0.3} align="center">
														<RiChat1Line color={_isDarkTheme ? palette.light_gray : palette.dark_gray} />
														<Text type="small" fontWeight={700} isDarkTheme={_isDarkTheme}>
															Chats: {postFullDetails?.chatUsers}
														</Text>
													</Flexed>
												</Flexed>
												{_loginUserDetails?.id === postFullDetails?.customerId && !postFullDetails?.sold && postFullDetails?.activate && !postFullDetails?.isBlockedAd && potentialBuyers?.length ? (
													<div onClick={() => setPotentialBuyerModel(true)} style={{cursor: 'pointer'}}>
														<Text type="large" color={'blue'} fontWeight={700} isDarkTheme={_isDarkTheme} onClick={() => setPotentialBuyerModel(true)}>
															Potential Buyers for your ad
														</Text>
														<Spacer height={0.5} />
													</div>
												) : (
													''
												)}
												{postFullDetails?.sold && (
													<SoldWrapper margin="1rem 0rem 0rem 0rem" direction="row" align="center" justify="space-between" gap={0.5}>
														<div>
															<Text type="normal" margin="0rem 0rem 0.2rem 0rem" fontWeight={600}>
																Sold
															</Text>
															<Text type="normal">You marked this ad as sold on</Text>
															<Text type="normal">{moment(postFullDetails?.updatedAt).format('DD/MM/YYYY')}</Text>
														</div>
														<img src="/images/markSold.png" alt="markSold" />
													</SoldWrapper>
												)}
												{!postFullDetails?.activate && (
													<WarningWrapper margin="1rem 0rem 0rem 0rem" direction="row" align="center" justify="space-between" gap={0.5}>
														<div>
															<Text type="normal" margin="0rem 0rem 0.2rem 0rem" fontWeight={600}>
																Your Ad is disabled
															</Text>
															<Text type="normal" margin="0rem 0rem 0.5rem 0rem">
																You deactivated this ad on {moment(postFullDetails?.updatedAt).format('DD/MM/YYYY')}.
															</Text>
															<Button
																label="Republish Ad"
																type="cancel"
																ifClicked={() => {
																	activateAndDeactivatePost(!postFullDetails?.activate, postFullDetails?.id)
																}}
															/>
														</div>
														<img src="/images/icons/iconWarning.svg" alt="iconWarning" />
													</WarningWrapper>
												)}
												{postFullDetails?.isBlockedAd && (
													<BlockedWrapper margin="1rem 0rem 0rem 0rem" direction="row" align="center" justify="space-between" gap={0.5}>
														<div>
															<Text type="normal" margin="0rem 0rem 0.2rem 0rem" fontWeight={600}>
																Your Ad is Blocked
															</Text>
															<Text type="normal" margin="0rem 0rem 0.5rem 0rem">
																This Ad is blocked by admin on {moment(postFullDetails?.updatedAt).format('DD/MM/YYYY')}.
															</Text>
														</div>
														<GoBlocked fontSize={'5rem'} color={palette.danger} />
													</BlockedWrapper>
												)}
											</>
										)}
									</div>
								</StickyDetailFlex>
							)}
						</Col>
					</Row>
				</Container>
			</StickyWrapper>
			<Wrapper>
				<Spacer height={1} />
				{postFullDetails && <TopBottomAdvertisement show="top" paidAdvertisement={paidAdvertisement} />}
				<Row>
					<Col lg={8} lgOrder={1} order={2}>
						{postFullDetails != '' && (
							<div id="customCarosal" style={{position: 'relative'}}>
								{postFullDetails?.isFeatured && <FeaturedAddTag>Featured</FeaturedAddTag>}
								{postImage?.length > 1 ? (
									<Carousel
										showThumbs={false}
										onClickItem={(index: any) => {
											setLightBoxOpen(true)
											setPhotoIndex(index)
											setSelectedIndex(index)
										}}>
										{postImage?.map((img: any, key: number) => {
											return (
												<div id={key + 'w'}>
													<Images src={process.env.REACT_APP_IMAGE_URL + img} />
												</div>
											)
										})}
									</Carousel>
								) : (
									<Images
										src={process.env.REACT_APP_IMAGE_URL + postImage[0]}
										onClick={() => {
											setLightBoxOpen(true)
											setPhotoIndex(0)
											setSelectedIndex(0)
										}}
									/>
								)}
							</div>
						)}
						<Spacer height={1} />

						{postFullDetails != '' && (
							<Content>
								<Flexed direction="row" justify="space-between" align="center" gap={0.2}>
									{postFullDetails?.typeCategory != 'Cars on Installments' ? (
										<Heading level={3} fontWeight={700} isDarkTheme={_isDarkTheme} margin={'0rem 0rem 1rem 0rem'}>
											${postFullDetails?.price?.toLocaleString()}
										</Heading>
									) : (
										<div></div>
									)}
									<Flexed direction="row" align="center" gap={1}>
										{_loginUserDetails?.id === postFullDetails?.customerId && !postFullDetails?.sold && postFullDetails?.activate && !postFullDetails?.isBlockedAd && (
											<FaEdit
												cursor="pointer"
												fontSize={24}
												fill={_isDarkTheme ? palette.light_gray : palette.dark_gray}
												onClick={() => {
													_navigate({
														pathname: '/edit-post',
														search: createSearchParams({
															id: postFullDetails?.id
														}).toString()
													})
												}}
											/>
										)}
										{!postFullDetails?.isBlockedAd && (
											<BiShareAlt
												onClick={() => {
													setOpenShareModal(true)
												}}
												cursor="pointer"
												fontSize={24}
												fill={_isDarkTheme ? palette.light_gray : palette.dark_gray}
											/>
										)}
										{_loginUserDetails?.id != postFullDetails?.customerId &&
											!postFullDetails?.sold &&
											postFullDetails?.activate &&
											!postFullDetails?.isBlockedAd &&
											(addFav ? (
												<AiFillHeart
													cursor="pointer"
													fontSize={28}
													onClick={() => {
														if (_loginUserDetails?.id) {
															removeFavPost()
														} else {
															setOpenAuthModal(true)
														}
													}}
													fill={`${palette.danger}`}
												/>
											) : (
												<AiOutlineHeart
													cursor="pointer"
													fontSize={28}
													onClick={() => {
														if (_loginUserDetails?.id) {
															addFavPost()
														} else {
															setOpenAuthModal(true)
														}
													}}
													fill={_isDarkTheme ? palette.light_gray : palette.dark_gray}
												/>
											))}
									</Flexed>
								</Flexed>
								<Text fontWeight="bold" type="normal" font isDarkTheme={_isDarkTheme}>
									{postFullDetails?.title}
								</Text>
								<Spacer height={0.8} />
								<AddressAndTime justify="space-between">
									<Flexed direction="row" gap={0.5}>
										<div>
											<HiOutlineLocationMarker cursor="pointer" fontSize={18} color={_isDarkTheme ? palette.light_gray : palette.dark_gray} />
										</div>
										<LocationText type="normal" isDarkTheme={_isDarkTheme}>
											{address}
										</LocationText>
									</Flexed>

									<Text type="normal" isDarkTheme={_isDarkTheme} style={{whiteSpace: 'nowrap'}}>
										{moment(postFullDetails?.createdAt).fromNow(true)} ago{' '}
									</Text>
								</AddressAndTime>
							</Content>
						)}

						<Spacer height={1} />

						{postFullDetails != '' && (
							<Content>
								<Heading level={3} fontWeight={700} isDarkTheme={_isDarkTheme} margin={'0rem 0rem 1rem 0rem'}>
									Details
								</Heading>
								<Grid>
									{postFullDetails?.brand && (
										<Flexed direction="row" justify="space-between" align="center" gap={0.2}>
											<Flexed direction="row" align="center" gap={0.5}>
												<Text type="normal" isDarkTheme={_isDarkTheme}>
													{postFullDetails?.businessType === 'Vehicles' ? 'Make' : 'Brand'}
												</Text>
											</Flexed>
											<Text type="normal" fontWeight="bold" isDarkTheme={_isDarkTheme}>
												{postFullDetails?.brand}
											</Text>
										</Flexed>
									)}
									{postFullDetails?.type && (
										<Flexed direction="row" justify="space-between" align="center" gap={0.2}>
											<Flexed direction="row" align="center" gap={0.5}>
												<Text type="normal" isDarkTheme={_isDarkTheme}>
													Type{' '}
												</Text>
											</Flexed>
											<Text type="normal" fontWeight="bold" isDarkTheme={_isDarkTheme}>
												{postFullDetails?.type}
											</Text>
										</Flexed>
									)}
									{postFullDetails?.model && (
										<Flexed direction="row" justify="space-between" align="center" gap={0.2}>
											<Flexed direction="row" align="center" gap={0.5}>
												<Text type="normal" isDarkTheme={_isDarkTheme}>
													Model{' '}
												</Text>
											</Flexed>
											<Text type="normal" fontWeight="bold" isDarkTheme={_isDarkTheme}>
												{postFullDetails?.model}
											</Text>
										</Flexed>
									)}
									{postFullDetails?.typeCategory != 'Cars on Installments' && (
										<Flexed direction="row" justify="space-between" align="center" gap={0.2}>
											<Text type="normal" isDarkTheme={_isDarkTheme}>
												Price
											</Text>
											<Text type="normal" fontWeight="bold" isDarkTheme={_isDarkTheme}>
												${postFullDetails?.price?.toLocaleString()}
											</Text>
										</Flexed>
									)}

									{postFullDetails?.bedRooms && (
										<Flexed direction="row" justify="space-between" align="center" gap={0.2}>
											<Flexed direction="row" align="center" gap={0.5}>
												<Text type="normal" isDarkTheme={_isDarkTheme}>
													Bedrooms
												</Text>
											</Flexed>
											<Text type="normal" fontWeight="bold" isDarkTheme={_isDarkTheme}>
												{postFullDetails?.bedRooms}
											</Text>
										</Flexed>
									)}

									{postFullDetails?.floorLevel && (
										<Flexed direction="row" justify="space-between" align="center" gap={0.2}>
											<Flexed direction="row" align="center" gap={0.5}>
												<Text type="normal" isDarkTheme={_isDarkTheme}>
													Floor Level
												</Text>
											</Flexed>
											<Text type="normal" fontWeight="bold" isDarkTheme={_isDarkTheme}>
												{postFullDetails?.floorLevel}
											</Text>
										</Flexed>
									)}

									{postFullDetails?.area && (
										<Flexed direction="row" justify="space-between" align="center" gap={0.2}>
											<Flexed direction="row" align="center" gap={0.5}>
												<Text type="normal" isDarkTheme={_isDarkTheme}>
													Area
												</Text>
											</Flexed>
											<Text type="normal" fontWeight="bold" isDarkTheme={_isDarkTheme}>
												{postFullDetails?.area}
											</Text>
										</Flexed>
									)}

									{postFullDetails?.furnished && (
										<Flexed direction="row" justify="space-between" align="center" gap={0.2}>
											<Flexed direction="row" align="center" gap={0.5}>
												<Text type="normal" isDarkTheme={_isDarkTheme}>
													Furnished
												</Text>
											</Flexed>
											<Text type="normal" fontWeight="bold" isDarkTheme={_isDarkTheme}>
												{postFullDetails?.furnished}
											</Text>
										</Flexed>
									)}

									{postFullDetails?.bathRooms && (
										<Flexed direction="row" justify="space-between" align="center" gap={0.2}>
											<Flexed direction="row" align="center" gap={0.5}>
												<Text type="normal" isDarkTheme={_isDarkTheme}>
													Bathrooms
												</Text>
											</Flexed>
											<Text type="normal" fontWeight="bold" isDarkTheme={_isDarkTheme}>
												{postFullDetails?.bathRooms}
											</Text>
										</Flexed>
									)}

									{postFullDetails?.areaUnit && (
										<Flexed direction="row" justify="space-between" align="center" gap={0.2}>
											<Flexed direction="row" align="center" gap={0.5}>
												<Text type="normal" isDarkTheme={_isDarkTheme}>
													Area unit{' '}
												</Text>
											</Flexed>
											<Text type="normal" fontWeight="bold" isDarkTheme={_isDarkTheme}>
												{postFullDetails?.areaUnit}
											</Text>
										</Flexed>
									)}
									{postFullDetails?.constructionState && (
										<Flexed direction="row" justify="space-between" align="center" gap={0.2}>
											<Flexed direction="row" align="center" gap={0.5}>
												<Text type="normal" isDarkTheme={_isDarkTheme}>
													Construction State{' '}
												</Text>
											</Flexed>
											<Text type="normal" fontWeight="bold" isDarkTheme={_isDarkTheme}>
												{postFullDetails?.constructionState}
											</Text>
										</Flexed>
									)}
									{postFullDetails?.storeys && (
										<Flexed direction="row" justify="space-between" align="center" gap={0.2}>
											<Flexed direction="row" align="center" gap={0.5}>
												<Text type="normal" isDarkTheme={_isDarkTheme}>
													No of storeys{' '}
												</Text>
											</Flexed>
											<Text type="normal" fontWeight="bold" isDarkTheme={_isDarkTheme}>
												{postFullDetails?.storeys}
											</Text>
										</Flexed>
									)}
									{postFullDetails?.year && (
										<Flexed direction="row" justify="space-between" align="center" gap={0.2}>
											<Flexed direction="row" align="center" gap={0.5}>
												<Text type="normal" isDarkTheme={_isDarkTheme}>
													Year{' '}
												</Text>
											</Flexed>
											<Text type="normal" fontWeight="bold" isDarkTheme={_isDarkTheme}>
												{postFullDetails?.year}
											</Text>
										</Flexed>
									)}
									{postFullDetails?.kmDriven && (
										<Flexed direction="row" justify="space-between" align="center" gap={0.2}>
											<Flexed direction="row" align="center" gap={0.5}>
												<Text type="normal" isDarkTheme={_isDarkTheme}>
													KM's driven{' '}
												</Text>
											</Flexed>
											<Text type="normal" fontWeight="bold" isDarkTheme={_isDarkTheme}>
												{postFullDetails?.kmDriven}
											</Text>
										</Flexed>
									)}
									{postFullDetails?.condition && (
										<Flexed direction="row" justify="space-between" align="center" gap={0.2}>
											<Flexed direction="row" align="center" gap={0.5}>
												<Text type="normal" isDarkTheme={_isDarkTheme}>
													Condition{' '}
												</Text>
											</Flexed>
											<Text type="normal" fontWeight="bold" isDarkTheme={_isDarkTheme}>
												{postFullDetails?.condition}
											</Text>
										</Flexed>
									)}
									{postFullDetails?.fuel && (
										<Flexed direction="row" justify="space-between" align="center" gap={0.2}>
											<Flexed direction="row" align="center" gap={0.5}>
												<Text type="normal" isDarkTheme={_isDarkTheme}>
													Fuel{' '}
												</Text>
											</Flexed>
											<Text type="normal" fontWeight="bold" isDarkTheme={_isDarkTheme}>
												{postFullDetails?.fuel}
											</Text>
										</Flexed>
									)}
									{postFullDetails?.registrationCity && (
										<Flexed direction="row" justify="space-between" align="center" gap={0.2}>
											<Flexed direction="row" align="center" gap={0.5}>
												<Text type="normal" isDarkTheme={_isDarkTheme}>
													Registration city{' '}
												</Text>
											</Flexed>
											<Text type="normal" fontWeight="bold" isDarkTheme={_isDarkTheme} style={{textAlign: 'right'}}>
												{postFullDetails?.registrationCity}
											</Text>
										</Flexed>
									)}
									{postFullDetails?.carDocuments && (
										<Flexed direction="row" justify="space-between" align="center" gap={0.2}>
											<Flexed direction="row" align="center" gap={0.5}>
												<Text type="normal" isDarkTheme={_isDarkTheme}>
													Car documents{' '}
												</Text>
											</Flexed>
											<Text type="normal" fontWeight="bold" isDarkTheme={_isDarkTheme}>
												{postFullDetails?.carDocuments}
											</Text>
										</Flexed>
									)}
									{postFullDetails?.assembly && (
										<Flexed direction="row" justify="space-between" align="center" gap={0.2}>
											<Flexed direction="row" align="center" gap={0.5}>
												<Text type="normal" isDarkTheme={_isDarkTheme}>
													Assembly{' '}
												</Text>
											</Flexed>
											<Text type="normal" fontWeight="bold" isDarkTheme={_isDarkTheme}>
												{postFullDetails?.assembly}
											</Text>
										</Flexed>
									)}
									{postFullDetails?.transmission && (
										<Flexed direction="row" justify="space-between" align="center" gap={0.2}>
											<Flexed direction="row" align="center" gap={0.5}>
												<Text type="normal" isDarkTheme={_isDarkTheme}>
													Transmission{' '}
												</Text>
											</Flexed>
											<Text type="normal" fontWeight="bold" isDarkTheme={_isDarkTheme}>
												{postFullDetails?.transmission}
											</Text>
										</Flexed>
									)}
									{postFullDetails?.downPayment && (
										<Flexed direction="row" justify="space-between" align="center" gap={0.2}>
											<Flexed direction="row" align="center" gap={0.5}>
												<Text type="normal" isDarkTheme={_isDarkTheme}>
													Down payment{' '}
												</Text>
											</Flexed>
											<Text type="normal" fontWeight="bold" isDarkTheme={_isDarkTheme}>
												{postFullDetails?.downPayment}
											</Text>
										</Flexed>
									)}
									{postFullDetails?.monthlyInstallment && (
										<Flexed direction="row" justify="space-between" align="center" gap={0.2}>
											<Flexed direction="row" align="center" gap={0.5}>
												<Text type="normal" isDarkTheme={_isDarkTheme}>
													Monthly Installment{' '}
												</Text>
											</Flexed>
											<Text type="normal" fontWeight="bold" isDarkTheme={_isDarkTheme}>
												{postFullDetails?.monthlyInstallment}
											</Text>
										</Flexed>
									)}
									{postFullDetails?.installmentPlan && (
										<Flexed direction="row" justify="space-between" align="center" gap={0.2}>
											<Flexed direction="row" align="center" gap={0.5}>
												<Text type="normal" isDarkTheme={_isDarkTheme}>
													Installment Plan{' '}
												</Text>
											</Flexed>
											<Text type="normal" fontWeight="bold" isDarkTheme={_isDarkTheme}>
												{postFullDetails?.installmentPlan}
											</Text>
										</Flexed>
									)}
									{postFullDetails?.registered && (
										<Flexed direction="row" justify="space-between" align="center" gap={0.2}>
											<Flexed direction="row" align="center" gap={0.5}>
												<Text type="normal" isDarkTheme={_isDarkTheme}>
													Registered{' '}
												</Text>
											</Flexed>
											<Text type="normal" fontWeight="bold" isDarkTheme={_isDarkTheme}>
												{postFullDetails?.registered}
											</Text>
										</Flexed>
									)}
									{postFullDetails?.deviceType && (
										<Flexed direction="row" justify="space-between" align="center" gap={0.2}>
											<Flexed direction="row" align="center" gap={0.5}>
												<Text type="normal" isDarkTheme={_isDarkTheme}>
													Device Type{' '}
												</Text>
											</Flexed>
											<Text type="normal" fontWeight="bold" isDarkTheme={_isDarkTheme}>
												{postFullDetails?.deviceType}
											</Text>
										</Flexed>
									)}
								</Grid>
							</Content>
						)}

						<Spacer height={1} />

						{postFullDetails != '' && (
							<Content>
								<Heading level={3} fontWeight={700} isDarkTheme={_isDarkTheme} margin={'0rem 0rem 1rem 0rem'}>
									Description
								</Heading>
								<Text style={{overflow: 'hidden', overflowWrap: 'anywhere'}}>
									{postFullDetails?.description.split('\n').map((paragraph: any, index: any) => (
										<Text type="normal" isDarkTheme={_isDarkTheme} key={index}>
											{paragraph}
										</Text>
									))}
								</Text>
							</Content>
						)}

						<Spacer height={1} />

						{features?.length > 0 && (
							<Content>
								<Heading level={3} fontWeight={700} isDarkTheme={_isDarkTheme} margin={'0rem 0rem 1rem 0rem'}>
									Features
								</Heading>
								<Flexed direction="row" align="center" gap={0.5} flexWrap="wrap">
									{features?.map((featuresName) => {
										return (
											<Features>
												<Text type="normal" isDarkTheme={_isDarkTheme}>
													{featuresName}
												</Text>
											</Features>
										)
									})}
								</Flexed>
							</Content>
						)}
						<Spacer height={2} />
						{relatedAds?.length > 0 && (
							<>
								<Divider />
								<Spacer height={2} />
								<PropertyRelatedAds relatedAds={relatedAds} />
							</>
						)}
						{postFullDetails && <TopBottomAdvertisement show="bottom" paidAdvertisement={paidAdvertisement} />}

						{windowSize?.width < 992 && postFullDetails && <RightAdvertisement paidAdvertisement={paidAdvertisement} />}
					</Col>

					<Col lg={4} lgOrder={2} order={1}>
						{_loginUserDetails?.id === postFullDetails?.customerId && !postFullDetails?.sold && postFullDetails?.activate && !postFullDetails?.isBlockedAd && potentialBuyers?.length ? (
							<div onClick={() => setPotentialBuyerModel(true)} style={{cursor: 'pointer'}}>
								<Text type="large" color={'blue'} fontWeight={700} isDarkTheme={_isDarkTheme} onClick={() => setPotentialBuyerModel(true)}>
									Potential Buyers for your ad
								</Text>
								<Spacer height={0.5} />
							</div>
						) : (
							''
						)}
						{postFullDetails != '' && (
							<Content>
								{_loginUserDetails?.id != postFullDetails?.customerId ? (
									<>
										<Flexed direction="row" align="center" gap={1}>
											<SeeProfile width={3.75} height={3.75} onClick={goToProfile}>
												{postFullDetails?.userPhoto ? (
													<Profile width={3.75} height={3.75} src={process.env.REACT_APP_IMAGE_URL + postFullDetails?.userPhoto} />
												) : (
													<Text fontSize={1.8} color="white" fontWeight={700}>
														{postFullDetails?.userName && postFullDetails?.userName[0].toUpperCase()}
													</Text>
												)}
											</SeeProfile>
											<Flexed onClick={goToProfile} pointer>
												<Text type="normal" fontWeight="bold" isDarkTheme={_isDarkTheme}>
													{postFullDetails?.userName}
												</Text>

												<Text type="normal" isDarkTheme={_isDarkTheme}>
													Member since {moment(postFullDetails?.customerIdFkey?.createdAt).format('MMM YYYY')}
												</Text>

												<Flexed direction="row" pointer align="center" margin="0.2rem 0rem 0rem 0rem">
													<Text type="normal" lineHeight="0" color={'red'} fontWeight="500">
														See profile
													</Text>
													<FaAngleRight color={palette.red} />
												</Flexed>
											</Flexed>
										</Flexed>

										{postFullDetails?.showNoInAds && (
											<>
												<Spacer height={1} />
												<Phone
													isDarkTheme={_isDarkTheme}
													onClick={() => {
														if (_loginUserDetails?.id) {
															if (!showPhone) {
																setLoadingPhone(true)
																setShowPhone(true)
																setTimeout(() => {
																	setLoadingPhone(false)
																}, 300)
																adsStatics(postFullDetails, false, true, false)
															} else if (showPhone) {
																handleCall(postFullDetails?.phone)
															}
														} else {
															setOpenAuthModal(true)
														}
													}}>
													<FiPhone color={palette.red} />
													{showPhone ? <> {loadingPhone ? <LoadingSpinner onLoad={() => setLoadingPhone(false)} /> : postFullDetails?.phone}</> : 'Show phone number'}
												</Phone>
											</>
										)}

										<Spacer height={1} />

										<Chat
											isDarkTheme={_isDarkTheme}
											onClick={() => {
												if (_loginUserDetails?.id) {
													setClickOnChat(true)
													adsStatics(postFullDetails, false, false, true)
													router.navigate(
														_loginUserDetails?.id && _loginUserDetails?.id == postFullDetails?.customerId
															? `/chat`
															: `/chat?id=${postFullDetails?.customerId}&isBlocked=${postFullDetails?.isBlocked}&addId=${postFullDetails?.id}`
													)
												} else {
													setOpenAuthModal(true)
												}
											}}>
											<RiChat1Line fontSize={20} />
											Chat
										</Chat>
									</>
								) : (
									<>
										<Flexed flexWrap="wrap" direction="row" gap={0.8} align="center" margin="0rem 0rem 0.5rem 0rem">
											<Flexed direction="row" gap={0.3} align="center">
												<AiOutlineEye color={_isDarkTheme ? palette.light_gray : palette.dark_gray} />
												<Text type="small" fontWeight={700} isDarkTheme={_isDarkTheme}>
													Views: {postFullDetails?.views}
												</Text>
											</Flexed>
											<Flexed direction="row" gap={0.3} align="center">
												<AiOutlineEye color={_isDarkTheme ? palette.light_gray : palette.dark_gray} />
												<Text type="small" fontWeight={700} isDarkTheme={_isDarkTheme}>
													Estimated Views: {postFullDetails?.estimatedViews ? formatNumber(postFullDetails?.estimatedViews) : 0}
												</Text>
											</Flexed>

											<Flexed direction="row" gap={0.3} align="center">
												<FiPhone color={_isDarkTheme ? palette.light_gray : palette.dark_gray} />
												<Text type="small" fontWeight={700} isDarkTheme={_isDarkTheme}>
													Tel: {postFullDetails?.tellUsers}
												</Text>
											</Flexed>

											<Flexed direction="row" gap={0.3} align="center">
												<AiFillHeart color={_isDarkTheme ? palette.light_gray : palette.dark_gray} />
												<Text type="small" fontWeight={700} isDarkTheme={_isDarkTheme}>
													Likes: {postFullDetails?.likes}
												</Text>
											</Flexed>

											<Flexed direction="row" gap={0.3} align="center">
												<RiChat1Line color={_isDarkTheme ? palette.light_gray : palette.dark_gray} />
												<Text type="small" fontWeight={700} isDarkTheme={_isDarkTheme}>
													Chats: {postFullDetails?.chatUsers}
												</Text>
											</Flexed>
										</Flexed>

										{postFullDetails?.sold && (
											<SoldWrapper margin="1rem 0rem 0rem 0rem" direction="row" align="center" justify="space-between" gap={0.5}>
												<div>
													<Text type="normal" margin="0rem 0rem 0.2rem 0rem" fontWeight={600}>
														Sold
													</Text>
													<Text type="normal">You marked this ad as sold on</Text>
													<Text type="normal">{moment(postFullDetails?.updatedAt).format('DD/MM/YYYY')}</Text>
												</div>
												<img src="/images/markSold.png" alt="markSold" />
											</SoldWrapper>
										)}
										{!postFullDetails?.activate && (
											<WarningWrapper margin="1rem 0rem 0rem 0rem" direction="row" align="center" justify="space-between" gap={0.5}>
												<div>
													<Text type="normal" margin="0rem 0rem 0.2rem 0rem" fontWeight={600}>
														Your Ad is disabled
													</Text>
													<Text type="normal" margin="0rem 0rem 0.5rem 0rem">
														You deactivated this ad on {moment(postFullDetails?.updatedAt).format('DD/MM/YYYY')}.
													</Text>
													<Button
														label="Republish Ad"
														type="cancel"
														ifClicked={() => {
															activateAndDeactivatePost(!postFullDetails?.activate, postFullDetails?.id)
														}}
													/>
												</div>
												<img src="/images/icons/iconWarning.svg" alt="iconWarning" />
											</WarningWrapper>
										)}
										{postFullDetails?.isBlockedAd && (
											<BlockedWrapper margin="1rem 0rem 0rem 0rem" direction="row" align="center" justify="space-between" gap={0.5}>
												<div>
													<Text type="normal" margin="0rem 0rem 0.2rem 0rem" fontWeight={600}>
														Your Ad is Blocked
													</Text>
													<Text type="normal" margin="0rem 0rem 0.5rem 0rem">
														This Ad is blocked by admin on {moment(postFullDetails?.updatedAt).format('DD/MM/YYYY')}.
													</Text>
												</div>
												<GoBlocked fontSize={'5rem'} color={palette.danger} />
											</BlockedWrapper>
										)}
									</>
								)}
							</Content>
						)}

						<Spacer height={1} />

						{postFullDetails != '' && (
							<Content>
								<Heading level={3} fontWeight={700} isDarkTheme={_isDarkTheme} margin={'0rem 0rem 1rem 0rem'}>
									Location
								</Heading>
								<Flexed direction="row" gap={0.5}>
									<div>
										<HiOutlineLocationMarker cursor="pointer" fontSize={18} color={_isDarkTheme ? palette.light_gray : palette.dark_gray} />
									</div>
									<LocationText type="normal" isDarkTheme={_isDarkTheme}>
										{address}
									</LocationText>
								</Flexed>
							</Content>
						)}
						{postFullDetails != '' && (
							<Flexed margin="1rem 0rem" justify="space-between" direction="row" align="center" gap={0.5}>
								<Text type="normal" isDarkTheme={_isDarkTheme}>
									AD ID {postFullDetails?.id}
								</Text>
								{_loginUserDetails?.id != postFullDetails?.customerId && (
									<Report
										onClick={() => {
											if (_loginUserDetails?.id) {
												setReportAds(true)
											} else {
												setOpenAuthModal(true)
											}
										}}
										direction="row"
										align="center"
										gap={0.5}>
										<RiFlag2Fill cursor="pointer" fontSize={18} color={_isDarkTheme ? palette.light_gray : palette.dark_gray} />
										<Text type="small" fontWeight="bold" isDarkTheme={_isDarkTheme}>
											Report this ad
										</Text>
									</Report>
								)}
							</Flexed>
						)}
						{windowSize?.width > 992 && postFullDetails && <RightAdvertisement paidAdvertisement={paidAdvertisement} />}
					</Col>
					{postFullDetails === '' && (
						<Col order={3}>
							<Spacer height={5} />
							{!isLoading ? (
								<Text type="small" isCentered isDarkTheme={_isDarkTheme}>
									No post available
								</Text>
							) : (
								<Text type="small" isCentered isDarkTheme={_isDarkTheme}>
									The post is loading...
								</Text>
							)}
						</Col>
					)}
				</Row>
			</Wrapper>

			{openShareModal && (
				<SocialShareModal
					onCloseModal={() => {
						setOpenShareModal(false)
					}}
				/>
			)}

			{reportAds && (
				<AdsReport
					postOwnerId={postFullDetails?.customerId}
					open={reportAds}
					onCloseModal={() => {
						setReportAds(false)
					}}
					setIsLoading={setIsLoading}
				/>
			)}
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
			<AuthModal
				open={openAuthModal}
				onCloseModal={() => {
					setOpenAuthModal(false)
				}}
			/>
			{lightBoxOpen && (
				<Lightbox
					mainSrc={process.env.REACT_APP_IMAGE_URL + postImage[photoIndex]}
					nextSrc={postImage?.length > 1 ? postImage[(photoIndex + 1) % postImage?.length] : false}
					prevSrc={postImage?.length > 1 ? postImage[(photoIndex + postImage?.length - 1) % postImage?.length] : false}
					onCloseRequest={() => setLightBoxOpen(false)}
					onMovePrevRequest={() => setPhotoIndex((photoIndex + postImage?.length - 1) % postImage?.length)}
					onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % postImage?.length)}
				/>
			)}

			{potentialBuyerModel && (
				<PotentialBuyersModel
					open={potentialBuyerModel}
					onCloseModal={() => {
						setPotentialBuyerModel(false)
					}}
					title={`Potential buyers for your ad`}
					label={`Choose a buyer to sell your ad by contacting or chat with them`}
					users={potentialBuyers}
					adId={postFullDetails?.id}
					onClick={() => {
						setPotentialBuyerModel(false)
					}}
				/>
			)}
		</>
	)
}

const StickyWrapper = styled.div<any>`
	position: -webkit-sticky; /* Safari */
	position: -webkit-sticky;
	position: sticky;
	top: 0rem;
	width: 100%;
	background: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
	z-index: 6;
	display: ${({scroll}) => (scroll > 300 ? '' : 'none')};
	padding: 1rem 0rem;
`

const StickyDetailFlex = styled(Flexed)`
	justify-content: flex-start;
	margin-bottom: 1rem;
	${media.md`
		justify-content: flex-end;
		margin-bottom:0rem;
	`}
`

const Wrapper = styled(Container)`
	min-height: calc(100vh - 70px);
	${media.md`min-height: calc(100vh - 136px);`}
`

const Images = styled.img`
	max-height: 300px;
	min-height: 300px;
	width: 100%;
	object-fit: cover;
	border-radius: 0.5rem;
	overflow: hidden;
	${media.sm`
		max-height: 500px;
		min-height: 500px;
	`}
	${media.md`
		max-height: 500px;
		min-height: 500px;
	`}
	${media.lg`
		max-height: 500px;
		min-height: 500px;
	`}
	${media.xl`
		max-height: 600px;
		min-height: 600px;
	`}
`

const Content = styled.div`
	border: 1px solid ${palette.light_gray};
	border-radius: 0.5rem;
	padding: 1.5rem 1rem 1rem;
`

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	row-gap: 1rem;
	column-gap: 2rem;
	${media.md`grid-template-columns: 1fr 1fr;`}
`

const Features = styled.div`
	border: 1px solid ${palette.light_gray};
	border-radius: 0.5rem;
	padding: 0.5rem 0.8rem;
`

const Report = styled(Flexed)`
	cursor: pointer;
	&:hover div {
		color: ${palette.red} !important;
	}
	&:hover svg {
		color: ${palette.red} !important;
	}
`

const SeeProfile = styled(Flexed)`
	position: relative;
	width: ${({width}) => width}rem;
	height: ${({height}) => height}rem;
	border-radius: 0.5rem;
	overflow: hidden;
	background: ${palette.red};
	color: ${palette.white};
	display: flex;
	justify-content: center;
	cursor: pointer;
	align-items: center;
`

const Profile = styled.div<any>`
	background-image: ${({src}) => (src ? `url(${src})` : palette.light_gray)};
	background-size: cover;
	background-position: center;
	width: ${({width}) => width}rem;
	height: ${({height}) => height}rem;
	border-radius: 0.5rem;
`

const Phone = styled.div<any>`
	background: ${palette.red_light};
	border-radius: 0.3rem;
	padding: 0.5rem 0.8rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	justify-content: center;
	font-size: 1rem;
	// font-weight: 600;
	font-family: 'Montserrat', sans-serif;
	color: ${palette.black};
	cursor: pointer;
	&:hover {
		background: ${palette.red_light_hover};
	}
`

const Chat = styled(Phone)<any>`
	background: ${({isDarkTheme}) => (isDarkTheme ? palette.green_light_hover : palette.green_light)};
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.white : palette.black)};
	& svg {
		color: ${({isDarkTheme}) => (isDarkTheme ? palette.green : palette.green)};
	}
	&:hover {
		background: ${({isDarkTheme}) => (isDarkTheme ? palette.green : palette.green_light_hover)};
		color: ${({isDarkTheme}) => (isDarkTheme ? palette.white : palette.black)};
	}
	&:hover svg {
		color: ${({isDarkTheme}) => (isDarkTheme ? palette.green_light : palette.green)};
	}
`
const LoadingSpinner = styled.div`
	border: 2.5px solid rgba(0, 0, 0, 0.1);
	border-top: 2.5px solid ${palette.light_gray};
	border-radius: 50%;
	width: 20px;
	height: 20px;
	animation: spin 1s linear infinite;

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`

const SoldWrapper = styled(Flexed)`
	padding: 1rem;
	background: #e5fcfb;
	border-radius: 0.4rem;
	& img {
		width: 4rem;
	}
`

const WarningWrapper = styled(SoldWrapper)`
	background: #fffbef;
`

const BlockedWrapper = styled(SoldWrapper)`
	background: #f9766d;
`

const Icons = styled.svg<any>`
	height: 1rem;
	fill: ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
`

const LocationText = styled(Text)`
	overflow: hidden;
	overflow-wrap: anywhere;
`

const AddressAndTime = styled(Flexed)`
	gap: 1rem;
	${media.sm`flex-direction: row; gap 1.5rem;`}
`

export default PropertyDetails
