/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * AuthModal Component
 *
 * @component
 * @description Modal for authentication (login).
 *
 * @param {Object} props - Component props.
 * @param {boolean} props.open - Flag to control the visibility of the modal.
 * @param {function} props.onCloseModal - Callback function to handle modal close.
 */

import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {Text, Spacer, Flexed} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {BsXLg} from 'react-icons/bs'
import {useSelector} from 'react-redux'
import LogIn from '../../pages/customerAuth/Login'
import {media} from 'styled-bootstrap-grid'

interface ICategoriesModalProps {
	open: boolean
	onCloseModal: any
	onClick?: any
}

interface IProps {
	isDarkTheme: boolean
}

const AuthModal = ({open, onCloseModal}: ICategoriesModalProps) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	return (
		<>
			<Modal
				open={open}
				center
				onClose={() => {
					onCloseModal()
				}}
				classNames={{
					overlay: 'customOverlay',
					modal: 'blockModal'
				}}
				showCloseIcon={false}
				// @ts-ignore
				styles={{zIndex: '2'}}>
				<ModalWrapper isDarkTheme={_isDarkTheme}>
					<Head direction="row" align="center" justify="space-between">
						<Text type="large" lineHeight="1.438" fontWeight="700" isDarkTheme={_isDarkTheme}></Text>
						<CrossIcon
							isDarkTheme={_isDarkTheme}
							onClick={() => {
								onCloseModal()
							}}
						/>
					</Head>
					<Body>
						<LogIn formModal={true} onCloseModal={onCloseModal} />
					</Body>
				</ModalWrapper>
			</Modal>
		</>
	)
}

const ModalWrapper = styled.div<IProps>`
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	padding: 1rem;
`

const Head = styled(Flexed)`
	padding: 1rem;
	${media.sm`
    width: 400px;
	`}
	${media.md`
    width: 400px;
	`}
`

const Body = styled.div`
	padding: 0rem 1rem 1rem;
`

const CrossIcon = styled(BsXLg)<IProps>`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 1.3rem;
`

export default AuthModal
