/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CreatePost Component
 *
 * This component provides a UI for users to post advertisements. It includes a multi-step form
 * where users can select main categories, type categories, and sub-type categories for their post.
 * The component also handles the interaction with the API to fetch and display categories.
 *
 * @component
 *
 * @param {Object} props - The properties passed to the CreatePost component.
 * @param {boolean} props.setIsLoading - A function to set the loading state.
 *
 */
import {useEffect, useState, ChangeEvent} from 'react'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import 'react-responsive-modal/styles.css'
import {Spacer, Flexed, Text, Heading, Divider} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {BsXLg} from 'react-icons/bs'
import {useSelector, useDispatch} from 'react-redux'
import InputField, {Mandatory} from '../../components/common/InputField'
import Button from '../../components/common/Button'
import {MdOutlineCloudUpload} from 'react-icons/md'
import {api} from '../../components/api/callAxios'
import {toast} from 'react-toastify'
import {Link, useNavigate} from 'react-router-dom'
import DropDown from '../../components/common/DropDown'
import InputSelect from '../../components/common/InputSelect'
import {
	_landAndPlotsAttributes,
	_housesSale,
	_housesRent,
	_apartmentsAndFlats,
	_shopsOfficesCommercialSpace,
	_portionsAndFloors,
	_rooms,
	_carsAccessories,
	_spareParts,
	_vehiclesCondition,
	_cars,
	_carsInstallments,
	_tabletsCondition,
	_smartWatchesCondition,
	_chargingCablesTypes,
	_chargersTypes,
	_screenTypes,
	_headPhonesTypes,
	_coverCases
} from '../../constants/listingAttributes'
import InputLocationForCreateForm from '../../components/common/InputLocationForCreateForm'
import {currencyFormate, verifyJSON} from '../../constants/commonFunctions'
import {adIdForFeaturedAd} from '../../actions/authActions'
import RegistationCityField from '../../components/common/RegistationCityField'
import moment from 'moment'
import {doGenerateAiResponse} from '../../components/api/apis'
import Select from 'react-select'
import AlertModel from '../../components/modals/AlertModel'
import PotentialBuyersModel from '../../components/modals/PotentialBuyersModel'
import {formatNumber} from '../../utils'

interface IProps {
	isDarkTheme: boolean
}

const CreatePostForm = ({seletedMainCategory, seletedTypeCategory, seletedSubTypeCategory, clear, setIsLoading, editPostDetails}: any) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	let _navigate = useNavigate()
	const _dispatch = useDispatch()
	const [postId, setPostId] = useState(editPostDetails?.id ? editPostDetails?.id : '')
	const [adTitle, setAdTitle] = useState(editPostDetails?.title ? editPostDetails?.title : '')
	const [description, setDescription] = useState(editPostDetails?.description ? editPostDetails?.description : '')
	const [price, setPrice] = useState(editPostDetails?.price ? editPostDetails?.price : '')
	const [area, setArea] = useState(editPostDetails?.area ? editPostDetails?.area : '')
	const [userName, setUserName] = useState(editPostDetails?.userName ? editPostDetails?.userName : _loginUserDetails?.fullname ? _loginUserDetails?.fullname : _loginUserDetails?.firstname)
	const [userImage, setUserImage] = useState(editPostDetails?.userPhoto ? editPostDetails?.userPhoto : _loginUserDetails?.photo)
	const [newImage, setNewImage] = useState<string[]>([])
	const [location, setLocation] = useState(editPostDetails?.location ? editPostDetails?.location : '')
	const [phone, setPhone] = useState(editPostDetails?.phone ? editPostDetails?.phone : _loginUserDetails?.phone)
	const [showPhoneNoInAds, setShowPhoneNoInAds] = useState(editPostDetails?.id ? editPostDetails?.showNoInAds : _loginUserDetails?.showNoInAds)
	const [registrationCity, setRegistrationCity] = useState(editPostDetails?.registrationCity ? editPostDetails?.registrationCity : '')
	const [downPayment, setDownPayment] = useState(editPostDetails?.downPayment ? editPostDetails?.downPayment : '')
	const [monthlyInstallment, setMonthlyInstallment] = useState(editPostDetails?.monthlyInstallment ? editPostDetails?.monthlyInstallment : '')
	const [year, setYear] = useState(editPostDetails?.year ? editPostDetails?.year : '')
	const [model, setModel] = useState(editPostDetails?.model ? editPostDetails?.model : '')
	const [kmDriven, setKmDriven] = useState(editPostDetails?.kmDriven ? editPostDetails?.kmDriven : '')
	const [attributes, setAttributes] = useState<any>(null)

	const [selectedType, setSelectedType] = useState<any>(editPostDetails?.type ? editPostDetails?.type : '')
	const [selectedDeviceType, setSelectedDeviceType] = useState<any>(editPostDetails?.deviceType ? editPostDetails?.deviceType : '')
	const [selectedFeatures, setSelectedFeatures] = useState<any>([])
	const [selectedAreaUnit, setSelectedAreaUnit] = useState<any>(editPostDetails?.areaUnit ? editPostDetails?.areaUnit : '')
	const [selectedFurnished, setSelectedFurnished] = useState<any>(editPostDetails?.furnished ? editPostDetails?.furnished : '')
	const [selectedStoreys, setSelectedStoreys] = useState<any>(editPostDetails?.storeys ? editPostDetails?.storeys : '')
	const [selectedConstructionState, setSelectedConstructionState] = useState<any>(editPostDetails?.constructionState ? editPostDetails?.constructionState : '')
	const [selectedFuel, setSelectedFuel] = useState<any>(editPostDetails?.fuel ? editPostDetails?.fuel : '')
	const [selectedCondition, setSelectedCondition] = useState<any>(editPostDetails?.condition ? editPostDetails?.condition : '')
	const [selectedTransmission, setSelectedTransmission] = useState<any>(editPostDetails?.transmission ? editPostDetails?.transmission : '')
	const [selectedRegistered, setSelectedRegistered] = useState<any>(editPostDetails?.registered ? editPostDetails?.registered : '')
	const [selectedAssembly, setSelectedAssembly] = useState<any>(editPostDetails?.assembly ? editPostDetails?.assembly : '')
	const [selectedCarDocuments, setSelectedCarDocuments] = useState<any>(editPostDetails?.carDocuments ? editPostDetails?.carDocuments : '')

	const [bedRooms, setBedRooms] = useState(editPostDetails?.bedRooms ? editPostDetails?.bedRooms : '')
	const [bathRooms, setBathRooms] = useState(editPostDetails?.bathRooms ? editPostDetails?.bathRooms : '')
	const [floorLevel, setFloorLevel] = useState(editPostDetails?.floorLevel ? editPostDetails?.floorLevel : '')
	const [make, setMake] = useState(editPostDetails?.brand ? editPostDetails?.brand : '')
	const [installmentPlan, setInstallmentPlan] = useState(editPostDetails?.installmentPlan ? editPostDetails?.installmentPlan : '')
	const [useAi, setUseAI] = useState(false)
	const [imageContentForAI, setImagesContentForAI] = useState<string[]>([])
	const [loadMoreConditions, setLoadMoreConditions] = useState<string[]>([])
	const [estimatedViews, setEstimatedViews] = useState<any>(0)
	const [estimatedViewsModel, setEstimatedViewsModel] = useState<any>(false)
	const [verifyImageResponse, setVerifyImageResponse] = useState<any>('')
	const [verifyImageModel, setVerifyImageModel] = useState<any>(false)
	const [potentialBuyerModel, setPotentialBuyerModel] = useState<any>(false)

	const [bedRoomsOption, setBedRoomsOption] = useState([
		{value: '1', label: '1'},
		{value: '2', label: '2'},
		{value: '3', label: '3'},
		{value: '4', label: '4'},
		{value: '5', label: '5'},
		{value: '6+', label: '6+'},
		{value: 'studio', label: 'Studio'}
	])
	const [bathRoomsOption, setBathRoomsOption] = useState([
		{value: '1', label: '1'},
		{value: '2', label: '2'},
		{value: '3', label: '3'},
		{value: '4', label: '4'},
		{value: '5', label: '5'},
		{value: '6', label: '6'},
		{value: '7+', label: '7+'}
	])
	const [floorLevelOption, setFloorLevelOption] = useState([
		// { value: '0', label: '0' },
		{value: '1', label: '1'},
		{value: '2', label: '2'},
		{value: '3', label: '3'},
		{value: '4', label: '4'},
		{value: '5', label: '5'},
		{value: '6', label: '6'},
		{value: '7+', label: '7+'},
		{value: 'ground', label: 'Ground'}
	])
	const [makeOption, setMakeOption] = useState([
		{value: 'Adam', label: 'Adam'},
		{value: 'Audi', label: 'Audi'},
		{value: 'BAIC', label: 'BAIC'},
		{value: 'Bentley', label: 'Bentley'},
		{value: 'BMW', label: 'BMW'},
		{value: 'Buick', label: 'Buick'},
		{value: 'Cadilac', label: 'Cadilac'},
		{value: 'Changan', label: 'Changan'},
		{value: 'Chery', label: 'Chery'},
		{value: 'Chevrolet', label: 'Chevrolet'},
		{value: 'Chrysler', label: 'Chrysler'},
		{value: 'Classic & Antiques', label: 'Classic & Antiques'},
		{value: 'Daewoo', label: 'Daewoo'},
		{value: 'Daihatsu', label: 'Daihatsu'},
		{value: 'Datsun', label: 'Datsun'},
		{value: 'DFSK', label: 'DFSK'},
		{value: 'Dodge', label: 'Dodge'},
		{value: 'Dongfeng', label: 'Dongfeng'},
		{value: 'FAW', label: 'FAW'},
		{value: 'Fiat', label: 'Fiat'},
		{value: 'Ford', label: 'Ford'},
		{value: 'GMC', label: 'GMC'},
		{value: 'Haval', label: 'Haval'},
		{value: 'Hino', label: 'Hino'},
		{value: 'Honda', label: 'Honda'},
		{value: 'Hummer', label: 'Hummer'},
		{value: 'Hyundai', label: 'Hyundai'},
		{value: 'Isuzu', label: 'Isuzu'},
		{value: 'JAC', label: 'JAC'},
		{value: 'Jaguar', label: 'Jaguar'},
		{value: 'Jeep', label: 'Jeep'},
		{value: 'JW Fortland', label: 'JW Fortland'},
		{value: 'KIA', label: 'KIA'},
		{value: 'Land Rover', label: 'Land Rover'},
		{value: 'Land Rover', label: 'Land Rover'},
		{value: 'Lexus', label: 'Lexus'},
		{value: 'Mazda', label: 'Mazda'},
		{value: 'Mercedes', label: 'Mercedes'},
		{value: 'MG', label: 'MG'},
		{value: 'Mitsubishi', label: 'Mitsubishi'},
		{value: 'Nissan', label: 'Nissan'},
		{value: 'Peugeot', label: 'Peugeot'},
		{value: 'Porsche', label: 'Porsche'},
		{value: 'Prince', label: 'Prince'},
		{value: 'Proton', label: 'Proton'},
		{value: 'Range Rover', label: 'Range Rover'},
		{value: 'Renault', label: 'Renault'},
		{value: 'Suzuki', label: 'Suzuki'},
		{value: 'Ssangyong', label: 'Ssangyong'},
		{value: 'Subaru', label: 'Subaru'},
		{value: 'Toyota', label: 'Toyota'},
		{value: 'United', label: 'United'},
		{value: 'Volkswagen', label: 'Volkswagen'}
	])
	const [tabletsBrandOption, setTabletsBrandOption] = useState([
		{value: 'Apple', label: 'Apple'},
		{value: 'Samsung', label: 'Samsung'},
		{value: 'Lenovo', label: 'Lenovo'},
		{value: 'Huawei', label: 'Huawei'},
		{value: 'Dany Tabs', label: 'Dany Tabs'},
		{value: 'Amazon', label: 'Amazon'},
		{value: 'Asus', label: 'Asus'},
		{value: 'Dell', label: 'Dell'},
		{value: 'Alcatel', label: 'Alcatel'},
		{value: 'Huion', label: 'Huion'},
		{value: 'Wacom', label: 'Wacom'},
		{value: 'Acer', label: 'Acer'},
		{value: 'Honor', label: 'Honor'},
		{value: 'RCA', label: 'RCA'},
		{value: 'Mione', label: 'Mione'},
		{value: 'Q Tabs', label: 'Q Tabs'},
		{value: 'Other Tablets', label: 'Other Tablets'}
	])
	const [smartWatchOption, setSmartWatchOption] = useState([
		{value: 'Apple', label: 'Apple'},
		{value: 'Samsung', label: 'Samsung'},
		{value: 'Mi', label: 'Mi'},
		{value: 'Huawei', label: 'Huawei'},
		{value: 'Haylou', label: 'Haylou'},
		{value: 'Amazfit', label: 'Amazfit'},
		{value: 'Realme', label: 'Realme'},
		{value: 'Garmin', label: 'Garmin'},
		{value: 'Fitbit', label: 'Fitbit'},
		{value: 'Honor', label: 'Honor'},
		{value: 'Oppo', label: 'Oppo'},
		{value: 'Lenovo', label: 'Lenovo'},
		{value: 'Others', label: 'Others'}
	])
	const [mobilePhoneOption, setMobilePhoneOption] = useState([
		{value: 'Acer', label: 'Acer'},
		{value: 'Alcatel', label: 'Alcatel'},
		{value: 'Apple', label: 'Apple iPhone'},
		{value: 'Asus', label: 'Asus'},
		{value: 'BlackBerry', label: 'BlackBerry'},
		{value: 'Calme', label: 'Calme'},
		{value: 'Club', label: 'Club'},
		{value: "G'Five", label: "G'Five"},
		{value: 'Google', label: 'Google'},
		{value: 'Gright', label: 'Gright'},
		{value: 'Haier', label: 'Haier'},
		{value: 'Honor', label: 'Honor'},
		{value: 'HTC', label: 'HTC'},
		{value: 'Huawei', label: 'Huawei'},
		{value: 'iNew', label: 'iNew'},
		{value: 'Infinix', label: 'Infinix'},
		{value: 'Lava', label: 'Lava'},
		{value: 'Lenovo', label: 'Lenovo'},
		{value: 'LG', label: 'LG'},
		{value: 'Meizu', label: 'Meizu'},
		{value: 'Mobilink JazzX', label: 'Mobilink JazzX'},
		{value: 'Motorola', label: 'Motorola'},
		{value: 'Nokia', label: 'Nokia'},
		{value: 'One Plus', label: 'One Plus'},
		{value: 'OPPO', label: 'OPPO'},
		{value: 'Panasonic', label: 'Panasonic'},
		{value: 'QMobile', label: 'QMobile'},
		{value: 'Realme', label: 'Realme'},
		{value: 'RIVO', label: 'RIVO'},
		{value: 'Samsung Mobile', label: 'Samsung Mobile'},
		{value: 'Sony', label: 'Sony'},
		{value: 'Sony Ericsson', label: 'Sony Ericsson'},
		{value: 'Tecno', label: 'Tecno'},
		{value: 'Vivo', label: 'Vivo'},
		{value: 'VOICE', label: 'VOICE'},
		{value: 'Xiaomi', label: 'Xiaomi'},
		{value: 'Zte', label: 'Zte'},
		{value: 'Other Mobiles', label: 'Other Mobiles'}
	])
	const [installmentPlanOption, setInstallmentPlanOption] = useState([
		{value: 'Flexible', label: 'Flexible'},
		{value: '1 Year', label: '1 Year'},
		{value: '2 Years', label: '2 Years'},
		{value: '3 Years', label: '3 Years'},
		{value: '4 Years', label: '4 Years'},
		{value: '5 Years', label: '5 Years'},
		{value: '6 Years', label: '6 Years'},
		{value: '7 Years', label: '7 Years'}
	])

	const [map, setMap] = useState(null)
	const [clat, setCLat] = useState<any>('')
	const [clng, setCLng] = useState<any>('')

	const [adTitleError, setAdTitleError] = useState('')
	const [areaError, setAreaError] = useState('')
	const [descriptionError, setDescriptionError] = useState('')
	const [priceError, setPriceError] = useState('')
	const [userNameError, setUserNameError] = useState('')
	const [phoneError, setPhoneError] = useState('')
	const [imageError, setImageError] = useState('')
	const [locationError, setLocationError] = useState('')
	const [modelError, setModelError] = useState('')
	const [registrationCityError, setRegistrationCityError] = useState('')
	const [downPaymentError, setDownPaymentError] = useState('')
	const [monthlyInstallmentError, setMonthlyInstallmentError] = useState('')
	const [yearError, setYearError] = useState('')
	const [kmDrivenError, setKmDrivenError] = useState('')

	const [typeError, setTypeError] = useState('')
	const [deviceTypeError, setDeviceTypeError] = useState<any>('')
	const [featuresError, setFeaturesError] = useState('')
	const [areaUnitError, setAreaUnitError] = useState('')

	const [furnishedError, setFurnishedError] = useState<any>('')
	const [storeysError, setStoreysError] = useState<any>('')
	const [constructionStateError, setConstructionStateError] = useState<any>('')
	const [conditionError, setConditionError] = useState<any>('')
	const [fuelError, setFuelError] = useState<any>('')
	const [transmissionError, setTransmissionError] = useState<any>('')
	const [registeredError, setRegisteredError] = useState<any>('')
	const [assemblyError, setAssemblyError] = useState<any>('')
	const [carDocumentsError, setCarDocumentsError] = useState<any>('')

	const [bedRoomsError, setBedRoomsError] = useState('')
	const [bathRoomsError, setBathRoomsError] = useState('')
	const [floorLevelError, setFloorLevelError] = useState('')
	const [makeError, setMakeError] = useState('')
	const [installmentPlanError, setInstallmentPlanError] = useState('')
	const [potentialBuyers, setPotentialBuyers] = useState<any>([])
	const [createdAd, setCreatedAd] = useState<any>('')

	const selectedBedRoomsOptionValue = async (val: any) => {
		setBedRooms(val)
		setBedRoomsError('')
	}

	const selectedBathRoomsOptionValue = async (val: any) => {
		setBathRooms(val)
		setBathRoomsError('')
	}

	const selectedFloorLevelOptionValue = async (val: any) => {
		setFloorLevel(val)
		setFloorLevelError('')
	}

	const selectedMakeOptionValue = async (val: any) => {
		setMake(val)
		setMakeError('')
	}

	const selectedInstallmentPlanOptionValue = async (val: any) => {
		setInstallmentPlan(val)
		setInstallmentPlanError('')
	}

	useEffect(() => {
		setIsLoading(true)
		if (seletedSubTypeCategory?.title) {
			setAttributes(handleAttributes(seletedSubTypeCategory?.title))
			setLoadMoreConditions(handleAttributes(seletedSubTypeCategory?.title))
		} else {
			setAttributes(handleAttributes(seletedTypeCategory?.title))
			setLoadMoreConditions(handleAttributes(seletedTypeCategory?.title))
		}
		setIsLoading(false)
	}, [])

	useEffect(() => {
		if (editPostDetails != '' && editPostDetails != undefined) {
			let _photos: any = verifyJSON(editPostDetails?.photos) ? JSON.parse(editPostDetails?.photos) : editPostDetails?.photos
			setNewImage(_photos)
			if (editPostDetails?.features) {
				let _postFeatures: any = verifyJSON(editPostDetails?.features) ? JSON.parse(editPostDetails?.features) : editPostDetails?.features
				setSelectedFeatures(_postFeatures)
			}
			if (seletedSubTypeCategory?.title) {
				setAttributes(handleAttributes(seletedSubTypeCategory?.title))
				setLoadMoreConditions(handleAttributes(seletedSubTypeCategory?.title))
			} else {
				setAttributes(handleAttributes(seletedTypeCategory?.title))
				setLoadMoreConditions(handleAttributes(seletedTypeCategory?.title))
			}
		}
	}, [editPostDetails])

	const handleAttributes = (type: any) => {
		switch (type) {
			case 'Land & Plots':
				return _landAndPlotsAttributes
			case 'Houses for Sale':
				return _housesSale
			case 'Houses for Rent':
				return _housesRent
			case 'Apartments & Flats':
				return _apartmentsAndFlats
			case 'Shops - Offices - Commercial Space':
				return _shopsOfficesCommercialSpace
			case 'Portions & Floors':
				return _portionsAndFloors
			case 'Roommates & Paying Guests':
				return _rooms
			case 'Rooms':
				return _rooms
			case 'Cars':
				return _cars
			case 'Cars on Installments':
				return _carsInstallments
			case 'Cars Accessories':
				return _carsAccessories
			case 'Spare Parts':
				return _spareParts
			case 'Buses, Vans & Trucks':
				return _vehiclesCondition
			case 'Rickshaw & Chingchi':
				return _vehiclesCondition
			case 'Other Vehicles':
				return _vehiclesCondition
			case 'Boats':
				return _vehiclesCondition
			case 'Tractors & Trailers':
				return _vehiclesCondition
			case 'Tablets':
				return _tabletsCondition
			case 'Smart Watches':
				return _smartWatchesCondition
			case 'Mobile Phones':
				return _tabletsCondition
			case 'Charging Cables':
				return _chargingCablesTypes
			case 'Converters':
				return _smartWatchesCondition
			case 'Chargers':
				return _chargersTypes
			case 'Screens':
				return _screenTypes
			case 'Screens Protectors':
				return _screenTypes
			case 'Mobile Stands':
				return _smartWatchesCondition
			case 'Ring Lights':
				return _smartWatchesCondition
			case 'Selfie Sticks':
				return _smartWatchesCondition
			case 'Power Banks':
				return _smartWatchesCondition
			case 'External Memory':
				return _smartWatchesCondition
			case 'Headphones':
				return _headPhonesTypes
			case 'Earphones':
				return _headPhonesTypes
			case 'Covers & Cases':
				return _coverCases
			default:
				return palette.white
		}
	}

	const addPost = () => {
		if (formValidation()) {
			setIsLoading(true)
			api.post('/ads/create_ad', {
				title: adTitle,
				businessType: seletedMainCategory?.title,
				typeCategory: seletedTypeCategory?.title,
				subCategory: seletedSubTypeCategory?.title,
				description: description,
				furnished: selectedFurnished,
				bedRooms: bedRooms,
				bathRooms: bathRooms,
				floorLevel: floorLevel,
				storeys: selectedStoreys,
				constructionState: selectedConstructionState,
				type: selectedType,
				features: selectedFeatures,
				areaUnit: selectedAreaUnit,
				area: area,
				price: seletedTypeCategory?.title === 'Cars on Installments' ? 0 : price,
				photos: newImage,
				location: location,
				userName: userName,
				userPhoto: userImage,
				phone: phone,
				showNoInAds: showPhoneNoInAds,
				year: year,
				kmDriven: kmDriven,
				brand: make,
				condition: selectedCondition,
				model: model,
				fuel: selectedFuel,
				registrationCity: registrationCity,
				carDocuments: selectedCarDocuments,
				assembly: selectedAssembly,
				transmission: selectedTransmission,
				registered: selectedRegistered,
				downPayment: downPayment,
				monthlyInstallment: monthlyInstallment,
				installmentPlan: installmentPlan,
				deviceType: selectedDeviceType,
				estimatedViews: parseInt(estimatedViews) ? parseInt(estimatedViews) : 0
			})
				.then((res) => {
					if (res.data) {
						setIsLoading(false)
						toast.success('Ad created successfully.')
						_dispatch(adIdForFeaturedAd(res?.data?.createAds?.id))
						setNewImage([])
						setImagesContentForAI([])
						setCreatedAd(res?.data?.createAds)

						if (res?.data?.potentialBuyers) {
							getPotentialBuyerUsers(res?.data?.potentialBuyers)
						} else {
							clear()
							_navigate('/payments/packages')
						}
					}
				})
				.catch((e) => {
					if (e?.response) {
						toast.error(e?.response?.data?.message)
						setIsLoading(false)
					}
				})
		} else {
			toast.error('Mandatory fields are required')
		}
	}

	const getPotentialBuyerUsers = async (userIds: any) => {
		try {
			await api
				.post(`customers/view_all_user`, {userIds})
				.then(async (response) => {
					setPotentialBuyerModel(true)
					setPotentialBuyers(response?.data)
				})
				.catch(function (error) {
					console.log(error)
				})
		} catch (error) {
			console.log('===error', error)
		}
	}

	const editPost = (postId: any) => {
		if (formValidation()) {
			setIsLoading(true)
			api.put(`/ads/${postId}`, {
				title: adTitle,
				businessType: seletedMainCategory?.title,
				typeCategory: seletedTypeCategory?.title,
				subCategory: seletedSubTypeCategory?.title,
				description: description,
				furnished: selectedFurnished,
				bedRooms: bedRooms,
				bathRooms: bathRooms,
				floorLevel: floorLevel,
				storeys: selectedStoreys,
				constructionState: selectedConstructionState,
				type: selectedType,
				features: selectedFeatures,
				areaUnit: selectedAreaUnit,
				area: area,
				price: seletedTypeCategory?.title === 'Cars on Installments' ? 0 : price,
				photos: newImage,
				location: location,
				userName: userName,
				userPhoto: userImage,
				phone: phone,
				showNoInAds: showPhoneNoInAds,
				year: year,
				kmDriven: kmDriven,
				brand: make,
				condition: selectedCondition,
				model: model,
				fuel: selectedFuel,
				registrationCity: registrationCity,
				carDocuments: selectedCarDocuments,
				assembly: selectedAssembly,
				transmission: selectedTransmission,
				registered: selectedRegistered,
				downPayment: downPayment,
				monthlyInstallment: monthlyInstallment,
				installmentPlan: installmentPlan,
				deviceType: selectedDeviceType,
				estimatedViews: parseInt(estimatedViews) ? parseInt(estimatedViews) : 0
			})
				.then((res: any) => {
					if (res?.data) {
						setIsLoading(false)
						toast.success('Ad updated successfully.')
						if (res.data?.isFeatured) {
							_navigate('/my-ads')
						} else {
							_dispatch(adIdForFeaturedAd(res?.data?.id))
							_navigate('/payments/packages')
						}
						setNewImage([])
					}
				})
				.catch((e) => {
					if (e?.response) {
						toast.error(e?.response?.data?.message)
						setIsLoading(false)
					}
				})
		} else {
			toast.error('Mandatory fields are required')
		}
	}

	const formValidation = () => {
		let isValid = true
		if (adTitle < 5) {
			setAdTitleError('Ad title should contain at least 5 alphanumeric characters. Please edit the field')
			isValid = false
		}
		if (attributes?.areaUnit && area > 99999) {
			setAreaError(`The maximum allowed value is 99999`)
			isValid = false
		}
		if (attributes?.areaUnit && area === '') {
			setAreaError('Area is required')
			isValid = false
		}
		if (userName.length < 3) {
			setUserNameError(`A minimum length of 3 characters is allowed. Please edit the field.`)
			isValid = false
		}

		if (userName === '') {
			setUserNameError('Name is required')
			isValid = false
		}
		if (seletedTypeCategory?.title !== 'Cars on Installments') {
			if (price == 0) {
				setPriceError('Price can not be zero')
				isValid = false
			}
			if (price === '') {
				setPriceError('Price is required')
				isValid = false
			}
			if (price > 500000000) {
				setPriceError(`The maximum allowed value is 500 million`)
				isValid = false
			}
		}

		if (description < 20) {
			setDescriptionError('Description should contain at least 10 alphanumeric characters. Please edit the field')
			isValid = false
		}
		if (newImage?.length === 0) {
			setImageError('Image is required')
			isValid = false
		}
		if (location === '') {
			setLocationError('Location is required')
			isValid = false
		}

		if (attributes?.type && selectedType === '') {
			setTypeError('Type is required')
			isValid = false
		}

		if (attributes?.deviceType && selectedDeviceType === '') {
			setDeviceTypeError('Device Type is required')
			isValid = false
		}

		if (attributes?.features && selectedFeatures.length === 0) {
			setFeaturesError('Features is required')
			isValid = false
		}
		if (attributes?.areaUnit && selectedAreaUnit === '') {
			setAreaUnitError('Area unit is required')
			isValid = false
		}

		if (attributes?.furnished && selectedFurnished === '') {
			setFurnishedError('Furnished status is required')
			isValid = false
		}
		if (attributes?.noOfStoreys && selectedStoreys === '') {
			setStoreysError('No of storeys is required')
			isValid = false
		}
		if (attributes?.constructionState && selectedConstructionState === '') {
			setConstructionStateError('Construction status is required')
			isValid = false
		}
		if (attributes?.fuel && selectedFuel === '') {
			setFuelError('This field is required')
			isValid = false
		}

		if (attributes?.transmission && selectedTransmission === '') {
			setTransmissionError('This field is required')
			isValid = false
		}

		if (attributes?.registered && selectedRegistered === '') {
			setRegisteredError('This field is required')
			isValid = false
		}

		if (attributes?.assembly && selectedAssembly === '') {
			setAssemblyError('This field is required')
			isValid = false
		}

		if (attributes?.carDocuments && selectedCarDocuments === '') {
			setCarDocumentsError('Car documents are required')
			isValid = false
		}

		if (
			(seletedTypeCategory?.title === 'Houses for Sale' ||
				seletedTypeCategory?.title === 'Houses for Rent' ||
				seletedTypeCategory?.title === 'Apartments & Flats' ||
				seletedTypeCategory?.title === 'Portions & Floors' ||
				seletedTypeCategory?.title === 'Vacation Rentals - Guest Houses') &&
			bedRooms === ''
		) {
			setBedRoomsError('No of rooms are required')
			isValid = false
		}
		if (
			(seletedTypeCategory?.title === 'Houses for Sale' ||
				seletedTypeCategory?.title === 'Houses for Rent' ||
				seletedTypeCategory?.title === 'Apartments & Flats' ||
				seletedTypeCategory?.title === 'Portions & Floors' ||
				seletedTypeCategory?.title === 'Vacation Rentals - Guest Houses') &&
			bathRooms === ''
		) {
			setBathRoomsError('No of baths are required')
			isValid = false
		}
		if ((seletedTypeCategory?.title === 'Apartments & Flats' || seletedTypeCategory?.title === 'Shops - Offices - Commercial Space' || seletedTypeCategory?.title === 'Portions & Floors') && floorLevel === '') {
			setFloorLevelError('No of floors are required')
			isValid = false
		}
		if (
			(seletedTypeCategory?.title === 'Cars' ||
				seletedTypeCategory?.title === 'Cars on Installments' ||
				seletedTypeCategory?.title === 'Tablets' ||
				seletedTypeCategory?.title === 'Smart Watches' ||
				seletedTypeCategory?.title === 'Mobile Phones') &&
			make === ''
		) {
			setMakeError('This field is required')
			isValid = false
		}
		if (seletedTypeCategory?.title === 'Cars on Installments' && installmentPlan === '') {
			setInstallmentPlanError('This field is required')
			isValid = false
		}

		if ((seletedTypeCategory?.title === 'Cars' || seletedTypeCategory?.title === 'Cars on Installments') && model === '') {
			setModelError('Model is required')
			isValid = false
		}

		if (seletedTypeCategory?.title === 'Cars' && registrationCity === '') {
			setRegistrationCityError('This field is required')
			isValid = false
		}

		if (attributes?.condition && selectedCondition === '') {
			setConditionError('Condition status is required')
			isValid = false
		}

		if (seletedTypeCategory?.title === 'Cars on Installments') {
			if (downPayment < 50000) {
				setDownPaymentError('The minimum allowed value is 50000')
				isValid = false
			}
			if (downPayment > 50000000) {
				setDownPaymentError(`The maximum allowed value is 50000000`)
				isValid = false
			}
			if (downPayment === '') {
				setDownPaymentError('This field is required')
				isValid = false
			}

			if (monthlyInstallment < 5000) {
				setMonthlyInstallmentError('The minimum allowed value is 5000')
				isValid = false
			}
			if (monthlyInstallment > 5000000) {
				setMonthlyInstallmentError(`The maximum allowed value is 5000000`)
				isValid = false
			}
			if (monthlyInstallment === '') {
				setMonthlyInstallmentError('This field is required')
				isValid = false
			}
		}

		if (
			seletedTypeCategory?.title === 'Cars' ||
			seletedTypeCategory?.title === 'Cars on Installments' ||
			seletedTypeCategory?.title === 'Buses, Vans & Trucks' ||
			seletedTypeCategory?.title === 'Rickshaw & Chingchi' ||
			seletedTypeCategory?.title === 'Tractors & Trailers'
		) {
			if (year < 1900) {
				setYearError('The minimum allowed value is 1900')
				isValid = false
			}
			if (year > new Date().getFullYear()) {
				setYearError(`The maximum allowed value is ${new Date().getFullYear() + 1}`)
				isValid = false
			}
			if (year === '') {
				setYearError('Year status is required')
				isValid = false
			}
		}

		if (seletedTypeCategory?.title === 'Cars' || seletedTypeCategory?.title === 'Buses, Vans & Trucks' || seletedTypeCategory?.title === 'Rickshaw & Chingchi' || seletedTypeCategory?.title === 'Tractors & Trailers') {
			if (kmDriven < 1) {
				setKmDrivenError(`The minimum allowed value is 1`)
				isValid = false
			}
			if (kmDriven > 999999) {
				setKmDrivenError(`The maximum allowed value is 999999`)
				isValid = false
			}
			if (kmDriven === '') {
				setKmDrivenError('This field is required')
				isValid = false
			}
		}

		return isValid
	}

	const handleCapture = (event: ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files
		if (files && files.length > 0) {
			// Calculate the number of additional images that can be uploaded without exceeding 7
			const remainingImages = Math.max(7 - newImage.length, 0)
			const selectedFiles = Array.from(files).slice(0, remainingImages)

			selectedFiles.map(async (res: any) => {
				const generativePart: any = await fileToGenerativePart(res)
				setImagesContentForAI((prevImages) => [...prevImages, generativePart])
				if (useAi) {
					generateImageAiResponse(generativePart)
				}

				// console.log('=====generativePart', generativePart)
			})

			const newImagePromises: Promise<string>[] = selectedFiles.map((file) => {
				return new Promise<string>((resolve) => {
					const reader = new FileReader()

					reader.readAsDataURL(file)

					reader.onload = () => {
						if (reader.readyState === 2) {
							resolve(reader.result as string)
						}
					}
				})
			})

			Promise.all(newImagePromises)
				.then((results) => {
					// Add the new images to the existing ones
					setNewImage((prevImages) => [...prevImages, ...results])
					setImageError('')
				})
				.catch((error) => {
					console.error('Error reading images:', error)
				})
		}
	}

	const fileToGenerativePart = (file: any) => {
		return new Promise((resolve: any, reject: any) => {
			const reader: any = new FileReader()

			reader.onloadend = () => {
				const data = reader.result.split(',')[1] // Extract base64 data
				const mimeType = file.type

				resolve({
					inlineData: {
						data,
						mimeType
					}
				})
			}

			reader.onerror = reject

			reader.readAsDataURL(file)
		})
	}

	const deleteSelectImage = (index: any) => {
		const deleteImage = newImage.filter((value, ind) => {
			return ind !== index
		})
		let images: any = imageContentForAI.filter((val, ind) => {
			return ind !== index
		})
		setImagesContentForAI(images)
		setNewImage(deleteImage)
	}

	const generateTitleAiResponse = async (_text: any, isPrice = false, images?: any) => {
		try {
			setIsLoading(true)
			let res: any = await doGenerateAiResponse(_text, images)
			if (res) {
				if (isPrice) {
					let price = verifyJSON(`${res}`) ? JSON.parse(`${res}`) : 0
					setPrice(price?.price ? price?.price : price ? price : 0)
				} else {
					setDescription(res)
				}
				setIsLoading(false)
			}
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			console.log('error', error)
		}
	}

	const generatePrice = async (data: any) => {
		let _filteredData = Object.fromEntries(
			Object.entries(data).map(([key, value]) => {
				if (key === 'price') {
					return [key, '']
				} else {
					return [key, value]
				}
			})
		)
		generateTitleAiResponse(
			`Give me reasonable price to sale this, analyze markete trend and provide price in numbers only, give only one price in between not give text as i show this directly in input field, ${
				imageContentForAI.length ? 'use images also to generate price' : ''
			} as detials like this in json ${JSON.stringify(_filteredData)} **Please respond with a single, rounded number representing the reasonable selling price based on the provided details. Do not include any text or explanations.**`,
			true,
			imageContentForAI
		)
	}
	let data = {
		title: adTitle,
		businessType: seletedMainCategory?.title,
		typeCategory: seletedTypeCategory?.title,
		...(seletedSubTypeCategory?.title && {
			subCategory: seletedSubTypeCategory?.title
		}),

		description: description,
		furnished: selectedFurnished,
		bedRooms: bedRooms,
		bathRooms: bathRooms,
		floorLevel: floorLevel,
		storeys: selectedStoreys,
		constructionState: selectedConstructionState,
		type: selectedType,
		features: selectedFeatures,
		areaUnit: selectedAreaUnit,
		area: area,
		price: seletedTypeCategory?.title === 'Cars on Installments' ? 0 : price,
		// photos: newImage,
		location: location,
		userName: userName,
		// userPhoto: userImage,
		phone: phone,
		showNoInAds: showPhoneNoInAds,
		year: year,
		kmDriven: kmDriven,
		brand: make,
		condition: selectedCondition,
		model: model,
		fuel: selectedFuel,
		registrationCity: registrationCity,
		carDocuments: selectedCarDocuments,
		assemble: selectedAssembly,
		transmission: selectedTransmission,
		registered: selectedRegistered,
		downPayment: downPayment,
		monthlyInstallment: monthlyInstallment,
		installmentPlan: installmentPlan,
		deviceType: selectedDeviceType
	}

	let filteredData = Object.fromEntries(Object.entries(data).filter(([_, v]) => v !== undefined && v !== null && v !== ''))

	const generateDescription = async (price = false) => {
		let _filteredData = Object.fromEntries(
			Object.entries(data).map(([key, value]) => {
				if (key === 'description') {
					return [key, '']
				} else {
					return [key, value]
				}
			})
		)
		if (Object.values(filteredData)?.length && price == false) {
			generateTitleAiResponse(
				`Give me description in maximum of 4096 words, analyze markete trend and response should be in english, ${
					imageContentForAI.length ? 'use images also to generate relevant description' : ''
				} as detials like this in json ${JSON.stringify(
					_filteredData
				)} **Please respond with relevant details. Don't use user phone number in desciption. Ensure title is related to businessType and typeCategory and give pros only as i want to sale this.**`,
				false,
				imageContentForAI
			)
		} else if (Object.values(filteredData)?.length && price) {
			generatePrice(filteredData)
		}
	}

	const generateImageAiResponse = async (images?: any) => {
		try {
			setIsLoading(true)
			let _text = `Verify images is according to provided json data:${JSON.stringify(
				filteredData
			)}.Ensure title is related to businessType and typeCategory. **Please verify and respond with in one line, don't use json or any words related to data.**`
			let res: any = await doGenerateAiResponse(_text, images)
			if (res) {
				setVerifyImageResponse(res)
				setVerifyImageModel(true)
				setIsLoading(false)
			}
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			console.log('error', error)
		}
	}

	const generateImageResponse = async (images?: any) => {
		try {
			let _filteredData = Object.fromEntries(
				Object.entries(data).map(([key, value]) => {
					if (key === 'businessType' || key === 'typeCategory') {
						return [key, value]
					} else {
						return [key, '']
					}
				})
			)
			setIsLoading(true)
			// let _text = `parse image and use this json data:${JSON.stringify(
			// 	Object.keys(data)
			// )} and generate response.Ensure title is related to businessType and typeCategory. Don't use contact information in desciption. Provide description more and more and maximum of 4029 word and price value always in numbers. **Please verify and respond with in all possible details. If fields not relevant to business type and type category then don't return field also or return null or empty string. Result always in single object no text from start or last.**`
			// const _text = `Generate a response for the following ad based on the images and provided details: ${JSON.stringify(
			// 	data
			// )}. Ensure the title is related to businessType and typeCategory. Do not include contact information in the description. Provide a descriptive and informative response with a maximum of 4029 words. Return the price value as a number. Exclude fields that are not relevant to the business type and category. Return the response as a single JSON object.`
			const _text = `Parse image and use this provided data: ${JSON.stringify(
				_filteredData
			)} and generate relevant response. Ensure title is related to businessType and typeCategory. Don't use contact information in description. Provide description with maximum of 4029 words and price value always in numbers. Verify and respond with all possible details. Return response in all relevant fields. If fields are not relevant to business type and type category, return null or empty string. Result should be a single object without any extra text.`
			let res: any = await doGenerateAiResponse(_text, images)
			if (res) {
				console.log('=========res image response', res)
				if (res) {
					let data = verifyJSON(res) ? JSON.parse(res) : {}
					console.log('=========data', data)

					if (!data?.title) {
						const stringWithEmbeddedJSON = res
						const jsonMatch = stringWithEmbeddedJSON.match(/```json([\s\S]*?)```/)

						if (jsonMatch && jsonMatch[1]) {
							const extractedJSON = jsonMatch[1].trim()

							try {
								const parsedObject = JSON.parse(extractedJSON)
								data = parsedObject
							} catch (error) {
								console.error('Error parsing JSON:', error)
							}
						} else {
							console.error('No JSON found in the string.')
						}
					}

					if (data?.price) {
						setPrice(data?.price)
					}
					if (data?.description) {
						setDescription(data?.description)
					}
					if (data?.title) {
						setAdTitle(data?.title)
					}
					if (data?.area) {
						setArea(data?.area)
					}
					if (data?.areaUnit) {
						setSelectedAreaUnit(data?.areaUnit)
					}
					if (data?.assemble) {
						setSelectedAssembly(data?.assemble)
					}
					if (data?.bathRooms) {
						setBathRooms(data?.bathRooms)
					}
					if (data?.bedRooms) {
						setBedRooms(data?.bedRooms)
					}
					if (data?.brand) {
						setMake(data?.brand)
					}
					if (data?.carDocuments) {
						setSelectedCarDocuments(data?.carDocuments)
					}
					if (data?.condition) {
						setSelectedCondition(data?.condition)
					}
					if (data?.constructionState) {
						setSelectedConstructionState(data?.constructionState)
					}
					if (data?.deviceType) {
						setSelectedDeviceType(data?.deviceType)
					}
					if (data?.downPayment) {
						setDownPayment(data?.downPayment)
					}
					if (data?.floorLevel) {
						setFloorLevel(data?.floorLevel)
					}
					if (data?.fuel) {
						setSelectedFuel(data?.fuel)
					}
					if (data?.furnished) {
						setSelectedFurnished(data?.furnished)
					}
					if (data?.installmentPlan) {
						setInstallmentPlan(data?.installmentPlan)
					}
					if (data?.features?.length) {
						let _features = attributes?.features?.concat(data?.features)

						setAttributes({...attributes, features: [...new Set(_features)]})
						setTimeout(() => {
							setSelectedFeatures(data?.features)
						}, 300)
					}
					if (data?.kmDriven) {
						setKmDriven(data?.kmDriven)
					}
					if (data?.model) {
						setModel(data?.model)
					}
					if (data?.monthlyInstallment) {
						setMonthlyInstallment(data?.monthlyInstallment)
					}
					if (data?.registered?.length) {
						let registered = attributes?.registered?.concat(data?.registered)
						setAttributes({...attributes, registered: [...new Set(registered)]})
						setSelectedRegistered(data?.registered)
					}
					if (data?.registrationCity) {
						setRegistrationCity(data?.registrationCity)
					}
					if (data?.storeys) {
						setSelectedStoreys(data?.storeys)
					}
					if (data?.transmission) {
						setSelectedTransmission(data?.transmission)
					}
					if (data?.type) {
						setSelectedType(data?.type)
					}
					if (data?.year) {
						setYear(data?.year)
					}
				}
				// toast.success(res)
				setIsLoading(false)
			}
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			console.log('error', error)
		}
	}

	const generateEstimatedViewPrediction = async (images?: any) => {
		try {
			setIsLoading(true)
			let _text = `Verify all details added and provides expected performance of the ad, such as estimated views as details are like this in json ${JSON.stringify(
				filteredData
			)}. **Please respond with a single, rounded number based on the provided details. Do not include any text or explanations.**`
			let res: any = await doGenerateAiResponse(_text, images)
			if (res) {
				setEstimatedViews(res)
				setEstimatedViewsModel(true)
				setIsLoading(false)
			}
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			console.log('error', error)
		}
	}

	const generateOptionsUsingAI = async (key: any) => {
		if (attributes[key]?.length) {
			try {
				// setIsLoading(true)
				let _text = `Generate expected options using details of businessType and typeCategory details are like this in json ${JSON.stringify({
					filteredData,
					[key]: attributes[key]
				})}. **Please respond with relevant details. Don't use user phone number in desciption. Ensure by using businessType and typeCategory result and provide related more values for ${key}. Give me all possible values only, not text, in array as i want to use in javascript.**`
				let res: any = await doGenerateAiResponse(_text)
				if (res) {
					let verifyJson = verifyJSON(res.split(',')) ? JSON.parse(res.split(',')) : []
					if (verifyJson?.length) {
						setAttributes((prevAttributes: any) => ({
							...prevAttributes,
							[key]: [...new Set([...(prevAttributes[key] || []), ...verifyJson])]
						}))
					}

					setIsLoading(false)
				}
				setIsLoading(false)
			} catch (error) {
				setIsLoading(false)
				console.log('error', error)
			}
		}
	}

	useEffect(() => {
		setTimeout(() => {
			if (attributes) {
				Object.keys(attributes).map((key) => {
					generateOptionsUsingAI(key)
				})
			}
		}, 1500)
	}, [loadMoreConditions])

	return (
		<FormWrapper>
			<Row>
				<Col>
					<Spacer height={2} />
				</Col>
				<Col>
					<Heading textTransform="uppercase" level={4} fontWeight={700} isDarkTheme={_isDarkTheme} margin={'0rem 0rem 1rem 0rem'}>
						Selected Category
					</Heading>
					<Flexed direction="row" gap={1} align="center">
						<Text type="small" isDarkTheme={_isDarkTheme}>
							{seletedMainCategory?.title} {seletedTypeCategory?.title && '/' + ' ' + seletedTypeCategory?.title} {seletedSubTypeCategory?.title && '/' + ' ' + seletedSubTypeCategory.title}
						</Text>
						{clear && (
							<Change type="normal" fontWeight="500" isDarkTheme={_isDarkTheme} onClick={clear}>
								Changes
							</Change>
						)}
					</Flexed>
				</Col>
				<Col>
					<Spacer height={2} />
				</Col>
				<Divider isDarkTheme={_isDarkTheme} />
				<Col>
					<Spacer height={2} />
					<Heading textTransform="uppercase" level={4} fontWeight={700} isDarkTheme={_isDarkTheme} margin={'0rem 0rem 1rem 0rem'}>
						INCLUDE SOME DETAILS
					</Heading>
				</Col>
				<Col>
					<InputField
						error={adTitleError}
						label="Ad title"
						required
						value={adTitle}
						handleChange={(e: any) => {
							setAdTitleError('')
							if (e == '') {
								setAdTitleError('Title should contain at least 5 alphanumeric characters. Please edit the field.')
							}
							if (e.length < 5) {
								setAdTitleError('A minimum length of 5 characters is allowed. Please edit the field.')
							}
							if (e?.length <= 70) {
								setAdTitle(e)
							}
						}}
					/>
					{!adTitleError && (
						<Flexed direction="row" justify="space-between" gap={1} margin="0.1rem 0rem 0rem 0rem">
							<Text isDarkTheme={_isDarkTheme} type="xsmall">
								Mention the key features of your item (e.g. brand, model, age, type)
							</Text>
							<Text isDarkTheme={_isDarkTheme} type="xsmall">
								{adTitle?.length}/70
							</Text>
						</Flexed>
					)}
					<Spacer height={1} />
				</Col>
				<Divider isDarkTheme={_isDarkTheme} />
				<Col>
					<CustomDiv>
						<Heading textTransform="uppercase" level={4} fontWeight={700} isDarkTheme={_isDarkTheme} margin={'1rem 0rem'}>
							UPLOAD UP TO 7 PHOTOS
						</Heading>
						<Link to="">
							<span
								onClick={() => {
									if (imageContentForAI?.length) {
										generateImageAiResponse(imageContentForAI)
									} else {
										toast.error(imageContentForAI?.length == 0 ? 'Please add image first to generate AI response' : '')
									}
								}}>
								{useAi ? 'Not using AI ?' : 'Use AI to verify images is related to your ad title or not ?'}{' '}
							</span>
						</Link>
						<Link to="">
							<span
								onClick={() => {
									if (imageContentForAI?.length) {
										generateImageResponse(imageContentForAI)
									} else {
										toast.error(imageContentForAI?.length == 0 ? 'Please add image first to generate AI response' : '')
									}
								}}>
								{useAi ? 'Not using AI ?' : 'Want to generate field details from images ?'}
							</span>
						</Link>
					</CustomDiv>

					<Flexed direction="row" flexWrap="wrap" gap={0.5}>
						{newImage?.map((value: any, index: any) => {
							return (
								<ImgWrapper key={index}>
									<ShowImage src={value?.startsWith('data:') ? value : process.env.REACT_APP_IMAGE_URL + value} />
									<DeleteImage
										onClick={() => {
											deleteSelectImage(index)
										}}
										isDarkTheme={_isDarkTheme}
									/>
								</ImgWrapper>
							)
						})}
						{newImage?.length < 7 && (
							<InputWrapper>
								<Upload>
									<MdOutlineCloudUpload color={_isDarkTheme ? palette.light_gray : palette.gray} fontSize={24} />
									<Text type="xsmall" isDarkTheme={_isDarkTheme}>
										Upload Image
									</Text>
									<FileInput
										multiple
										id="faceImage"
										accept="image/*"
										type="file"
										onChange={(e) => {
											handleCapture(e)
										}}
									/>
								</Upload>
							</InputWrapper>
						)}
					</Flexed>
				</Col>
				<Col>
					{imageError ? (
						<Text fontSize={0.7} type="small" color="danger" margin="0rem 0rem 1rem 0rem">
							{imageError}
						</Text>
					) : (
						<Text isDarkTheme={_isDarkTheme} type="xsmall">
							For the cover picture we recommend using the landscape mode.
						</Text>
					)}
					<Spacer height={1} />
				</Col>
				<Divider isDarkTheme={_isDarkTheme} />
				<Spacer height={1} />
				<Col>
					<InputField
						error={descriptionError}
						type="textarea"
						required
						placeholder="Type here"
						label="Description"
						value={description}
						useAI={true}
						AiGenerateTitle={'Generate description using AI? Please enter all details to get accurate result.'}
						handleAiGeneratedResponse={() => generateDescription()}
						handleChange={(e: any) => {
							setDescriptionError('')
							if (e == '') {
								setDescriptionError('Description should contain at least 10 alphanumeric characters. Please edit the field.')
							}
							if (e.length < 20) {
								setDescriptionError('A minimum length of 20 characters is allowed. Please edit the field.')
							}
							if (e?.length <= 4096) {
								setDescription(e)
							}
						}}
					/>
					{!descriptionError && (
						<Flexed direction="row" justify="space-between" gap={1}>
							<Text isDarkTheme={_isDarkTheme} type="xsmall">
								Include condition, features and reason for selling
							</Text>
							<Text isDarkTheme={_isDarkTheme} type="xsmall">
								{description?.length}/4096
							</Text>
						</Flexed>
					)}
					<Spacer height={1} />
				</Col>

				{attributes?.furnished && (
					<Col>
						<Text type="normal" margin="0rem 0rem 0.19rem 0rem" fontWeight={500} textTransform="capitalize" isDarkTheme={_isDarkTheme}>
							Furnished <Mandatory>*</Mandatory>
						</Text>
						<Flexed direction="row" flexWrap="wrap" gap={0.5}>
							{attributes?.furnished?.map((name: any) => {
								return (
									<Options
										active={name === selectedFurnished}
										isDarkTheme={_isDarkTheme}
										onClick={() => {
											setSelectedFurnished(name)
											setFurnishedError('')
										}}>
										{name}
									</Options>
								)
							})}
						</Flexed>
						{furnishedError && (
							<Text fontSize={0.7} type="small" color="danger">
								{furnishedError}
							</Text>
						)}
						<Spacer height={1} />
					</Col>
				)}

				{(seletedTypeCategory?.title === 'Houses for Sale' ||
					seletedTypeCategory?.title === 'Houses for Rent' ||
					seletedTypeCategory?.title === 'Apartments & Flats' ||
					seletedTypeCategory?.title === 'Portions & Floors' ||
					seletedTypeCategory?.title === 'Vacation Rentals - Guest Houses') && (
					<Col>
						<InputSelect label="Bedrooms" name="collection" error={bedRoomsError} required defalutValue={bedRooms} options={bedRoomsOption} onChange={(e: any) => selectedBedRoomsOptionValue(e?.value)} />
						{/* <DropDown error={bedRoomsError} required firstSelected={bedRooms} label="Bedrooms" name="collection" hasChanged={(value: any) => selectedBedRoomsOptionValue(value)} options={bedRoomsOption} /> */}
						<Spacer height={1} />
					</Col>
				)}
				{(seletedTypeCategory?.title === 'Houses for Sale' ||
					seletedTypeCategory?.title === 'Houses for Rent' ||
					seletedTypeCategory?.title === 'Apartments & Flats' ||
					seletedTypeCategory?.title === 'Portions & Floors' ||
					seletedTypeCategory?.title === 'Vacation Rentals - Guest Houses') && (
					<Col>
						<InputSelect label="Bathrooms" name="collection" error={bathRoomsError} required defalutValue={bathRooms} options={bathRoomsOption} onChange={(e: any) => selectedBathRoomsOptionValue(e?.value)} />
						{/* <DropDown error={bathRoomsError} required firstSelected={bathRooms} label="Bathrooms" name="collection" hasChanged={(value: any) => selectedBathRoomsOptionValue(value)} options={bathRoomsOption} /> */}
						<Spacer height={1} />
					</Col>
				)}
				{(seletedTypeCategory?.title === 'Apartments & Flats' || seletedTypeCategory?.title === 'Shops - Offices - Commercial Space' || seletedTypeCategory?.title === 'Portions & Floors') && (
					<Col>
						<InputSelect label="Floor Level" name="collection" error={floorLevelError} required defalutValue={floorLevel} options={floorLevelOption} onChange={(e: any) => selectedFloorLevelOptionValue(e?.value)} />
						{/* <DropDown error={floorLevelError} required firstSelected={floorLevel} label="Floor Level" name="collection" hasChanged={(value: any) => selectedFloorLevelOptionValue(value)} options={floorLevelOption} /> */}
						<Spacer height={1} />
					</Col>
				)}
				{(seletedTypeCategory?.title === 'Cars' || seletedTypeCategory?.title === 'Cars on Installments') && (
					<Col>
						<InputSelect label="Make" name="collection" error={makeError} required defalutValue={{label: make, value: make}} value={make} options={makeOption} onChange={(e: any) => selectedMakeOptionValue(e?.value)} />
						{/* <DropDown error={makeError} required firstSelected={make} label="Make" name="collection" hasChanged={(value: any) => selectedMakeOptionValue(value)} options={makeOption} /> */}
						<Spacer height={1} />
					</Col>
				)}
				{(seletedTypeCategory?.title === 'Tablets' || seletedTypeCategory?.title === 'Smart Watches' || seletedTypeCategory?.title === 'Mobile Phones') && (
					<Col>
						{/* <DropDown
							error={makeError}
							required
							firstSelected={make}
							label="Brand"
							name="collection"
							hasChanged={(value: any) => selectedMakeOptionValue(value)}
							options={seletedTypeCategory?.title === 'Smart Watches' ? smartWatchOption : seletedTypeCategory?.title === 'Mobile Phones' ? mobilePhoneOption : tabletsBrandOption}
						/> */}

						<InputSelect
							label="Brand"
							name="collection"
							error={makeError}
							required
							defalutValue={make}
							options={seletedTypeCategory?.title === 'Smart Watches' ? smartWatchOption : seletedTypeCategory?.title === 'Mobile Phones' ? mobilePhoneOption : tabletsBrandOption}
							onChange={(e: any) => selectedMakeOptionValue(e?.value)}
						/>
						<Spacer height={1} />
					</Col>
				)}

				{(seletedTypeCategory?.title === 'Cars' || seletedTypeCategory?.title === 'Cars on Installments') && (
					<Col>
						<InputField
							error={modelError}
							label="Model"
							value={model}
							required
							handleChange={(e: any) => {
								setModelError('')
								if (e == '') {
									setModelError('Model is required')
								}
								setModel(e)
							}}
						/>
						<Spacer height={1} />
					</Col>
				)}
				{seletedTypeCategory?.title === 'Cars' && (
					<Col>
						{/* <InputField error={registrationCityError} label="Registration City" value={registrationCity} required handleChange={(e: any) => {
                        setRegistrationCityError('');
                        if (e == '') {
                            setRegistrationCityError('This field is required')
                        }
                        setRegistrationCity(e)
                    }}
                    /> */}
						<RegistationCityField
							label="Registration City"
							required
							city={registrationCity}
							isEdit={editPostDetails?.id ? true : false}
							setCity={(city: any) => {
								setRegistrationCityError('')
								if (city == '') {
									setRegistrationCityError('This field is required')
								}
								setRegistrationCity(city)
							}}
							error={registrationCityError}
						/>
						<Spacer height={1} />
					</Col>
				)}

				{(seletedTypeCategory?.title === 'Cars' ||
					seletedTypeCategory?.title === 'Cars on Installments' ||
					seletedTypeCategory?.title === 'Buses, Vans & Trucks' ||
					seletedTypeCategory?.title === 'Rickshaw & Chingchi' ||
					seletedTypeCategory?.title === 'Tractors & Trailers') && (
					<Col>
						<InputField
							error={yearError}
							label="Year"
							value={year}
							required
							handleChange={(e: any) => {
								setYearError('')
								if (e == '') {
									setYearError('Year is required')
								}
								if (e < 1900) {
									setYearError('The minimum allowed value is 1900')
								}
								if (e > moment().format('YYYY')) {
									setYearError(`The maximum allowed value is ${moment().format('YYYY')}`)
								}
								setYear(e)
							}}
							allowOnlyNumbers={true}
						/>
						<Spacer height={1} />
					</Col>
				)}

				{(seletedTypeCategory?.title === 'Cars' || seletedTypeCategory?.title === 'Buses, Vans & Trucks' || seletedTypeCategory?.title === 'Rickshaw & Chingchi' || seletedTypeCategory?.title === 'Tractors & Trailers') && (
					<Col>
						<InputField
							error={kmDrivenError}
							label="KM's driven"
							value={kmDriven}
							required
							handleChange={(e: any) => {
								setKmDrivenError('')
								if (e == '') {
									setKmDrivenError('This field is required')
								}
								if (e > 999999) {
									setKmDrivenError(`The maximum allowed value is 999999`)
								}
								if (e < 1) {
									setKmDrivenError(`The minimum allowed value is 1`)
								}
								setKmDriven(e)
							}}
							allowOnlyNumbers={true}
						/>
						<Spacer height={1} />
					</Col>
				)}

				{attributes?.carDocuments && (
					<Col>
						<Text type="normal" margin="0rem 0rem 0.19rem 0rem" fontWeight={500} textTransform="capitalize" isDarkTheme={_isDarkTheme}>
							Car documents <Mandatory>*</Mandatory>
						</Text>
						<Flexed direction="row" flexWrap="wrap" gap={0.5}>
							{attributes?.carDocuments?.map((carDocuments: any) => {
								return (
									<Options
										active={carDocuments === selectedCarDocuments}
										isDarkTheme={_isDarkTheme}
										onClick={() => {
											setSelectedCarDocuments(carDocuments)
											setCarDocumentsError('')
										}}>
										{carDocuments}
									</Options>
								)
							})}
						</Flexed>
						{carDocumentsError && (
							<Text fontSize={0.7} type="small" color="danger">
								{carDocumentsError}
							</Text>
						)}
						<Spacer height={1} />
					</Col>
				)}

				{attributes?.deviceType && (
					<Col>
						<Text type="normal" margin="0rem 0rem 0.19rem 0rem" fontWeight={500} textTransform="capitalize" isDarkTheme={_isDarkTheme}>
							Device Type <Mandatory>*</Mandatory>
						</Text>
						<Flexed direction="row" flexWrap="wrap" gap={0.5}>
							{attributes?.deviceType?.map((deviceType: any) => {
								return (
									<Options
										active={deviceType === selectedDeviceType}
										isDarkTheme={_isDarkTheme}
										onClick={() => {
											setSelectedDeviceType(deviceType)
											setDeviceTypeError('')
										}}>
										{deviceType}
									</Options>
								)
							})}
						</Flexed>
						{deviceTypeError && (
							<Text fontSize={0.7} type="small" color="danger">
								{deviceTypeError}
							</Text>
						)}
						<Spacer height={1} />
					</Col>
				)}

				{attributes?.condition && (
					<Col>
						<Text type="normal" margin="0rem 0rem 0.19rem 0rem" fontWeight={500} textTransform="capitalize" isDarkTheme={_isDarkTheme}>
							Condition <Mandatory>*</Mandatory>
						</Text>
						<Flexed direction="row" flexWrap="wrap" gap={0.5}>
							{attributes?.condition?.map((condition: any) => {
								return (
									<Options
										active={condition === selectedCondition}
										isDarkTheme={_isDarkTheme}
										onClick={() => {
											setSelectedCondition(condition)
											setConditionError('')
										}}>
										{condition}
									</Options>
								)
							})}
						</Flexed>
						{conditionError && (
							<Text fontSize={0.7} type="small" color="danger">
								{conditionError}
							</Text>
						)}
						<Spacer height={1} />
					</Col>
				)}

				{attributes?.assembly && (
					<Col>
						<Text type="normal" margin="0rem 0rem 0.19rem 0rem" fontWeight={500} textTransform="capitalize" isDarkTheme={_isDarkTheme}>
							Assembly <Mandatory>*</Mandatory>
						</Text>
						<Flexed direction="row" flexWrap="wrap" gap={0.5}>
							{attributes?.assembly?.map((assembly: any) => {
								return (
									<Options
										active={assembly === selectedAssembly}
										isDarkTheme={_isDarkTheme}
										onClick={() => {
											setSelectedAssembly(assembly)
											setAssemblyError('')
										}}>
										{assembly}
									</Options>
								)
							})}
						</Flexed>
						{assemblyError && (
							<Text fontSize={0.7} type="small" color="danger">
								{assemblyError}
							</Text>
						)}
						<Spacer height={1} />
					</Col>
				)}

				{attributes?.transmission && (
					<Col>
						<Text type="normal" margin="0rem 0rem 0.19rem 0rem" fontWeight={500} textTransform="capitalize" isDarkTheme={_isDarkTheme}>
							Transmission <Mandatory>*</Mandatory>
						</Text>
						<Flexed direction="row" flexWrap="wrap" gap={0.5}>
							{attributes?.transmission?.map((transmission: any) => {
								return (
									<Options
										active={transmission === selectedTransmission}
										isDarkTheme={_isDarkTheme}
										onClick={() => {
											setSelectedTransmission(transmission)
											setTransmissionError('')
										}}>
										{transmission}
									</Options>
								)
							})}
						</Flexed>
						{transmissionError && (
							<Text fontSize={0.7} type="small" color="danger">
								{transmissionError}
							</Text>
						)}
						<Spacer height={1} />
					</Col>
				)}

				{attributes?.registered && (
					<Col>
						<Text type="normal" margin="0rem 0rem 0.19rem 0rem" fontWeight={500} textTransform="capitalize" isDarkTheme={_isDarkTheme}>
							Registered <Mandatory>*</Mandatory>
						</Text>
						<Flexed direction="row" flexWrap="wrap" gap={0.5}>
							{attributes?.registered?.map((registered: any) => {
								return (
									<Options
										active={registered === selectedRegistered}
										isDarkTheme={_isDarkTheme}
										onClick={() => {
											setSelectedRegistered(registered)
											setRegisteredError('')
										}}>
										{registered}
									</Options>
								)
							})}
						</Flexed>
						{registeredError && (
							<Text fontSize={0.7} type="small" color="danger">
								{registeredError}
							</Text>
						)}
						<Spacer height={1} />
					</Col>
				)}

				{seletedTypeCategory?.title === 'Cars on Installments' && (
					<Col>
						<InputField
							error={downPaymentError}
							label="Down payment"
							value={downPayment}
							required
							handleChange={(e: any) => {
								setDownPaymentError('')
								if (e == '') {
									setDownPaymentError('This field is required')
								}
								if (e < 50000) {
									setDownPaymentError('The minimum allowed value is 50000')
								}
								if (e > 50000000) {
									setDownPaymentError(`The maximum allowed value is 50000000`)
								}
								setDownPayment(e)
							}}
							allowOnlyNumbers={true}
						/>
						<Spacer height={1} />
					</Col>
				)}

				{seletedTypeCategory?.title === 'Cars on Installments' && (
					<Col>
						<InputField
							error={monthlyInstallmentError}
							label="Monthly installment"
							value={monthlyInstallment}
							required
							handleChange={(e: any) => {
								setMonthlyInstallmentError('')
								if (e == '') {
									setMonthlyInstallmentError('This field is required')
								}
								if (e < 5000) {
									setMonthlyInstallmentError('The minimum allowed value is 5000')
								}
								if (e > 5000000) {
									setMonthlyInstallmentError(`The maximum allowed value is 5000000`)
								}
								setMonthlyInstallment(e)
							}}
							allowOnlyNumbers={true}
						/>
						<Spacer height={1} />
					</Col>
				)}

				{seletedTypeCategory?.title === 'Cars on Installments' && (
					<Col>
						<DropDown
							error={installmentPlanError}
							required
							firstSelected={installmentPlan}
							label="Installment Plan"
							name="collection"
							hasChanged={(value: any) => selectedInstallmentPlanOptionValue(value)}
							options={installmentPlanOption}
						/>
						<Spacer height={1} />
					</Col>
				)}

				{attributes?.fuel && (
					<Col>
						<Text type="normal" margin="0rem 0rem 0.19rem 0rem" fontWeight={500} textTransform="capitalize" isDarkTheme={_isDarkTheme}>
							Fuel <Mandatory>*</Mandatory>
						</Text>
						<Flexed direction="row" flexWrap="wrap" gap={0.5}>
							{attributes?.fuel?.map((fuel: any) => {
								return (
									<Options
										active={fuel === selectedFuel}
										isDarkTheme={_isDarkTheme}
										onClick={() => {
											setSelectedFuel(fuel)
											setFuelError('')
										}}>
										{fuel}
									</Options>
								)
							})}
						</Flexed>
						{fuelError && (
							<Text fontSize={0.7} type="small" color="danger">
								{fuelError}
							</Text>
						)}
						<Spacer height={1} />
					</Col>
				)}

				{attributes?.noOfStoreys && (
					<Col>
						<Text type="normal" margin="0rem 0rem 0.19rem 0rem" fontWeight={500} textTransform="capitalize" isDarkTheme={_isDarkTheme}>
							No of storeys <Mandatory>*</Mandatory>
						</Text>
						<Flexed direction="row" flexWrap="wrap" gap={0.5}>
							{attributes?.noOfStoreys?.map((stories: any) => {
								return (
									<Options
										active={stories === selectedStoreys}
										isDarkTheme={_isDarkTheme}
										onClick={() => {
											setSelectedStoreys(stories)
											setStoreysError('')
										}}>
										{stories}
									</Options>
								)
							})}
						</Flexed>
						{storeysError && (
							<Text fontSize={0.7} type="small" color="danger">
								{storeysError}
							</Text>
						)}
						<Spacer height={1} />
					</Col>
				)}

				{attributes?.constructionState && (
					<Col>
						<Text type="normal" margin="0rem 0rem 0.19rem 0rem" fontWeight={500} textTransform="capitalize" isDarkTheme={_isDarkTheme}>
							Construction State <Mandatory>*</Mandatory>
						</Text>
						<Flexed direction="row" flexWrap="wrap" gap={0.5}>
							{attributes?.constructionState?.map((stateName: any) => {
								return (
									<Options
										active={stateName === selectedConstructionState}
										isDarkTheme={_isDarkTheme}
										onClick={() => {
											setSelectedConstructionState(stateName)
											setConstructionStateError('')
										}}>
										{stateName}
									</Options>
								)
							})}
						</Flexed>
						{constructionStateError && (
							<Text fontSize={0.7} type="small" color="danger">
								{constructionStateError}
							</Text>
						)}
						<Spacer height={1} />
					</Col>
				)}

				{attributes?.type && (
					<Col>
						<Text type="normal" margin="0rem 0rem 0.19rem 0rem" fontWeight={500} textTransform="capitalize" isDarkTheme={_isDarkTheme}>
							Type <Mandatory>*</Mandatory>
						</Text>
						<Flexed direction="row" flexWrap="wrap" gap={0.5}>
							{attributes?.type?.map((typeName: any) => {
								return (
									<Options
										active={typeName === selectedType}
										isDarkTheme={_isDarkTheme}
										onClick={() => {
											setSelectedType(typeName)
											setTypeError('')
										}}>
										{typeName}
									</Options>
								)
							})}
						</Flexed>
						{typeError && (
							<Text fontSize={0.7} type="small" color="danger">
								{typeError}
							</Text>
						)}
						<Spacer height={1} />
					</Col>
				)}

				{attributes?.features && (
					<Col>
						<Text type="normal" margin="0rem 0rem 0.19rem 0rem" fontWeight={500} textTransform="capitalize" isDarkTheme={_isDarkTheme}>
							Features <Mandatory>*</Mandatory>
						</Text>
						<Flexed direction="row" flexWrap="wrap" gap={0.5}>
							{attributes?.features?.map((featuresName: any) => {
								return (
									<Options
										active={selectedFeatures?.includes(featuresName)}
										isDarkTheme={_isDarkTheme}
										onClick={() => {
											setSelectedFeatures((pre: any) => {
												if (pre.includes(featuresName)) {
													return pre.filter((item: any) => item !== featuresName)
												} else {
													return [...pre, featuresName]
												}
											})
											setFeaturesError('')
										}}>
										{featuresName}
									</Options>
								)
							})}
						</Flexed>
						{featuresError && (
							<Text fontSize={0.7} type="small" color="danger">
								{featuresError}
							</Text>
						)}
						<Spacer height={1} />
					</Col>
				)}

				{attributes?.areaUnit && (
					<Col>
						<Text type="normal" margin="0rem 0rem 0.19rem 0rem" fontWeight={500} textTransform="capitalize" isDarkTheme={_isDarkTheme}>
							Area unit <Mandatory>*</Mandatory>
						</Text>
						<Flexed direction="row" flexWrap="wrap" gap={0.5}>
							{attributes?.areaUnit?.map((areaUnitName: any) => {
								return (
									<Options
										active={areaUnitName === selectedAreaUnit}
										isDarkTheme={_isDarkTheme}
										onClick={() => {
											setSelectedAreaUnit(areaUnitName)
											setAreaUnitError('')
										}}>
										{areaUnitName}
									</Options>
								)
							})}
						</Flexed>
						{areaUnitError && (
							<Text fontSize={0.7} type="small" color="danger">
								{areaUnitError}
							</Text>
						)}
						<Spacer height={1} />
						<InputField
							error={areaError}
							label="Area"
							required
							value={area}
							handleChange={(e: any) => {
								setAreaError('')
								if (e == '') {
									setAreaError('Area is required')
								}
								if (e > 99999) {
									setAreaError(`The maximum allowed value is 99999`)
								}
								setArea(e)
							}}
							allowOnlyNumbersAndDecimal={true}
						/>
						<Spacer height={1} />
					</Col>
				)}

				{seletedTypeCategory?.title !== 'Cars on Installments' && (
					<>
						{' '}
						<Divider isDarkTheme={_isDarkTheme} />
						<Col>
							<Heading textTransform="uppercase" level={4} fontWeight={700} isDarkTheme={_isDarkTheme} margin={'1rem 0rem'}>
								SET A PRICE
							</Heading>
							<InputField
								error={priceError}
								label="Price"
								value={price}
								required
								useAI={true}
								AiGenerateTitle={'Generate price using AI? Please enter all details to get accurate result.'}
								handleAiGeneratedResponse={() => generateDescription(true)}
								handleChange={(e: any) => {
									setPriceError('')
									if (e == '') {
										setPriceError('Price is required')
									}
									if (e == '0') {
										setPriceError('Price can not be zero')
									}
									if (e > 500000000) {
										setPriceError(`The maximum allowed value is 50 500 million`)
									}
									setPrice(e)
								}}
								allowOnlyNumbersAndDecimal={true}
							/>
							{!priceError && (
								<Text margin="0.1rem 0rem 0rem 0rem" fontSize={0.7} isDarkTheme={_isDarkTheme} type="xsmall">
									{currencyFormate(price)}
								</Text>
							)}
							<Spacer height={1} />
						</Col>
					</>
				)}
				<Divider isDarkTheme={_isDarkTheme} />

				<Col>
					<Heading textTransform="uppercase" level={4} fontWeight={700} isDarkTheme={_isDarkTheme} margin={'1rem 0rem'}>
						YOUR AD'S LOCATION
					</Heading>
					<InputLocationForCreateForm
						label="Location"
						required
						address={location}
						isEdit={editPostDetails?.id ? true : false}
						setAddress={(address: any) => {
							setLocationError('')
							if (address == '') {
								setLocationError('Location is required')
							}
							setLocation(address)
						}}
						error={locationError}
					/>
					<Spacer height={1} />
				</Col>
				<Divider isDarkTheme={_isDarkTheme} />
				<Col>
					<Heading textTransform="uppercase" level={4} fontWeight={700} isDarkTheme={_isDarkTheme} margin={'1rem 0rem'}>
						REVIEW YOUR DETAILS
					</Heading>
				</Col>
				<Col>
					<ProfileContent gap={1}>
						<div>
							<ProfileImg>
								{userImage ? (
									<Img isDarkTheme={_isDarkTheme} url={process.env.REACT_APP_IMAGE_URL + userImage} />
								) : (
									<Text fontSize={8} color="white" fontWeight="bold">
										{_loginUserDetails?.firstname && _loginUserDetails?.firstname[0].toUpperCase()}
									</Text>
								)}
							</ProfileImg>
							<Spacer height={1} />
						</div>
						<div style={{width: '100%'}}>
							<InputField
								error={userNameError}
								label="Name"
								required
								value={userName}
								handleChange={(e: any) => {
									setUserNameError('')
									if (e == '') {
										setUserNameError('Name is required')
									}
									if (e.length < 3) {
										setUserNameError(`A minimum length of 3 characters is allowed. Please edit the field.`)
									}
									if (e?.length <= 70) {
										setUserName(e)
									}
								}}
							/>
							<Spacer height={1} />

							{/* <InputPhoneNo error={phoneError} label="Mobile Phone Number" required value={phone} handleChange={(e: any) => {
                                setPhoneError('');
                                if (e?.value == '') {
                                    setPhoneError('Phone number is required')
                                }
                                setPhone(e?.value)
                            }} /> */}
							<Text fontWeight={500} isDarkTheme={_isDarkTheme} type="normal" margin="0rem 0rem 0.19rem 0rem">
								Mobile Phone Number
							</Text>
							<Text fontWeight={500} isDarkTheme={_isDarkTheme} type="normal">
								+{phone}
							</Text>

							<Spacer height={1} />

							<Flexed direction="row" gap={0.5} align="center" justify="space-between">
								<Text type="normal" disabled={!showPhoneNoInAds} fontWeight={500} isDarkTheme={_isDarkTheme}>
									Show my phone number in ads
								</Text>
								<div>
									<Switch
										onClick={() => {
											setShowPhoneNoInAds(!showPhoneNoInAds)
										}}
										toggle={showPhoneNoInAds}
										disabled={false}>
										<Dot toggle={showPhoneNoInAds} />
									</Switch>
								</div>
							</Flexed>
							<Spacer height={1} />
						</div>
					</ProfileContent>
				</Col>
				<Divider isDarkTheme={_isDarkTheme} />
				<Footer isDarkTheme={_isDarkTheme}>
					<Button
						label={editPostDetails?.id ? 'Edit now' : 'Post now'}
						width="100%"
						type="red"
						ifClicked={() => {
							if (formValidation()) {
								generateEstimatedViewPrediction(imageContentForAI.length ? imageContentForAI : '')
							}

							// editPostDetails?.id ? editPost(postId) : addPost()
						}}
					/>
				</Footer>
			</Row>
			{estimatedViewsModel && (
				<AlertModel
					open={estimatedViewsModel}
					onCloseModal={() => setEstimatedViewsModel(false)}
					estimatedViews={estimatedViews}
					label={`Your ad estimated views are ${formatNumber(estimatedViews)}. Would you like to continue to place ad?`}
					onClick={() => {
						setEstimatedViewsModel(false)
						editPostDetails?.id ? editPost(postId) : addPost()
					}}
				/>
			)}

			{potentialBuyerModel && (
				<PotentialBuyersModel
					open={potentialBuyerModel}
					onCloseModal={() => {
						setPotentialBuyerModel(false)
						_navigate('/payments/packages')
					}}
					title={`Potential buyers for your ad`}
					label={`Choose a buyer to sell your ad by contacting or chat with them`}
					users={potentialBuyers}
					adId={createdAd?.id}
					onClick={() => {
						setPotentialBuyerModel(false)
						_navigate('/payments/packages')
					}}
				/>
			)}
			{verifyImageModel && (
				<AlertModel
					open={verifyImageModel}
					onCloseModal={() => {
						setVerifyImageModel(false)
						setImagesContentForAI([])
						setNewImage([])
					}}
					title={`Image verification using title ${seletedMainCategory?.title} and category ${seletedTypeCategory?.title}`}
					label={`${verifyImageResponse} Would you like to continue by using this image?`}
					onClick={() => {
						setVerifyImageModel(false)
					}}
				/>
			)}
		</FormWrapper>
	)
}

export const CustomDiv = styled.div<any>`
	display: flex;
	flex-direction: row;
	align-items: ${({isInput}) => isInput != true && 'center'};
	gap: 1rem;
	${media.xs`
	flex-direction: column;
	gap: 0.5rem;`};
	${media.sm`flex-direction: column;
	gap: 0.5rem;
	`};
	${media.md`flex-direction: row;
	gap: 1rem;`};
`
const FormWrapper = styled(Container)<any>`
	border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.silver)};
	border-radius: 0.3rem;
	margin-bottom: 1rem;
`

const DeleteImage = styled(BsXLg)<IProps>`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.white : palette.white)};
	font-size: 1.3rem;
	background: ${({isDarkTheme}) => (isDarkTheme ? palette.danger : palette.danger)};
	width: 1.2rem;
	height: 1.2rem;
	border-radius: 2rem;
	padding: 0.3rem;
	position: absolute;
	top: 0.25rem;
	right: 0.25rem;
`

const Footer = styled.div<any>`
	padding: 1rem;
`

const InputWrapper = styled.div`
	position: relative;
	width: 120px;
	height: 120px;
	margin-bottom: 0.5rem;
`

const Upload = styled.label`
	padding: 1rem;
	width: 120px;
	height: 120px;
	border: 0.063rem dashed ${palette.light_gray};
	border-radius: 0.1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.7rem;
	flex-direction: column;
	cursor: pointer;
`

const FileInput = styled.input`
	display: none !important;
`

const ImgWrapper = styled.div`
	position: relative;
	width: 120px;
	height: 120px;
	margin-bottom: 0.5rem;
`

const ShowImage = styled.img`
	width: 120px;
	height: 120px;
	object-fit: cover;
	border: 0.063rem dashed ${palette.light_gray};
`

const Change = styled(Text)<any>`
	cursor: pointer;
	text-decoration: underline;
`

const Options = styled.div<any>`
	border: 1px solid ${({active, isDarkTheme}) => (active ? palette.red : isDarkTheme ? palette.light_silver : palette.silver)};
	border-radius: 0.3rem;
	font-size: 14px;
	background: ${({active}) => (active ? palette.red : '')};
	color: ${({active, isDarkTheme}) => (active ? palette.white : isDarkTheme ? palette.light_gray : palette.dark_gray)};
	font-weight: 500;
	padding: 0.5rem 0.8rem;
	cursor: pointer;
`

export const Switch = styled.div<any>`
	display: flex;
	align-items: center;
	justify-content: ${({toggle}) => (toggle ? 'flex-end' : 'flex-start')};
	background-color: ${({toggle}) => (toggle ? palette.red : palette.gray)};
	width: 40px;
	height: 26px;
	border-radius: 1rem;
	padding: 0.5rem 0.1rem;
	transition: justify-content 2s, transform 2s;
	border: 0.063rem solid ${({toggle}) => (toggle ? palette.red : palette.gray)};
	cursor: ${({disabled}) => (disabled ? 'no-drop' : 'pointer')};
	opacity: ${({disabled}) => (disabled ? '0.4' : '1')};
`

export const Dot = styled.div<any>`
	width: 20px;
	height: 20px;
	border-radius: 100%;
	background-color: ${palette.white};
	cursor: pointer;
`

const ProfileImg = styled.div<any>`
	position: relative;
	width: 12rem;
	height: 12rem;
	border-radius: 100%;
	overflow: hidden;
	background: ${palette.red};
	color: ${palette.white};
	display: flex;
	justify-content: center;
	cursor: pointer;
	align-items: center;
`

const Img = styled.div<any>`
	background: ${({url, defaultImage}) => (url ? `url(${url})` : defaultImage ? '' : palette.silver)};
	background-repeat: no-repeat;
	background-size: cover;
	border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.silver)};
	width: 12rem;
	height: 12rem;
	border-radius: 100%;
	cursor: pointer;
`

const ProfileContent = styled(Flexed)<any>`
	${media.xs`flex-direction: column`};
	${media.sm`flex-direction: row`};
	${media.md`flex-direction: row`};
`

export default CreatePostForm
