/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * LogInGoogle Component
 *
 * @component
 * @description Component for Google login button.
 *
 * @param {Object} props - Component props.
 * @param {Function} props.setIsLoading - Callback function to set the loading state.
 * @param {Function} props.setShowPhoneInput - Callback function to set the phone input visibility.
 * @param {Function} props.setUserId - Callback function to set the user ID.
 * @param {Function} props.setShowOtpInput - Callback function to set the OTP input visibility.
 * @param {Function} props.setPhone - Callback function to set the phone number.
 *
 */

import styled from 'styled-components'
import {Text} from '../../styled/shared'
import {useSelector} from 'react-redux'
import {palette} from '../../styled/colors'
import {api} from '../api/callAxios'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import {loginUserDetailsAction} from '../../actions/authActions'
import {useDispatch} from 'react-redux'

const LogInGoogle = ({setIsLoading, setShowPhoneInput, setUserId, setShowOtpInput, setPhone}: any) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	let _navigate = useNavigate()
	const _dispatch = useDispatch()
	const responseGoogle = async (response: any) => {
		setIsLoading(true)
		await api
			.post(`/customers/social_login`, {
				provider: 'google',
				socialId: '123456789012345678901',
				fcmToken: '',
				name: 'google',
				email: 'google@gmail.com'
			})
			.then(async (response) => {
				setIsLoading(false)
				if (response?.data?.data?.user?.user?.isVerified) {
					localStorage.setItem('authorization', response?.data?.data?.user?.accessToken)
					localStorage.setItem('userStatus', response?.data?.data?.user?.user_login)
					_dispatch(loginUserDetailsAction(response?.data?.data?.user?.user))
					_navigate('/post')
				} else if (response?.data?.data?.user?.user?.phone) {
					setPhone(response?.data?.data?.user?.user?.phone)
					setShowOtpInput(true)
					setShowPhoneInput(true)
				} else {
					setUserId(response?.data?.data?.user?.user?.id)
					setShowPhoneInput(true)
				}
			})
			.catch(function (error) {
				setIsLoading(false)
				if (error?.response) {
					toast.error(error?.response?.data?.message)
				}
			})
	}

	const onFailure = async (response: any) => {
		console.log(response)
	}
	return (
		<>
			<Button
				onClick={() => {
					responseGoogle('C')
				}}
				justify="center"
				isDarkTheme={_isDarkTheme}>
				<Icon src="/images/icons/google.svg" />

				<Text type="normal" isDarkTheme={_isDarkTheme}>
					Continue with Google
				</Text>
			</Button>
		</>
	)
}
const Button = styled.button<any>`
	align-items: center;
	padding: 0.563rem 0rem;
	font-weight: 600;
	width: 100%;
	cursor: pointer;
	white-space: nowrap;
	font-size: 0.875rem;
	position: relative;
	display: flex;
	justify-content: ${({justify}) => justify};
	transition: all ease 0.25s;
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	border: 0.063rem solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.silver)};
	border-radius: 0.375rem;
`

const Icon = styled.img`
	margin-right: 0.7rem;
	height: 1.563rem;
	width: 1.563rem;
`

export default LogInGoogle
