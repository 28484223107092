/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component for handling the payment methods during the featured ad upgrade.
 *
 * This component allows users to view and select payment methods for upgrading their ad to a featured position.
 * It displays the selected featured ad package details, price breakdown, and facilitates payment through credit/debit cards.
 *
 * @component
 */

import React, {useEffect, useState} from 'react'
import {Heading, Spacer, Text, Flexed, Divider, Loading} from '../../styled/shared'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {useSelector, useDispatch} from 'react-redux'
import styled from 'styled-components'
import {palette} from '../../styled/colors'
import {api} from '../../components/api/callAxios'
import {useNavigate, createSearchParams} from 'react-router-dom'
import moment from 'moment-timezone'
import {adIdForFeaturedAd, featuredAdAction} from '../../actions/authActions'
import {toast} from 'react-toastify'

const PaymentMethod = () => {
	let _navigate = useNavigate()
	const _dispatch = useDispatch()
	const _isDarkTheme = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const _selectedFeaturedAdPackage: any = useSelector<any>((state: any) => state.auth.selectedFeaturedAdPackage)
	const _adIdForFeaturedAd: any = useSelector<any>((state: any) => state.auth.adIdForFeaturedAd)
	let _saleTax: any = _selectedFeaturedAdPackage?.totalPrice * 0.05
	const [isLoading, setIsLoading] = useState(false)

	const createFeatureAds = async () => {
		setIsLoading(true)
		if (_adIdForFeaturedAd) {
			await api
				.post(`featureAds/feature_ad`, {
					adsId: _adIdForFeaturedAd,
					packageName: _selectedFeaturedAdPackage?.title,
					price: parseFloat(_selectedFeaturedAdPackage?.totalPrice) + parseFloat(_saleTax),
					validFor: _selectedFeaturedAdPackage.validity,
					packageDate: moment().format('DD-MM-YYYY HH:mm'),
					expiryDate: moment().add(_selectedFeaturedAdPackage?.validity, 'days').format('DD-MM-YYYY HH:mm')
				})
				.then(async (response) => {
					setIsLoading(false)
					toast.success('Your ad is featured now.')
					_navigate({
						pathname: '/post-details',
						search: createSearchParams({
							id: _adIdForFeaturedAd
						}).toString()
					})
					_dispatch(featuredAdAction(null))
					_dispatch(adIdForFeaturedAd(null))
				})
				.catch(function (error) {
					console.log(error)
					setIsLoading(false)
				})
		} else {
			toast.success('Add id is missing.')
		}
	}

	useEffect(() => {
		if (_selectedFeaturedAdPackage === null) {
			_navigate('/payments/packages')
		}
	}, [_selectedFeaturedAdPackage])
	return (
		<>
			<Wrapper>
				<Row justifyContent="center">
					<Col>
						<Spacer height={2} />
					</Col>

					<Col xl={10} lg={10}>
						<Heading textTransform="inherit" level={4} fontWeight={700} isDarkTheme={_isDarkTheme} margin={'0rem 0rem 0.5rem 0rem'}>
							Payment Methods
						</Heading>
						<InfoCard margin="1rem 0rem 0rem 0rem" direction="row" gap={1.3} align="center" justify="space-between">
							<Text textTransform="inherit" type="large" fontWeight={500} isDarkTheme={_isDarkTheme}>
								{_selectedFeaturedAdPackage?.title}
							</Text>
							<Text textTransform="inherit" type="large" fontWeight={500} isDarkTheme={_isDarkTheme}>
								${parseFloat(_selectedFeaturedAdPackage?.totalPrice) + parseFloat(_saleTax)}
							</Text>
						</InfoCard>
						<Flexed direction="row" justify="flex-end">
							<Text margin="0.2rem 0rem" isDarkTheme={_isDarkTheme} type="small">
								* Total price includes 5% sales tax
							</Text>
						</Flexed>
						<Spacer height={1} />
						<Heading textTransform="inherit" level={5} fontWeight={700} isDarkTheme={_isDarkTheme}>
							Price details
						</Heading>
						<Flexed direction="row" align="baseline" justify="space-between" gap={1} margin="0.5rem 0rem 0rem 0rem">
							<Text type="medium" isDarkTheme={_isDarkTheme}>
								Price
							</Text>
							<Text type="medium" isDarkTheme={_isDarkTheme}>
								${_selectedFeaturedAdPackage?.price}
							</Text>
						</Flexed>
						<Flexed direction="row" align="baseline" justify="space-between" gap={1} margin="0.5rem 0rem 0rem 0rem">
							<Text type="medium" isDarkTheme={_isDarkTheme}>
								Discount
							</Text>
							<Text type="medium" isDarkTheme={_isDarkTheme}>
								-$ {_selectedFeaturedAdPackage?.price - _selectedFeaturedAdPackage?.totalPrice}
							</Text>
						</Flexed>
						<Flexed direction="row" align="baseline" justify="space-between" gap={1} margin="0.5rem 0rem 0rem 0rem">
							<Text type="medium" isDarkTheme={_isDarkTheme}>
								Sales Tax (5%)
							</Text>
							<Text type="medium" isDarkTheme={_isDarkTheme}>
								${_saleTax}
							</Text>
						</Flexed>
						<Divider margin="1rem 0rem" />
						<Flexed direction="row" align="baseline" justify="space-between" gap={1}>
							<Heading textTransform="inherit" level={5} fontWeight={700} isDarkTheme={_isDarkTheme}>
								Total
							</Heading>
							<Heading textTransform="inherit" level={5} fontWeight={700} isDarkTheme={_isDarkTheme}>
								${parseFloat(_selectedFeaturedAdPackage?.totalPrice) + parseFloat(_saleTax)}
							</Heading>
						</Flexed>
						<Spacer height={3} />

						<Heading margin="0rem 0rem 0.8rem 0rem" textTransform="inherit" level={5} fontWeight={700} isDarkTheme={_isDarkTheme}>
							{' '}
							Pay by Credit / Debit Card
						</Heading>
						<InfoCard
							pointer
							width="360px"
							onClick={() => {
								createFeatureAds()
							}}>
							<Flexed margin="1rem" align="center" gap={0.8} justify="center">
								<CreditCard src="/images/credit_card.png" alt="credit_card" />
								<Heading textTransform="inherit" level={5} fontWeight={700} isDarkTheme={_isDarkTheme}>
									Card Payment
								</Heading>
								<Text isDarkTheme={_isDarkTheme} type="normal">
									Pay with Visa, Mastercard, or JCB
								</Text>
							</Flexed>
						</InfoCard>
					</Col>
				</Row>
				<Spacer height={5} />
			</Wrapper>
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</>
	)
}

const Wrapper = styled(Container)`
	min-height: calc(100vh - 70px);
`

const InfoCard = styled(Flexed)<any>`
	border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.silver)};
	border-radius: 4px;
	padding: 2rem;
	width: ${({width}) => width};
	cursor: ${({pointer}) => pointer};
`

const CreditCard = styled.img`
	border-radius: 4px;
	width: 4.5rem;
`

export default PaymentMethod
