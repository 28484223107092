/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * SubHeader component
 * @description SubHeader component displaying business categories and types.
 *
 * @component
 *
 * @param {Object} props - Component properties.
 * @param {Function} props.setBusniessType - Function to set the business type.
 * @param {Function} props.setTypeCategory - Function to set the type category.
 */

import React, {useEffect, useState, useRef} from 'react'
import {FaAngleDown} from 'react-icons/fa'
import {useSelector} from 'react-redux'
import {useNavigate, useLocation} from 'react-router-dom'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from '../../styled/colors'
import {Text, Flexed, Spacer} from '../../styled/shared'
import {api} from '../api/callAxios'

const SubHeader = ({setBusniessType, setTypeCategory}: any) => {
	const {pathname} = useLocation()
	const _ref: any = useRef(null)
	let _navigate = useNavigate()
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	const [allCategories, setAllCategories] = useState<any>([])
	const [subCategories, setSubCategories] = useState<any>([])
	const [openDrop, setOpenDrop] = useState(false)

	const getPost = async () => {
		await api
			.get(`/business-type`)
			.then(async (response: any) => {
				setAllCategories(response?.data)
			})
			.catch(function (error: any) {
				console.log(error)
			})
	}

	const getSpecificPost = async () => {
		await api
			.post(`/ads/ads_categories`, {
				userId: _loginUserDetails?.id ? _loginUserDetails?.id : '',
				categoryOne: allCategories[0]?.typeCategory[0]?.title,
				categoryTwo: allCategories[1]?.typeCategory[0]?.title,
				categoryThree: allCategories[2]?.typeCategory[0]?.title,
				categoryFour: allCategories[3]?.typeCategory[0]?.title
			})
			.then(async (response: any) => {
				setSubCategories(response?.data)
			})
			.catch(function (error: any) {
				console.log(error)
			})
	}

	useEffect(() => {
		getPost()
		const handleClickOutside = (event: any) => {
			if (_ref?.current && !_ref?.current?.contains(event.target)) {
				setOpenDrop(false)
			}
		}
		window.addEventListener('click', handleClickOutside)
		return () => {
			window.removeEventListener('click', handleClickOutside)
		}
	}, [])

	useEffect(() => {
		if (allCategories.length > 0) {
			getSpecificPost()
		}
	}, [allCategories, _loginUserDetails?.id])

	return (
		<Wrapper>
			<Container style={{height: '100%'}}>
				<Row style={{height: '100%'}}>
					<Col style={{position: 'relative', height: '100%'}}>
						<Content
							direction="row"
							align="center"
							justifyContent={
								(subCategories?.categoryTwoResults?.length > 0 && subCategories?.categoryThreeResults?.length > 0) ||
								(subCategories?.categoryTwoResults?.length > 0 && subCategories?.categoryFourResults?.length > 0) ||
								(subCategories?.categoryThreeResults?.length > 0 && subCategories?.categoryFourResults?.length > 0)
							}
							flexWrap="wrap">
							{allCategories?.map((val: any, index: any) => {
								return (
									<MenuWrap key={index + val?.title} pointer direction="row" gap={0.5} align="center">
										<Text
											id="heading"
											type="small"
											pointer
											isDarkTheme={_isDarkTheme}
											fontWeight={700}
											onClick={() => {
												setBusniessType(val?.title)
												setTypeCategory('')
												_navigate('/post')
											}}>
											{val?.title}
										</Text>
										<Arrow cursor="pointer" openDrop={openDrop} isDarkTheme={_isDarkTheme} />
										<DropContent isDarkTheme={_isDarkTheme}>
											{val?.typeCategory.map((type: any, index: any) => {
												return (
													<div key={index + type?.title}>
														<Menu
															pointer
															type="small"
															isDarkTheme={_isDarkTheme}
															onClick={() => {
																setBusniessType(val?.title)
																setTypeCategory(type?.title)
																_navigate('/post')
															}}>
															{type?.title}
														</Menu>
													</div>
												)
											})}
										</DropContent>
									</MenuWrap>
								)
							})}

							{subCategories?.categoryFourResults?.length > 0 && (
								<MenuLink
									pointer
									isDarkTheme={_isDarkTheme}
									type="small"
									isCentered
									fontWeight={700}
									onClick={() => {
										setBusniessType(subCategories?.categoryFourResults[0]?.businessType)
										setTypeCategory(allCategories[3]?.typeCategory[3]?.title)
										_navigate('/post')
									}}>
									{allCategories[3]?.typeCategory[3]?.title}
								</MenuLink>
							)}
							{subCategories?.categoryThreeResults?.length > 0 && (
								<MenuLink
									pointer
									isDarkTheme={_isDarkTheme}
									type="small"
									isCentered
									fontWeight={700}
									onClick={() => {
										setBusniessType(subCategories?.categoryThreeResults[0]?.businessType)
										setTypeCategory(allCategories[2]?.typeCategory[0]?.title)
										_navigate('/post')
									}}>
									{allCategories[2]?.typeCategory[0]?.title}
								</MenuLink>
							)}
							{subCategories?.categoryTwoResults?.length > 0 && (
								<MenuLink
									pointer
									isDarkTheme={_isDarkTheme}
									type="small"
									isCentered
									fontWeight={700}
									onClick={() => {
										setBusniessType(subCategories?.categoryTwoResults[0]?.businessType)
										setTypeCategory(allCategories[1]?.typeCategory[0]?.title)
										_navigate('/post')
									}}>
									{allCategories[1]?.typeCategory[0]?.title}
								</MenuLink>
							)}
						</Content>
					</Col>
				</Row>
			</Container>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	position: relative;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
	z-index: 4;
	height: 66px;
	display: none;
	${media.md`display:block`};
`

const Menu = styled(Text)`
	padding: 0.7rem 1rem;
	&:hover {
		color: ${palette.white};
		background: ${palette.red};
	}
`

const DropContent = styled.div<any>`
	display: none;
	position: absolute;
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	right: ${({right}) => (right ? `${right}rem` : 'auto')};
	box-shadow: ${palette.shadow};
	z-index: 5;
	border-radius: 0.5rem;
	overflow: hidden;
	width: fit-content;
	min-width: 140px;
	min-height: 4rem;
	top: 4rem;
	${media.xs`left:0;`}
	${media.lg`min-width: 160px;`}
`

const Arrow = styled(FaAngleDown)<any>`
	transition: transform 0.2s;
	transform: rotate(0deg);
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
`

const MenuWrap = styled(Flexed)`
	height: 100%;
	&:hover #heading {
		color: ${palette.red};
	}
	&:hover ${Arrow} {
		color: ${palette.red};
	}

	&:hover ${DropContent} {
		display: block;
	}

	&:hover ${Arrow} {
		transform: rotate(180deg);
	}
`

const MenuLink = styled(Menu)`
	display: none;
	${media.lg`display:block`};
	&:hover {
		color: ${palette.red};
		background: transparent !important;
	}
`

const Content = styled(Flexed)<any>`
	position: relative;
	height: 100%;
	justify-content: space-around;
	gap: 1.2rem;
	@media screen and (min-width: 992px) and (max-width: 9999px) {
		justify-content: ${({justifyContent}) => (justifyContent ? 'space-between' : 'flex-start')};
		gap: ${({justifyContent}) => (justifyContent ? '1.2rem' : '3rem')};
	}
`

export default SubHeader
