/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * FilterMenu Component
 *
 * @description Component for rendering filter menu items with various options like categories, business types, etc.
 *
 * @component
 *
 * @param {Object} props - The properties of the component.
 * @param {Function} props.clearFilter - Function to clear the filters.
 * @param {string} props.idName - The ID name for the filter.
 * @param {string} props.heading - The heading/title for the filter menu.
 * @param {Object} props.filter - The filter options and data.
 * @param {string} props.businessType - The selected business type.
 * @param {Function} props.setBusniessType - Function to set the business type.
 * @param {string} props.typeCategory - The selected type category.
 * @param {Function} props.setTypeCategory - Function to set the type category.
 * @param {string} props.subCategory - The selected sub-category.
 * @param {Function} props.setSubCategory - Function to set the sub-category.
 * @param {Array|string} props.selected - The selected filter items.
 * @param {Function} props.setSelected - Function to set the selected filter items.
 * @param {Function} props.onChangeCB - Callback function triggered on filter change.
 *
 * @returns {React.ReactNode} The rendered FilterMenu component.
 */

/**
 * Sidebar Component
 * Description: This component represents a sidebar that contains different filter menus and options for refining search results. It includes functionality to close the sidebar, clear filters, and set side filters based on user selections.
 *
 * Props:
 * - clearFilter: () => void - Callback function to clear filters.
 * - onClose: () => void - Callback function triggered when the sidebar is closed.
 * - setSideFilters: (filters: any) => void - Callback function triggered when side filters are set.
 * - businessType: any - Business type for filtering.
 * - setBusniessType: (type: any) => void - Callback function triggered when the business type is changed.
 * - typeCategory: any - Type category for filtering.
 * - setTypeCategory: (category: any) => void - Callback function triggered when the type category is changed.
 * - subCategory: any - Subcategory for filtering.
 * - setSubCategory: (subcategory: any) => void - Callback function triggered when the subcategory is changed.
 * - filterMaxPriceVal: any - Maximum price value for filtering.
 */

import React, {useEffect, useState, useRef} from 'react'
import {palette} from '../../styled/colors'
import {BsXLg} from 'react-icons/bs'
import styled from 'styled-components'
import {media} from 'styled-bootstrap-grid'
import {Heading, Divider, Flexed, Text, Spacer} from '../../styled/shared'
import {FaAngleDown} from 'react-icons/fa'
import {useSelector} from 'react-redux'
import {api} from '../api/callAxios'
import PriceRangerFilter from './PriceRangeFilter'
import InputFilters from './InputFilters'
import {calculateDownPayment} from '../../constants/commonFunctions'

interface FilterMenuProps {
	clearFilter?: any
	idName: string
	heading: string
	filter: any
	businessType?: any
	setBusniessType?: any
	typeCategory?: any
	setTypeCategory?: any
	subCategory?: any
	setSubCategory?: any
	selected?: any
	setSelected?: any
	onChangeCB?: any
}

interface IProps {
	dropOpen?: boolean
	isDarkTheme?: boolean
}

interface SidebarProps {
	clearFilter?: any
	onClose?: () => void
	setSideFilters: any
	businessType?: any
	setBusniessType?: any
	typeCategory?: any
	setTypeCategory?: any
	subCategory?: any
	setSubCategory?: any
	filterMaxPriceVal?: any
}

interface DataObject {
	title: string[]
}

const FilterMenu = ({clearFilter, idName, heading, filter, businessType, setBusniessType, typeCategory, setTypeCategory, subCategory, setSubCategory, selected, setSelected, onChangeCB}: FilterMenuProps) => {
	const [dropDownOpen, setDropDownOpen] = useState(true)
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const [activeType, setActiveType] = useState('')
	const [subType, setSubType] = useState('')
	const handleOnSelect = (item: any, filterType: string) => {
		if (filterType == 'areaUnit' || filterType == 'carDocuments' || filterType == 'assembly' || filterType == 'transmission') {
			if (selected != item?.title) {
				setSelected(item?.title)
				onChangeCB(item?.title, filterType)
			} else {
				setSelected('')
				onChangeCB('', filterType)
			}
			// } else if (selected.findIndex((x: number) => x !== item?.id) == -1) {
		} else if (selected?.find((x: number) => x == item?.title) == undefined) {
			let _selectedItems = selected
			_selectedItems?.push(item?.title)
			setSelected(_selectedItems)
			onChangeCB(_selectedItems, filterType)
		} else {
			let _selectedItems = selected?.filter((x: number) => x != item?.title)
			setSelected(_selectedItems)
			onChangeCB(_selectedItems, filterType)
		}
	}
	return (
		<div>
			<Menu
			// onClick={() => {
			// 	setDropDownOpen(!dropDownOpen)
			// }}
			>
				<Drop direction="row" align="center" justify="space-between">
					<Heading fontWeight={700} isDarkTheme={_isDarkTheme}>
						{heading}
					</Heading>
					{/* <Arrow dropOpen={dropDownOpen} isDarkTheme={_isDarkTheme} /> */}
				</Drop>
				<Spacer height={0.5} />
				{heading === 'Categories' && (
					<>
						<Text
							type="normal"
							pointer
							fontWeight={500}
							isDarkTheme={_isDarkTheme}
							onClick={() => {
								clearFilter()
							}}>
							All categories
						</Text>
						<Text
							margin="0.5rem 0rem 0rem 1rem"
							type="normal"
							pointer
							fontWeight={500}
							isDarkTheme={_isDarkTheme}
							onClick={() => {
								setBusniessType(filter?.businessType)
								setTypeCategory('')
								setSubCategory('')
							}}>
							{filter?.businessType}
						</Text>
						{filter?.typeCategorySubcategories?.length > 0 && (
							<Text
								margin="0.5rem 0rem 0rem 2rem"
								type="normal"
								pointer
								fontWeight={500}
								isDarkTheme={_isDarkTheme}
								onClick={() => {
									setBusniessType(filter?.businessType)
									setTypeCategory(filter?.typeCategory)
									setSubCategory('')
								}}>
								{filter?.typeCategory}
							</Text>
						)}
						<Text
							margin="0.5rem 0rem 0rem 3rem"
							type="normal"
							isDarkTheme={_isDarkTheme}
							pointer
							fontWeight={500}
							onClick={() => {
								setBusniessType(filter?.businessType)
								setTypeCategory(filter?.typeCategory)
								setSubCategory(filter?.subCategory)
							}}>
							{filter?.subCategory}
						</Text>
					</>
				)}
			</Menu>

			<DropWrapper gap={0.5} dropOpen={dropDownOpen}>
				{heading === 'Categories' && (businessType || typeCategory || subCategory) && (
					<>
						{(filter?.typeCategorySubcategories?.length === 0 || filter?.typeCategorySubcategories == undefined) &&
							filter?.categories?.map((i: any, key: number) => {
								return (
									<DropList
										margin="0.5rem 0rem 0rem 1rem"
										direction="row"
										align="center"
										gap={0.5}
										key={'typeCategorie' + key}
										onClick={() => {
											setTypeCategory(i?.typeCategory)
											setActiveType(i?.typeCategory)
											setSubCategory('')
										}}>
										<Label htmlFor={'typeCategorie' + key} isDarkTheme={_isDarkTheme} active={i?.typeCategory === activeType}>
											{i?.typeCategory} <span>({i?.count})</span>
										</Label>
									</DropList>
								)
							})}
						{filter?.typeCategorySubcategories?.map((i: any, key: number) => {
							return (
								<DropList
									margin="0.5rem 0rem 0rem 2rem"
									direction="row"
									align="center"
									gap={0.5}
									key={'subCategorie' + key}
									onClick={() => {
										setSubCategory(i?.subCategory)
										setSubType(i?.subCategory)
									}}>
									<Label htmlFor={'subCategorie' + key} isDarkTheme={_isDarkTheme} active={i?.subCategory === subType}>
										{i?.subCategory ? i?.subCategory : 'unKnown'} <span>({i?.count})</span>
									</Label>
								</DropList>
							)
						})}
					</>
				)}
				{heading != 'Categories' &&
					heading != 'Car Documents' &&
					heading != 'Area Unit' &&
					heading != 'Assembly' &&
					heading != 'Transmission' &&
					heading != 'Features' &&
					filter?.map((i: any, key: number) => {
						return (
							<DropList direction="row" align="center" gap={0.5} key={idName + key}>
								<input id={idName + key} name={i?.id} type="checkbox" checked={selected?.find((x: any) => x == i?.id)} onClick={() => handleOnSelect(i, idName)} />
								<Label htmlFor={idName + key} isDarkTheme={_isDarkTheme}>
									{i?.title} <span>({i?.count})</span>
								</Label>
							</DropList>
						)
					})}
				{(heading === 'Area Unit' || heading === 'Car Documents' || heading === 'Assembly' || heading === 'Transmission') &&
					filter?.map((i: any, key: number) => {
						return (
							<DropList direction="row" align="center" gap={0.5} key={idName + key}>
								<Label htmlFor={idName + key} active={i?.title === selected} isDarkTheme={_isDarkTheme} onClick={() => handleOnSelect(i, idName)}>
									{i?.title} <span>({i?.count})</span>
								</Label>
							</DropList>
						)
					})}
				{heading === 'Features' &&
					Object.entries(filter).map(([title, count]) => (
						<DropList direction="row" align="center" gap={0.5} key={idName + Math.random()}>
							<input id={title} name={title} type="checkbox" checked={selected?.find((x: any) => x == title)} onClick={() => handleOnSelect({title: title}, idName)} />
							<Label htmlFor={title} isDarkTheme={_isDarkTheme}>
								{title} ({count})
							</Label>
						</DropList>
					))}
			</DropWrapper>
		</div>
	)
}

const Sidebar = ({clearFilter, onClose, setSideFilters, filterMaxPriceVal, businessType, setBusniessType, typeCategory, setTypeCategory, subCategory, setSubCategory}: SidebarProps) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const [downPayMentRange, setDownPayMentRange] = useState<any>({lowestPayment: 0, highestPayment: 0})
	const [monthlyInstallmentRange, setMonthlyInstallmentRange] = useState<any>({lowestPayment: 0, highestPayment: 0})
	const [featuresCounts, setFeaturesCounts] = useState<{[key: string]: number}>({})
	const [filtersList, setFiltersList] = useState<any>([])
	const [selectedBrandFilter, setSelectedBrandFilter] = useState<any>([])
	const [selectedDeviceTypeFilter, setSelectedDeviceTypeFilter] = useState<any>([])
	const [selectedTypeFilter, setSelectedTypeFilter] = useState<any>([])
	const [selectedConditionFilter, setSelectedConditionFilter] = useState<any>([])
	const [selectedFurnishedFilter, setSelectedFurnishedFilter] = useState<any>([])
	const [selectedFeaturesFilter, setSelectedFeaturesFilter] = useState<any>([])
	const [selectedConstructionStateFilter, setSelectedConstructionStateFilter] = useState<any>([])
	const [selectedRegisteredFilter, setSelectedRegisteredFilter] = useState<any>([])
	const [selectedFuelFilter, setSelectedFuelFilter] = useState<any>([])
	const [selectedInstallmentPlanFilter, setSelectedInstallmentPlanFilter] = useState<any>([])
	const [selectedCarDocumentsFilter, setSelectedCarDocumentsFilter] = useState<any>('')
	const [selectedAssemblyFilter, setSelectedAssemblyFilter] = useState<any>('')
	const [selectedTransmissionFilter, setSelectedTransmissionFilter] = useState<any>('')
	const [selectedAreaUnitFilter, setSelectedAreaUnitFilter] = useState<any>('')
	const [selectedAreaFilter, setSelectedAreaFilter] = useState<any>([0, 99999])
	const [selectedYearFilter, setSelectedYearFilter] = useState<any>([1900, new Date().getFullYear() + 1])
	const [selectedKmDrivenFilter, setSelectedKmDrivenFilter] = useState<any>([0, 999999])
	const [selectedPriceFilter, setSelectedPriceFilter] = useState<any>([0, 0])
	const [selectedBathRoomsFilter, setSelectedBathRoomsFilter] = useState<any>([0, 0])
	const [selectedBedRoomsFilter, setSelectedBedRoomsFilter] = useState<any>([0, 0])
	const [selectedFloorLevelFilter, setSelectedFloorLevelFilter] = useState<any>([0, 0])
	const [selectedStoreysFilter, setSelectedStoreysFilter] = useState<any>([0, 0])
	const [selectedDownPaymentFilter, setSelectedDownPaymentFilter] = useState<any>([0, 0])
	const [selectedMonthlyInstallmentFilter, setSelectedMonthlyInstallmentFilter] = useState<any>([0, 0])

	const [refresh, setRefresh] = useState<any>(false)

	useEffect(() => {
		setRefresh(false)
		fetchAllFilters()
	}, [businessType, typeCategory, subCategory])

	const fetchAllFilters = async () => {
		await api
			.post(`/ads/search_categories`, {
				businessType: businessType,
				typeCategory: typeCategory,
				subCategory: subCategory
			})
			.then(async (response) => {
				setFiltersList(response?.data)
				setFeaturesCounts({})
				setSelectedBrandFilter([])
				setSelectedDeviceTypeFilter([])
				setSelectedTypeFilter([])
				setSelectedConditionFilter([])
				setSelectedFurnishedFilter([])
				setSelectedFeaturesFilter([])
				setSelectedConstructionStateFilter([])
				setSelectedFuelFilter([])
				setSelectedInstallmentPlanFilter([])
				setSelectedRegisteredFilter([])
				setSelectedCarDocumentsFilter('')
				setSelectedAssemblyFilter('')
				setSelectedTransmissionFilter('')
				setSelectedAreaUnitFilter('')
				setSelectedAreaFilter([0, 99999])
				setSelectedYearFilter([1900, new Date().getFullYear() + 1])
				setSelectedKmDrivenFilter([0, 999999])
				setSelectedPriceFilter([0, 0])
				setSelectedDownPaymentFilter([0, 0])
				setSelectedMonthlyInstallmentFilter([0, 0])
				setSelectedBathRoomsFilter([0, 0])
				setSelectedBedRoomsFilter([0, 0])
				setSelectedFloorLevelFilter([0, 0])
				setSelectedStoreysFilter([0, 0])
				const paymentRange = response?.data?.downPayment && calculateDownPayment(response?.data?.downPayment)
				setDownPayMentRange(paymentRange)
				const monthlyInstallmentRange = response?.data?.monthlyInstallment && calculateDownPayment(response?.data?.monthlyInstallment)
				setMonthlyInstallmentRange(monthlyInstallmentRange)
				setRefresh(true)
			})
			.catch(function (error) {
				console.log(error)
			})
	}

	const handleFiltersChange = async (term: any, filterType: string) => {
		let apiParams = {
			brand: selectedBrandFilter,
			deviceType: selectedDeviceTypeFilter,
			type: selectedTypeFilter,
			condition: selectedConditionFilter,
			price: selectedPriceFilter,
			carDocuments: selectedCarDocumentsFilter,
			assembly: selectedAssemblyFilter,
			transmission: selectedTransmissionFilter,
			areaUnit: selectedAreaUnitFilter,
			constructionState: selectedConstructionStateFilter,
			furnished: selectedFurnishedFilter,
			features: selectedFeaturesFilter,
			registered: selectedRegisteredFilter,
			downPayment: selectedDownPaymentFilter,
			monthlyInstallment: selectedMonthlyInstallmentFilter,
			installmentPlan: selectedInstallmentPlanFilter,
			fuel: selectedFuelFilter,
			area: selectedAreaFilter,
			year: selectedYearFilter,
			kmDriven: selectedKmDrivenFilter,
			bathRooms: selectedBathRoomsFilter,
			bedRooms: selectedBedRoomsFilter,
			floorLevel: selectedFloorLevelFilter,
			storeys: selectedStoreysFilter
		}
		filterType === 'brand' && (apiParams = {...apiParams, brand: term})
		filterType === 'deviceType' && (apiParams = {...apiParams, deviceType: term})
		filterType === 'type' && (apiParams = {...apiParams, type: term})
		filterType === 'condition' && (apiParams = {...apiParams, condition: term})
		filterType === 'price' && (apiParams = {...apiParams, price: term})
		filterType === 'constructionState' && (apiParams = {...apiParams, constructionState: term})
		filterType === 'registered' && (apiParams = {...apiParams, registered: term})
		filterType === 'downPayment' && (apiParams = {...apiParams, downPayment: term})
		filterType === 'monthlyInstallment' && (apiParams = {...apiParams, monthlyInstallment: term})
		filterType === 'installmentPlan' && (apiParams = {...apiParams, installmentPlan: term})
		filterType === 'fuel' && (apiParams = {...apiParams, fuel: term})
		filterType === 'furnished' && (apiParams = {...apiParams, furnished: term})
		filterType === 'features' && (apiParams = {...apiParams, features: term})
		filterType === 'carDocuments' && (apiParams = {...apiParams, carDocuments: term})
		filterType === 'assembly' && (apiParams = {...apiParams, assembly: term})
		filterType === 'transmission' && (apiParams = {...apiParams, transmission: term})
		filterType === 'areaUnit' && (apiParams = {...apiParams, areaUnit: term})
		filterType === 'area' && (apiParams = {...apiParams, area: term})
		filterType === 'year' && (apiParams = {...apiParams, year: term})
		filterType === 'kmDriven' && (apiParams = {...apiParams, kmDriven: term})
		filterType === 'bathRooms' && (apiParams = {...apiParams, bathRooms: term})
		filterType === 'bedRooms' && (apiParams = {...apiParams, bedRooms: term})
		filterType === 'floorLevel' && (apiParams = {...apiParams, floorLevel: term})
		filterType === 'storeys' && (apiParams = {...apiParams, storeys: term})
		setSideFilters(apiParams)
	}

	useEffect(() => {
		if (filtersList?.features?.length > 0) {
			const countOccurrences = () => {
				const counts: {[key: string]: number} = {}
				filtersList?.features?.forEach((data: any) => {
					data?.title?.forEach((title: any) => {
						counts[title] = (counts[title] || 0) + 1
					})
				})
				setFeaturesCounts(counts)
			}
			countOccurrences()
		}
	}, [filtersList])

	return (
		<SideNavBar>
			<SideBarContent isDarkTheme={_isDarkTheme}>
				<span>
					<FilterClose direction="row" margin="0rem 0rem -0.5rem 0rem" justify="flex-end">
						<CloseIcon onClick={onClose} isDarkTheme={_isDarkTheme} />
					</FilterClose>
					<FilterMenu
						clearFilter={clearFilter}
						idName="Categories"
						heading="Categories"
						filter={filtersList}
						businessType={businessType}
						setBusniessType={setBusniessType}
						typeCategory={typeCategory}
						setTypeCategory={setTypeCategory}
						subCategory={subCategory}
						setSubCategory={setSubCategory}
					/>
					<Divider margin="1rem 0rem" isDarkTheme={_isDarkTheme} />
					{refresh && (
						<>
							{filtersList?.year?.length > 0 && typeCategory != '' && typeCategory != undefined && typeCategory != null && (
								<>
									<InputFilters
										label="Year"
										valueTo={selectedYearFilter[0]}
										valueFrom={selectedYearFilter[1]}
										saveFilter={(val: any) => {
											setSelectedYearFilter(val)
											handleFiltersChange(val, 'year')
										}}
									/>
									<Divider margin="1rem 0rem" isDarkTheme={_isDarkTheme} />
								</>
							)}
							{filtersList?.kmDriven?.length > 0 && typeCategory != '' && typeCategory != undefined && typeCategory != null && (
								<>
									<InputFilters
										label="Km's Driven"
										valueTo={selectedKmDrivenFilter[0]}
										valueFrom={selectedKmDrivenFilter[1]}
										saveFilter={(val: any) => {
											setSelectedKmDrivenFilter(val)
											handleFiltersChange(val, 'kmDriven')
										}}
									/>
									<Divider margin="1rem 0rem" isDarkTheme={_isDarkTheme} />
								</>
							)}
							{typeCategory != 'Cars on Installments' && (
								<>
									{' '}
									<PriceRangerFilter
										valueTo={filterMaxPriceVal?.lowestPrice}
										valueFrom={filterMaxPriceVal?.highestPrice}
										saveFilter={(val: any) => {
											setSelectedPriceFilter(val)
											handleFiltersChange(val, 'price')
										}}
									/>
									<Divider margin="2rem 0rem" isDarkTheme={_isDarkTheme} />
								</>
							)}
							{filtersList?.brand?.length > 0 && typeCategory != '' && typeCategory != undefined && typeCategory != null && (
								<>
									<FilterMenu
										idName="brand"
										heading={businessType === 'Vehicles' ? 'Make' : 'Brand'}
										filter={filtersList?.brand}
										selected={selectedBrandFilter}
										setSelected={setSelectedBrandFilter}
										onChangeCB={handleFiltersChange}
									/>
									<Divider margin="1rem 0rem" isDarkTheme={_isDarkTheme} />
								</>
							)}
							{filtersList?.registered?.length > 0 && typeCategory != '' && typeCategory != undefined && typeCategory != null && (
								<>
									<FilterMenu idName="registered" heading="Registered" filter={filtersList?.registered} selected={selectedRegisteredFilter} setSelected={setSelectedRegisteredFilter} onChangeCB={handleFiltersChange} />
									<Divider margin="1rem 0rem" isDarkTheme={_isDarkTheme} />
								</>
							)}
							{filtersList?.downPayment?.length > 0 && typeCategory != '' && typeCategory != undefined && typeCategory != null && (
								<>
									<InputFilters
										label="Down Payment"
										valueTo={downPayMentRange?.lowestPayment}
										valueFrom={downPayMentRange?.highestPayment}
										saveFilter={(val: any) => {
											setSelectedDownPaymentFilter(val)
											handleFiltersChange(val, 'downPayment')
										}}
									/>
									<Divider margin="1rem 0rem" isDarkTheme={_isDarkTheme} />
								</>
							)}
							{filtersList?.monthlyInstallment?.length > 0 && typeCategory != '' && typeCategory != undefined && typeCategory != null && (
								<>
									<InputFilters
										label="Monthly Installment"
										valueTo={monthlyInstallmentRange?.lowestPayment}
										valueFrom={monthlyInstallmentRange?.highestPayment}
										saveFilter={(val: any) => {
											setSelectedMonthlyInstallmentFilter(val)
											handleFiltersChange(val, 'monthlyInstallment')
										}}
									/>
									<Divider margin="1rem 0rem" isDarkTheme={_isDarkTheme} />
								</>
							)}
							{filtersList?.installmentPlan?.length > 0 && typeCategory != '' && typeCategory != undefined && typeCategory != null && (
								<>
									<FilterMenu
										idName="installmentPlan"
										heading="Installment Plan"
										filter={filtersList?.installmentPlan}
										selected={selectedInstallmentPlanFilter}
										setSelected={setSelectedInstallmentPlanFilter}
										onChangeCB={handleFiltersChange}
									/>
									<Divider margin="1rem 0rem" isDarkTheme={_isDarkTheme} />
								</>
							)}
							{filtersList?.fuel?.length > 0 && typeCategory != '' && typeCategory != undefined && typeCategory != null && (
								<>
									<FilterMenu idName="fuel" heading="Fuel" filter={filtersList?.fuel} selected={selectedFuelFilter} setSelected={setSelectedFuelFilter} onChangeCB={handleFiltersChange} />
									<Divider margin="1rem 0rem" isDarkTheme={_isDarkTheme} />
								</>
							)}
							{filtersList?.carDocuments?.length > 0 && typeCategory != '' && typeCategory != undefined && typeCategory != null && (
								<>
									<FilterMenu idName="carDocuments" heading="Car Documents" filter={filtersList?.carDocuments} selected={selectedCarDocumentsFilter} setSelected={setSelectedCarDocumentsFilter} onChangeCB={handleFiltersChange} />
									<Divider margin="1rem 0rem" isDarkTheme={_isDarkTheme} />
								</>
							)}
							{filtersList?.assembly?.length > 0 && typeCategory != '' && typeCategory != undefined && typeCategory != null && (
								<>
									<FilterMenu idName="assembly" heading="Assembly" filter={filtersList?.assembly} selected={selectedAssemblyFilter} setSelected={setSelectedAssemblyFilter} onChangeCB={handleFiltersChange} />
									<Divider margin="1rem 0rem" isDarkTheme={_isDarkTheme} />
								</>
							)}
							{filtersList?.transmission?.length > 0 && typeCategory != '' && typeCategory != undefined && typeCategory != null && (
								<>
									<FilterMenu idName="transmission" heading="Transmission" filter={filtersList?.transmission} selected={selectedTransmissionFilter} setSelected={setSelectedTransmissionFilter} onChangeCB={handleFiltersChange} />
									<Divider margin="1rem 0rem" isDarkTheme={_isDarkTheme} />
								</>
							)}
							{filtersList?.deviceType?.length > 0 && typeCategory != '' && typeCategory != undefined && typeCategory != null && typeCategory === 'Accessories' && subCategory !== '' && (
								<>
									<FilterMenu idName="deviceType" heading="Device Type" filter={filtersList?.deviceType} selected={selectedDeviceTypeFilter} setSelected={setSelectedDeviceTypeFilter} onChangeCB={handleFiltersChange} />
									<Divider margin="1rem 0rem" isDarkTheme={_isDarkTheme} />
								</>
							)}
							{filtersList?.type?.length > 0 && typeCategory != '' && typeCategory != undefined && typeCategory != null && (
								<>
									<FilterMenu idName="type" heading="Type" filter={filtersList?.type} selected={selectedTypeFilter} setSelected={setSelectedTypeFilter} onChangeCB={handleFiltersChange} />
									<Divider margin="1rem 0rem" isDarkTheme={_isDarkTheme} />
								</>
							)}
							{filtersList?.condition?.length > 0 && typeCategory != '' && typeCategory != undefined && typeCategory != null && (
								<>
									<FilterMenu idName="condition" heading="Condition" filter={filtersList?.condition} selected={selectedConditionFilter} setSelected={setSelectedConditionFilter} onChangeCB={handleFiltersChange} />
									<Divider margin="1rem 0rem" isDarkTheme={_isDarkTheme} />
								</>
							)}
							{filtersList?.furnished?.length > 0 && typeCategory != '' && typeCategory != undefined && typeCategory != null && (
								<>
									<FilterMenu idName="furnished" heading="Furnished" filter={filtersList?.furnished} selected={selectedFurnishedFilter} setSelected={setSelectedFurnishedFilter} onChangeCB={handleFiltersChange} />
									<Divider margin="1rem 0rem" isDarkTheme={_isDarkTheme} />
								</>
							)}

							{filtersList?.bathRooms?.length > 0 && typeCategory != '' && typeCategory != undefined && typeCategory != null && (
								<>
									<InputFilters
										label="BathRooms"
										valueTo={selectedBathRoomsFilter[0]}
										valueFrom={selectedBathRoomsFilter[1]}
										saveFilter={(val: any) => {
											setSelectedBathRoomsFilter(val)
											handleFiltersChange(val, 'bathRooms')
										}}
									/>
									<Divider margin="1rem 0rem" isDarkTheme={_isDarkTheme} />
								</>
							)}
							{filtersList?.bedRooms?.length > 0 && typeCategory != '' && typeCategory != undefined && typeCategory != null && (
								<>
									<InputFilters
										label="BedRooms"
										valueTo={selectedBedRoomsFilter[0]}
										valueFrom={selectedBedRoomsFilter[1]}
										saveFilter={(val: any) => {
											setSelectedBedRoomsFilter(val)
											handleFiltersChange(val, 'bedRooms')
										}}
									/>
									<Divider margin="1rem 0rem" isDarkTheme={_isDarkTheme} />
								</>
							)}
							{filtersList?.floorLevel?.length > 0 && typeCategory != '' && typeCategory != undefined && typeCategory != null && (
								<>
									<InputFilters
										label="Floor Level"
										valueTo={selectedFloorLevelFilter[0]}
										valueFrom={selectedFloorLevelFilter[1]}
										saveFilter={(val: any) => {
											setSelectedFloorLevelFilter(val)
											handleFiltersChange(val, 'floorLevel')
										}}
									/>
									<Divider margin="1rem 0rem" isDarkTheme={_isDarkTheme} />
								</>
							)}
							{filtersList?.storeys?.length > 0 && typeCategory != '' && typeCategory != undefined && typeCategory != null && (
								<>
									<InputFilters
										label="No of Storeys"
										valueTo={selectedStoreysFilter[0]}
										valueFrom={selectedStoreysFilter[1]}
										saveFilter={(val: any) => {
											setSelectedStoreysFilter(val)
											handleFiltersChange(val, 'storeys')
										}}
									/>
									<Divider margin="1rem 0rem" isDarkTheme={_isDarkTheme} />
								</>
							)}

							{filtersList?.constructionState?.length > 0 && typeCategory != '' && typeCategory != undefined && typeCategory != null && (
								<>
									<FilterMenu
										idName="constructionState"
										heading="Construction State"
										filter={filtersList?.constructionState}
										selected={selectedConstructionStateFilter}
										setSelected={setSelectedConstructionStateFilter}
										onChangeCB={handleFiltersChange}
									/>
									<Divider margin="1rem 0rem" isDarkTheme={_isDarkTheme} />
								</>
							)}
							{filtersList?.features?.length > 0 && typeCategory != '' && typeCategory != undefined && typeCategory != null && (
								<>
									<FilterMenu idName="features" heading="Features" filter={featuresCounts} selected={selectedFeaturesFilter} setSelected={setSelectedFeaturesFilter} onChangeCB={handleFiltersChange} />
									<Divider margin="1rem 0rem" isDarkTheme={_isDarkTheme} />
								</>
							)}

							{filtersList?.areaUnit?.length > 0 && typeCategory != '' && typeCategory != undefined && typeCategory != null && (
								<>
									<FilterMenu idName="areaUnit" heading="Area Unit" filter={filtersList?.areaUnit} selected={selectedAreaUnitFilter} setSelected={setSelectedAreaUnitFilter} onChangeCB={handleFiltersChange} />
									<Divider margin="1rem 0rem" isDarkTheme={_isDarkTheme} />
								</>
							)}
							{filtersList?.area?.length > 0 && typeCategory != '' && typeCategory != undefined && typeCategory != null && (
								<>
									<InputFilters
										label="Area"
										valueTo={selectedAreaFilter[0]}
										valueFrom={selectedAreaFilter[1]}
										saveFilter={(val: any) => {
											setSelectedAreaFilter(val)
											handleFiltersChange(val, 'area')
										}}
									/>
									<Divider margin="2rem 0rem" isDarkTheme={_isDarkTheme} />
								</>
							)}
						</>
					)}
					<Spacer height="1" />
				</span>
			</SideBarContent>
		</SideNavBar>
	)
}

const SideNavBar = styled.div<any>`
	// width: 20.625rem;
	width: 17rem;
	position: absolute;
	transition: width 70ms ease-in-out 0s;
	z-index: 3;
	${media.lg`position: relative;`}
	@media screen and (min-width: 575px) and (max-width: 992px) {
		z-index: 4;
		top: 8rem;
	}
	@media screen and (min-width: 0) and (max-width: 574px) {
		z-index: 4;
		top: 12rem;
	}
`

const FilterClose = styled(Flexed)<any>`
	display: flex;
	${media.lg`display: none;`}
`

const CloseIcon = styled(BsXLg)<IProps>`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 1.3rem;
`

const SideBarContent = styled.div<any>`
	// width: 20.625rem;
	width: 17rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.light_black}` : `${palette.white}`)};
	padding: 0.5rem 0.938rem 0.5rem 0;
	position: fixed;
	z-index: 4;
	overflow-x: hidden;
	overflow-y: auto;
	height: calc(100vh - 202px);
	transition: width 70ms ease-in-out 0s;

	/* width */
	::-webkit-scrollbar {
		width: 3px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.black}` : `${palette.white}`)};
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.light_silver}` : `${palette.silver}`)};
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.light_silver}` : `${palette.silver}`)};
	}
	@media screen and (min-width: 575px) and (max-width: 992px) {
		left: 0;
		padding: 0.938rem;
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
		height: calc(100vh - 128px);
	}
	@media screen and (min-width: 0px) and (max-width: 574px) {
		left: 0;
		padding: 0.938rem;
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
		height: calc(100vh - 186px);
	}
`

const Menu = styled.div<any>``

const Drop = styled(Flexed)`
	cursor: pointer;
`

const DropWrapper = styled(Flexed)<IProps>`
	display: ${({dropOpen}) => (dropOpen ? `flex` : `none`)};
	padding: 0rem 1rem 0rem 1rem;
`

const DropList = styled(Flexed)<IProps>`
	cursor: pointer;
	margin: ${({margin}) => `${margin}`};
`

const Arrow = styled(FaAngleDown)<IProps>`
	transition: transform 0.2s;
	transform: ${({dropOpen}) => (dropOpen ? `rotate(-180deg)` : `rotate(0deg)`)};
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
`

const Label = styled.label<any>`
	margin: 0;
	cursor: pointer;
	font-size: 14px;
	color: ${({isDarkTheme, active}) => (active ? (isDarkTheme ? palette.white : palette.red) : isDarkTheme ? palette.light_gray : palette.gray)};
	font-weight: ${({active}) => (active ? '500' : '')};
	& span {
		color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.gray)};
		font-size: 14px;
	}
	&:hover {
		color: ${({isDarkTheme}) => (isDarkTheme ? palette.white : palette.red)};
	}
	&:hover span {
		color: ${({isDarkTheme}) => (isDarkTheme ? palette.white : palette.red)};
	}
`

export default Sidebar
