/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * AlertModel Component
 *
 * @component
 * @description Modal for displaying alerts with customizable content and actions.
 *
 * @param {Object} props - Component props.
 * @param {boolean} props.open - Flag to control the visibility of the modal.
 * @param {function} props.onCloseModal - Callback function to handle modal close.
 * @param {function} props.onClick - Callback function to handle the continue action.
 * @param {any} props.label - Content to be displayed in the alert.
 * @param {any} props.title - Title of the alert (optional).
 * @param {any} props.estimatedViews - Estimated views for the alert content.
 * @param {boolean} props.isDarkTheme - Flag indicating whether the app is in dark theme.
 */
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {Text, Spacer, Flexed} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {useSelector} from 'react-redux'
import {media} from 'styled-bootstrap-grid'

import axios from 'axios'
import useRouter from '../common/UserRouterHook'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

interface ICategoriesModalProps {
	open: boolean
	onCloseModal: any
	onClick?: any
	label?: any
	title?: any
	estimatedViews?: any
}

interface IProps {
	isDarkTheme: boolean
}

const AlertModel = ({open, onCloseModal, onClick, label, title, estimatedViews}: ICategoriesModalProps) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const router = useRouter()
	return (
		<>
			<Modal
				open={open}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'blockModal'
				}}
				showCloseIcon={false}
				// @ts-ignore
				styles={{zIndex: '2'}}>
				<ModalWrapper isDarkTheme={_isDarkTheme}>
					{title && (
						<Head direction="row" align="center" justify="center">
							<Text type="large" lineHeight="1.438" fontWeight="700" color={_isDarkTheme ? 'black' : 'black'} onClick={() => router.navigate('/')} pointer>
								{title}
							</Text>
						</Head>
					)}
					<Body>
						<Spacer height={1} />
						<Flexed direction="row" align="center" gap={1}>
							<Label htmlFor="spam" isDarkTheme={_isDarkTheme}>
								{label}
							</Label>
						</Flexed>

						<Spacer height={1.5} />
						<Flexed direction="row" align="center" justify="space-between" gap={1}>
							<Block
								isCancel={true}
								isDarkTheme={_isDarkTheme}
								onClick={() => {
									onCloseModal()
								}}>
								Cancel
							</Block>
							<Block
								isDarkTheme={_isDarkTheme}
								onClick={() => {
									onClick()
								}}>
								Continue
							</Block>
						</Flexed>
					</Body>
				</ModalWrapper>
			</Modal>
		</>
	)
}

const ModalWrapper = styled.div<IProps>`
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
`

const Head = styled(Flexed)`
	padding: 1rem;
	text-align: center;
	${media.sm`
    width: 400px;
	`}
	${media.md`
    width: 400px;
	`}
	background-color: #ebeeef;
`

const Body = styled.div`
	width: 25rem;
	padding: 0rem 1rem 1rem;
`

const Block = styled.div<any>`
	background: ${({isDarkTheme, isCancel}) => (isCancel ? palette.light_gray : isDarkTheme ? palette.dark_gray : palette.black)};
	border-radius: 0.3rem;
	padding: 0.5rem 0.8rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	justify-content: center;
	font-size: 1rem;
	font-weight: bold;
	color: ${({isDarkTheme, isCancel}) => (isCancel ? palette.black : isDarkTheme ? palette.white : palette.white)};
	cursor: pointer;
	width: 100%;
`

const Label = styled.label<any>`
	margin: 0;
	cursor: pointer;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
`

export default AlertModel
