/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * RegistrationCityField Component
 *
 * @component
 * @description A component for handling city input with autocomplete and geolocation features.
 *
 * @param {Object} props - Component props.
 * @param {string} props.label - Label for the input field.
 * @param {string} props.city - Current value of the city.
 * @param {Function} props.setCity - Function to set the city value.
 * @param {boolean} props.required - Flag indicating if the field is required.
 * @param {Object} props.error - Error message or object for the field.
 * @param {string} props.placeholder - Placeholder for the input field.
 * @param {boolean} props.disabled - Flag indicating if the input is disabled.
 * @param {boolean} props.isEdit - Flag indicating if the input is in edit mode.
 *
 */

import React, {useRef, useEffect, useState} from 'react'
import styled from 'styled-components'
import {palette} from '../../styled/colors'
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng
	// @ts-ignore
} from 'react-places-autocomplete'
import axios from 'axios'
import {BsXLg} from 'react-icons/bs'
import {BiCurrentLocation} from 'react-icons/bi'
import {useSelector} from 'react-redux'
import {Text} from '../../styled/shared'

const RegistationCityField = ({label, city, setCity, required, error, placeholder, disabled, isEdit}: any) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const [isNotSeleted, setIsNotSelected] = useState(isEdit ? false : true)
	const wrapperRef = useRef<HTMLDivElement>(null)

	const handleClickOutside = () => {
		if (isEdit) {
			if (isNotSeleted) {
				setIsNotSelected(true)
				setCity('')
			}
		} else {
			if (isNotSeleted && city) {
				setIsNotSelected(true)
				setCity('')
			}
		}
	}

	useEffect(() => {
		const handleClickOutside2 = (event: MouseEvent) => {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
				handleClickOutside() // Perform action when clicked outside
			}
		}

		// Add event listener when the component mounts
		document.addEventListener('mousedown', handleClickOutside2)

		// Remove event listener when the component unmounts
		return () => {
			document.removeEventListener('mousedown', handleClickOutside2)
		}
	}, [handleClickOutside])

	useEffect(() => {
		if (city === '') {
			setIsNotSelected(true)
		}
	}, [city])

	const handleSelect = async (city: any) => {
		if (city !== '') {
			const results = await geocodeByAddress(city)
			const latLng = await getLatLng(results[0])
			setIsNotSelected(false)
			setCity(city)
		}
	}
	const getCurrentLatLng = async () => {
		navigator.geolocation.getCurrentPosition(
			async (position) => {
				await getCurrentAddress(position.coords.latitude, position.coords.longitude)
			},
			function (error) {
				// eslint-disable-next-line eqeqeq
				if (error.code == error.PERMISSION_DENIED) {
				} else {
				}
			},
			{timeout: 5000, enableHighAccuracy: true}
		)
	}

	//Function to get Exact Address from above taken Latitude and longitude
	const getCurrentAddress = async (lat: any, lng: any) => {
		await axios
			.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${parseFloat(lat)},${parseFloat(lng)}&sensor=true&key=${process.env.REACT_APP_MAP_API_KEY}`)
			.then((response) => {
				if (response?.status == 200) {
					setCity(response?.data?.results[0]?.formatted_address)
					setIsNotSelected(false)
				}
			})
			.catch((error) => {
				setIsNotSelected(true)
				setCity('')
			})
	}

	return (
		<div
			ref={wrapperRef}
			onClick={() => {
				handleClickOutside()
			}}>
			{label && (
				<Label type="normal" margin="0rem 0rem 0.19rem 0rem" isDarkTheme={_isDarkTheme}>
					{label} {required ? <Mandatory>*</Mandatory> : ''}
				</Label>
			)}
			<PlacesAutocomplete
				value={city}
				onChange={setCity}
				onSelect={handleSelect}
				searchOptions={{
					types: ['(cities)'] // Restrict the results to city names
				}}>
				{({getInputProps, suggestions, getSuggestionItemProps, loading}: any) => (
					<div>
						<InnerWrapper isDarkTheme={_isDarkTheme} error={error}>
							<Input width={'100%'} borderRadius={'0.2rem'} margin={'0'} padding={'0.8rem 0.8rem 0.8rem 2.5rem'} value={city} {...getInputProps()} isDarkTheme={_isDarkTheme} placeholder="Search location" error={error} />
							{
								city ? (
									<CrossIcon
										isDarkTheme={_isDarkTheme}
										onClick={() => {
											setIsNotSelected(true)
											setCity('')
										}}
									/>
								) : null
								// <CurrentLocation isDarkTheme={_isDarkTheme}
								// 	onClick={() => {
								// 		getCurrentLatLng()
								// 	}}
								// />
							}
						</InnerWrapper>
						{suggestions.length > 0 && (
							<ListItems isDarkTheme={_isDarkTheme}>
								{loading ? (
									<ListItems>
										<LocationList isDarkTheme={_isDarkTheme}>Fetching locations</LocationList>{' '}
									</ListItems>
								) : null}
								{suggestions.map((suggestion: any, i: any) => {
									const style = {
										backgroundColor: suggestion.active ? '#F5F3ED' : `${_isDarkTheme ? `${palette.black}` : `${palette.white}`}`
									}
									return (
										<LocationList
											isDarkTheme={_isDarkTheme}
											key={i + 'mapkey'}
											{...getSuggestionItemProps(suggestion, {
												style
											})}>
											{suggestion?.description}
										</LocationList>
									)
								})}
							</ListItems>
						)}
					</div>
				)}
			</PlacesAutocomplete>
			{required && error && !disabled && (
				<Text fontSize={0.7} type="small" color="danger">
					{error?.message ? error?.message : error}
				</Text>
			)}
		</div>
	)
}

const Label = styled(Text)`
	font-weight: 500;
	text-transform: capitalize;
`

const Mandatory = styled.span`
	color: ${palette.danger};
`

const InnerWrapper = styled.div<any>`
	width: 100%;
	position: relative;
	border-radius: 0.375rem;
	// border: 1px solid ${({error, disabled, isDarkTheme}) => (disabled ? `${palette.silver}` : error ? `${palette.danger}` : isDarkTheme ? `${palette.light_silver}` : `${palette.silver}`)};
`

const Input = styled.input<any>`
	font-size: 1.1rem;
	width: 100%;
	border: 0;
	font-size: 0.875rem;
	outline: none;
	line-height: 2rem;
	padding: 0.5rem 3rem 0.5rem 0.8rem;
	background: ${({disabled, bgTransparent, isDarkTheme}) => (bgTransparent ? 'transparent' : disabled ? `${palette.silver}` : isDarkTheme ? `${palette.black}` : `${palette.white}`)};
	color: ${({disabled, isDarkTheme}) => (disabled ? (isDarkTheme ? palette.light_gray : palette.gray_400) : isDarkTheme ? `${palette.light_gray}` : `${palette.dark_gray}`)};
	border-radius: 0.375rem;
	border: 1px solid ${({error, disabled, isDarkTheme}) => (disabled ? `${palette.silver}` : error ? `${palette.danger}` : isDarkTheme ? `${palette.light_silver}` : `${palette.silver}`)};
	&:focus {
		border: 1px solid ${({error, disabled}) => (disabled ? 'none' : error ? `${palette.danger}` : `${palette.red}`)};
	}
`

const CrossIcon = styled(BsXLg)<any>`
	cursor: pointer;
	position: absolute;
	top: 0;
	right: 1rem;
	bottom: 0;
	margin: auto;
	z-index: 1;
	padding: 0.2rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.silver}` : `${palette.gray}`)};
	font-size: 1.3rem;
`

const CurrentLocation = styled(BiCurrentLocation)<any>`
	cursor: pointer;
	position: absolute;
	top: 0;
	right: 1rem;
	bottom: 0;
	margin: auto;
	// z-index: 1;
	padding: 0.2rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.silver}` : `${palette.gray}`)};
	font-size: 1.6rem;
`

const ListItems = styled.div<any>`
	position: absolute;
	background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.black}` : `${palette.white}`)};
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.silver}` : `${palette.gray}`)};
	z-index: 1;
	width: calc(100% - 1.875rem);
	border-radius: 0.5rem;
	border: 1px solid ${({error, disabled, isDarkTheme}) => (disabled ? `${palette.silver}` : error ? `${palette.danger}` : isDarkTheme ? `${palette.light_silver}` : `${palette.silver}`)};
	overflow: hidden;
`

const LocationList = styled.div<any>`
	background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.black}` : `${palette.white}`)};
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.silver}` : `${palette.gray}`)};
	text-align: left;
	padding: 0.5rem;
	cursor: pointer;
	font-weight: 300;
	font-size: 1rem;
	&:hover {
		background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.red : palette.red)} !important;
		color: ${palette.white};
	}
`

export default RegistationCityField
