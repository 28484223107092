/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Enumeration of categories using in listing app.
 * @type {string[]}
 */

export const _areaUnit = ['Kanal', 'Marla', 'Square Feet', 'Square Meter', 'Square Yards']
export const _furnished = ['Unfurnished', 'Furnished']
export const _constructionState = ['Grey Structure', 'Finished']
export const _outDoorFeatures = ['Corner Plot', 'Park Facing', 'Disputed', 'Sewerage', 'Electricity', 'Water Supply', 'Gas Supply', 'Boundry Wall']
export const _inDoorFeatures = ['Servant Quarters', 'Drawing Room', 'Dining Room', 'Kitchen', 'Study Room', 'Prayer Room', 'Powder Room', 'Gym', 'Store Room', 'Steam Room', 'Lounge or Sitting Room', 'Laundry Room']
export const _commercialFeatures = ['Parking Spaces Available', 'Lobby in Building', 'Double Glazed Windows', 'Central Air Conditiong', 'Central Heating', 'Electricity Backup', 'Waste Disposal', 'Elevators']
export const _carFeatures = [
	'ABS',
	'Air Bags',
	'Air Conditioning',
	'Alloy Rims',
	'AM/FM Radio',
	'CD Player',
	'Cassette Player',
	'Cool Box',
	'Cruise Control',
	'Climate Control',
	'DVD Player',
	'Front Speakers',
	'Front Camera',
	'Heated Seats',
	'Immobilizer Key',
	'Keyless Entry',
	'Navigation System',
	'Power Locks',
	'Power Mirrors',
	'Power Steering',
	'Power Windows',
	'Rear Seat Entertainment',
	'Rear AC Vents',
	'Rear speakers',
	'Rear Camera',
	'Sun Roof',
	'Steering Switches',
	'USB and Auxillary Cable'
]
export const _landType = ['Agricultural Land', 'Commercial Plots', 'Files', 'Industrial Land', 'Residential Plots', 'Plot Form']
export const _commercialType = ['Office', 'Shop', 'Warehouse', 'Factory', 'Building']
export const _roomType = ['Single', 'Sharing']
export const _chargingCablesType = ['IOS', 'Micro-USB/Android', 'USB Type-C', 'Others']
export const _headPhonesType = ['Wired', 'Wireless']
export const _device = ['Tablet', 'Mobile', 'Smart Watch']
export const _noOfStoreys = ['1', '2', '3', '4+']
export const _condition = ['New', 'Used']
export const _tabletCondition = ['New', 'Open Box', 'Used', 'Refurbished', 'For Parts or Not Working']
export const _partsType = ['Car Parts', 'Other Parts']
export const _fuel = ['Petrol', 'Diesel', 'LPG', 'CNG', 'Hybrid', 'Electric']
export const _carDocuments = ['Original', 'Duplicate']
export const _assembly = ['Local', 'Imported']
export const _transmission = ['Automatic', 'Manual']
export const _registered = ['Yes', 'No']

export const _landAndPlotsAttributes = {
	type: [..._landType],
	features: [..._outDoorFeatures],
	areaUnit: [..._areaUnit]
}

export const _housesSale = {
	furnished: [..._furnished],
	constructionState: [..._constructionState],
	features: [..._inDoorFeatures],
	areaUnit: [..._areaUnit]
}

export const _housesRent = {
	furnished: [..._furnished],
	noOfStoreys: [..._noOfStoreys],
	constructionState: [..._constructionState],
	features: [..._inDoorFeatures],
	areaUnit: [..._areaUnit]
}

export const _apartmentsAndFlats = {
	furnished: [..._furnished],
	features: [..._inDoorFeatures],
	areaUnit: [..._areaUnit]
}

export const _shopsOfficesCommercialSpace = {
	type: [..._commercialType],
	features: [..._commercialFeatures],
	areaUnit: [..._areaUnit]
}

export const _portionsAndFloors = {
	furnished: [..._furnished],
	features: [..._inDoorFeatures],
	areaUnit: [..._areaUnit]
}

export const _rooms = {
	furnished: [..._furnished],
	type: [..._roomType]
}

export const _cars = {
	features: [..._carFeatures],
	condition: [..._condition],
	transmission: [..._transmission],
	assembly: [..._assembly],
	carDocuments: [..._carDocuments],
	fuel: [..._fuel]
}

export const _carsInstallments = {
	features: [..._carFeatures],
	condition: [..._condition],
	transmission: [..._transmission],
	assembly: [..._assembly],
	carDocuments: [..._carDocuments],
	fuel: [..._fuel],
	registered: [..._registered]
}

export const _carsAccessories = {
	condition: [..._condition]
}

export const _spareParts = {
	type: [..._partsType],
	condition: [..._condition]
}

export const _vehiclesCondition = {
	condition: [..._condition]
}

export const _tabletsCondition = {
	condition: [..._tabletCondition]
}

export const _smartWatchesCondition = {
	condition: [..._condition]
}

export const _chargingCablesTypes = {
	type: [..._chargingCablesType],
	condition: [..._condition]
}

export const _chargersTypes = {
	deviceType: [..._device],
	type: [..._chargingCablesType],
	condition: [..._condition]
}

export const _screenTypes = {
	type: [..._device]
}

export const _headPhonesTypes = {
	type: [..._headPhonesType],
	condition: [..._condition]
}

export const _coverCases = {
	type: [..._device],
	condition: [..._condition]
}
