/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * DropDown Component
 * Description: This component provides a customizable dropdown interface. It includes options for labels, sublabels, default values, and a list of selectable items.
 * Users can toggle the dropdown, select an option, and receive feedback on the selected value. The component supports various configurations, such as disabling, error handling, and customization of appearance.
 *
 * @component DropDown
 *
 * @param {string} firstSelected - The initially selected value in the dropdown.
 * @param {boolean} timeDrop - Flag indicating whether the dropdown is for time selection.
 * @param {string} label - The main label for the dropdown.
 * @param {string} subLabel - Additional sublabel for contextual information.
 * @param {Array} options - An array of objects representing the selectable options in the dropdown.
 * @param {string} name - Unique identifier for the dropdown.
 * @param {function} hasChanged - Callback function triggered when the selected value changes.
 * @param {string} error - Error message to display if applicable.
 * @param {boolean} disabled - Flag indicating whether the dropdown is disabled.
 * @param {number} width - Width of the dropdown.
 * @param {number} dropDownListHeight - Height of the dropdown list.
 * @param {boolean} required - Flag indicating whether the dropdown is required.
 *
 */

import React, {useState, useEffect, useRef} from 'react'
import styled from 'styled-components'
import {palette} from '../../styled/colors'
import {AiFillCaretDown} from 'react-icons/ai'
import {Text} from '../../styled/shared'
import {useSelector} from 'react-redux'
import {Mandatory} from './InputField'

const DropDown = ({firstSelected, timeDrop, label, subLable, options, name, hasChanged, error, disabled, width, dropDownListHeight, required}: any) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const myRef = useRef()

	const [isOpen, setIsOpen] = useState(false)
	const [selectedOption, setSelectedOption] = useState(firstSelected)
	// @ts-ignore
	DropDown['handleClickOutside_' + name] = () => {
		setIsOpen(false)
	}

	const toggling = () => {
		if (options?.length > 0) {
			setIsOpen(!isOpen)
		}
	}

	const onOptionClicked = (option: any) => () => {
		setSelectedOption(option.label)
		hasChanged(option.value)
		setIsOpen(false)
	}

	const handleClickOutside = (e: any) => {
		// @ts-ignore
		if (!myRef.current.contains(e.target)) {
			setIsOpen(false)
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		return () => document.removeEventListener('mousedown', handleClickOutside)
	})

	return (
		<>
			{label && (
				<Label type="normal" margin="0rem 0rem 0.19rem 0rem" isDarkTheme={_isDarkTheme}>
					{label}
					{subLable && <SubLable>{subLable}</SubLable>}
					{required ? <Mandatory> *</Mandatory> : ''}
				</Label>
			)}
			<DropDownContainer isDarkTheme={_isDarkTheme} ref={myRef} width={width} isdisabled={disabled || options?.length == 0} error={disabled || options?.length == 0 ? false : error}>
				<DropDownHeader timeDrop={timeDrop} onClick={() => toggling()}>
					{selectedOption || (
						<DefaultValue isdisabled={disabled} isDarkTheme={_isDarkTheme}>
							Select
						</DefaultValue>
					)}{' '}
					{timeDrop && selectedOption ? (selectedOption < '12:00' ? 'am' : 'pm') : ''}
					<Icon id="icon" isDarkTheme={_isDarkTheme}>
						{' '}
						{!isOpen ? <AiFillCaretDown /> : <ArrowUp />}{' '}
					</Icon>
				</DropDownHeader>
				{isOpen && (
					<DropDownListContainer isdisabled={disabled}>
						<DropDownList isDarkTheme={_isDarkTheme} dropDownListHeight={dropDownListHeight}>
							{options?.map((option: any) => (
								<ListItem timeDrop={timeDrop} isDarkTheme={_isDarkTheme} onClick={onOptionClicked(option)} key={Math.random()}>
									{option.label} {timeDrop ? (option.label < '12:00' ? 'am' : 'pm') : ''}
								</ListItem>
							))}
						</DropDownList>
					</DropDownListContainer>
				)}
			</DropDownContainer>
			{error && !disabled && (
				<Text fontSize={0.7} type="small" color="danger">
					{error?.message ? error?.message : error}
				</Text>
			)}
		</>
	)
}

const Label = styled(Text)`
	font-weight: 500;
	text-transform: capitalize;
`
const SubLable = styled.span`
	font-size: 1rem;
	line-height: 2rem;
	color: ${palette.text_color};
	font-family: 'Roboto';
	text-transform: capitalize;
`

const DropDownContainer = styled('button')<any>`
	color: ${({isdisabled, isDarkTheme}) => (isdisabled || isDarkTheme ? `${palette.light_gray}` : `${palette.dark_gray}`)};
	border: 1px solid ${({error, disabled, isDarkTheme}) => (disabled ? `${palette.silver}` : error ? `${palette.danger}` : isDarkTheme ? `${palette.light_silver}` : `${palette.silver}`)};
	background: ${({isdisabled, bgTransparent, isDarkTheme}) => (bgTransparent ? 'transparent' : isdisabled ? `#fafafa` : isDarkTheme ? `${palette.black}` : `${palette.white}`)};
	border-radius: 0.375rem;
	padding: 0.5rem 0.8rem;
	cursor: ${({isdisabled}) => (isdisabled ? `no-drop` : `pointer`)};
	pointer-events: ${({isdisabled}) => (isdisabled ? `none` : `auto`)};
	position: relative;
	font-size: 0.875rem;
	width: ${({width}) => (width ? `${width}rem` : '100%')};
	line-height: 2rem;
	text-align: left;
	&:focus {
		border: 1px solid ${({error, isdisabled}) => (isdisabled ? 'none' : error ? `${palette.danger}` : `${palette.red}`)};
	}
	&:focus #icon {
		color: ${({isdisabled}) => (isdisabled ? `${palette.light_gray}` : `${palette.gray}`)};
	}
`

const Icon = styled.i<any>`
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 0.7rem;
	margin-left: 1rem;
	opacity: 0.5;
`

const DropDownHeader = styled('div')<any>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-family: 'Roboto';
	text-transform: ${({timeDrop}) => (timeDrop ? '' : 'capitalize')};
`
const DefaultValue = styled.span<any>`
	opacity: 0.5;
	color: ${({isdisabled, isDarkTheme}) => (isdisabled || isDarkTheme ? `${palette.silver}` : `${palette.black}`)};
`

const DropDownListContainer = styled('div')<any>`
	display: ${({isdisabled}) => (isdisabled ? `none` : `block`)};
	position: absolute;
	left: 0;
	right: 0;
	top: 3.3rem;
	z-index: 99;
`

const DropDownList = styled('ul')<any>`
	padding: 0;
	border-radius: 0.375rem;
	text-align: left;
	margin: 0;
	background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.black}` : `${palette.white}`)};
	border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.light_silver}` : `${palette.silver}`)};
	box-sizing: border-box;
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.gray}` : `${palette.dark_gray}`)};
	box-shadow: 0px 4px 8px rgb(0 0 0 / 15%);
	font-size: 0.875rem;
	line-height: 1.54rem;
	font-weight: 400;
	height: auto;
	max-height: ${({dropDownListHeight}) => (dropDownListHeight ? dropDownListHeight : `350px`)};
	overflow-y: auto;
	overflow-x: hidden;

	/* width */
	::-webkit-scrollbar {
		width: 0.5rem;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.black}` : `${palette.white}`)};
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.light_silver}` : `${palette.silver}`)};
	}
	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.light_silver}` : `${palette.silver}`)};
	}
`

const ListItem = styled('li')<any>`
	list-style: none;
	line-height: 2rem;
	white-space: nowrap;
	padding: 0.5rem 0.8rem;
	font-family: 'Roboto';
	text-transform: ${({timeDrop}) => (timeDrop ? '' : 'capitalize')};
	border-bottom: 1px solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.light_silver}` : `${palette.silver}`)};
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.light_gray}` : `${palette.dark_gray}`)};

	&:hover {
		background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.red : palette.red)};
		color: ${palette.white};
	}
	&:last-child {
		border-bottom: 0;
	}
`

const ArrowUp = styled(AiFillCaretDown)`
	transform: rotate(180deg);
`

export default DropDown
