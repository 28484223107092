/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * ProductCard Component
 *
 * @component
 * @description Represents a card for displaying product information.
 *
 * @param {Object} props - Component props.
 * @param {boolean} [props.view] - Flag to determine the view mode.
 * @param {Object} props.post - Product information.
 * @param {Object} props.favAd - Favorite ad information.
 */
import PropertyCardContent from './PropertyCardContent'

interface IBusinessCardProps {
	post: any
	view?: boolean
	favAd?: any
}

const ProductCard = ({view, post, favAd}: IBusinessCardProps) => {
	return <PropertyCardContent view={view} post={post} favAd={favAd} />
}

export default ProductCard
