/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Packages Component
 *
 * This component displays the available packages for featuring an ad,
 * allowing users to choose from different options to enhance the visibility of their ad.
 *
 * @component
 */

import React, {useEffect, useState} from 'react'
import {Heading, Spacer, Text, Flexed} from '../../styled/shared'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {useSelector, useDispatch} from 'react-redux'
import styled from 'styled-components'
import {palette} from '../../styled/colors'
import Button from '../../components/common/Button'
import {MdRadioButtonChecked, MdRadioButtonUnchecked} from 'react-icons/md'
import {useNavigate, createSearchParams} from 'react-router-dom'
import {featuredAdAction} from '../../actions/authActions'
import useWindowSize from '../../hooks/useWindowSize'

const Packages = () => {
	const _navigate = useNavigate()
	const _dispatch = useDispatch()
	const windowSize = useWindowSize()
	const _selectedFeaturedAdPackage: any = useSelector<any>((state: any) => state.auth.selectedFeaturedAdPackage)
	const _adIdForFeaturedAd: any = useSelector<any>((state: any) => state.auth.adIdForFeaturedAd)
	const _isDarkTheme = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const [showExample, setShowExample] = useState(false)
	const [active, setActive] = useState('Feature 1 Ad for 7 Days')
	const _packagesList = [
		{title: 'Feature 1 Ad for 7 Days', validity: '7', suggestes: false, description: 'Reach upto 3 times more buyers', price: '1300', discount: '', totalPrice: '1300'},
		{title: 'Feature 1 Ad for 14 Days', validity: '14', suggestes: false, description: 'Reach upto 6 times more buyers', price: '2600', discount: '25', totalPrice: '1960'},
		{title: 'Feature 1 Ad for 30 Days', validity: '30', suggestes: true, description: 'Reach upto 10 times more buyers', price: '5571', discount: '54', totalPrice: '2600'}
	]
	useEffect(() => {
		if (_selectedFeaturedAdPackage === null) {
			_dispatch(featuredAdAction(_packagesList[0]))
		}
	}, [_selectedFeaturedAdPackage])
	return (
		<>
			<Wrapper>
				<Row justifyContent="center">
					<Col>
						<Spacer height={2} />
					</Col>
					{showExample ? (
						<Col xl={10} lg={10}>
							<img
								style={{width: '100%'}}
								src="/images/feature_example.gif"
								alt="feature_example"
								onClick={() => {
									setShowExample(false)
								}}
							/>
						</Col>
					) : (
						<Col xl={10} lg={10}>
							<GreetingCard direction={windowSize?.width > 576 ? 'row' : 'column'} align="center" gap={2}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74.653 82.143" width="74.65" height="82.14">
									<path fill="#002f35" d="M3.3 26.55L0 23.25v-7.02L16.23 0h7.03l3.3 3.3v7.02L10.31 26.55zM48.1 3.29L51.4 0h7.02l16.23 16.23v7.03l-3.29 3.3h-7.03L48.1 10.31z"></path>
									<path fill="#fff" d="M18.5 6.28a1.6 1.6 0 1 1 3.19 0 1.6 1.6 0 0 1-3.2 0zm48.18 14.27a1.6 1.6 0 1 1 3.2 0 1.6 1.6 0 0 1-3.2 0z"></path>
									<path fill="#ffce32" d="M53.78 66.99l4.18-2.8 5.9 8.8-4.17 2.8zM38.92 82.14l.01-10.6h5.04l-.01 10.6zm-20.15-9.15l5.91-8.8 4.18 2.8-5.91 8.8z"></path>
									<path
										fill="#23e5db"
										d="M63.02 38.66l.01-.01L36.29 11.9a5.49 5.49 0 0 0-7.77 0L12.88 27.65a5.49 5.49 0 0 0 .01 7.75l2.62 2.62 23.86 23.86v-.01a4.14 4.14 0 0 0 6.02-5.7 4.14 4.14 0 0 0 5.8-5.89 4.14 4.14 0 0 0 5.9-5.8l.02-.02a4.14 4.14 0 0 0 5.9-5.79z"></path>
									<path fill="#00a49f" d="M63.52 28.57L46.29 11.45a5.33 5.33 0 0 0-7.5.17L25.61 24.78a4.14 4.14 0 1 0 5.6 6.1h.01l8.12-8.12a5.51 5.51 0 0 1 7.78-.01L61.3 36.9l2.22-2.32a4.26 4.26 0 0 0 0-6.02z"></path>
								</svg>
								<div>
									<Heading textTransform="inherit" level={4} fontWeight={700} isDarkTheme={_isDarkTheme} margin={'0rem 0rem 0.5rem 0rem'}>
										Your Ad has been uploaded successfully!
									</Heading>
									<CustomText type="medium" isDarkTheme={_isDarkTheme}>
										Your Ad will soon be reachable to <span>millions</span> of buyers
									</CustomText>
								</div>
							</GreetingCard>
							<Spacer height={2} />
							<InfoCard>
								<Flexed direction={windowSize?.width > 576 ? 'row' : 'column'} align="flex-start" gap={2} justify="space-between">
									<div>
										<Heading textTransform="inherit" level={4} fontWeight={700} isDarkTheme={_isDarkTheme} margin={'0rem 0rem 0.5rem 0rem'}>
											Reach More Buyers and Sell Faster and Upgrade your Ad to a top position
										</Heading>
										<Heading textTransform="inherit" margin="2rem 0rem 0rem 0rem" level={5} fontWeight={700} isDarkTheme={_isDarkTheme}>
											What is Featured Ad ?
										</Heading>
										<Flexed direction="row" align="center" gap={1} margin="1rem 0rem 0rem 0rem">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.44 14.5" fill="#0cb132" width="1.2rem" height="1.2rem">
												<path d="M6.6 14.21L.3 7.9a.97.97 0 0 1-.01-1.38l1.37-1.37a.97.97 0 0 1 1.38 0L7.29 9.4 16.41.28a.97.97 0 0 1 1.38 0l1.37 1.38a.97.97 0 0 1 0 1.38L7.98 14.21a.97.97 0 0 1-1.38 0z"></path>
											</svg>
											<Text isDarkTheme={_isDarkTheme} type="normal">
												Get noticed with <span style={{fontWeight: 'bold'}}>“FEATURED”</span> tag in a top position
											</Text>
										</Flexed>
										<Flexed direction="row" align="center" gap={1} margin="1rem 0rem 0rem 0rem">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.44 14.5" fill="#0cb132" width="1.2rem" height="1.2rem">
												<path d="M6.6 14.21L.3 7.9a.97.97 0 0 1-.01-1.38l1.37-1.37a.97.97 0 0 1 1.38 0L7.29 9.4 16.41.28a.97.97 0 0 1 1.38 0l1.37 1.38a.97.97 0 0 1 0 1.38L7.98 14.21a.97.97 0 0 1-1.38 0z"></path>
											</svg>
											<Text isDarkTheme={_isDarkTheme} type="normal">
												Ad will be highlighted to top position
											</Text>
										</Flexed>
									</div>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 325.91 196.68" width={windowSize?.width > 576 ? '25rem' : '15rem'} className="_4a7691d5">
										<defs>
											<filter id="a" width="227.64" height="196.68" x="98.27" y="0" filterUnits="userSpaceOnUse">
												<feOffset dy="1"></feOffset>
												<feGaussianBlur result="blur" stdDeviation="3"></feGaussianBlur>
												<feFlood flood-opacity=".16"></feFlood>
												<feComposite in2="blur" operator="in"></feComposite>
												<feComposite in="SourceGraphic"></feComposite>
											</filter>
											<filter id="b" width="162.26" height="96.91" x="0" y="60.64" filterUnits="userSpaceOnUse">
												<feOffset dy="1"></feOffset>
												<feGaussianBlur result="blur-2" stdDeviation="3"></feGaussianBlur>
												<feFlood flood-opacity=".16"></feFlood>
												<feComposite in2="blur-2" operator="in"></feComposite>
												<feComposite in="SourceGraphic"></feComposite>
											</filter>
										</defs>
										<g fill="#fff" stroke="#969fa0" filter="url(#a)">
											<path stroke="none" d="M107.27 8H316.9v178.68H107.28z"></path>
											<path fill="none" d="M107.77 8.5H316.4v177.68H107.78z"></path>
										</g>
										<path fill="#eaeeef" d="M112.58 13.3H311.6v113.23H112.58zm0 119.42h156.57v7.96H112.58zm0 14.16h156.57v8.85H112.58zm0 15.03h116.76v8.85H112.58z"></path>
										<g fill="#ffce32" stroke="#ffce32">
											<path stroke="none" d="M115.23 13.3h92v24.78h-92z"></path>
											<path fill="none" d="M115.73 13.8h91v23.78h-91z"></path>
										</g>
										<text fill="#012f34" font-family="Roboto-Bold, Roboto" font-size="14" font-weight="700" transform="translate(127.61 31.61)">
											<tspan x="0" y="0">
												FEATURED
											</tspan>
										</text>
										<path fill="none" stroke="#012f34" stroke-dasharray="5" stroke-linecap="round" stroke-width="2" d="M10.18 142.9s138.04 28.32 142.04-73.22" filter="url(#b)"></path>
										<path fill="#012f34" d="M160.11 69.68l-7.82-8.1-8.1 7.82z"></path>
									</svg>
								</Flexed>
								<Flexed direction="row" align="baseline" justify="space-between" gap={1} margin="2rem 0rem 0rem 0rem">
									<Heading textTransform="inherit" margin="2rem 0rem 0rem 0rem" level={5} fontWeight={700} isDarkTheme={_isDarkTheme}>
										FEATURE AD
									</Heading>
									<Button
										type="red"
										label="See Example"
										ifClicked={() => {
											setShowExample(true)
										}}
									/>
								</Flexed>
								{_packagesList?.map((pkg: any, index: any) => {
									return (
										<PackagesCard
											key={index + 'pkg'}
											active={active === pkg?.title}
											margin="1rem 0rem 0rem 0rem"
											direction={windowSize?.width > 576 ? 'row' : 'column'}
											gap={1.3}
											align="center"
											isDarkTheme={_isDarkTheme}
											onClick={() => {
												setActive(pkg?.title)
												_dispatch(featuredAdAction(pkg))
											}}>
											{pkg?.discount && (
												<DiscountLable>
													<DiscountInPersent>-{pkg?.discount}%</DiscountInPersent>
													<img src="/images/icons/discount_label.webp" alt="discount_label" />
												</DiscountLable>
											)}
											{active === pkg?.title ? <MdRadioButtonChecked fontSize="1.8rem" color={palette.red} /> : <MdRadioButtonUnchecked fontSize="1.8rem" color={palette.gray_400} />}
											<InnerFlex direction={windowSize?.width > 576 ? 'row' : 'column'} gap={2} align="center" justify="space-between">
												<div>
													<Flexed direction={windowSize?.width > 576 ? 'row' : 'column'} align="center">
														<Heading textTransform="inherit" level={5} fontWeight={700} isDarkTheme={_isDarkTheme}>
															{pkg?.title}
														</Heading>
														{pkg?.suggestes && <Suggested>Suggested</Suggested>}
													</Flexed>

													<Text margin={windowSize?.width > 576 ? '0rem 0rem 0rem 0rem' : '1rem 0rem 0rem 0rem'} isDarkTheme={_isDarkTheme} type="normal">
														{pkg?.description}
													</Text>
												</div>
												<div>
													{pkg?.discount && (
														<DiscountTag textTransform="inherit" level={5} color="red" fontWeight={700} isDarkTheme={_isDarkTheme}>
															${pkg?.price}
														</DiscountTag>
													)}
													<Heading textTransform="inherit" level={5} color="red" fontWeight={700} isDarkTheme={_isDarkTheme}>
														${pkg?.totalPrice}
													</Heading>
												</div>
											</InnerFlex>
										</PackagesCard>
									)
								})}
							</InfoCard>
							<Text margin="1.5rem 0rem" isDarkTheme={_isDarkTheme} isCentered type="xsmall">
								These prices are exclusive of tax, 5% tax will be charged on checkout
							</Text>
							<Flexed direction="row" gap={2} align="center" justify="flex-end">
								<Button
									label="Skip, View your Ad"
									type="cancel"
									ifClicked={() => {
										_navigate({
											pathname: '/post-details',
											search: createSearchParams({
												id: _adIdForFeaturedAd
											}).toString()
										})
									}}
								/>
								<Button
									type="red"
									label="Upgrade your ad"
									ifClicked={() => {
										_navigate('/payments/payment-selection')
									}}
								/>
							</Flexed>
						</Col>
					)}
				</Row>
				<Spacer height={5} />
			</Wrapper>
		</>
	)
}

const Wrapper = styled(Container)`
	min-height: calc(100vh - 70px);
`

const GreetingCard = styled(Flexed)`
	border: 0.1rem solid #26e4db;
	border-radius: 4px;
	padding: 1rem;
	${media.sm`padding: 3rem;`};
	${media.md`padding: 3rem;`};
`

const InfoCard = styled(Flexed)`
	border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.silver)};
	border-radius: 4px;
	padding: 1rem;
	${media.sm`padding: 3rem;`};
	${media.md`padding: 3rem;`};
`

const CustomText = styled(Text)<any>`
	& span {
		color: ${({isDarkTheme}) => (isDarkTheme ? palette.white : palette.dark_gray)};
		font-weight: 800;
	}
`

const InnerFlex = styled(Flexed)`
	width: 100%;
`

const PackagesCard = styled(Flexed)<any>`
	position: relative;
	cursor: pointer;
	border-radius: 4px;
	padding: 3rem;
	padding: 2.6rem 2rem;
	border: 0.1rem solid ${({active, isDarkTheme}) => (active ? palette.red : isDarkTheme ? palette.light_silver : palette.silver)};
	background: ${({active, isDarkTheme}) => (active ? (isDarkTheme ? 'linear-gradient(rgb(224 0 1 / 18%),#19193c00);' : `linear-gradient(rgb(224 0 1 / 18%), rgb(255, 255, 255));`) : '')};
	&:hover {
		border: 0.1rem solid ${palette.red};
		background: ${({isDarkTheme}) => (isDarkTheme ? 'linear-gradient(rgb(224 0 1 / 18%),#19193c00);' : `linear-gradient(rgb(224 0 1 / 18%), rgb(255, 255, 255));`)};
	}
`

const DiscountTag = styled.del<any>`
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.white : palette.dark_gray)};
	letter-spacing: 0.02em;
	font-weight: 700;
	font-size: 1.125rem;
	line-height: 2rem;
`

const Suggested = styled.div`
	background: ${palette.yellow};
	padding-left: 0.8rem;
	padding-right: 0.8rem;
	border-radius: 0.75rem;
	margin-left: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
`

const DiscountLable = styled.div`
	position: absolute;
	top: 0;
	right: 2rem;
	& img {
		height: 3.2rem;
	}
`

const DiscountInPersent = styled.span`
	position: absolute;
	font-size: 0.8rem;
	color: ${palette.red};
	font-weight: 600;
	text-align: center;
	width: 100%;
`

export default Packages
