/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * BlockUserModal Component
 *
 * @component
 * @description Modal for blocking or unblocking a user.
 *
 * @param {Object} props - Component props.
 * @param {boolean} props.open - Flag to control the visibility of the modal.
 * @param {function} props.onCloseModal - Callback function to handle modal close.
 * @param {boolean} props.isBlocked - Flag indicating whether the user is blocked.
 * @param {function} props.onClick - Callback function for handling block/unblock action.
 * @param {Object} props.user - User information.
 * @param {boolean} props.isChat - Flag indicating whether the action is related to a chat.
 */

import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {Text, Spacer, Flexed} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {BsXLg} from 'react-icons/bs'
import {useSelector} from 'react-redux'
import {media} from 'styled-bootstrap-grid'
import {api} from '../api/callAxios'
import useRouter from '../common/UserRouterHook'
import {toast} from 'react-toastify'

interface ICategoriesModalProps {
	open: boolean
	onCloseModal: any
	isBlocked?: any
	onClick: any
	user?: any
	isChat?: any
}

interface IProps {
	isDarkTheme: boolean
}

const BlockUserModal = ({open, onCloseModal, isBlocked, onClick, user, isChat}: ICategoriesModalProps) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const router = useRouter()

	const blockUser = async () => {
		if (router?.query?.id || isChat) {
			await api
				.post(`block_user/block`, {foreignUserId: isChat ? user?.id : router?.query?.id})
				.then(async (response) => {
					if (response) {
						onClick()
						toast.success('User blocked successfully')
					}
				})
				.catch(function (error) {
					console.log(error)
					if (error?.response?.data?.message) {
						toast.error(error?.response?.data?.message)
					}
					onCloseModal()
				})
		}
	}

	const unblockUser = async () => {
		if (router?.query?.id || isChat) {
			await api
				.post(`block_user/unblock`, {foreignUserId: isChat ? user?.id : router?.query?.id})
				.then(async (response) => {
					if (response) {
						toast.success('User unblocked successfully')
						onClick()
					}
				})
				.catch(function (error) {
					console.log(error)
					if (error?.response?.data?.message) {
						toast.error(error?.response?.data?.message)
					}
					onCloseModal()
				})
		}
	}

	return (
		<>
			<Modal
				open={open}
				center
				onClose={() => {
					onCloseModal()
				}}
				classNames={{
					overlay: 'customOverlay',
					modal: 'blockModal'
				}}
				showCloseIcon={false}
				// @ts-ignore
				styles={{zIndex: '2'}}>
				<ModalWrapper isDarkTheme={_isDarkTheme}>
					<Head direction="row" align="center" justify="space-between">
						<Text type="large" isCentered lineHeight="1.438" fontWeight="700" isDarkTheme={_isDarkTheme}>
							{isBlocked ? 'Unblock' : 'Block'} {user?.fullname ? user?.fullname : 'User'}?
						</Text>
						<CrossIcon
							isDarkTheme={_isDarkTheme}
							onClick={() => {
								onCloseModal()
							}}
						/>
					</Head>
					<Body>
						<Spacer height={1} />
						<Text isCentered isDarkTheme={_isDarkTheme}>
							{isBlocked ? 'Unblock' : ''} {isBlocked ? <b>{user?.fullname ? user?.fullname : 'User'}</b> : ''} {isBlocked ? 'to send messages.' : 'Blocked contacts will no longer be able to send you messages.'}
						</Text>

						<Spacer height={1.5} />

						<Flexed direction="row" align="center" justify="space-between" gap={1}>
							<Block isDarkTheme={_isDarkTheme} onClick={() => (isBlocked ? unblockUser() : blockUser())}>
								{isBlocked ? 'Unblock' : 'Block'}
							</Block>
							<Cancel
								onClick={() => {
									onCloseModal()
								}}>
								Cancel
							</Cancel>
						</Flexed>
					</Body>
				</ModalWrapper>
			</Modal>
		</>
	)
}

const ModalWrapper = styled.div<IProps>`
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	padding: 1rem;
`

const Head = styled(Flexed)`
	padding: 1rem;
	width: 100%;
	${media.sm`
    width: 100%;
	`}
	${media.md`
	width: 100%;
	`}
`

const Body = styled.div`
	padding: 0rem 1rem 1rem;
`

const CrossIcon = styled(BsXLg)<IProps>`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 1.3rem;
`

const Block = styled.div<any>`
	background: ${({isDarkTheme}) => (isDarkTheme ? palette.dark_gray : palette.black)};
	border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
	border-radius: 0.3rem;
	padding: 0.5rem 0.8rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	justify-content: center;
	font-size: 1rem;
	font-weight: bold;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.white : palette.white)};
	cursor: pointer;
	width: 100%;
	&:hover {
		background: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.dark_gray)};
	}
`

const Cancel = styled(Block)<any>`
	border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
	background: ${palette.white};
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.dark_gray : palette.dark_gray)};
	&:hover {
		border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.white : palette.black)};
		background: ${palette.white};
	}
`

export default BlockUserModal
