/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * SocialShareModal Component
 *
 * @component
 * @description Modal for sharing content on various social media platforms.
 *
 * @param {Object} props - Component props.
 * @param {function} props.onCloseModal - Callback function to handle modal close.
 * @param {Object} props.data - Data to be shared.
 */

import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {Text, Spacer, Flexed} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {FaFacebookF, FaLinkedinIn} from 'react-icons/fa'
import {FiMail} from 'react-icons/fi'
import {BsTwitter} from 'react-icons/bs'
import {BsWhatsapp} from 'react-icons/bs'
import {media} from 'styled-bootstrap-grid'
import copy from 'copy-to-clipboard'
import {WhatsappShareButton, FacebookShareButton, EmailShareButton, LinkedinShareButton, TwitterShareButton} from 'react-share'
import {useSelector} from 'react-redux'
import {BsXLg} from 'react-icons/bs'
import useWindowSize from '../../hooks/useWindowSize'
import {MdContentCopy} from 'react-icons/md'
import {Toltip} from './DealerCallModal'

const SocialShareModal = ({onCloseModal, data}: any) => {
	const windowSize = useWindowSize()
	const _isDarkTheme = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const [textCoppied, setTextCoppied] = useState(false)
	const [reqUrl, setReqUrl] = useState('')

	useEffect(() => {
		getReqUrl()
	}, [])

	const getReqUrl = () => {
		let basePath = window?.location?.href
		setReqUrl(basePath)
	}
	return (
		<>
			<Modal
				open={true}
				onClose={() => {
					onCloseModal()
				}}
				center
				showCloseIcon={false}
				classNames={{
					overlay: 'customOverlay',
					modal: 'blockModal'
				}}>
				<ModalWrapper isDarkTheme={_isDarkTheme}>
					<Head direction="row" align="center" justify="space-between">
						<Text type="large" lineHeight="1.438" fontWeight="700" isDarkTheme={_isDarkTheme}>
							Share
						</Text>
						<CrossIcon
							isDarkTheme={_isDarkTheme}
							onClick={() => {
								onCloseModal()
							}}
						/>
					</Head>
					<Body>
						<Flex direction="row" justify="center">
							<WhatsappShareButton url={reqUrl}>
								<Cover background="whatsApp" isDarkTheme={_isDarkTheme}>
									<BsWhatsapp fill="#ffffff" />
								</Cover>
							</WhatsappShareButton>
							<TwitterShareButton url={reqUrl}>
								<Cover background="twitter" isDarkTheme={_isDarkTheme}>
									<BsTwitter fill="#ffffff" />
								</Cover>
							</TwitterShareButton>

							<EmailShareButton url={reqUrl}>
								<Cover background="mail" isDarkTheme={_isDarkTheme}>
									<FiMail style={{color: 'white'}} />
								</Cover>
							</EmailShareButton>
							<LinkedinShareButton url={reqUrl}>
								<Cover background="linkedIn" isDarkTheme={_isDarkTheme}>
									<FaLinkedinIn fill="#ffffff" />
								</Cover>
							</LinkedinShareButton>
							<FacebookShareButton url={reqUrl}>
								<Cover sharer={true} background="faceBook" isDarkTheme={_isDarkTheme}>
									<FaFacebookF fill="#ffffff" />
								</Cover>
							</FacebookShareButton>
						</Flex>
						<Spacer height="1" />
						<Flexed direction={windowSize?.width > 575 ? 'row' : 'column'} gap={1}>
							<LinkInput isDarkTheme={_isDarkTheme}>
								<Text isDarkTheme={_isDarkTheme} fontWeight={600}>
									{reqUrl}
								</Text>
							</LinkInput>
							<div style={{position: 'relative'}}>
								{textCoppied && <Toltip isDarkTheme={_isDarkTheme}>copied</Toltip>}
								<Button
									isDarkTheme={_isDarkTheme}
									onClick={() => {
										setTextCoppied(true)
										copy(reqUrl)
										setTimeout(() => {
											setTextCoppied(false)
										}, 500)
									}}>
									<MdContentCopy />
								</Button>
							</div>
						</Flexed>
					</Body>
				</ModalWrapper>
			</Modal>
		</>
	)
}

const ModalWrapper = styled.div<any>`
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	padding: 1rem;
`

const Head = styled(Flexed)`
	padding: 1rem;
`

const Body = styled.div`
	${media.sm`
    width: 30rem;
	`}
	${media.md`
    width: 30rem;
	`}
	padding: 0rem 1rem 1rem;
`

const Cover = styled.div<any>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 3rem;
	height: 3rem;
	border-radius: 5rem;
	background: ${({background}) => `${palette[background]}`};
	cursor: pointer;
	font-size: 1.5rem;
`

const Flex = styled(Flexed)`
	flex-wrap: wrap;
	gap: 1.2rem;
`

const LinkInput = styled.div<any>`
	padding: 0.5rem 0.8rem;
	border-radius: 0.375rem;
	border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.silver)};
	height: 45px;
	display: flex;
	align-items: center;
	width: 100%;
	& div {
		overflow: hidden;
		overflow-wrap: anywhere;
	}
`

const Button = styled.div<any>`
	padding: 0.5rem 1rem;
	border-radius: 0.375rem;
	border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.silver)};
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.white : palette.black)};
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	cursor: pointer;
	&:hover {
		border: 1px solid ${palette.red};
		background: ${palette.red};
		color: ${palette.white};
	}
`

const CrossIcon = styled(BsXLg)<any>`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 1.3rem;
`

export default SocialShareModal
