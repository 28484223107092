/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Home Component
 *
 * The Home component represents the main page of the application. It includes
 * the MainCover and Category components, allowing users to interact with
 * business types, type categories, and subcategories.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.setBusniessType - A function to set the selected business type.
 * @param {Function} props.setTypeCategory - A function to set the selected type category.
 * @param {Function} props.setSubCategory - A function to set the selected subcategory.
 *
 */

import MainCover from '../components/MainCover'
import Category from '../components/Category'

const Home = ({setBusniessType, setTypeCategory, setSubCategory}: any) => {
	return (
		<>
			<MainCover />
			<Category setBusniessType={setBusniessType} setTypeCategory={setTypeCategory} setSubCategory={setSubCategory} />
		</>
	)
}

export default Home
