/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * PropertyDealerProfile component.
 * Displays details about a property dealer, including their ads and contact information.
 * Allows users to report, block, call, and share the dealer's profile.
 *
 */

import React, {useEffect, useState, useRef} from 'react'
import styled from 'styled-components'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {Flexed, Heading, Loading, Spacer, Text} from '../../styled/shared'
import {useSelector} from 'react-redux'
import {palette} from '../../styled/colors'
import {BiShareAlt} from 'react-icons/bi'
import ProductCard from '../../components/products/ProductCard'
import {api} from '../../components/api/callAxios'
import ReportUserModal from '../../components/modals/ReportUserModal'
import BlockUserModal from '../../components/modals/BlockUserModal'
import useRouter from '../../components/common/UserRouterHook'
import LocationSearch from '../../components/common/LocationSearch'
import SocialShareModal from '../../components/modals/SocialShareModal'
import {FiPhone} from 'react-icons/fi'
import Button from '../../components/common/Button'
import DealerCallModal from '../../components/modals/DealerCallModal'

const PropertyDealerProfile = () => {
	const _isDarkTheme = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const router = useRouter()
	const [searchLocation, setSearchLocation] = useState('')
	const [dealerAds, setDealerAds] = useState([])
	const [copyDealerAds, setCopyDealerAds] = useState([])
	const [reportUserModal, setReportBlockUser] = useState(false)
	const [blockUserModal, setBlockUser] = useState(false)
	const [customer, setCustomer] = useState<any>()
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	const [isLoading, setIsLoading] = useState(false)
	const [openShareModal, setOpenShareModal] = useState(false)
	const [openCallModal, setOpenCallModal] = useState(false)
	const [page, setPage] = useState(1)
	const [loadMore, setLoadMore] = useState(false)

	useEffect(() => {
		getDealerDetails(1)
	}, [])

	const getDealerDetails = async (page: any, _loading = true) => {
		if (router?.query?.id) {
			setIsLoading(_loading)
			await api
				.post(`/customers/customer_detail`, {
					page: page,
					pageSize: 15,
					userId: router?.query?.id
				})
				.then(async (response) => {
					setCustomer(response?.data?.customer?.customer)
					let ads = response?.data?.customer?.userAds?.ads
					if (page > 1) {
						ads = dealerAds.concat(ads)
					}
					_loading == true && setDealerAds(ads)
					setCopyDealerAds(ads)
					setLoadMore(response?.data?.customer?.userAds?.remaining > 0 ? true : false)
					setIsLoading(false)
				})
				.catch(function (error) {
					console.log(error)
					setIsLoading(false)
				})
		}
	}

	const filterPost = async (location: any) => {
		if (router?.query?.id && location != '') {
			setIsLoading(true)
			await api
				.post(`/ads/user_ads_search`, {
					userId: router?.query?.id,
					// loginUser: _loginUserDetails?.id,
					location: location
				})
				.then(async (response) => {
					setDealerAds(response?.data)
					setIsLoading(false)
				})
				.catch(function (error) {
					console.log(error)
					setIsLoading(false)
				})
		} else {
			setDealerAds(copyDealerAds)
		}
	}

	const loadMoreData = () => {
		setPage(page + 1)
		getDealerDetails(page + 1)
	}

	return (
		<>
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
			<Container>
				<Spacer height={1} />
				<Row>
					<Col xl={3}>
						<Content>
							<ProfileContent align="center">
								<SeeProfile>
									{customer?.photo ? (
										<div>
											<Profile src={process.env.REACT_APP_IMAGE_URL + customer?.photo}></Profile>
										</div>
									) : (
										<Text fontSize={6} color="white" fontWeight={700}>
											{customer?.fullname ? customer?.fullname[0].toUpperCase() : customer?.firstname[0].toUpperCase()}
										</Text>
									)}
								</SeeProfile>
								<div>
									<Heading style={{overflow: 'hidden', overflowWrap: 'anywhere'}} level={4} fontWeight={700} isDarkTheme={_isDarkTheme}>
										{customer?.fullname ? customer?.fullname : customer?.firstname}
									</Heading>
									<Spacer height={0.5} />
									<Text pointer type="normal" fontWeight="bold" isDarkTheme={_isDarkTheme}>
										Published ads - {customer?.ads?.length}
									</Text>
									<Spacer height={1} />
									{_loginUserDetails?.id != customer?.customerId && (
										<div>
											<Phone
												isDarkTheme={_isDarkTheme}
												onClick={() => {
													if (_loginUserDetails?.id) {
														setOpenCallModal(true)
													}
												}}>
												<FiPhone color={palette.red} />
												Call
											</Phone>
										</div>
									)}
									<Spacer height={1} />
									<Chat
										isDarkTheme={_isDarkTheme}
										onClick={() => {
											setOpenShareModal(true)
										}}>
										<BiShareAlt fontSize={20} />
										Share user profile
									</Chat>

									{_loginUserDetails?.id == customer?.id
										? ''
										: _loginUserDetails?.id && (
												<Flexed margin="1rem 0rem" justify="space-between" direction="row" align="center" gap={0.5}>
													<Report
														onClick={() => {
															setReportBlockUser(true)
														}}
														type="normal"
														fontWeight="bold"
														isDarkTheme={_isDarkTheme}>
														Report user
													</Report>
													<Line />
													<Report
														onClick={() => {
															setBlockUser(true)
														}}
														type="normal"
														fontWeight="bold"
														isDarkTheme={_isDarkTheme}>
														{customer?.isBlocked ? 'Unblock user' : 'Block user'}
													</Report>
												</Flexed>
										  )}
								</div>
							</ProfileContent>
						</Content>
						<Spacer height={2} />
					</Col>
					<Col xl={8.5} xlOffset={0.5}>
						<Wrapper>
							<Row>
								<Col xl={6} lg={6} md={6} sm={6} xs={12}>
									<LocationSearch
										setSearchLocation={setSearchLocation}
										appSearch={(location: any) => {
											filterPost(location)
										}}
									/>
								</Col>
							</Row>
							<Spacer height={2} />
							<Row>
								{dealerAds?.map((business: any, key: number) => {
									return (
										<Col xl={6} lg={6} md={6} sm={6} xs={12}>
											<ProductCard key={key} post={business} favAd={(e: any) => getDealerDetails(page, false)} />
										</Col>
									)
								})}
								{dealerAds?.length > 0 && !isLoading && loadMore && searchLocation.trim().length === 0 && (
									<Col>
										<Flexed direction="row" justify="center" margin="0rem 0rem 2rem 0rem">
											<Button label="Load More" type="red" ifClicked={() => loadMoreData()} />
										</Flexed>
									</Col>
								)}
								{dealerAds?.length === 0 && (
									<Col>
										<Spacer height={5} />
										{!isLoading ? (
											<Text type="small" isCentered isDarkTheme={_isDarkTheme}>
												Post details not available.
											</Text>
										) : (
											<Text type="small" isCentered isDarkTheme={_isDarkTheme}>
												The post is loading...
											</Text>
										)}
									</Col>
								)}
							</Row>
						</Wrapper>
					</Col>
				</Row>
			</Container>
			{reportUserModal && (
				<ReportUserModal
					isBlocked={customer?.isBlocked}
					open={reportUserModal}
					onClick={() => {
						getDealerDetails(page)
						setReportBlockUser(false)
					}}
					onCloseModal={() => {
						setReportBlockUser(false)
					}}
				/>
			)}
			{blockUserModal && (
				<BlockUserModal
					open={blockUserModal}
					isBlocked={customer?.isBlocked}
					onClick={() => {
						getDealerDetails(page)
						setBlockUser(false)
					}}
					user={customer}
					onCloseModal={() => {
						setBlockUser(false)
					}}
				/>
			)}
			{openShareModal && (
				<SocialShareModal
					onCloseModal={() => {
						setOpenShareModal(false)
					}}
				/>
			)}

			{openCallModal && (
				<DealerCallModal
					customer={customer}
					onCloseModal={() => {
						setOpenCallModal(false)
					}}
				/>
			)}
		</>
	)
}

const Wrapper = styled(Container)`
	padding: 0rem;
`

const Line = styled.div`
	height: 1.5rem;
	width: 1px;
	background: ${palette.light_gray};
`

const Content = styled.div`
	padding: 1rem;
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 4px 5px rgba(0, 0, 0, 0.07), 0px 1px 10px rgba(0, 0, 0, 0.06);
	border-radius: 0.5rem;
`

const ProfileContent = styled(Flexed)`
	flex-direction: column;
	gap: 1rem;
	${media.sm`
	flex-direction: row;
	gap:2rem;
	`}
	${media.xl`
	flex-direction: column;
	gap:1rem;
	`}
`

const Report = styled(Text)`
	cursor: pointer;
	text-decoration: underline;
	text-underline-offset: 4px;
	&:hover {
		color: ${palette.red} !important;
	}
`

const SeeProfile = styled(Flexed)`
	position: relative;
	width: 13rem;
	height: 13rem;
	border-radius: 0.5rem;
	overflow: hidden;
	background: ${palette.red};
	color: ${palette.white};
	display: flex;
	justify-content: center;
	cursor: pointer;
	align-items: center;
`

const Profile = styled.div<any>`
	background-image: ${({src}) => (src ? `url(${src})` : palette.light_gray)};
	background-size: cover;
	background-position: center;
	width: 13rem;
	height: 13rem;
	border-radius: 0.5rem;
`

const Phone = styled.div<any>`
	background: ${palette.red_light};
	border-radius: 0.3rem;
	padding: 0.5rem 0.8rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	justify-content: center;
	font-size: 1rem;
	font-family: 'Montserrat', sans-serif;
	color: ${palette.black};
	cursor: pointer;
	&:hover {
		background: ${palette.red_light_hover};
	}
`

const Chat = styled(Phone)<any>`
	background: ${({isDarkTheme}) => (isDarkTheme ? palette.green_light_hover : palette.green_light)};
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.white : palette.black)};
	& svg {
		color: ${({isDarkTheme}) => (isDarkTheme ? palette.green : palette.green)};
	}
	&:hover {
		background: ${({isDarkTheme}) => (isDarkTheme ? palette.green : palette.green_light_hover)};
		color: ${({isDarkTheme}) => (isDarkTheme ? palette.white : palette.black)};
	}
	&:hover svg {
		color: ${({isDarkTheme}) => (isDarkTheme ? palette.green_light : palette.green)};
	}
`

export default PropertyDealerProfile
