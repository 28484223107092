/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * ReportUserModal Component
 *
 * @component
 * @description Modal for reporting and potentially blocking a user.
 *
 * @param {Object} props - Component props.
 * @param {boolean} props.open - Flag indicating whether the modal is open.
 * @param {function} props.onCloseModal - Callback function to handle modal close.
 * @param {function} props.onClick - Callback function to handle user action after reporting.
 * @param {boolean} props.isBlocked - Flag indicating whether the user is already blocked.
 * @param {Object} props.user - User object to report.
 */

import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {Text, Spacer, Flexed} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {BsXLg} from 'react-icons/bs'
import {useSelector} from 'react-redux'
import {media} from 'styled-bootstrap-grid'
import InputField from '../common/InputField'
import {api} from '../api/callAxios'
import useRouter from '../common/UserRouterHook'
import {toast} from 'react-toastify'

interface ICategoriesModalProps {
	open: boolean
	onCloseModal: any
	onClick?: any
	isBlocked?: any
	user?: any
}

interface IProps {
	isDarkTheme: boolean
}

const ReportUserModal = ({open, onCloseModal, onClick, isBlocked, user}: ICategoriesModalProps) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const [report, setReport] = useState('')
	const router = useRouter()
	const [isWantBlock, setIsWantBlock] = useState(false)
	const [comment, setComment] = useState('')
	const [reportError, setReportError] = useState(false)
	const [commentError, setCommentError] = useState(false)

	const handleOnSelect = (reportType: string) => {
		setReport(reportType)
	}

	const addUserReport = async () => {
		if (router.query?.id || user) {
			await api
				.post(`/reportUser/add_report`, {foreginUserId: user ? user?.id : router.query?.id, reason: report, comment: comment, blockAlso: isWantBlock})
				.then(async (response) => {
					if (response) {
						toast.success('User reported successfully')
						setCommentError(false)
						setReport('')
						setComment('')
						setIsWantBlock(false)
						setReportError(false)
						onClick()
					}
				})
				.catch(function (error) {
					console.log(error?.response?.data)
					if (error?.response?.data?.message) {
						toast.error(error?.response?.data?.message)
					}
					setCommentError(false)
					setReport('')
					setComment('')
					setIsWantBlock(false)
					setReportError(false)
					onClick()
				})
		}
	}

	return (
		<>
			<Modal
				open={open}
				center
				onClose={() => {
					onCloseModal()
				}}
				classNames={{
					overlay: 'customOverlay',
					modal: 'blockModal'
				}}
				showCloseIcon={false}
				// @ts-ignore
				styles={{zIndex: '2'}}>
				<ModalWrapper isDarkTheme={_isDarkTheme}>
					<Head direction="row" align="center" justify="space-between">
						<Text type="large" lineHeight="1.438" fontWeight="700" isDarkTheme={_isDarkTheme}>
							User Report?
						</Text>
						<CrossIcon
							isDarkTheme={_isDarkTheme}
							onClick={() => {
								onCloseModal()
								setCommentError(false)
								setReport('')
								setComment('')
								setIsWantBlock(false)
								setReportError(false)
							}}
						/>
					</Head>
					<Body>
						<Spacer height={1} />
						<Flexed direction="row" align="center" gap={1}>
							<input id="spam" name={'report'} type="radio" checked={'spam' == report} onClick={() => handleOnSelect('spam')} />
							<Label htmlFor="spam" isDarkTheme={_isDarkTheme}>
								Spam
							</Label>
						</Flexed>
						<Spacer height={0.5} />
						<Flexed direction="row" align="center" gap={1}>
							<input id="fraud" name={'report'} type="radio" checked={'fraud' == report} onClick={() => handleOnSelect('fraud')} />
							<Label htmlFor="fraud" isDarkTheme={_isDarkTheme}>
								Fraud
							</Label>
						</Flexed>
						<Spacer height={0.5} />
						<Flexed direction="row" align="center" gap={1}>
							<input id="inappropriateProfilePicture" name={'report'} type="radio" checked={'inappropriateProfilePicture' == report} onClick={() => handleOnSelect('inappropriateProfilePicture')} />
							<Label htmlFor="inappropriateProfilePicture" isDarkTheme={_isDarkTheme}>
								Inappropriate profile picture
							</Label>
						</Flexed>
						<Spacer height={0.5} />
						<Flexed direction="row" align="center" gap={1}>
							<input id="thisUserIsThreateningMe" name={'report'} type="radio" checked={'thisUserIsThreateningMe' == report} onClick={() => handleOnSelect('thisUserIsThreateningMe')} />
							<Label htmlFor="thisUserIsThreateningMe" isDarkTheme={_isDarkTheme}>
								This user is threatening me
							</Label>
						</Flexed>
						<Spacer height={0.5} />
						<Flexed direction="row" align="center" gap={1}>
							<input id="thisUserIsInsultingMe" name={'report'} type="radio" checked={'thisUserIsInsultingMe' == report} onClick={() => handleOnSelect('thisUserIsInsultingMe')} />
							<Label htmlFor="thisUserIsInsultingMe" isDarkTheme={_isDarkTheme}>
								This user is insulting me
							</Label>
						</Flexed>
						<Spacer height={0.5} />
						<Flexed direction="row" align="center" gap={1}>
							<input id="other" name={'report'} type="radio" checked={'other' == report} onClick={() => handleOnSelect('other')} />
							<Label htmlFor="other" isDarkTheme={_isDarkTheme}>
								Other
							</Label>
						</Flexed>

						<Text fontSize={0.7} type="small" color="danger">
							{report ? '' : reportError ? 'Please select a report reason' : ''}
						</Text>

						<Spacer height={1.5} />

						<InputField
							label="Comment"
							type="textarea"
							value={comment}
							handleChange={(e: any) => {
								if (e?.length <= 500) {
									setComment(e)
								}
							}}
						/>
						{!commentError && (
							<Flexed direction="row" justify="space-between" gap={1}>
								<Text isDarkTheme={_isDarkTheme} type="xsmall">
									{comment?.length}/500
								</Text>
							</Flexed>
						)}
						<Text fontSize={0.7} type="small" color="danger">
							{comment?.trim().length > 0 ? '' : commentError ? `Please add a comment to help us understand what's wrong with this user.` : ''}
						</Text>

						<Spacer height={1.5} />

						{!isBlocked && (
							<Flexed direction="row" align="center" gap={1}>
								<input id="block" name="block" type="checkbox" checked={isWantBlock} onClick={() => setIsWantBlock(!isWantBlock)} />
								<Label htmlFor="block" isDarkTheme={_isDarkTheme}>
									I also want to block this user
								</Label>
							</Flexed>
						)}

						<Spacer height={1.5} />

						<Flexed direction="row" align="center" justify="space-between" gap={1}>
							<Block
								isDarkTheme={_isDarkTheme}
								onClick={() => {
									if (report && comment.trim().length > 0) {
										addUserReport()
									} else {
										setCommentError(comment.trim().length == 0 ? true : false)
										setReportError(report.trim().length == 0 ? true : false)
									}
								}}>
								Send complaint
							</Block>
						</Flexed>
					</Body>
				</ModalWrapper>
			</Modal>
		</>
	)
}

const ModalWrapper = styled.div<IProps>`
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	padding: 1rem;
`

const Head = styled(Flexed)`
	padding: 1rem;
	width: 250px;
	${media.sm`
    width: 400px;
	`}
	${media.md`
    width: 400px;
	`}
`

const Body = styled.div`
	// width: 25rem;
	padding: 0rem 1rem 1rem;
`

const CrossIcon = styled(BsXLg)<IProps>`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 1.3rem;
`

const Block = styled.div<any>`
	background: ${({isDarkTheme}) => (isDarkTheme ? palette.dark_gray : palette.black)};
	border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
	border-radius: 0.3rem;
	padding: 0.5rem 0.8rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	justify-content: center;
	font-size: 1rem;
	font-weight: bold;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.white : palette.white)};
	cursor: pointer;
	width: 100%;
	&:hover {
		background: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.dark_gray)};
	}
`

const Label = styled.label<any>`
	margin: 0;
	cursor: pointer;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
`

export default ReportUserModal
