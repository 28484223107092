/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * PotentialBuyersModel Component
 *
 * @component
 * @description Modal for potential buyers with user information.
 *
 * @param {Object} props - Component props.
 * @param {boolean} props.open - Flag indicating whether the modal is open.
 * @param {function} props.onCloseModal - Callback function to handle modal close.
 * @param {function} props.onClick - Callback function for the "Continue" button click.
 * @param {string} props.label - Label for the potential buyers list.
 * @param {Array} props.users - Array of potential buyers.
 * @param {string} props.title - Title for the modal.
 * @param {string} props.adId - Ad ID for navigation.
 */
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {Text, Spacer, Flexed} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {useSelector} from 'react-redux'
import {media} from 'styled-bootstrap-grid'

import axios from 'axios'
import useRouter from '../common/UserRouterHook'
import {DefaultIcon} from './ItemSoldModal'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

interface ICategoriesModalProps {
	open: boolean
	onCloseModal: any
	onClick?: any
	label?: any
	title?: any
	estimatedViews?: any
	users?: any
	adId?: any
}

interface IProps {
	isDarkTheme: boolean
}

const PotentialBuyersModel = ({open, onCloseModal, onClick, label, users, title, adId}: ICategoriesModalProps) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const router = useRouter()
	return (
		<>
			<Modal
				open={open}
				center
				onClose={() => {
					onCloseModal()
				}}
				classNames={{
					overlay: 'customOverlay',
					modal: 'blockModal'
				}}
				showCloseIcon={false}
				// @ts-ignore
				styles={{zIndex: '2'}}>
				<ModalWrapper isDarkTheme={_isDarkTheme}>
					{title && (
						<Head direction="row" align="center" justify="center">
							<Text type="large" lineHeight="1.438" fontWeight="700" color={_isDarkTheme ? 'black' : 'black'} onClick={() => router.navigate('/')} pointer>
								{title}
							</Text>
						</Head>
					)}
					<Body>
						<Spacer height={1} />
						<Flexed direction="row" align="center" gap={1}>
							<Label htmlFor="spam" isDarkTheme={_isDarkTheme}>
								{label}
							</Label>
						</Flexed>

						<Spacer height={0.5} />
						{users?.length > 0 ? (
							<div>
								<ul style={{listStyleType: 'none'}}>
									{users.map((user: any) => (
										<li
											key={user.id}
											onClick={() => {
												adId && router.navigate(`/chat?id=${user.id}&isBlocked=${false}&addId=${adId}`)
											}}
											style={{cursor: 'pointer', gap: '0.5rem', display: 'flex', marginBottom: '0.5rem', alignItems: 'center'}}>
											<DefaultIcon src={user?.photo ? process.env.REACT_APP_IMAGE_URL + user?.photo : '/images/icons/icon_Profile.png'} alt="icon_Profile" />
											<div>
												<Text type="small" color={_isDarkTheme ? 'white' : 'black'} pointer>
													{user.fullname}
												</Text>
												{user?.showNoInAds ? (
													<Text type="small" color={_isDarkTheme ? 'white' : 'black'} pointer>
														{user?.phone}
													</Text>
												) : (
													''
												)}
											</div>
										</li>
									))}
								</ul>
							</div>
						) : (
							<>
								<Spacer height={0.5} />
								<Text isDarkTheme={_isDarkTheme} fontWeight={500} isCentered>
									No user found
								</Text>
								<Spacer height={1.5} />
							</>
						)}
						<Spacer height={1.5} />
						<Flexed direction="row" align="center" justify="space-between" gap={1}>
							<Block
								isCancel={true}
								isDarkTheme={_isDarkTheme}
								onClick={() => {
									onCloseModal()
								}}>
								Cancel
							</Block>
							<Block
								isDarkTheme={_isDarkTheme}
								onClick={() => {
									onClick()
								}}>
								Continue
							</Block>
						</Flexed>
					</Body>
				</ModalWrapper>
			</Modal>
		</>
	)
}

const ModalWrapper = styled.div<IProps>`
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
`

const Head = styled(Flexed)`
	padding: 1rem;
	text-align: center;
	${media.sm`
    width: 400px;
	`}
	${media.md`
    width: 400px;
	`}
	background-color: #ebeeef;
`

const Body = styled.div`
	width: 25rem;
	padding: 0rem 1rem 1rem;
`

const Block = styled.div<any>`
	background: ${({isDarkTheme, isCancel}) => (isCancel ? palette.light_gray : isDarkTheme ? palette.dark_gray : palette.black)};
	border-radius: 0.3rem;
	padding: 0.5rem 0.8rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	justify-content: center;
	font-size: 1rem;
	font-weight: bold;
	color: ${({isDarkTheme, isCancel}) => (isCancel ? palette.black : isDarkTheme ? palette.white : palette.white)};
	cursor: pointer;
	width: 100%;
`

const Label = styled.label<any>`
	margin: 0;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
`

export default PotentialBuyersModel
