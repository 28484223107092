/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * API Configuration component
 */

import axios from 'axios'
import {toast} from 'react-toastify'

/*
 * API Configuration
 * Description: This function configures an Axios instance for making API calls with the specified base URL and headers.
 */

export const api = axios.create({
	baseURL: process.env.REACT_APP_API,
	headers: {
		'Content-Type': 'application/json'
	}
})

api.interceptors.request.use(
	(request: any) => {
		let token = localStorage.getItem('authorization')
		if (token) {
			request.headers.common.Authorization = `Bearer ${token}`
		}
		return request
	},
	(error) => {
		return Promise.reject(error)
	}
)

api.interceptors.response.use(
	(response) => {
		return response
	},
	function (error) {
		return Promise.reject(error)
	}
)

export const doGetOwnerProfile = async () => {
	let response: any = []
	await api
		.post('/owner/view_owner')
		.then((res) => {
			if (res?.data.status) {
				response = res?.data?.data
			}
		})
		.catch((e) => {
			console.error(e)
			toast.error(e?.response?.data?.message)
		})
	return response
}

/**
 * doGetOwnerProfile Function
 *
 * Description: Fetches the owner's profile using the API.
 * @returns {Promise<any>} - A promise that resolves to the owner's profile data or an empty array if an error occurs.
 */
export const doGetCustomerProfile = async () => {
	let response: any = []
	await api
		.post('/customers/view_user')
		.then((res) => {
			if (res?.data) {
				response = res?.data
			}
		})
		.catch((e) => {
			console.error(e)
			toast.error(e?.response?.data?.message)
			window.location.href = '/customer-login'
		})
	return response
}

/**
 * doGetCustomerProfile Function
 *
 * Description: Fetches the customer's profile using the API.
 * @returns {Promise<any>} - A promise that resolves to the customer's profile data or an empty array if an error occurs.
 */
export const checkFeaturedPostStatus = async () => {
	await api
		.post(`/featureAds/feature_ads_status`)
		.then(async (response) => {})
		.catch(function (error) {})
}

/**
 * checkFeaturedPostStatus Function
 *
 * Description: Checks the status of featured posts using the API.
 */
export const checkAdvertisementStatus = async () => {
	await api
		.post(`/paidAds/paid_ads_status`)
		.then(async (response) => {})
		.catch(function (error) {})
}
