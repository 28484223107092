/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Main App Component
 * @component
 * @description
 * This component serves as the main entry point for the application. It includes routing for different pages and
 * manages the overall layout, including the header, subheader, and footer.
 *
 */

import React, {useEffect, useState, useRef} from 'react'
import {Route, Routes} from 'react-router-dom'
import styled from 'styled-components'
import Header from './components/common/Header'
import Home from './pages/Home'
import CustomerLogin from './pages/customerAuth/Login'
import CustomerForgotPassword from './pages/customerAuth/ForgotPassword'
import CustomerSignUp from './pages/customerAuth/SignUp'
import CustomerProfile from './pages/customerAuth/CustomerProfile'
import PropertyDetails from './pages/business/PropertyDetails'
import EditPost from './pages/posts/EditPost'
import CreatePost from './pages/posts/CreatePost'
import {useLocation} from 'react-router-dom'
import Footer from './components/common/Footer'
import {useSelector} from 'react-redux'
import {palette} from './styled/colors'
import AllPosts from './pages/posts/AllPosts'
import MyPosts from './pages/posts/MyPosts'
import MyFavourites from './pages/posts/MyFavourites'
import PropertyDealerProfile from './pages/business/PropertyDealerProfile'
import {Loading} from './styled/shared'
import WeavyChat from './pages/WeavyChat'
import Packages from './pages/payment/Packages'
import PaymentMethod from './pages/payment/PaymentMethod'
import SubHeader from './components/common/SubHeader'
import Settings from './pages/Settings'
import useRouter from './components/common/UserRouterHook'
import ContactUs from './components/common/Contactus'
import Features from './components/common/Features'
import AdBanner from './components/common/adBanner'
import FrontPage from './pages/FrontPage'

interface IProps {
	isDarkTheme: boolean
}

const App = () => {
	const {pathname} = useLocation()
	const _useRef = useRef<any>()
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const [searchLocation, setSearchLocation] = useState('')
	const [searchKeyword, setSearchKeyword] = useState('')
	const [sideFilters, setSideFilters] = useState({sort: [], category: []})
	const [priceRange, setPriceRange] = useState('')
	const [isLoading, setIsLoading] = useState<any>(false)
	const [page, setPage] = useState(1)
	const [pageNoSearch, setPageNoSearch] = useState(1)
	const [copyAdsSearchResults, setCopyAdsSearchResults] = useState([])
	const [businessType, setBusniessType] = useState('')
	const [typeCategory, setTypeCategory] = useState('')
	const [subCategory, setSubCategory] = useState('')
	const router = useRouter()
	let _token = localStorage.getItem('authorization')

	useEffect(() => {
		if (_isDarkTheme) {
			document.body.style.backgroundColor = '#141414'
		} else {
			document.body.style.backgroundColor = '#ffffff'
		}
	}, [_isDarkTheme])

	const appSearch = (keyword: any, location: any) => {
		if (_useRef?.current) {
			setPageNoSearch(1)
			setCopyAdsSearchResults([])
			_useRef.current.mainAppSearch(keyword, location, sideFilters, priceRange, businessType, typeCategory, subCategory, 1)
		}
	}

	const clearFilter = () => {
		setBusniessType('')
		setTypeCategory('')
		setSubCategory('')
		setSearchKeyword('')
		setSearchLocation('')
	}

	useEffect(() => {
		if (_token == undefined && (pathname == '/app' || pathname == '/create-post' || pathname == '/edit-post' || pathname == '/settings' || pathname == '/connections' || pathname == '/chat' || pathname == '/payments/packages' || pathname == '/payments/payment-selection' || pathname == '/customer-profile' || pathname == '/my-favourites' || pathname == '/my-ads')) {
			router.navigate('/customer-login')
		}
	}, [_token])

	return (
		<div>
			<Header clearFilter={clearFilter} searchKeyword={searchKeyword} searchLocation={searchLocation} setSearchLocation={setSearchLocation} setSearchKeyword={setSearchKeyword} appSearch={(keyword: any, location: any) => appSearch(keyword, location)} />
			{pathname != '/' && pathname != '/customer-login' && pathname != '/customer-signUp' && pathname != '/forgot-password' && pathname != '/create-post' && pathname != '/edit-post' && pathname != '/chat' && pathname != '/payments/packages' && pathname != '/payments/payment-selection' && <SubHeader setBusniessType={setBusniessType} setTypeCategory={setTypeCategory} />}
			<Main isDarkTheme={_isDarkTheme}>
				<Routes>
					<Route path="/" element={<FrontPage />}></Route>
					<Route path="/demo" element={<Home setBusniessType={setBusniessType} setTypeCategory={setTypeCategory} setSubCategory={setSubCategory} />}></Route>
					<Route path="/customer-login" element={<CustomerLogin />}></Route>
					<Route path="/customer-signUp" element={<CustomerSignUp />}></Route>
					<Route path="/forgot-password" element={<CustomerForgotPassword />}></Route>
					<Route path="/customer-profile" element={<CustomerProfile />}></Route>
					<Route path="/post" element={<AllPosts ref={_useRef} clearFilter={clearFilter} searchKeyword={searchKeyword} searchLocation={searchLocation} sideFilters={sideFilters} setSideFilters={setSideFilters} priceRange={priceRange} setPriceRange={setPriceRange} businessType={businessType} setBusniessType={setBusniessType} typeCategory={typeCategory} setTypeCategory={setTypeCategory} subCategory={subCategory} setSubCategory={setSubCategory} page={page} setPage={setPage} pageNoSearch={pageNoSearch} setPageNoSearch={setPageNoSearch} copyAdsSearchResults={copyAdsSearchResults} setCopyAdsSearchResults={setCopyAdsSearchResults} />}></Route>
					<Route path="/create-post" element={<CreatePost />}></Route>
					<Route path="/post-details" element={<PropertyDetails />}></Route>
					<Route path="/edit-post" element={<EditPost />}></Route>
					<Route path="/dealer-profile" element={<PropertyDealerProfile />}></Route>
					<Route path="/my-ads" element={<MyPosts />}></Route>
					<Route path="/my-favourites" element={<MyFavourites />}></Route>
					<Route path="/chat" element={<WeavyChat />}></Route>
					<Route path="/payments/packages" element={<Packages />}></Route>
					<Route path="/payments/payment-selection" element={<PaymentMethod />}></Route>
					<Route path="/settings" element={<Settings />}></Route>
					<Route path="/contact-us" element={<ContactUs />}></Route>
				</Routes>
			</Main>
			<AdBanner />
			{pathname === '/' && <Footer setBusniessType={setBusniessType} setTypeCategory={setTypeCategory} setSubCategory={setSubCategory} />}
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</div>
	)
}

const Main = styled.div<IProps>`
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_black : palette.white)};
`

export default App
