/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Category Component
 *
 * @component
 * @description Represents a section for displaying categories and subcategories.
 *
 * @param {Object} props - Component props.
 * @param {Function} props.setBusniessType - Function to set the business type.
 * @param {Function} props.setTypeCategory - Function to set the type category.
 * @param {Function} props.setSubCategory - Function to set the subcategory.
 */

import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Flexed, Heading, Spacer, Text, Loading} from '../styled/shared'
import {palette} from '../styled/colors'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {useSelector} from 'react-redux'
import {MdKeyboardArrowRight} from 'react-icons/md'
import PropertyCardContent from './products/PropertyCardContent'
import {api, checkFeaturedPostStatus} from './api/callAxios'
import Slider from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import {useNavigate} from 'react-router-dom'

interface IProps {
	isDarkTheme: boolean
}

const Category = ({setBusniessType, setTypeCategory, setSubCategory}: any) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	let _navigate = useNavigate()
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	const [isLoading, setIsLoading] = useState<any>(false)
	const [allCategories, setAllCategories] = useState<any>([])
	const [subCategories, setSubCategories] = useState<any>([])
	const getPost = async () => {
		setIsLoading(true)
		await api
			.get(`/business-type`)
			.then(async (response: any) => {
				setAllCategories(response?.data)
				setIsLoading(false)
			})
			.catch(function (error: any) {
				console.log(error)
				setIsLoading(false)
			})
	}

	const getSpecificPost = async () => {
		setIsLoading(true)
		await api
			.post(`/ads/ads_categories`, {
				userId: _loginUserDetails?.id ? _loginUserDetails?.id : '',
				categoryOne: allCategories[0]?.typeCategory[0]?.title,
				categoryTwo: allCategories[1]?.typeCategory[0]?.title,
				categoryThree: allCategories[2]?.typeCategory[0]?.title,
				categoryFour: allCategories[3]?.typeCategory[0]?.title
			})
			.then(async (response: any) => {
				setSubCategories(response?.data)
				setIsLoading(false)
			})
			.catch(function (error: any) {
				console.log(error)
				setIsLoading(false)
			})
	}

	useEffect(() => {
		checkFeaturedPostStatus()
		getPost()
	}, [])

	useEffect(() => {
		if (allCategories.length > 0) {
			getSpecificPost()
		}
	}, [allCategories, _loginUserDetails?.id])

	const getCategoriesIcons = (type: any) => {
		switch (type) {
			case 'Property for Sale':
				return '/images/property_sale_category_img.png'
			case 'Property for Rent':
				return '/images/property_rent_category_img.png'
			case 'Vehicles':
				return '/images/vehicles_categories_img.png'
			case 'Mobiles':
				return '/images/mobile_category_img.png'
			default:
				return '/images/property_sale_category_img.png'
		}
	}

	const responsive = {
		superLargeDesktop: {
			breakpoint: {max: 4000, min: 3000},
			items: 3,
			slidesToSlide: 1
		},
		desktop: {
			breakpoint: {max: 3000, min: 1024},
			items: 3,
			slidesToSlide: 1
		},
		tablet: {
			breakpoint: {max: 1024, min: 600},
			items: 2,
			slidesToSlide: 1
		},
		mobile: {
			breakpoint: {max: 600, min: 0},
			items: 1.2,
			slidesToSlide: 1
		}
	}
	return (
		<>
			<Container id="home">
				<Spacer height="5" />
				{allCategories.length > 0 && (
					<Row>
						<Col>
							<Heading level={3} isDarkTheme={_isDarkTheme} fontWeight={700}>
								All categories
							</Heading>
						</Col>
					</Row>
				)}
				<Spacer height="1.5" />
				<Row>
					{allCategories?.map((items: any) => {
						return (
							<Col
								xl={3}
								lg={3}
								md={6}
								sm={6}
								xs={6}
								onClick={() => {
									setBusniessType(items.title)
									_navigate('/post')
								}}>
								<Card isDarkTheme={_isDarkTheme}>
									<Logo src={getCategoriesIcons(items?.title)} />
									<Title isDarkTheme={_isDarkTheme} margin="0.5rem 0rem 0rem 0rem" isCentered fontWeight={700}>
										{items.title}
									</Title>
								</Card>
							</Col>
						)
					})}
				</Row>
				{subCategories?.categoryOneResults?.length > 0 && (
					<>
						<Spacer height="3" />
						<Row>
							<Col>
								<Flexed direction="row" align="center" justify="space-between" margin="0rem 0rem 1.5rem 0rem">
									<Heading level={3} isDarkTheme={_isDarkTheme} fontWeight={700}>
										{allCategories[0]?.typeCategory[0]?.title}
									</Heading>
									<Flexed direction="row" align="center">
										{subCategories?.categoryOneResults?.length > 2 && (
											<GoToLink
												type="normal"
												fontWeight={700}
												pointer
												onClick={() => {
													setBusniessType(subCategories?.categoryOneResults[0]?.businessType)
													setTypeCategory(allCategories[0]?.typeCategory[0]?.title)
													_navigate('/post')
												}}>
												View more
												<MdKeyboardArrowRight />
											</GoToLink>
										)}
									</Flexed>
								</Flexed>
							</Col>
						</Row>
						<Slider responsive={responsive}>
							{subCategories?.categoryOneResults?.map((val: any) => {
								return (
									<Col>
										<PropertyCardContent post={val} />
									</Col>
								)
							})}
						</Slider>
					</>
				)}
				{subCategories?.categoryTwoResults?.length > 0 && (
					<>
						<Spacer height="2" />
						<Row>
							<Col>
								<Flexed direction="row" align="center" justify="space-between" margin="0rem 0rem 1.5rem 0rem">
									<Heading level={3} isDarkTheme={_isDarkTheme} fontWeight={700}>
										{allCategories[1]?.typeCategory[0]?.title}
									</Heading>
									<Flexed direction="row" align="center">
										{subCategories?.categoryTwoResults?.length > 2 && (
											<GoToLink
												type="normal"
												fontWeight={700}
												pointer
												onClick={() => {
													setBusniessType(subCategories?.categoryTwoResults[0]?.businessType)
													setTypeCategory(allCategories[1]?.typeCategory[0]?.title)
													_navigate('/post')
												}}>
												View more
												<MdKeyboardArrowRight />
											</GoToLink>
										)}
									</Flexed>
								</Flexed>
							</Col>
						</Row>
						<Slider responsive={responsive}>
							{subCategories?.categoryTwoResults?.map((val: any) => {
								return (
									<Col>
										<PropertyCardContent post={val} />
									</Col>
								)
							})}
						</Slider>
					</>
				)}
				{subCategories?.categoryThreeResults?.length > 0 && (
					<>
						<Spacer height="2" />
						<Row>
							<Col>
								<Flexed direction="row" align="center" justify="space-between" margin="0rem 0rem 1.5rem 0rem">
									<Heading level={3} isDarkTheme={_isDarkTheme} fontWeight={700}>
										{allCategories[2]?.typeCategory[0]?.title}
									</Heading>
									<Flexed direction="row" align="center">
										{subCategories?.categoryThreeResults?.length > 2 && (
											<GoToLink
												type="normal"
												fontWeight={700}
												pointer
												onClick={() => {
													setBusniessType(subCategories?.categoryThreeResults[0]?.businessType)
													setTypeCategory(allCategories[2]?.typeCategory[0]?.title)
													_navigate('/post')
												}}>
												View more
												<MdKeyboardArrowRight />
											</GoToLink>
										)}
									</Flexed>
								</Flexed>
							</Col>
						</Row>
						<Slider responsive={responsive}>
							{subCategories?.categoryThreeResults?.map((val: any) => {
								return (
									<Col>
										<PropertyCardContent post={val} />
									</Col>
								)
							})}
						</Slider>
					</>
				)}
				{subCategories?.categoryFourResults?.length > 0 && (
					<>
						<Spacer height="2" />
						<Row>
							<Col>
								<Flexed direction="row" align="center" justify="space-between" margin="0rem 0rem 1.5rem 0rem">
									<Heading level={3} isDarkTheme={_isDarkTheme} fontWeight={700}>
										{allCategories[3]?.typeCategory[0]?.title}
									</Heading>
									<Flexed direction="row" align="center">
										{subCategories?.categoryFourResults?.length > 2 && (
											<GoToLink
												type="normal"
												fontWeight={700}
												pointer
												onClick={() => {
													setBusniessType(subCategories?.categoryFourResults[0]?.businessType)
													setTypeCategory(allCategories[3]?.typeCategory[0]?.title)
													_navigate('/post')
												}}>
												View more
												<MdKeyboardArrowRight />
											</GoToLink>
										)}
									</Flexed>
								</Flexed>
							</Col>
						</Row>
						<Slider responsive={responsive}>
							{subCategories?.categoryFourResults?.map((val: any) => {
								return (
									<Col>
										<PropertyCardContent post={val} />
									</Col>
								)
							})}
						</Slider>
					</>
				)}

				<Spacer height="5" />
			</Container>

			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</>
	)
}

const Logo = styled.img`
	object-fit: cover;
	height: 4rem;
	opacity: 1;
	transition: all ease 1s;
	${media.sm`height:5.875rem;`}
	${media.md`height:5.875rem;`}
`

const Title = styled(Text)`
	position: absolute;
	top: 8rem;
	font-size: 1rem;
	left: 0;
	right: 0;
	margin: auto;
	transition: all ease 0.5s;
	${media.xs`top: 5.5rem;  font-size: 0.75rem;`}
`

const Description = styled(Text)`
	// display:none;
	top: 14rem;
	position: absolute;
	padding: 0rem 1rem;
	left: 0;
	right: 0;
	margin: auto;
	transition: all ease 0.5s;
`

const Card = styled.div<IProps>`
	position: relative;
	overflow: hidden;
	text-align: center;
	padding: 1rem;
	border-radius: 1rem;
	box-shadow: ${palette.shadow};
	min-height: 13rem;
	cursor: pointer;
	margin-bottom: 1.875rem;
	background: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	${media.xs` min-height: 8rem;`}
	&:hover {
		box-shadow: ${palette.shadowHover};
	}
`

const GoToLink = styled(Text)`
	color: ${palette.red};
	&:hover,
	&:hover svg {
		color: ${palette.red_hover};
	}
	& svg {
		font-size: 24px;
	}
`

export default Category
