/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * InputFilters Component
 *
 * @component
 *
 * @description
 * An input component for setting filters, especially designed for numeric range filters.
 * It includes a label, two input fields for minimum and maximum values, and additional logic
 * for handling specific cases like the 'Year' filter, including minimum and maximum blur events.
 *
 * @param {Object} props - The properties of the component.
 * @param {string} props.label - The label for the filter.
 * @param {number} props.valueTo - The current value for the 'to' range.
 * @param {number} props.valueFrom - The current value for the 'from' range.
 * @param {Function} props.saveFilter - Function to save the filter values.
 *
 */

import {useEffect, useState} from 'react'
import RangeSlider from 'react-range-slider-input'
import 'react-range-slider-input/dist/style.css'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import {Text, Heading, Spacer, Flexed} from '../../styled/shared'
import InputField from './InputField'

const InputFilters = ({label, valueTo, valueFrom, saveFilter}: any) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const [value, setValue] = useState<any>([0, 0])
	useEffect(() => {
		setValue([valueTo, valueFrom])
	}, [valueTo, valueFrom])

	const handleMinBlur = () => {
		if (label === 'Year') {
			if (parseFloat(value[0]) < 1900) {
				setValue([1900, parseFloat(value[1])])
				saveFilter([1900, parseFloat(value[1])])
			} else if (parseFloat(value[0]) > parseFloat(value[1])) {
				setValue([parseFloat(value[1]), parseFloat(value[1])])
				saveFilter([parseFloat(value[1]), parseFloat(value[1])])
			} else if (parseFloat(value[0]) < parseFloat(value[1])) {
				setValue([parseFloat(value[0]), parseFloat(value[1])])
				saveFilter([parseFloat(value[0]), parseFloat(value[1])])
			}
		}
	}

	const handleMaxBlur = () => {
		if (label === 'Year') {
			if (parseFloat(value[0]) > parseFloat(value[1])) {
				setValue([parseFloat(value[0]), parseFloat(value[0])])
				saveFilter([parseFloat(value[0]), parseFloat(value[0])])
			} else if (parseFloat(value[1]) > new Date().getFullYear() + 1) {
				setValue([parseFloat(value[0]), new Date().getFullYear() + 1])
				saveFilter([parseFloat(value[0]), new Date().getFullYear() + 1])
			} else if (parseFloat(value[1]) <= new Date().getFullYear() + 1) {
				setValue([parseFloat(value[0]), parseFloat(value[1])])
				saveFilter([parseFloat(value[0]), parseFloat(value[1])])
			}
		}
	}

	return (
		<>
			<Heading fontWeight={700} isDarkTheme={_isDarkTheme}>
				{label}
			</Heading>
			<Spacer height={0.5} />
			<FilterInputWrapper direction="row" gap={1} margin="0rem 0rem 0.2rem 0rem">
				<InputField
					value={value[0]}
					allowOnlyNumbersAndDecimal={true}
					onBlur={handleMinBlur}
					handleChange={(e: any) => {
						if (e === '') {
							e = 0
						}
						if (label === 'Year') {
							setValue([parseFloat(e), parseFloat(value[1])])
						} else if (parseFloat(e) < parseFloat(value[1])) {
							setValue([parseFloat(e), parseFloat(value[1])])
							saveFilter([parseFloat(e), parseFloat(value[1])])
						}
					}}
				/>
				<InputField
					value={value[1]}
					allowOnlyNumbersAndDecimal={true}
					onBlur={handleMaxBlur}
					handleChange={(e: any) => {
						if (e === '') {
							e = 0
						}
						if (label === 'Year') {
							setValue([parseFloat(value[0]), parseFloat(e)])
						} else if (parseFloat(e) > parseFloat(value[0])) {
							setValue([parseFloat(value[0]), parseFloat(e)])
							saveFilter([parseFloat(value[0]), parseFloat(e)])
						}
					}}
				/>
			</FilterInputWrapper>
			{(label === 'BathRooms' || label === 'BedRooms' || label === 'Floor Level' || label === 'No of Storeys') && (
				<TtleDiv>
					<Text isDarkTheme={_isDarkTheme} type="xsmall">
						{' '}
						min
					</Text>{' '}
					<Text isDarkTheme={_isDarkTheme} type="xsmall">
						max
					</Text>
				</TtleDiv>
			)}
		</>
	)
}
const TtleDiv = styled.div`
	margin-bottom: 0.6rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
`

export const FilterInputWrapper = styled(Flexed)`
	& input {
		padding: 0rem 0.8rem;
	}
`
export default InputFilters
