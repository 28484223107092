/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * DealerCallModal Component
 *
 * @component
 * @description Modal for displaying dealer call details.
 *
 * @param {Object} props - Component props.
 * @param {function} props.onCloseModal - Callback function to handle modal close.
 * @param {Object} props.customer - Customer details.
 */

import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {Text, Spacer, Flexed, Heading} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {useSelector} from 'react-redux'
import {BsXLg} from 'react-icons/bs'
import clipboardCopy from 'clipboard-copy'
import {media} from 'styled-bootstrap-grid'
import {FiPhone} from 'react-icons/fi'
import {MdContentCopy} from 'react-icons/md'
import useWindowSize from '../../hooks/useWindowSize'

const DealerCallModal = ({onCloseModal, customer}: any) => {
	const _isDarkTheme = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const [textCoppied, setTextCoppied] = useState(false)
	const windowSize = useWindowSize()
	const handleCopyClick = async (textToCopy: any) => {
		try {
			await clipboardCopy(textToCopy)
			setTextCoppied(true)
			setTimeout(() => {
				setTextCoppied(false)
			}, 500)
		} catch (error) {
			setTextCoppied(false)
			console.error('Failed to copy text to clipboard', error)
		}
	}
	return (
		<>
			<Modal
				open={true}
				onClose={() => {
					onCloseModal()
				}}
				center
				showCloseIcon={false}
				classNames={{
					overlay: 'customOverlay',
					modal: 'blockModal'
				}}>
				<ModalWrapper isDarkTheme={_isDarkTheme}>
					<Head direction="row" justify="space-between" gap={1}>
						<Flexed direction="row" gap={1} align="center">
							<div>
								<SeeProfile>
									{customer?.photo ? (
										<div>
											<Profile src={process.env.REACT_APP_IMAGE_URL + customer?.photo}></Profile>
										</div>
									) : (
										<Text fontSize={1.5} color="white" fontWeight={700}>
											{customer?.fullname ? customer?.fullname[0].toUpperCase() : customer?.firstname[0].toUpperCase()}
										</Text>
									)}
								</SeeProfile>
							</div>
							<CustomHeading level={4} fontWeight={700} isDarkTheme={_isDarkTheme}>
								{customer?.fullname ? customer?.fullname : customer?.firstname}
							</CustomHeading>
						</Flexed>
						<CrossIcon
							isDarkTheme={_isDarkTheme}
							onClick={() => {
								onCloseModal()
							}}
						/>
					</Head>
					<Body>
						<Spacer height={0.5} />
						<CustomText type="normal" fontWeight={500} isCentered isDarkTheme={_isDarkTheme}>
							Don't forget to mention <span>our app</span> when you call.
						</CustomText>
						<Spacer height={2} />
						<Flexed direction={windowSize?.width > 575 ? 'row' : 'column'} gap={0.5}>
							<LinkInput isDarkTheme={_isDarkTheme}>
								<Text isDarkTheme={_isDarkTheme} fontWeight={600}>
									{customer?.phone}
								</Text>
							</LinkInput>
							<Button
								isDarkTheme={_isDarkTheme}
								onClick={() => {
									const telLink = `tel:${customer?.phone}`
									window.location.href = telLink
								}}>
								<FiPhone />
							</Button>
							<div style={{position: 'relative'}}>
								{textCoppied && <Toltip isDarkTheme={_isDarkTheme}>copied</Toltip>}
								<Button
									isDarkTheme={_isDarkTheme}
									onClick={() => {
										handleCopyClick('+' + customer?.phone)
									}}>
									<MdContentCopy />
								</Button>
							</div>
						</Flexed>
					</Body>
				</ModalWrapper>
			</Modal>
		</>
	)
}

const ModalWrapper = styled.div<any>`
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	padding: 1rem;
`

const CustomHeading = styled(Text)`
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow-wrap: anywhere;
	font-size: 1rem !important;
	${media.sm`
    width: 10rem;
    width: 14rem;
    font-size: 1.2rem !important;;
    `}
	${media.md`
    width: 10rem;
    width: 14rem;
    font-size: 1.2rem !important;;
    `}
`

const Head = styled(Flexed)`
	padding: 1rem;
	${media.sm`
    width: 400px;
	`}
	${media.md`
    width: 400px;
	`}
`

const Body = styled.div`
	padding: 0rem 1rem 1rem;
`

const SeeProfile = styled(Flexed)`
	position: relative;
	border-radius: 0.5rem;
	overflow: hidden;
	background: ${palette.red};
	color: ${palette.white};
	display: flex;
	justify-content: center;
	cursor: pointer;
	align-items: center;
	width: 3rem;
	height: 3rem;
	${media.sm`
    width: 4rem;
	height: 4rem;
	`}
	${media.md`
    width: 4rem;
	height: 4rem;
	`}
`

const Profile = styled.div<any>`
	background: ${({src}) => (src ? `url(${src})` : palette.light_gray)};
	background-size: cover;
	background-position: center;
	border-radius: 0.5rem;
	width: 3rem;
	height: 3rem;
	${media.sm`
    width: 4rem;
	height: 4rem;
	`}
	${media.md`
    width: 4rem;
	height: 4rem;
	`}
`

const CrossIcon = styled(BsXLg)<any>`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 1.3rem;
`

const CustomText = styled(Text)<any>`
	& span {
		color: ${palette.red};
	}
`

const LinkInput = styled.div<any>`
	padding: 0.5rem 0.8rem;
	border-radius: 0.375rem;
	border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.silver)};
	height: 45px;
	display: flex;
	align-items: center;
	width: 100%;
	& div {
		overflow: hidden;
		overflow-wrap: anywhere;
	}
`

const Button = styled.div<any>`
	padding: 0.5rem 1rem;
	border-radius: 0.375rem;
	border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.silver)};
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.white : palette.black)};
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	cursor: pointer;
	&:hover {
		border: 1px solid ${palette.red};
		background: ${palette.red};
		color: ${palette.white};
	}
`

export const Toltip = styled.div<any>`
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	background: ${({isDarkTheme}) => (isDarkTheme ? palette.white : palette.black)};
	padding: 0.2rem 0.5rem;
	font-size: 12px;
	position: absolute;
	top: -2rem;
	right: 0;
	margin: auto;
	border-radius: 0.5rem;
	&::after {
		content: '';
		position: absolute;
		top: 100%;
		left: 47%;
		border-width: 1.5px;
		border-style: solid;
		border-color: ${({isDarkTheme}) => (isDarkTheme ? palette.white : palette.black)};
	}
`

export default DealerCallModal
