/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 * palette colors
 * Description: Common defined colour used in app.
 */

export const palette: any = {
	white: '#ffffff',
	black: '#141414',
	light_black: '#232323',
	dark_gray: '#434343',
	light_gray: '#DCDCDC',
	blue: '#1366ff',
	blue_hover: '#074ed1',
	blue_light_hover: '#074ed124',
	aqua_blue: '#00BCDD',
	golden: '#efb300',
	success: '#0e7b2f',
	gray: '#5C6B73',
	gray_300: '#f3f3f3',
	gray_400: '#9f9f9f',
	silver: '#E1E1E1',
	light_silver: '#434343',
	danger: '#EF402B',
	danger_hover: '#e3311b',
	danger_light_hover: '#e3311b4a',
	red: '#E00000',
	red_hover: 'rgb(242, 61, 61)',
	red_light: '#fff6f5',
	red_light_hover: 'rgba(255, 218, 212, 0.8)',
	green: '#1dad18',
	green_light: '#eef8ed',
	green_light_hover: 'rgba(67, 187, 63, 0.2)',
	yellow: 'rgb(255, 206, 50)',
	opacity: {
		white: '#ffffff2b'
	},
	whatsApp: '#43BD47',
	twitter: '#00acee',
	mail: '#38B000',
	linkedIn: '#2595EE',
	faceBook: '#344D8C',
	shadow: '0px 4px 10px rgba(0, 0, 0, 0.10)',
	shadowHover: '0px 4px 10px rgba(0, 0, 0, 0.30)',
	main_cover_overlay_light: 'rgb(0 0 0 / 40%)',
	main_cover_overlay_dark: 'rgb(0 0 0 / 65%)',
	off_black: '#161814',
	gray_gradient: 'linear-gradient(91.88deg,#b2b2b2 5.72%,#ffffff 95.59%)',
	off_white: '#fafafa',
	theme_Text: '#8c9b94',
	description_Text: '#c1c1c1',
	black2: '#000000b0',
	light: '#f5f5f5',
	grapes: '#2E2732',
	dark: '#191a1c',
	off_dark: '#2d2d2d'
}
