/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * PropertyCardContent Component
 *
 * @component
 * @description Represents a card for displaying property information.
 *
 * @param {Object} props - Component props.
 * @param {boolean} [props.view] - Flag to determine the view mode.
 * @param {Object} props.post - Property information.
 * @param {Function} [props.getMyFavouritesPosts] - Function to get favorite posts.
 * @param {Object} [props.favAd] - Favorite ad information.
 */

import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {palette} from '../../styled/colors'
import {FeaturedAddTag, SoldAddTag, Flexed, Heading, Spacer, Text} from '../../styled/shared'
import {AiOutlineHeart, AiFillHeart} from 'react-icons/ai'
import {useSelector} from 'react-redux'
import {createSearchParams, useNavigate} from 'react-router-dom'
import {verifyJSON} from '../../constants/commonFunctions'
import moment from 'moment'
import {api} from '../api/callAxios'
import AuthModal from '../../components/modals/AuthModal'
import {toast} from 'react-toastify'
import {BsDash, BsDot} from 'react-icons/bs'
import {RiChat1Line} from 'react-icons/ri'
import {FiPhone} from 'react-icons/fi'
import useRouter from '../../components/common/UserRouterHook'

interface IBusinessCardProps {
	post: any
	getMyFavouritesPosts?: any
	view?: boolean
	favAd?: any
}

const PropertyCardContent = ({view, post, getMyFavouritesPosts, favAd}: IBusinessCardProps) => {
	const [addFav, setAddFav] = useState(false)
	const _isDarkTheme = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	const [address, setAddress]: any = useState('')
	const [openAuthModal, setOpenAuthModal] = useState(false)
	let _navigate = useNavigate()
	const router: any = useRouter()
	let photo = verifyJSON(post?.photos) ? JSON.parse(post?.photos) : post?.photos

	const goToDetails = () => {
		_navigate({
			pathname: '/post-details',
			search: createSearchParams({
				id: post?.id
			}).toString()
		})
	}
	useEffect(() => {
		if (post?.location) {
			let locationJson = post?.location
			let locationObject = verifyJSON(locationJson) ? JSON.parse(locationJson) : locationJson
			var address = locationObject?.split('#')
			setAddress(address[0])
		}
		setAddFav(post?.isFav)
	}, [post])

	const addFavPost = async () => {
		await api
			.post(`favoriteAds/add_fav`, {addId: post?.id})
			.then(async (response) => {
				favAd && favAd(post)
				if (response) {
					setAddFav(!addFav)
				}
			})
			.catch(function (error) {
				if (error?.response) {
					toast.error(error?.response?.data?.message)
				}
			})
	}

	const removeFavPost = async () => {
		await api
			.post(`favoriteAds/remove_fav`, {addId: post?.id})
			.then(async (response) => {
				favAd && favAd(post)
				if (response) {
					setAddFav(!addFav)
					getMyFavouritesPosts()
				}
			})
			.catch(function (error) {
				if (error?.response) {
					toast.error(error?.response?.data?.message)
				}
			})
	}

	const handleCall = (phoneNumber: any) => {
		const telLink = `tel:${phoneNumber}`
		window.location.href = telLink
	}

	const adsStatics = async (data: any, view: any, tel: any, chats: any) => {
		await api
			.post(`/adsStatics/create_adsStatics`, {
				ownerId: data?.customerIdFkey?.id,
				adsId: data?.id,
				userId: _loginUserDetails?.id,
				viewAd: view,
				tel: tel,
				chat: chats
			})
			.then(async (response) => {})
			.catch(function (error) {
				if (error?.response) {
					toast.error(error?.response?.data?.message)
				}
			})
	}

	return (
		<>
			<Wrapper isDarkTheme={_isDarkTheme} isSold={post?.sold} view={view}>
				{post?.isFeatured && !post?.sold && <FeaturedAddTag>Featured</FeaturedAddTag>}
				{post?.sold && <SoldAddTag>Sold</SoldAddTag>}
				<div>
					<Cover onClick={goToDetails} isSold={post?.sold} view={view}>
						<Image view={view} src={photo[0]?.startsWith('data:') ? photo[0] : process.env.REACT_APP_IMAGE_URL + photo[0]} />
					</Cover>
				</div>
				<Content view={view}>
					<Flexed direction="row" justify="space-between" align="center" gap={0.2}>
						{post?.typeCategory != 'Cars on Installments' ? (
							<Heading onClick={goToDetails} level={5} fontWeight={700} isDarkTheme={_isDarkTheme}>
								${post?.price?.toLocaleString()}
							</Heading>
						) : (
							<CustomText onClick={goToDetails} fontSize={1.125} lineHeight={2} fontWeight={700} isDarkTheme={_isDarkTheme}>
								{post?.title}
							</CustomText>
						)}
						{_loginUserDetails?.id != post?.customerId && (
							<div>
								{addFav ? (
									<AiFillHeart
										cursor="pointer"
										fontSize={28}
										onClick={() => {
											if (_loginUserDetails?.id) {
												removeFavPost()
											} else {
												setOpenAuthModal(true)
											}
										}}
										fill={`${palette.danger}`}
									/>
								) : (
									<AiOutlineHeart
										cursor="pointer"
										fontSize={28}
										onClick={() => {
											if (_loginUserDetails?.id) {
												addFavPost()
											} else {
												setOpenAuthModal(true)
											}
										}}
										fill={_isDarkTheme ? palette.light_gray : palette.dark_gray}
									/>
								)}
							</div>
						)}
					</Flexed>

					<Spacer height={0.3} />

					{post?.typeCategory != 'Cars on Installments' ? (
						<CustomText onClick={goToDetails} type="small" isDarkTheme={_isDarkTheme}>
							{post?.title}
						</CustomText>
					) : (
						<Text onClick={goToDetails} type="small" fontWeight="500" isDarkTheme={_isDarkTheme}>
							${post?.downPayment?.toLocaleString()}
							<BsDash />
							{post?.installmentPlan}
						</Text>
					)}

					<Spacer height={0.5} />
					{post?.bedRooms == '' && post?.bathRooms == '' && post?.areaUnit == '' && post?.kmDriven == '' && <Spacer height={1.125} />}
					<Flexed onClick={goToDetails} direction="row" align="center" gap={0.6}>
						{post?.bedRooms != '' && (
							<Flexed direction="row" align="center" gap={0.3}>
								<Icons isDarkTheme={_isDarkTheme} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 12" className="_4225178d">
									<path fill-rule="evenodd" d="M1 .3L.3 1v10.7h1.4v-1.4h10.6v1.4h1.4v-6L13 5H6.3V3.7L5.7 3h-4V1L1 .3zM1.7 5H5v-.7H1.7V5zm0 3.3v-2h10.6V9H1.7v-.7z"></path>
								</Icons>
								<Text type="xsmall" fontWeight={600} isDarkTheme={_isDarkTheme}>
									{post?.bedRooms}
								</Text>
							</Flexed>
						)}
						{post?.bathRooms != '' && (
							<Flexed direction="row" align="center" gap={0.3}>
								<Icons isDarkTheme={_isDarkTheme} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 12" className="_4225178d">
									<path fill-rule="evenodd" d="M1.7 10h10.6V6.7H1.7V10zM13 5.3H3v-3A1 1 0 1 1 5 2h-.7v1.3H7V2h-.7a2.3 2.3 0 0 0-4.6.3v3H1L.3 6v4.7l.7.6h.7v.7H3v-.7h8v.7h1.3v-.7h.7l.7-.6V6l-.7-.7z"></path>
								</Icons>
								<Text type="xsmall" fontWeight={600} isDarkTheme={_isDarkTheme}>
									{post?.bathRooms}
								</Text>
							</Flexed>
						)}
						{post?.areaUnit && (
							<Flexed direction="row" align="center" gap={0.3}>
								<Icons isDarkTheme={_isDarkTheme} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="_4225178d">
									<path fill-rule="evenodd" d="M3 3L2 4v16l1 1h18l1-1V4l-1-1H3zm8 10v6H4v-6h7zm0-8v6H4V5h7zm9 8v6h-7v-6h7zm0-8v6h-7V5h7z"></path>
								</Icons>
								<Text type="xsmall" fontWeight={600} isDarkTheme={_isDarkTheme}>
									{post?.area}
									{post?.areaUnit === 'Square Feet' ? 'sqft' : post?.areaUnit === 'Square Yards' ? 'sqyd' : post?.areaUnit === 'Square Meter' ? 'sqm' : post?.areaUnit}
								</Text>
							</Flexed>
						)}
						{post?.kmDriven && (
							<Flexed direction="row" align="center" gap={0.3}>
								<Icons isDarkTheme={_isDarkTheme} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 12" className="_4225178d">
									<path
										fill-rule="evenodd"
										d="M7 .3c3.7 0 6.7 3 6.7 6.7 0 1.5-.2 3.1-1.6 4.5l-.4.2H2.3l-.4-.2C.5 10 .3 8.5.3 7 .3 3.3 3.3.3 7 .3zm0 1.4C4 1.7 1.7 4 1.7 7c0 1.6.2 2.6 1 3.3h8.7c.7-.7 1-1.7 1-3.3 0-3-2.5-5.3-5.4-5.3zm3.3 2v1l-.8.8-.7.6.2 1A2 2 0 1 1 8 5l1.5-1.4h1zM7 6.3a.7.7 0 1 0 0 1.4.7.7 0 0 0 0-1.4z"></path>
								</Icons>
								<Text type="xsmall" fontWeight={600} isDarkTheme={_isDarkTheme}>
									{post?.kmDriven} km
									<BsDot fontSize={18} />
									{post?.year}
								</Text>
							</Flexed>
						)}
					</Flexed>

					<Spacer height={0.5} />

					{address && (
						<CustomText onClick={goToDetails} type="small" isDarkTheme={_isDarkTheme}>
							{address}
						</CustomText>
					)}

					<Spacer height={0.3} />

					<Text onClick={goToDetails} type="xsmall" isDarkTheme={_isDarkTheme}>
						{moment(post?.createdAt).fromNow(true)} ago{' '}
					</Text>

					<Spacer height={view ? 0.7 : 0.5} />

					{_loginUserDetails?.id != post?.customerId && view && (
						<div>
							<Flexed direction="row" gap={0.5}>
								{post?.showNoInAds && (
									<Phone
										isDarkTheme={_isDarkTheme}
										onClick={() => {
											if (_loginUserDetails?.id) {
												handleCall(post?.phone)
												adsStatics(post, false, true, false)
											}
										}}>
										<FiPhone color={palette.red} />
										Call
									</Phone>
								)}
								<Chat
									isDarkTheme={_isDarkTheme}
									onClick={() => {
										if (_loginUserDetails?.id) {
											adsStatics(post, false, false, true)
											router.navigate(_loginUserDetails?.id && _loginUserDetails?.id == post?.customerId ? `/chat` : `/chat?id=${post?.customerId}&isBlocked=${post?.isBlocked}&addId=${post?.id}`)
										} else {
											setOpenAuthModal(true)
										}
									}}>
									<RiChat1Line fontSize={20} />
									Chat
								</Chat>
							</Flexed>
						</div>
					)}
				</Content>
			</Wrapper>
			<AuthModal
				open={openAuthModal}
				onCloseModal={() => {
					setOpenAuthModal(false)
				}}
			/>
		</>
	)
}

const Image = styled.img<any>`
	width: 100%;
	height: ${({view}) => (view ? '12rem' : '10rem')};
	border-radius: ${({view}) => (view ? '0.39rem' : '0')};
	overflow: hidden;
	transition: all 0.2s ease-in-out;
	object-fit: cover;
`

const Wrapper = styled.div<any>`
	position: relative;
	margin: 0 0 2rem;
	display: ${({view}) => (view ? 'flex' : '')};
	width: 100%;
	border-radius: 0.39rem;
	transition: all 0.3s ease-in-out;
	background: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	box-shadow: ${palette.shadow};
	overflow: hidden;
	pointer-events: ${({isSold}) => (isSold ? 'none' : '')};
	opacity: ${({isSold}) => (isSold ? '0.5' : '1')};
	&:hover ${Image} {
		transform: scale(1.04);
	}
`

const Cover = styled.div<any>`
	width: ${({view}) => (view ? '20rem' : '100%')};
	height: ${({view}) => (view ? '12rem' : '10rem')};
	overflow: hidden;
	cursor: pointer;
	pointer-event: ${({isSold}) => (isSold ? 'none' : '')};
	@media screen and (min-width: 1400px) and (max-width: 1470px) {
		width: ${({view}) => (view ? '20rem' : '100%')};
	}
	@media screen and (min-width: 1200px) and (max-width: 1399px) {
		width: ${({view}) => (view ? '20rem' : '100%')};
	}
	@media screen and (min-width: 600px) and (max-width: 1199px) {
		width: ${({view}) => (view ? '15rem' : '100%')};
	}
	@media screen and (min-width: 0px) and (max-width: 599px) {
		width: ${({view}) => (view ? '12.5rem' : '100%')};
	}
`

const Content = styled.div<any>`
	padding: 0.5rem;
	padding: ${({view}) => (view ? '0.5rem 0.5rem 0.5rem 1rem' : '0.5rem')};
	width: 100%;
`

const Icons = styled.svg<any>`
	height: 0.875rem;
	fill: ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
`

const CustomText = styled(Text)`
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow-wrap: anywhere;
`

const Phone = styled.div<any>`
	background: ${palette.red_light};
	border-radius: 0.3rem;
	padding: 0.3rem 0.8rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	justify-content: center;
	font-size: 0.85rem;
	font-weight: 600;
	font-family: 'Montserrat', sans-serif;
	color: ${palette.black};
	cursor: pointer;
	&:hover {
		background: ${palette.red_light_hover};
	}
`

const Chat = styled(Phone)<any>`
	background: ${({isDarkTheme}) => (isDarkTheme ? palette.green_light_hover : palette.green_light)};
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.white : palette.black)};
	& svg {
		color: ${({isDarkTheme}) => (isDarkTheme ? palette.green : palette.green)};
	}
	&:hover {
		background: ${({isDarkTheme}) => (isDarkTheme ? palette.green : palette.green_light_hover)};
		color: ${({isDarkTheme}) => (isDarkTheme ? palette.white : palette.black)};
	}
	&:hover svg {
		color: ${({isDarkTheme}) => (isDarkTheme ? palette.green_light : palette.green)};
	}
`

export default PropertyCardContent
