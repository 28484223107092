/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Root Reducer
 * @module rootReducer
 *
 * @description
 * This is the root reducer that combines all individual reducers into a single reducer for the Redux store.
 *
 */

import {combineReducers} from 'redux'
import auth from './authReducer'

export default combineReducers({
	auth
})
