/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * LogIn Component: Represents the login functionality for customers.
 *
 * This component includes a form for users to log in using their email and password.
 * Additionally, users can choose to log in with their Google or Facebook accounts.
 * If the login attempt fails due to an incorrect password or an unknown email,
 * the component provides an option to add a phone number for verification.
 * After successful login, user details are stored in the Redux store, and the user is redirected
 * to the home page or the page specified during the login attempt.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.formModal - Indicates whether the component is rendered inside a modal.
 * @param {function} props.onCloseModal - Callback function to close the modal.
 */

import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {palette} from '../../styled/colors'
import {Flexed, Heading, Spacer, Text, Loading} from '../../styled/shared'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {useSelector, useDispatch} from 'react-redux'
import Button from '../../components/common/Button'
import LogInGoogle from '../../components/common/LogInGoogle'
import {useNavigate} from 'react-router-dom'
import {api} from '../../components/api/callAxios'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import {toast} from 'react-toastify'
import {loginUserDetailsAction} from '../../actions/authActions'
import InputFieldAuth from '../../components/common/InputFieldAuth'
import CustomerOTPVerification from './CustomerOTPVerification'
import {Border, CustomText} from './SignUp'
import LogInFaceBook from '../../components/common/LogInFaceBook'
import InputPhoneNo from '../../components/common/InputPhoneNo'

interface IProps {
	isDarkTheme?: boolean
}

const LogIn = ({formModal, onCloseModal}: any) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	let _navigate = useNavigate()
	const _dispatch = useDispatch()
	const [isLoading, setIsLoading] = useState(false)
	const [showOtpInput, setShowOtpInput] = useState(false)
	const [showPhoneInput, setShowPhoneInput] = useState(false)
	const [dataForSignIn, setDataForSignIn] = useState('')
	const [userId, setUserId] = useState('')

	const [phone, setPhone] = useState('')
	const [phoneError, setPhoneError] = useState('')

	useEffect(() => {
		localStorage.clear()
	}, [])

	const validationSchema = Yup.object().shape({
		email: Yup.string().email().required('This field is required'),
		password: Yup.string().min(8, 'Password must be at least 8 characters').required('This field is required')
	})

	const {handleSubmit, setValue, getValues, formState, trigger, register} = useForm<any>({
		resolver: yupResolver(validationSchema)
	})
	const {errors} = formState

	async function onChange({name, value}: {name: string; value: string}) {
		if (name === 'email') {
			setValue(name as never, value?.toLowerCase() as never)
			await trigger(name as never)
		} else {
			setValue(name as never, value as never)
			await trigger(name as never)
		}
	}

	//submit signup
	const onSubmit = (data: any) => {
		setIsLoading(true)
		setDataForSignIn(data)
		api.post(`/auth/login`, {...data, role: 'customer'})
			.then((res) => {
				if (res?.data?.status) {
					setIsLoading(false)
					if (!res?.data?.data?.isBlock) {
						localStorage.setItem('authorization', res?.data?.data?.accessToken)
						localStorage.setItem('userStatus', res?.data?.data?.user_login)
						_dispatch(loginUserDetailsAction(res?.data?.data?.user))
						if (!formModal) {
							_navigate('/')
						} else {
							onCloseModal()
						}
					} else {
						toast.error(res?.data?.messsage)
					}
				} else {
					toast.error(res?.data?.messsage)
					setPhone(res?.data?.data?.phone)
					setShowOtpInput(true)
					setIsLoading(false)
				}
			})
			.catch((e) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setIsLoading(false)
				}
			})
	}

	const addPhoneNumber = async () => {
		setIsLoading(true)
		await api
			.put(`/customers/update_phone/${userId}`, {
				phone: phone
			})
			.then(async (response) => {
				setIsLoading(false)
				setShowOtpInput(true)
			})
			.catch(function (error) {
				setIsLoading(false)
				if (error?.response) {
					toast.error(error?.response?.data?.message)
				}
			})
	}

	return (
		<>
			<MainWrapper fluid formModal={formModal}>
				{!showOtpInput ? (
					<Row justifyContent="center">
						<Col xl={formModal ? 12 : 4} lg={formModal ? 12 : 4} md={formModal ? 12 : 6} sm={formModal ? 12 : 10} xs={formModal ? 12 : 10}>
							{!showPhoneInput ? (
								<>
									<Spacer height={formModal ? 0 : 2} />
									<Flexed justify="center">
										<Heading level={2} isCentered isDarkTheme={_isDarkTheme}>
											Sign In
										</Heading>

										<Spacer height={2} />

										<LogInGoogle setIsLoading={setIsLoading} setPhone={setPhone} setShowOtpInput={setShowOtpInput} setUserId={setUserId} setShowPhoneInput={setShowPhoneInput} />

										<Spacer height={1} />

										<LogInFaceBook setIsLoading={setIsLoading} setPhone={setPhone} setShowOtpInput={setShowOtpInput} setUserId={setUserId} setShowPhoneInput={setShowPhoneInput} />

										<Border isDarkTheme={_isDarkTheme}>
											<CustomText fontSize="1" lineHeight={2} isDarkTheme={_isDarkTheme}>
												Or Login With
											</CustomText>
										</Border>

										<Spacer height={1.5} />
									</Flexed>
									<form onSubmit={handleSubmit(onSubmit)}>
										<Flexed justify="center">
											<Column lg={12}>
												<InputFieldAuth label="Email" type="email" placeholder="Enter" name="email" register={register} required error={errors.email as any} />
											</Column>
											<Spacer height={1} />

											<Column lg={12}>
												<InputFieldAuth label="Password" type="password" placeholder="Password" name="password" register={register} required error={errors.password as any} />
											</Column>
											<Spacer height={1} />

											<Column lg={12}>
												<Flexed direction="row" justify="space-between" align="center">
													<Text
														textTransform="capitalize"
														type="normal"
														lineHeight={2}
														isDarkTheme={_isDarkTheme}
														pointer
														onClick={() => {
															_navigate('/forgot-password')
														}}>
														Forgot password?
													</Text>
												</Flexed>
											</Column>
											<Spacer height={1.5} />

											<Column lg={12}>
												<Button label="Sign In" width="100%" type="submit" />

												<Spacer height={1} />

												<Flexed direction="row" justify="center" flexWrap="wrap" gap={0.5} align="center">
													<Text textTransform="capitalize" type="normal" isDarkTheme={_isDarkTheme}>
														If you don’t have account?
													</Text>
													<Heading
														level={5}
														isDarkTheme={_isDarkTheme}
														pointer
														onClick={() => {
															_navigate('/customer-signUp')
														}}>
														Sign Up
													</Heading>
												</Flexed>
											</Column>
										</Flexed>
									</form>
									<Spacer height={2} />
								</>
							) : (
								<>
									<Spacer height={formModal ? 0 : 2} />
									<Flexed justify="center">
										<Heading level={2} isCentered isDarkTheme={_isDarkTheme}>
											Add Your Phone No
										</Heading>
										<Spacer height={2} />
									</Flexed>
									<InputPhoneNo
										error={phoneError}
										label="Phone"
										required
										value={phone}
										handleChange={(e: any) => {
											setPhoneError('')
											if (e?.value == '' && !isLoading) {
												setPhoneError('phone number is required')
											}
											setPhone(e?.value?.trim())
										}}
									/>
									<Spacer height={1.5} />
									<Button
										disabled={phone?.length < 10}
										label="Continue"
										width="100%"
										ifClicked={() => {
											if (phone != '') {
												addPhoneNumber()
											} else {
												setPhoneError('phone number is required')
											}
										}}
									/>
								</>
							)}
						</Col>
					</Row>
				) : (
					<CustomerOTPVerification socialVerification={showPhoneInput} phone={phone} dataForSignIn={dataForSignIn} setIsLoading={setIsLoading} />
				)}
			</MainWrapper>
			{isLoading && (
				<Loading position="absolute" left={0}>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</>
	)
}

const MainWrapper = styled(Container)<any>`
	min-height: ${({formModal}) => (formModal ? '' : 'calc(100vh - 70px)')};
`

const Column = styled(Col)`
	padding: 0rem;
`
export default LogIn
