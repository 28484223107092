/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Settings Component
 *
 * The Settings component allows users to configure privacy settings,
 * change their password, and manage notification preferences.
 *
 * @component
 */

import React, {useState, useEffect} from 'react'
import {Spacer, Flexed, Text, Loading, Divider} from '../styled/shared'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {palette} from '../styled/colors'
import styled from 'styled-components'
import InputField from '../components/common/InputField'
import Button from '../components/common/Button'
import {useSelector, useDispatch} from 'react-redux'
import {api} from '../components/api/callAxios'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import {loginUserDetailsAction} from '../actions/authActions'
import {Dot, Switch} from './posts/CreatePostForm'
import {media} from 'styled-bootstrap-grid'

const Settings = () => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	let _dispatch = useDispatch()
	const _navigate = useNavigate()
	const [userId, setUserId] = useState('')
	const [currentPassword, setCurrentPassword] = useState('')
	const [newPassword, setNewPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [showPhoneNoInAds, setShowPhoneNoInAds] = useState(true)
	const [specialOffers, setSpecialOffers] = useState(true)
	const [recommendations, setRecommendations] = useState(true)
	const [chatSafetyTips, setChatSafetyTips] = useState(true)
	const [activeTab, setActiveTab] = useState('privacy')
	const [isCurrentPassword, setIsCurrentPassword] = useState(false)

	const [isLoading, setIsLoading] = useState(false)
	const [newPasswordError, setNewPasswordError] = useState('')
	const [confirmPasswordError, setConfirmPasswordError] = useState('')

	useEffect(() => {
		if (_loginUserDetails?.id) {
			getUserDetails()
		}
	}, [_loginUserDetails])

	const getUserDetails = () => {
		setIsLoading(true)
		api.get(`/customers/${_loginUserDetails?.id}?page=${1}&pageSize=${15}`)
			.then((res) => {
				if (res?.data) {
					setUserId(res?.data?.customer?.customer?.id)
					setSpecialOffers(res?.data?.customer?.customer?.notifications?.specialOffers)
					setRecommendations(res?.data?.customer?.customer?.notifications?.recommendations)
					setChatSafetyTips(res?.data?.customer?.customer?.notifications?.chatSafetyTips)
					setShowPhoneNoInAds(res?.data?.customer?.customer?.showNoInAds)
				}
				setIsLoading(false)
			})
			.catch((e) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setIsLoading(false)
				}
			})
	}

	const upDatePassword = () => {
		if (formValidation()) {
			setIsLoading(true)
			api.put(`/customers/update_password/${userId}`, {
				password: newPassword
			})
				.then((res) => {
					if (res?.data) {
						setIsLoading(false)
						toast.success('Password updated successfully')
						setNewPassword('')
						setCurrentPassword('')
						setConfirmPassword('')
						setIsCurrentPassword(false)
					}
				})
				.catch((e) => {
					if (e?.response) {
						toast.error(e?.response?.data?.message)
						setIsLoading(false)
					}
				})
		}
	}

	const formValidation = () => {
		let isValid = true
		if (newPassword === '') {
			setNewPasswordError('Password must be at least 8 characters.')
			isValid = false
		}
		if (confirmPassword === '') {
			setConfirmPasswordError('Password must match')
			isValid = false
		}
		if (newPassword?.trim().length < 7) {
			setNewPasswordError('Password must be at least 8 characters.')
			isValid = false
		}
		if (newPassword != confirmPassword) {
			setConfirmPasswordError('Password must match')
			isValid = false
		} else {
			setConfirmPasswordError('')
		}

		return isValid
	}

	const displayNumberInAds = (isShowPhoneNoInAds: any) => {
		setIsLoading(true)
		api.put(`/customers/show_number/${userId}`, {
			showNoInAds: isShowPhoneNoInAds
		})
			.then((res) => {
				if (res?.data?.status) {
					setShowPhoneNoInAds(res?.data?.data?.showNoInAds)
					_dispatch(loginUserDetailsAction(res?.data?.data))
				}
				setIsLoading(false)
			})
			.catch((e) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setIsLoading(false)
				}
			})
	}

	const notificationsSettings = (specialOffers: any, recommendations: any, chatSafetyTips: any) => {
		setIsLoading(true)
		api.put(`/customers/update_notification/${userId}`, {
			notifications: {
				specialOffers: specialOffers,
				recommendations: recommendations,
				chatSafetyTips: chatSafetyTips
			}
		})
			.then((res) => {
				if (res?.data?.status) {
					setSpecialOffers(res?.data?.data?.notifications?.specialOffers)
					setRecommendations(res?.data?.data?.notifications?.recommendations)
					setChatSafetyTips(res?.data?.data?.notifications?.chatSafetyTips)
					toast.success(res?.data?.message)
				}
				setIsLoading(false)
			})
			.catch((e) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setIsLoading(false)
				}
			})
	}

	const matchCurrentPassword = () => {
		if (currentPassword.trim()?.length > 0) {
			setIsLoading(true)
			api.put(`/customers/match/password/${userId}`, {
				password: currentPassword
			})
				.then((res) => {
					if (res?.data) {
						setIsCurrentPassword(true)
						toast.success('Your current password has been matched successfully.')
					} else {
						setIsCurrentPassword(false)
						toast.error('Your current password does not match.')
					}
					setIsLoading(false)
				})
				.catch((e) => {
					if (e?.response) {
						toast.error(e?.response?.data?.message)
						setIsLoading(false)
					}
				})
		}
	}

	return (
		<>
			<Main>
				<Spacer height={1} />
				<Row justifyContent="center">
					<Col lg={7} md={10} sm={12}>
						<Row>
							<Col>
								<Flexed direction="row" justify="center" gap={1}>
									<Toggle
										isDarkTheme={_isDarkTheme}
										active={activeTab === 'privacy'}
										onClick={() => {
											setActiveTab('privacy')
										}}>
										Privacy
									</Toggle>
									<Toggle
										isDarkTheme={_isDarkTheme}
										active={activeTab === 'notifications'}
										onClick={() => {
											setActiveTab('notifications')
										}}>
										Notifications
									</Toggle>
								</Flexed>
								<Spacer height={1} />
							</Col>
						</Row>
						{activeTab === 'privacy' ? (
							<>
								<AccountContainer>
									<Inner>
										<Text type="medium" margin="0rem 0rem 1rem 0rem" fontWeight={700} isDarkTheme={_isDarkTheme}>
											My ads settings
										</Text>
									</Inner>
									<Flexed direction="row" align="center" justify="space-between" gap={1}>
										<Text type="normal" fontWeight={500} isDarkTheme={_isDarkTheme}>
											Show my phone number in ads
										</Text>
										<div>
											<Switch
												onClick={() => {
													displayNumberInAds(!showPhoneNoInAds)
												}}
												toggle={showPhoneNoInAds}
												disabled={false}>
												<Dot toggle={showPhoneNoInAds} />
											</Switch>
										</div>
									</Flexed>
								</AccountContainer>
								<Spacer height={1} />
								<AccountContainer>
									<Wrapper>
										<Row>
											<Col>
												<Inner>
													<Text type="medium" margin="0rem 0rem 1rem 0rem" fontWeight={700} isDarkTheme={_isDarkTheme}>
														Change password
													</Text>
												</Inner>
											</Col>
											<Col>
												<InputField
													type="password"
													required
													label="Current Password"
													placeholder="Current Password"
													value={currentPassword}
													handleChange={(e: any) => {
														setCurrentPassword(e)
													}}
													onBlur={() => matchCurrentPassword()}
												/>
												<Spacer height={1} />
											</Col>
											<Col>
												<InputField
													disabled={!isCurrentPassword}
													type="password"
													required
													label="New Password"
													placeholder="New Password"
													error={newPasswordError}
													value={newPassword}
													handleChange={(e: any) => {
														setNewPasswordError('')
														if (newPassword == '') {
															setNewPasswordError('Password must be at least 8 characters.')
														}
														if (newPassword?.trim().length < 7) {
															setNewPasswordError('Password must be at least 8 characters.')
														}
														setNewPassword(e)
													}}
												/>
												<Spacer height={1} />
											</Col>
											<Col>
												<InputField
													disabled={!isCurrentPassword}
													type="password"
													required
													error={confirmPasswordError}
													label="Confirm Password"
													placeholder="Confirm Password"
													value={confirmPassword}
													handleChange={(e: any) => {
														if (e?.length < 7) {
															setConfirmPasswordError('password must be at least 8 characters.')
														} else if (e !== newPassword) {
															setConfirmPasswordError('password must match')
														} else {
															setConfirmPasswordError('')
														}
														setConfirmPassword(e)
													}}
												/>
												<Spacer height={1} />
											</Col>
										</Row>
										<Row>
											<Col>
												<Spacer height={1} />
												<Button medium disabled={!isCurrentPassword} type="red" label="Change Password" ifClicked={upDatePassword} />
											</Col>
										</Row>
									</Wrapper>
								</AccountContainer>
							</>
						) : (
							<AccountContainer>
								<Inner>
									<Text type="medium" margin="0rem 0rem 1rem 0rem" fontWeight={700} isDarkTheme={_isDarkTheme}>
										Notifications
									</Text>
								</Inner>
								<Flexed direction="row" align="center" justify="space-between" gap={1}>
									<div>
										<Text type="normal" margin="0rem 0rem 0.5rem 0rem" fontWeight={500} isDarkTheme={_isDarkTheme}>
											Special communications & offers
										</Text>
										<Text type="xsmall" isDarkTheme={_isDarkTheme}>
											Receive updates, offers and more
										</Text>
									</div>
									<div>
										<Switch
											onClick={() => {
												notificationsSettings(!specialOffers, recommendations, chatSafetyTips)
											}}
											toggle={specialOffers}
											disabled={false}>
											<Dot toggle={specialOffers} />
										</Switch>
									</div>
								</Flexed>
								<Divider isDarkTheme={_isDarkTheme} margin="1rem 0rem" />
								<Flexed direction="row" align="center" justify="space-between" gap={1}>
									<div>
										<Text type="normal" margin="0rem 0rem 0.5rem 0rem" fontWeight={500} isDarkTheme={_isDarkTheme}>
											Recommendations
										</Text>
										<Text type="xsmall" isDarkTheme={_isDarkTheme}>
											Receive recommendations based on your activity
										</Text>
									</div>
									<div>
										<Switch
											onClick={() => {
												notificationsSettings(specialOffers, !recommendations, chatSafetyTips)
											}}
											toggle={recommendations}
											disabled={false}>
											<Dot toggle={recommendations} />
										</Switch>
									</div>
								</Flexed>
								<Divider isDarkTheme={_isDarkTheme} margin="1rem 0rem" />
								<Flexed direction="row" align="center" justify="space-between" gap={1}>
									<div>
										<Text type="normal" margin="0rem 0rem 0.5rem 0rem" fontWeight={500} isDarkTheme={_isDarkTheme}>
											Chat Safety Tips
										</Text>
										<Text type="xsmall" isDarkTheme={_isDarkTheme}>
											Receive safety tips based on your chat activity
										</Text>
									</div>
									<div>
										<Switch
											onClick={() => {
												notificationsSettings(specialOffers, recommendations, !chatSafetyTips)
											}}
											toggle={chatSafetyTips}
											disabled={false}>
											<Dot toggle={chatSafetyTips} />
										</Switch>
									</div>
								</Flexed>
							</AccountContainer>
						)}
						<Spacer height={1} />
					</Col>
				</Row>
			</Main>
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</>
	)
}

const Main = styled(Container)`
	min-height: calc(100vh - 70px);
	${media.md`min-height: calc(100vh - 136px);`}
`

const AccountContainer = styled.div`
	padding: 20px;
	border: 1px solid #ccc;
	border-radius: 8px;
	margin: 0px;
`

const Inner = styled.div`
	margin-bottom: 15px;
	border-bottom: 1px solid #ccc;
`

const Wrapper = styled(Container)`
	padding: 0;
`

const Toggle = styled.div<any>`
	text-transform: uppercase;
	display: inline-block;
	border: none;
	border-radius: 0.25rem;
	padding: 0.65rem 1rem;
	font-size: 0.875rem;
	height: 2.5rem;
	color: ${({active, isDarkTheme}) => (active ? palette.white : isDarkTheme ? palette.white : palette.black)};
	background: ${({active}) => (active ? 'linear-gradient(180deg,#E00000 0%,#E00000 100%)' : '')};
	margin: undefined;
	font-weight: 600;
	cursor: pointer;
	min-width: 6rem;
	width: 100%;
	line-height: 32px;
	white-space: nowrap;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
	transition: all ease 0.25s;
	font-family: 'Montserrat', sans-serif;
`

export default Settings
