/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Redux Reducer for Authentication State
 * @module authReducer
 *
 * @description
 * This reducer manages the authentication-related state, including the dark theme preference, user details, business details, checkout details, featured ad information, search parameters, image search status, and saved search image.
 *
 * @param {Object} state - The current state of the authentication.
 * @param {Object} action - The action dispatched to update the state.
 * @returns {Object} - The new state after applying the action.
 *
 */

import * as types from '../actions/types'

const initialState = {
	isDarkTheme: false,
	loginUserDetails: {},
	loginBusinessDetails: {},
	checkOutDetail: null,
	selectedFeaturedAdPackage: null,
	adIdForFeaturedAd: null,
	search: null,
	isImageSearch: false,
	saveSearchImage: ''
}

export default function authReducer(state = initialState, action: any) {
	switch (action.type) {
		case types.SWITCH_THEME: {
			return {
				...state,
				isDarkTheme: action.value
			}
		}
		case types.LOGIN_USER_DETAILS:
			return {
				...state,
				loginUserDetails: action.value
			}
		case types.LOGIN_BUSINESS_DETAILS:
			return {
				...state,
				loginBusinessDetails: action.value
			}
		case types.CHECK_OUT_DETAIL:
			return {
				...state,
				checkOutDetail: action.value
			}
		case types.AD_ID_FOR_FERTURED_AD:
			return {
				...state,
				adIdForFeaturedAd: action.value
			}
		case types.FERTURED_AD_ACTION:
			return {
				...state,
				selectedFeaturedAdPackage: action.value
			}
		case types.SEARCH:
			return {
				...state,
				search: action.value
			}
		case types.IMAGE_SEARCH:
			return {
				...state,
				isImageSearch: action.value
			}
		case types.SAVE_SEARCH_IMAGE:
			return {
				...state,
				saveSearchImage: action.value
			}
		default:
			return state
	}
}
