/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Common Constants for Classifieds Categories
 *
 * These constants include arrays of predefined values for various attributes related to different categories
 * such as land and plots, houses for sale/rent, apartments and flats, shops/offices/commercial space, etc.
 *
 * @module CommonConstants
 */

import moment from 'moment'

/**
 * Array of weekdays
 * @type {string[]}
 */
export const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

/**
 * Get the textual representation of a rating
 *
 * @param {number} rating - The rating value
 * @returns {string} - The textual representation of the rating
 */
export function getRatingText(rating: number) {
	return rating > 4.5 ? 'Excellent' : rating > 4.5 ? 'Great' : rating > 4 ? 'Average' : rating > 3.5 ? 'Adjustable' : rating > 3 ? 'Bad' : 'Very Bad'
}

/**
 * Calculate the time after a certain number of minutes
 *
 * @param {number} m - Number of minutes
 * @param {boolean} [format=false] - Whether to format the result
 * @returns {string|Date} - Formatted date or Date object
 */
export const timeAfterMinutes = (m: any, format = false) => {
	return moment(new Date(new Date().getTime() + m * 60000)).format('DD/MM/YYYY HH:mm')
}

const R = 6371 // Radius of the earth in miles
/**
 * Distance calculation based on latitude and longitude
 *
 * @async
 * @param {number} lat1 - Latitude of the first location
 * @param {number} lon1 - Longitude of the first location
 * @param {number} lat2 - Latitude of the second location
 * @param {number} lon2 - Longitude of the second location
 * @returns {Promise<number>} - Distance in miles
 */
export const getDistanceFromLatLonInMiles = async (lat1: any, lon1: any, lat2: any, lon2: any) => {
	let dLat = deg2rad(lat1 - lat2)
	let dLon = deg2rad(lon1 - lon2)
	let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
	let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
	let d = R * c //km
	let m = d * 0.621371 //miles
	return m
}

function deg2rad(deg: any) {
	return deg * (Math.PI / 180)
}

/**
 * Verify if a string is a valid JSON
 *
 * @param {string} data - The input data
 * @returns {boolean} - True if valid JSON, false otherwise
 */
export const verifyJSON = (data: any) => {
	try {
		JSON.parse(data)
		return true
	} catch (error) {
		return false
	}
}

/**
 * Format a number as currency with appropriate units
 *
 * @param {number} number - The input number
 * @returns {string} - Formatted currency string
 */
export const currencyFormate = (number: any) => {
	if (number >= 1000000) {
		const crores = (number / 1000000).toFixed(3)
		return crores + ' Million'
	} else if (number >= 100000 && number <= 999999) {
		const lacs = (number / 100000).toFixed(3)
		return lacs + ' Lacs'
	} else if (number >= 1000) {
		const thousands = (number / 1000).toFixed(3)
		return thousands + ' Thousand'
	} else {
		return number.toString()
	}
}

/**
 * Calculate the price range from an array of prices
 *
 * @param {Array<{ price: number }>} arr - Array of price objects
 * @returns {{ lowestPrice: number, highestPrice: number }} - Object containing the lowest and highest prices
 */
export const calculatePriceRange = (arr: any) => {
	if (arr.length === 0) {
		return {highestPrice: 0, lowestPrice: 0} // Return 0 for both highest and lowest prices if the array is empty
	}
	const prices = arr.map((item: any) => item.price)
	const highestPrice = Math.max(...prices)
	const lowestPrice = Math.min(...prices)

	return {lowestPrice, highestPrice}
}

/**
 * Calculate the down payment from an array of payment objects
 *
 * @param {Array<{ title: number }>} arr - Array of payment objects
 * @returns {{ lowestPayment: number, highestPayment: number }} - Object containing the lowest and highest payments
 */
export const calculateDownPayment = (arr: any) => {
	if (arr?.length === 0) {
		return {highestPrice: 0, lowestPrice: 0} // Return 0 for both highest and lowest prices if the array is empty
	}

	// Use Array.reduce to find the highest and lowest prices
	const payment = arr.map((item: any) => item.title)
	const highestPayment = Math.max(...payment)
	const lowestPayment = Math.min(...payment)

	return {lowestPayment, highestPayment}
}
