/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Footer Component
 * Description: This component represents the footer of the application, displaying the company logo, social media links, and additional navigation links.
 *
 * @component Footer
 *
 * @param {Function} setBusniessType - A function to set the business type.
 * @param {Function} setTypeCategory - A function to set the type category.
 * @param {Function} setSubCategory - A function to set the sub-category.
 *
 */

import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {Flexed, Spacer, Text} from '../../styled/shared'
import {FaFacebookF, FaLinkedinIn} from 'react-icons/fa'
import {palette} from '../../styled/colors'
import {useSelector} from 'react-redux'
import {api} from '../api/callAxios'
import {CommonEnum} from '../../utils'
import useRouter from './UserRouterHook'
import moment from 'moment'
import {TbWorld} from 'react-icons/tb'

interface IProps {
	isDarkTheme: boolean
}

const Footer = ({setBusniessType, setTypeCategory, setSubCategory}: any) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const [allCategories, setAllCategories] = useState<any>([])
	const router = useRouter()

	const getPost = async () => {
		await api
			.get(`/business-type`)
			.then(async (response: any) => {
				setAllCategories(response?.data)
			})
			.catch(function (error: any) {
				console.log(error)
			})
	}

	useEffect(() => {
		getPost()
	}, [])
	return (
		<Section isDarkTheme={_isDarkTheme}>
			<Wrapper>
				<Row justifyContent="center">
					<CustomColMain lg={2.4} xs={12} sm={12} md={12}>
						<SubSection>
							<Content>
								<Image src={CommonEnum.logo} />
							</Content>
							<Spacer height={1} />
							<Icon>
								<ArrowAvatar>
									<a href={CommonEnum.linkedinUrl} target="_black">
										<FaLinkedinIn color={`${_isDarkTheme ? palette.silver : palette.gray}`} />
									</a>
								</ArrowAvatar>

								<ArrowAvatar>
									<a href={CommonEnum.fbUrl} target="_black">
										<FaFacebookF color={`${_isDarkTheme ? palette.silver : palette.gray}`} />
									</a>
								</ArrowAvatar>

								<ArrowAvatar>
									<a href={CommonEnum.websiteUrl} target="_black">
										<TbWorld color={`${_isDarkTheme ? palette.silver : palette.gray}`} />
									</a>
								</ArrowAvatar>
							</Icon>

							<Flexed direction="row" gap="1" flexWrap="wrap">
								<FooterList>
									<NewHeading type="small" href="/contact-us" onClick={() => router.navigate('/contact-us')} isDarkTheme={_isDarkTheme}>
										Contact us
									</NewHeading>
								</FooterList>
								<FooterList>
									<NewHeading type="small" href="/demo" onClick={() => router.navigate('/demo')} isDarkTheme={_isDarkTheme}>
										Demo
									</NewHeading>
								</FooterList>
							</Flexed>
						</SubSection>
					</CustomColMain>
				</Row>
			</Wrapper>
			<Wrapper>
				<Row>
					<Col>
						<Text type="small" isCentered isDarkTheme={_isDarkTheme}>
							© {moment().format('YYYY')} {CommonEnum.AppName}. All rights reserved.
						</Text>
					</Col>
				</Row>
			</Wrapper>
		</Section>
	)
}

const Section = styled.div<IProps>`
	background: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	border-top: 0.063rem solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.silver)};
	padding: 1.5rem 0 1.5rem 0;
`

const Wrapper = styled(Container)`
	max-width: 1500px;
`

const NewHeading = styled(Text)`
	text-transform: capitalize;
	cursor: pointer;
	&:hover {
		color: ${palette.red};
	}
`

const ArrowAvatar = styled.div`
	vertical-align: middle;
	width: 2.188rem;
	height: 2.188rem;
	border-radius: 50%;
	border: 0.063rem solid ${palette.silver};
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 0.5rem;
	cursor: pointer;
	&:not(:last-child) {
		margin-right: 0.625rem;
	}
	& a {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&:hover {
		border: 0.063rem solid ${palette.red};
		background: ${palette.red};
	}
	&:hover a > svg {
		color: white !important;
	}
`
const SubSection = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
`

const CustomColMain = styled(Col)`
	margin-bottom: 1rem;
`

const Image = styled.img`
	height: 6rem;
`
const Content = styled.div``

const Icon = styled.div`
	display: flex;
	flex-wrap: wrap;
`

const FooterList = styled.li`
	list-style: none;
	margin-top: 0.5rem;
`

export default Footer
