/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * A custom React hook that tracks the window size.
 * @returns {{ width: number | undefined, height: number | undefined }} - An object containing the current width and height of the window.
 */

import {useState, useEffect} from 'react'

function useWindowSize() {
	const [windowSize, setWindowSize] = useState<any>({
		width: undefined,
		height: undefined
	})

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight
			})
		}

		window.addEventListener('resize', handleResize)
		handleResize()
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return windowSize
}

export default useWindowSize
