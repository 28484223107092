import * as types from './types'

export const switchTheme = (payload: any) => {
	return {
		type: types.SWITCH_THEME,
		value: payload
	}
}

export const loginUserDetailsAction = (payload: any) => {
	return {
		type: types.LOGIN_USER_DETAILS,
		value: payload
	}
}

export const loginBusinessDetailsAction = (payload: any) => {
	return {
		type: types.LOGIN_BUSINESS_DETAILS,
		value: payload
	}
}

export const checkOutDetailAction = (payload: any) => {
	return {
		type: types.CHECK_OUT_DETAIL,
		value: payload
	}
}

export const adIdForFeaturedAd = (payload: any) => {
	return {
		type: types.AD_ID_FOR_FERTURED_AD,
		value: payload
	}
}

export const featuredAdAction = (payload: any) => {
	return {
		type: types.FERTURED_AD_ACTION,
		value: payload
	}
}

export const handleHomeBanerSearch = (payload: any) => {
	return {
		type: types.SEARCH,
		value: payload
	}
}

export const isImageSearch = (payload: any) => {
	return {
		type: types.IMAGE_SEARCH,
		value: payload
	}
}

export const saveSearchImage = (payload: any) => {
	return {
		type: types.SAVE_SEARCH_IMAGE,
		value: payload
	}
}
