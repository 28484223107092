/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * useRouter Hook
 *
 * @function
 * @description Custom hook for handling routing-related functionality using React Router.
 *
 * @returns {Object} - Object with routing-related properties and functions.
 * @property {Function} navigate - Function to navigate to a specified route.
 * @property {Function} replace - Function to replace the current route.
 * @property {string} pathname - Current pathname of the route.
 * @property {Object} query - Object containing parsed query parameters.
 * @property {Object} match - Object representing the match of the current route.
 * @property {Object} location - Object representing the current location.
 * @property {Function} setParams - Function to set the search parameters of the route.
 */

import {useMemo} from 'react'
import {useParams, useSearchParams, useLocation, useNavigate, useMatch} from 'react-router-dom'
import queryString from 'query-string'

const useRouter = () => {
	const [searchParam, setSearchParams] = useSearchParams()
	const params = useParams()
	const location = useLocation()
	const navigate = useNavigate()
	const match = useMatch('')

	return useMemo(() => {
		return {
			navigate: navigate,
			replace: navigate,
			pathname: location.pathname,
			query: {
				...queryString.parse(location.search),
				...params
			},
			match,
			location,
			setParams: setSearchParams
		}
	}, [params, match, location, navigate, setSearchParams])
}

export default useRouter
