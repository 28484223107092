import React from 'react'
import {useSelector} from 'react-redux'
import {Container, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from '../../styled/colors'

const FirstStep = () => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	return (
		<Container>
			<Heading1 isDarkTheme={_isDarkTheme}>Acquire the versatility of our Classifieds/Ads Management System for your business</Heading1>
			<Wraper>
				<Image src="./images/main.svg" />

				<div>
					<Card>
						<MobileImage src="./images/1.svg" />
						<Heading2 isDarkTheme={_isDarkTheme}>Streamline Your Classifieds/Ads Business Across Various Platforms</Heading2>
						<Text isDarkTheme={_isDarkTheme}>We offer a comprehensive solution for managing your classified/ads business in the form of:</Text>
						<Flex>
							<Button>Admin Panel</Button>
							<Button>Web Portal</Button>
							<Button>iOS & Android Apps</Button>
						</Flex>
						<HR></HR>
					</Card>
					<Card>
						<MobileImage src="./images/3.svg" />
						<Heading2 isDarkTheme={_isDarkTheme}>Access classifieds/Ads Business Right At Your Fingertips</Heading2>
						<Text isDarkTheme={_isDarkTheme}>From registration to ads listing, chat, sales, and purchase, we consolidate your day-to-day operations in one convenient place.</Text>
						<Flex>
							<Button>Cars Ads</Button>
							<Button>Property Ads</Button>
							<Button>Mobile Ads</Button>
							<Button>Dashboard</Button>
							<Button>Block Ads</Button>
							<Button>Block User</Button>
							<Button>Filters</Button>
							<Button>Post unlimited Ads</Button>
							<Button>Features Ads</Button>
							<Button>Favourite Ads</Button>
							<Button>Chat between Seller & Buyer</Button>
							<Button>Emails</Button>
							<Button>Voice Search</Button>
							<Button>AI based Image Search</Button>
							<Button>Search by Location or Title</Button>
							<Button>Online Payments</Button>
							<Button>Light/Dark Mode</Button>
							<Button>Fully Responsive</Button>
							<Button>Multiple Sub Categories against each Category</Button>
						</Flex>

						<HR></HR>
					</Card>
					<Card>
						<MobileImage src="./images/2.svg" />
						<Heading2 isDarkTheme={_isDarkTheme}>Purchase it now and start leveraging its capabilities immediately!</Heading2>
						<Text isDarkTheme={_isDarkTheme}>
							Acquire this Classifieds/Ads Management System to save time and money while benefiting from its well-written, commented, and thoroughly tested code.
							<br />
							Customize it with your desired feature set effortlessly using this reliable code base.
						</Text>

						<Flex>
							<Button>The backend has been developed using Nest.Js, with integration into a MySQL database</Button>
							<Button>The admin panel and web portal have been developed using React.Js</Button>
							<Button>The mobile apps (iOS & Android) have been developed using ReactNative</Button>
						</Flex>

						<HR></HR>
					</Card>
				</div>
			</Wraper>
		</Container>
	)
}

const Wraper = styled.div`
	display: grid;
	gap: 1.4rem;
	grid-template-columns: 1fr;
	margin-top: 2rem;
	${media.lg` grid-template-columns: 1.5fr 2fr;`}
`

const Card = styled.div`
	padding: 1rem;
`

const Image = styled.img`
	width: 100%;
	display: none;
	${media.lg`display:block; `}
`

const MobileImage = styled.img`
	width: 100%;
	${media.lg` display:none`}
`

const Heading1 = styled.h1<any>`
	font-size: 1.5rem;
	font-weight: bold;
	text-align: center;
	padding-top: 2rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.white : '')};
	${media.md`font-size: 2.9rem;`}
`
const Heading2 = styled.h2<any>`
	font-size: 2.3rem;
	margin-top: 2rem;
	font-weight: 500;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.white : '')};
`
const Text = styled.p<any>`
	font-size: 18px;
	margin-top: 0.8rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.white : '')};
`
const Flex = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
`

const Button = styled.button`
	background: ${palette.red};
	color: ${palette.white};
	padding: 8px 16px 5px;
	border: none;
	font-size: 19px;
	border-radius: 3px;
	font-weight: 500;
	margin-top: 1rem;
	width: 100%;
	${media.md` width: fit-content;`}
`

const HR = styled.hr`
	width: 586px;
	margin-top: 4rem;
	display: none;

	${'' /* ${media.md` display:none`} */}
`

export default FirstStep
