/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * InputSelect Component
 * @description Component for select options valuse.
 *
 * @component
 *
 * @param {any} defalutValue - The default value for the Select component.
 * @param {any} value - The current value of the Select component.
 * @param {boolean} isMulti - Boolean indicating whether multi-select is enabled.
 * @param {any} options - The available options for the Select component.
 * @param {Function} onChange - Callback function triggered on value change.
 * @param {string} name - The name attribute for the Select component.
 * @param {boolean} disabled - Boolean indicating whether the Select component is disabled.
 * @param {any} error - Error object associated with the Select component.
 * @param {string} label - The label for the Select component.
 * @param {string} subLable - The sub-label for the Select component.
 * @param {boolean} required - Boolean indicating whether the Select component is required.
 * @param {any} ref - Ref object for the Select component.
 *
 */

import {useSelector} from 'react-redux'
import Select from 'react-select'
import styled from 'styled-components'
import {palette} from '../../styled/colors'
import {Text} from '../../styled/shared'

const InputSelect = ({defalutValue, value, isMulti, options, onChange, name, disabled, error, label, subLable, required, ref}: any) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	let set = new Set()
	let values: any = []
	value &&
		isMulti &&
		value?.forEach((item: any) => {
			if (!set.has(item?.label)) {
				set.add(item?.label)
				values.push(item)
			}
		})

	const colourStyles = {
		control: (provided: any, state: any) => {
			return {
				...provided,
				borderColor: state.isFocused ? `${palette.danger} !important` : palette.silver,
				boxShadow: 'none !important',
				'&:hover': {
					borderColor: state.isFocused ? `${palette.danger} !important` : palette.silver,
					boxShadow: 'none !important'
				}
			}
		},

		option: (styles: any, {data, isDisabled, isFocused, isSelected}: any) => {
			return {
				...styles,
				cursor: isDisabled ? 'not-allowed' : 'default',
				backgroundColor: isSelected ? palette.danger : null,
				':hover': {
					backgroundColor: isDisabled ? null : _isDarkTheme ? palette.light_black : palette.silver,
					color: isDisabled ? null : palette.danger
				},
				':focus': {
					backgroundColor: isSelected ? palette.danger : null,
					color: isDisabled ? null : palette.danger
				},
				':active': {
					backgroundColor: isSelected ? palette.red : null,
					color: isDisabled ? null : palette.red
				}
			}
		}
	}

	return (
		<>
			{label && (
				<Label type="normal" margin="0rem 0rem 0.19rem 0rem" isDarkTheme={_isDarkTheme}>
					{label}
					{required ? <Mandatory> *</Mandatory> : ''}
					{subLable && <SubLable>{subLable}</SubLable>}
				</Label>
			)}
			<Select
				// menuIsOpen={true}
				styles={colourStyles}
				ref={ref}
				defaultValue={defalutValue}
				name={name}
				value={isMulti ? values : options?.filter((option: any) => option.label === (value || defalutValue) || option.value === (value || defalutValue))}
				onChange={(e: any) => onChange(e)}
				options={options}
				isMulti={isMulti}
				isDisabled={disabled}
				required={required}
			/>
			{error && !disabled && (
				<Text fontSize={0.7} type="small" color="danger">
					{error?.message}
				</Text>
			)}
		</>
	)
}

const Label = styled(Text)`
	font-weight: 500;
	text-transform: capitalize;
`
const SubLable = styled.span`
	font-size: 1rem;
	line-height: 2rem;
	color: ${palette.text_color};
	font-family: 'Roboto';
	text-transform: capitalize;
`
const Mandatory = styled.span`
	color: ${palette.danger};
`
export default InputSelect
