/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * PriceRangerFilter Component
 *
 * @component
 * @description A component for filtering prices using a range slider.
 *
 *
 * @param {Object} props - Component props.
 * @param {number} props.valueTo - Minimum value for the price range.
 * @param {function} props.setValueTo - Function to set the minimum value for the price range.
 * @param {number} props.valueFrom - Maximum value for the price range.
 * @param {function} props.setValueFrom - Function to set the maximum value for the price range.
 * @param {function} props.saveFilter - Function to save the applied filter.
 *
 */

import {useEffect, useState} from 'react'
import RangeSlider from 'react-range-slider-input'
import 'react-range-slider-input/dist/style.css'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import {currencyFormate} from '../../constants/commonFunctions'
import {palette} from '../../styled/colors'
import {Text, Heading, Spacer, Flexed} from '../../styled/shared'
import InputField from './InputField'
import {FilterInputWrapper} from './InputFilters'

const PriceRangerFilter = ({valueTo, setValueTo, valueFrom, setValueFrom, saveFilter}: any) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const [value, setValue] = useState([valueTo, valueFrom])
	const [minValFormate, setMinValFormate] = useState(0)
	const [maxValFormate, setMaxValFormate] = useState(0)
	let _min: any = 0
	let _max: any = 0

	useEffect(() => {
		setValue([valueTo, valueFrom])
	}, [valueTo, valueFrom])

	const setRangeSlider = async (e: any) => {
		setValue(e)
		// saveFilter(e)
	}

	useEffect(() => {
		_min = currencyFormate(value[0])
		setMinValFormate(_min)
		_max = currencyFormate(value[1])
		setMaxValFormate(_max)
	}, [value])

	const handleBlur = () => {
		if (parseFloat(value[0]) > parseFloat(value[1])) {
			setValue([value[0], value[0]])
			saveFilter([value[0], value[0]])
		}
	}

	return (
		<>
			<Heading fontWeight={700} isDarkTheme={_isDarkTheme}>
				Price
			</Heading>
			<Spacer height={0.5} />
			<FilterInputWrapper direction="row" gap={1} margin="0rem 0rem 0.2rem 0rem">
				<InputField
					value={value[0]}
					allowOnlyNumbersAndDecimal={true}
					handleChange={(e: any) => {
						if (e === '') {
							e = 0
						}
						if (parseFloat(e) < parseFloat(value[1])) {
							setValue([parseFloat(e), parseFloat(value[1])])
							saveFilter([parseFloat(e), parseFloat(value[1])])
						}
					}}
				/>
				<InputField
					value={value[1]}
					allowOnlyNumbersAndDecimal={true}
					onBlur={handleBlur}
					handleChange={(e: any) => {
						if (e === '') {
							e = 0
						}
						if (parseFloat(e) > parseFloat(value[0])) {
							setValue([parseFloat(value[0]), parseFloat(e)])
							saveFilter([parseFloat(value[0]), parseFloat(e)])
						}
					}}
				/>
			</FilterInputWrapper>
			<TtleDiv>
				<Text isDarkTheme={_isDarkTheme} type="xsmall">
					{' '}
					{minValFormate}
				</Text>{' '}
				<Text isDarkTheme={_isDarkTheme} type="xsmall">
					{maxValFormate}
				</Text>
			</TtleDiv>
			<Spacer height={1} />
			<WrapperRangeSlider padding={valueTo === valueFrom ? '0rem 1rem' : '0rem'}>
				<RangeSlider
					id="range-slider-yellow"
					min={valueTo}
					max={valueFrom}
					value={value}
					onInput={setRangeSlider}
					onThumbDragEnd={() => {
						saveFilter(value)
					}}
				/>
			</WrapperRangeSlider>
		</>
	)
}

const TtleDiv = styled.div`
	margin-bottom: 0.6rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
`

const WrapperRangeSlider = styled.div<any>`
	padding: ${({padding}) => padding};
	& .range-slider__thumb,
	.range-slider__range {
		background: ${palette.red};
	}
`

export default PriceRangerFilter
