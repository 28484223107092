/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * MainCover Component
 *
 * @component
 * @description Represents the main cover section of the application.
 * Simple search and Ai with voice search also there to search content related to image or voice
 */

import React, {useRef, useState} from 'react'
import styled from 'styled-components'
import {palette} from '../styled/colors'
import {useNavigate} from 'react-router-dom'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {Flexed, Loading, Text} from '../styled/shared'
import InputField from './common/InputField'
import Button from './common/Button'
import {handleHomeBanerSearch, isImageSearch, saveSearchImage} from '../actions/authActions'
import {useSelector, useDispatch} from 'react-redux'
import {adAllFields, doGenerateAiResponse} from './api/apis'
import {verifyJSON} from '../constants/commonFunctions'
import VoiceRecognition from './common/VoiceRecognition'

const MainCover = () => {
	const _navigate = useNavigate()
	let _dispatch = useDispatch()
	const [search, setSearch] = useState('')
	const [isLoading, setIsLoading] = useState(false)

	const fileInputRef: any = useRef(null)

	const handleLinkClick = () => {
		// Trigger the file input when the link is clicked
		if (fileInputRef?.current) {
			fileInputRef.current.click()
		}
	}

	const handleFileChange = async (event: any) => {
		if (event.target.files?.length) {
			let res = await fileToGenerativePart(event.target.files[0])
			console.log('res:', res)
			if (res) {
				generateImageAiResponse([res])
			}
		}
	}

	const fileToGenerativePart = (file: any) => {
		return new Promise((resolve: any, reject: any) => {
			const reader: any = new FileReader()

			reader.onloadend = () => {
				const data = reader.result.split(',')[1] // Extract base64 data
				const mimeType = file.type

				resolve({
					inlineData: {
						data,
						mimeType
					}
				})
			}

			reader.onerror = reject

			reader.readAsDataURL(file)
		})
	}

	const generateImageAiResponse = async (images?: any) => {
		try {
			_dispatch(isImageSearch(false))
			_dispatch(saveSearchImage(''))
			setIsLoading(true)
			// const _text = `Parse image and use this provided data: ${JSON.stringify(
			// 	adAllFields
			// )} and generate relevant response. Ensure title is related to businessType and typeCategory. Don't use contact information in description. Provide description with maximum of 4029 words and price value always in numbers. Verify and respond with all possible details. If fields are not relevant to business type and type category, return null or empty string. Result should be a single object without any extra text.`

			const _text = `Parse image and use this provided data: ${JSON.stringify(
				adAllFields
			)} and generate relevant response. Ensure title is related to businessType and typeCategory. Don't use contact information in description. Provide description with maximum of 4029 words and price value always in numbers. Verify and respond with all possible details. Return response in all relevant fields. If fields are not relevant to business type and type category, return null or empty string. Result should be a single object without any extra text.`
			let res: any = await doGenerateAiResponse(_text, images)
			if (res) {
				console.log('ressss', res)
				_dispatch(isImageSearch(true))
				_dispatch(saveSearchImage(images))

				let data = verifyJSON(res) ? JSON.parse(res) : {}
				if (!data?.title) {
					const stringWithEmbeddedJSON = res

					const jsonMatch = stringWithEmbeddedJSON.match(/```json([\s\S]*?)```/)

					if (jsonMatch && jsonMatch[1]) {
						const extractedJSON = jsonMatch[1].trim()

						try {
							const parsedObject = JSON.parse(extractedJSON)
							data = parsedObject
						} catch (error) {
							console.error('Error parsing JSON:', error)
						}
					} else {
						console.error('No JSON found in the string.')
					}
				}

				if (data?.title) {
					setSearch(data?.title)
					_dispatch(handleHomeBanerSearch(data?.title))
					_navigate('/post')
				}
				setIsLoading(false)
			}
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			console.log('error', error)
		}
	}

	return (
		<>
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
			<Container>
				<Row>
					<Col>
						<MainContent>
							<Text fontSize={1.4} lineHeight={2} isCentered fontWeight="bold" color="white" style={{textShadow: '0 2px 4px rgb(0 0 0 / 35%)'}}>
								The best place to buy your house, sell your mobiles and cars.
							</Text>
							<Wrapper>
								<InputWrapper gap={1} align="center">
									<InputField
										type="search"
										placeholder="Search for anything"
										value={search}
										handleChange={(e: any) => {
											setSearch(e)
										}}
									/>
									<VoiceRecognition iconsColor={true} setText={(e: any) => setSearch(e)} />
									<div>
										<Button
											label="Search"
											type="red"
											ifClicked={() => {
												_navigate('/post')
												if (search?.trim().length > 0) {
													_dispatch(handleHomeBanerSearch(search))
												}
											}}
										/>
									</div>
								</InputWrapper>
								<span style={{display: 'flex', justifyContent: 'flex-end', marginTop: '0.2rem'}}>
									<CustomLink onClick={handleLinkClick}>Image Search</CustomLink>
									<input type="file" accept=".jpg, .png, .jpeg, .webp" ref={fileInputRef} style={{display: 'none'}} onChange={handleFileChange} />
								</span>
							</Wrapper>
						</MainContent>
					</Col>
				</Row>
			</Container>
		</>
	)
}

export const CustomLink = styled.span`
	color: ${palette.white};
	font-weight: 500;
	cursor: pointer;
	font-size: 0.9rem;
	&:hover {
		color: blue;
	}
`

const MainContent = styled.div`
	position: relative;
	background-image: url('/images/hero_image_7.jpeg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	min-height: 16.063rem;
	border-radius: 0.5rem;
	overflow: hidden;
	margin: 1rem 0rem 0rem 0rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	align-items: center;
	justify-content: space-around;
	padding: 1rem 1rem;
	${media.sm` padding: 2rem 3rem 2rem 3rem`};
	${media.md` padding: 2rem 4rem 2rem 4rem`};
	${media.lg` padding: 2rem 12rem 2rem 12rem`};
`

const Wrapper = styled(Flexed)`
	width: 100%;
	background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.main_cover_overlay_dark}` : `${palette.main_cover_overlay_light}`)};
	padding: 1rem;
	border-radius: 0.5rem;
	padding-bottom: 0.5rem;
`

const InputWrapper = styled(Flexed)`
	width: 100%;
	flex-direction: column;
	${media.sm`flex-direction:row`};
	${media.md`flex-direction:row`};
	& #inputWrapper {
		width: 100%;
	}
	& button {
		width: 19% !important;
	}
`

export default MainCover
