/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * EditPost Component
 *
 * This component allows users to edit an existing advertisement. It fetches the details of the
 * specified advertisement using the provided ad ID and displays a form for editing the ad details.
 *
 * @component
 */

import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {Heading, Spacer, Flexed, Loading} from '../../styled/shared'
import {useSelector} from 'react-redux'
import {api} from '../../components/api/callAxios'
import {palette} from '../../styled/colors'
import {FaAngleRight} from 'react-icons/fa'
import CreatePostForm from './CreatePostForm'
import {useSearchParams} from 'react-router-dom'

const EditPost = () => {
	const _isDarkTheme = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	const [searchParams] = useSearchParams()
	const [isLoading, setIsLoading] = useState(false)
	const _id: any = searchParams.get('id')
	const [postFullDetails, setPostFullDetails] = useState<any>('')

	const getPostDetails = async (postId: string) => {
		setIsLoading(true)
		await api
			.post(`/ads/ad_detail`, {
				adsId: postId,
				user: _loginUserDetails
			})
			.then(async (response: any) => {
				setPostFullDetails(response?.data?.ads)
				setIsLoading(false)
			})
			.catch(function (error: any) {
				console.log(error)
				setIsLoading(false)
			})
	}

	useEffect(() => {
		getPostDetails(_id)
	}, [_id])

	return (
		<>
			<Container>
				<Row>
					<Col>
						<Spacer height={2} />
					</Col>
					<Col>
						<Heading textTransform="uppercase" level={4} isCentered fontWeight={700} isDarkTheme={_isDarkTheme} margin={'0rem 0rem 1rem 0rem'}>
							EDIT AD
						</Heading>
					</Col>
					<Col>
						{postFullDetails && (
							<CreatePostForm
								setIsLoading={setIsLoading}
								seletedMainCategory={{title: postFullDetails?.businessType}}
								seletedTypeCategory={{title: postFullDetails?.typeCategory}}
								seletedSubTypeCategory={{title: postFullDetails?.subCategory}}
								editPostDetails={postFullDetails}
							/>
						)}
					</Col>
				</Row>
			</Container>
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</>
	)
}

export default EditPost
