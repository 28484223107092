/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Button Component
 * Description: Reusable button component with various customization options like label, icon, type, size, and styling.
 *
 * @component Button
 *
 * @param {string} id - The unique identifier for the button.
 * @param {Function} ifClicked - The function to be executed when the button is clicked.
 * @param {boolean} disabled - Flag indicating whether the button is disabled.
 * @param {string} textTransformation - The text transformation style ('capitalize' or 'uppercase').
 * @param {string} label - The label or text content of the button.
 * @param {string} icon - The icon source for the button.
 * @param {string} type - The type of the button (e.g., 'clear', 'featured', 'sold').
 * @param {boolean} stretchMobile - Flag indicating whether the button should stretch on mobile devices.
 * @param {boolean} stretchWeb - Flag indicating whether the button should stretch on web devices.
 * @param {boolean} hasShadow - Flag indicating whether the button has a shadow.
 * @param {boolean} hasBorder - Flag indicating whether the button has a border.
 * @param {boolean} medium - Flag indicating medium size for the button.
 * @param {boolean} small - Flag indicating small size for the button.
 * @param {string} margin - The margin style for the button.
 * @param {string} width - The width style for the button.
 * @param {string} tooltip - The tooltip text for the button.
 * @param {string} color - The color of the button.
 * @param {string} fontWeight - The font weight of the button text.
 *
 */

import styled from 'styled-components'
import {palette} from '../../styled/colors'

const Button = ({id, ifClicked, disabled, textTransformation, label, icon, type, stretchMobile, stretchWeb, hasShadow, hasBorder, medium, small, margin, width, tooltip, color, fontWeight}: any) => {
	return (
		<Btn
			id={id}
			type={type}
			color={color}
			hasLabel={label}
			disabled={disabled}
			onClick={ifClicked}
			medium={medium}
			small={small}
			hasBorder={hasBorder}
			margin={margin}
			width={width}
			stretchMobile={stretchMobile}
			stretchWeb={stretchWeb}
			textTransformation={textTransformation}
			fontWeight={fontWeight}
			onKeyDown={(e: any) => e == 'enter' && ifClicked()}
			hasShadow={hasShadow}>
			{icon && <Icon color={color} hasLabel={label} src={`/images/icons/${icon}`} />}
			{label}
		</Btn>
	)
}

const handleColorType = (type: any) => {
	switch (type) {
		case 'clear':
			return palette.dark_gray
		case 'featured':
			return palette.black
		case 'sold':
			return palette.white
		default:
			return palette.white
	}
}

const handleBackgroundType = (type: any, index: any) => {
	switch (type) {
		case 'clear':
			return palette.white
		case 'complete':
			return palette.success
		case 'success':
			return palette.success
		case 'pending':
			return palette.golden
		case 'danger':
			return palette.danger
		case 'red':
			return palette.red
		case 'submit':
			return palette.red
		case 'cancel':
			return palette.black
		case 'sold':
			return palette.dark_gray
		case 'featured':
			return palette.yellow
		default:
			return palette.blue
	}
}

const handleBorderColor = (type: any) => {
	switch (type) {
		case 'clear':
			return palette.white
		case 'dark_grey_border':
			return palette.silver

		default:
			return palette.blue
	}
}

const handleHoverBackgroundType = (type: any) => {
	switch (type) {
		case 'clear':
			return palette.white
		case 'cancel':
			return palette.dark_gray
		case 'red':
			return palette.red_hover
		case 'submit':
			return palette.red_hover
		case 'featured':
			return palette.yellow
		default:
			return palette.blue
	}
}

const Btn = styled.button<any>`
	opacity: ${({disabled}) => (disabled ? '0.5' : '1')};
	text-transform: ${({textTransformation}) => (textTransformation === 'capitalize' ? 'capitalize' : 'uppercase')};
	display: inline-block;
	border: ${({hasBorder, type}) => (hasBorder ? `0.063rem solid ${handleBorderColor(type)}` : 'none')};
	border-radius: 0.25rem;
	padding: 0.65rem 1rem;
	font-size: ${({small}) => (small ? '0.75rem' : '0.875rem')};
	height: ${({small, medium}) => (small ? '2rem' : medium ? '2.5rem' : '3.125rem')};
	color: ${({hasBorder, type}) => (hasBorder ? palette[type] : handleColorType(type))};
	background: ${({type, hasBorder}) => (hasBorder ? `transparent` : `linear-gradient(180deg,${handleBackgroundType(type, 1)} 0%, ${handleBackgroundType(type, 2)} 100%)`)};
	margin: ${({margin}) => `${margin}`};
	font-weight: ${({fontWeight}) => (fontWeight ? fontWeight : '600')};
	opacity: ${({type}) => (type === 'disable' ? '50%' : '')};
	cursor: ${({disabled}) => (disabled ? 'no-drop' : 'pointer')};
	min-width: 6rem;
	width: ${({width}) => (width ? width : 'min-content')};
	line-height: 32px;
	white-space: nowrap;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
	transition: all ease 0.25s;
	font-family: 'Montserrat', sans-serif;
	&:hover {
		background: ${({type, disabled}) => (disabled ? '' : `linear-gradient(180deg,${handleHoverBackgroundType(type)} 40%, ${handleHoverBackgroundType(type)} 100%)`)};
	}
`

const Icon = styled.img<any>`
	margin-right: ${({hasLabel}) => (hasLabel ? ` 0.5rem` : 'none')};
`

Btn.defaultProps = {
	type: 'primary',
	ifClicked: () => null
}

export default Button
