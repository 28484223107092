/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Description: Default variables used commonly in web app like application name and logo of the application, social urls.
 * Price value format for to use million, thousend and billions.
 */

export const CommonEnum = Object.freeze({
    AppName: 'ListingApp',
    logo: '/images/Logo.png',
    fbUrl: 'https://www.facebook.com/SmartSoftStudios',
    linkedinUrl: 'https://www.linkedin.com/company/smart-soft-studios',
    websiteUrl: 'https://www.smartsoftstudios.com/',
    appStoreUrl: 'https://itunes.apple.com/app/',
    googleStoreUrl: 'https://play.google.com/store/apps/details?id={YOUR_PACKAGE_NAME}',
    contactUsServerUrl: `https://api.economicsale.finance/booking`

});


export function formatNumber(number) {
    if (number < 1000) {
        return number.toString();
    } else if (number < 1000000) {
        return (number / 1000).toFixed(1) + 'k';
    } else if (number < 1000000000) {
        return (number / 1000000).toFixed(1) + 'M';
    } else {
        return (number / 1000000000).toFixed(1) + 'B';
    }
}