/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * ForgotPassword component handles the password recovery process.
 * It includes steps for entering email, receiving an OTP, and setting a new password.
 *
 * @component
 */

import React, {useState} from 'react'
import styled from 'styled-components'
import {Flexed, Heading, Loading, Spacer, Text} from '../../styled/shared'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {useSelector} from 'react-redux'
import Button from '../../components/common/Button'
import {useNavigate} from 'react-router-dom'
import InputField from '../../components/common/InputField'
import {api} from '../../components/api/callAxios'
import {toast} from 'react-toastify'
import VerificationInput from 'react-verification-input'
import {useDispatch} from 'react-redux'
import {loginUserDetailsAction} from '../../actions/authActions'

interface IProps {
	isDarkTheme?: boolean
}

const ForgotPassword = () => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	let _navigate = useNavigate()
	const [isLoading, setIsLoading] = useState(false)
	const [step, setStep] = useState(1)
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [otpCode, setOtpCode] = useState('')

	const [emailError, setEmailError] = useState('')
	const [confirmPasswordError, setConfirmPasswordError] = useState('')
	const [otpCodeError, setOtpCodeError] = useState('')
	const _dispatch = useDispatch()

	const forgot = () => {
		if (formValidation()) {
			setIsLoading(true)
			api.post(`/auth/forgot_password`, {
				email: email,
				role: 'customer'
			})
				.then((res) => {
					setIsLoading(false)
					setStep(2)
					// toast.success('Your ad mark as sold successfully.')
				})
				.catch((e) => {
					if (e?.response) {
						toast.error(e?.response?.data?.message)
						setIsLoading(false)
					}
				})
		}
	}

	const sentOtp = () => {
		setIsLoading(true)
		api.post(`/auth/send_otp`, {
			email: email,
			role: 'customer'
		})
			.then((res) => {
				setIsLoading(false)
				setStep(3)
				// toast.success('Your ad mark as sold successfully.')
			})
			.catch((e) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setIsLoading(false)
				}
			})
	}

	const updatePassword = () => {
		if (passwordValidation()) {
			setIsLoading(true)
			api.post(`/auth/update_password`, {
				otp: parseInt(otpCode),
				email: email,
				password: password,
				role: 'customer'
			})
				.then((res) => {
					setIsLoading(false)
					if (!res?.data?.data?.isBlock) {
						localStorage.setItem('authorization', res?.data?.data?.accessToken)
						localStorage.setItem('userStatus', res?.data?.data?.user_login)
						_dispatch(loginUserDetailsAction(res?.data?.data?.user))
						// toast.success('Login successfully')
						_navigate('/')
					} else {
						toast.error(res?.data?.messsage)
					}
				})
				.catch((e) => {
					if (e?.response) {
						toast.error(e?.response?.data?.message)
						setIsLoading(false)
						setOtpCodeError(e?.response?.data?.message)
					}
				})
		}
	}

	const formValidation = () => {
		let isValid = true
		if (email === '') {
			setEmailError('email is required')
			isValid = false
		}
		return isValid
	}

	const passwordValidation = () => {
		let isValid = true
		if (password !== confirmPassword) {
			setConfirmPasswordError('password must match')
			isValid = false
		} else {
			setConfirmPasswordError('')
		}
		return isValid
	}

	return (
		<>
			<MainWrapper fluid>
				<Row justifyContent="center">
					<Col xl={4} lg={4} md={6} sm={10} xs={10}>
						<Spacer height={6} />
						<Flexed justify="center">
							<Heading level={2} isCentered isDarkTheme={_isDarkTheme}>
								Forgot Password
							</Heading>
							<Spacer height={2} />
							{step === 1 && (
								<Text type="normal" isCentered isDarkTheme={_isDarkTheme}>
									Enter your email address and we’ll send a link to change your password.
								</Text>
							)}
							{step === 2 && (
								<Text type="normal" isCentered isDarkTheme={_isDarkTheme}>
									Enter your new password.
								</Text>
							)}
							{step === 3 && (
								<Text type="normal" isCentered isDarkTheme={_isDarkTheme}>
									Please enter the validation code that was sent to your email.
								</Text>
							)}
							<Spacer height={1} />
							{step === 1 && (
								<Column lg={12}>
									<InputField
										error={emailError}
										label="Email"
										placeholder="Enter your email"
										value={email}
										required
										handleChange={(e: any) => {
											const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i
											setEmailError('')
											if (e === '') {
												setEmailError('email is required')
											} else if (regex.test(e) == false) {
												setEmailError('invalid email')
											}
											setEmail(e)
										}}
									/>
									<Spacer height={1} />
								</Column>
							)}

							{step === 2 && (
								<>
									<Column>
										<InputField
											type="password"
											required={password != ''}
											label="New Password"
											placeholder="Password"
											value={password}
											handleChange={(e: any) => {
												setPassword(e)
											}}
										/>
										<Spacer height={1} />
									</Column>
									<Column>
										<InputField
											type="password"
											required={password != ''}
											placeholder="Confirm Password"
											error={confirmPasswordError}
											label="Confirm Password"
											value={confirmPassword}
											handleChange={(e: any) => {
												if (e?.length < 8) {
													setConfirmPasswordError('password must be at least 8 characters')
												} else if (e !== password) {
													setConfirmPasswordError('password must match')
												} else {
													setConfirmPasswordError('')
												}
												setConfirmPassword(e)
											}}
										/>
										<Spacer height={1} />
									</Column>
								</>
							)}

							{step === 3 && (
								<>
									<Column>
										<Flexed direction="row" justify="center">
											<VerificationInput
												validChars="0-9"
												classNames={{
													character: 'character'
												}}
												onChange={(e: any) => {
													setOtpCodeError('')
													setOtpCode(e)
												}}
												value={otpCode}
											/>
										</Flexed>
										<div>
											<Spacer height={0.5} />
											<Text isCentered fontSize={0.7} type="small" color="danger">
												{otpCodeError}
											</Text>
											<Spacer height={1} />
										</div>
									</Column>
								</>
							)}

							<Column lg={12}>
								{step === 1 && (
									<Button
										type="red"
										label="Reset Password"
										width="100%"
										ifClicked={() => {
											forgot()
										}}
									/>
								)}
								{step === 2 && (
									<Button
										type="red"
										label="Continue"
										width="100%"
										ifClicked={() => {
											sentOtp()
										}}
									/>
								)}
								{step === 3 && (
									<Button
										type="red"
										label="Continue"
										width="100%"
										ifClicked={() => {
											updatePassword()
										}}
									/>
								)}
								<Spacer height={1} />

								<Flexed direction="row" justify="center" gap={0.5} align="center">
									<Text textTransform="capitalize" type="normal" isDarkTheme={_isDarkTheme}>
										Go back to
									</Text>
									<Heading
										level={5}
										isDarkTheme={_isDarkTheme}
										pointer
										onClick={() => {
											_navigate('/customer-login')
										}}>
										Sign In
									</Heading>
								</Flexed>
							</Column>
						</Flexed>
						<Spacer height={6} />
					</Col>
				</Row>
			</MainWrapper>
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</>
	)
}

const Column = styled(Col)`
	padding: 0rem;
`

const MainWrapper = styled(Container)<any>`
	min-height: ${({formModal}) => (formModal ? '' : 'calc(100vh - 70px)')};
`

export default ForgotPassword
