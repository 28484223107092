/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * MyPosts Component
 *
 * This component displays and manages the user's ads. Users can view, edit, delete,
 * mark as sold, and activate/deactivate their ads. It fetches the user's ads from the
 * API and provides options to filter, search, and load more ads.
 *
 * @component
 */

import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Flexed, Heading, Spacer, Text, Loading, Divider} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {useSelector} from 'react-redux'
import 'react-multi-carousel/lib/styles.css'
import {useNavigate, createSearchParams} from 'react-router-dom'
import {api, checkFeaturedPostStatus} from '../../components/api/callAxios'
import Button from '../../components/common/Button'
import {toast} from 'react-toastify'
import {RiChat1Line} from 'react-icons/ri'
import {AiOutlineEye, AiFillHeart} from 'react-icons/ai'
import {FiPhone} from 'react-icons/fi'
import {HiDotsHorizontal} from 'react-icons/hi'
import DeleteAdsModal from '../../components/modals/DeleteAdsModal'
import moment from 'moment'
import ItemSoldModal from '../../components/modals/ItemSoldModal'
import AdDeactivateModal from '../../components/modals/AdDeactivateModal'
import InputField from '../../components/common/InputField'
import {formatNumber} from '../../utils'

const MyPosts = () => {
	const [isLoading, setIsLoading] = useState<any>(true)
	let _navigate = useNavigate()
	const [myAdsList, setMyAdsList] = useState<any>([])
	const [myAdsListFilter, setMyAdsListFilter] = useState<any>([])
	const [copyMyAdsList, setCopyMyAdsList] = useState([])
	const _isDarkTheme = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	const [search, setSearch] = useState<any>('')
	const [serverDate, setServerDate] = useState<any>('')
	const [openAdsDeleteModal, setOpenAdsDeleteModal] = useState<any>(false)
	const [openAdsDeactivateModal, setOpenAdsDeactivateModal] = useState<any>(false)
	const [openAdsSoldModal, setOpenAdsSoldModal] = useState<any>(false)
	const [deleteAdsId, setDeleteAdsId] = useState<any>('')
	const [deactivateAdsId, setDeactivateAdsId] = useState<any>({status: '', id: ''})
	const [soldAdsInfo, setSoldAdsIdAdsInfo] = useState<any>('')
	const [page, setPage] = useState(1)
	const [pageSize, setPageSize] = useState(15)
	const [loadMore, setLoadMore] = useState(false)
	const [filterStatus, setFilterStatus] = useState<any>('all')

	const getMyAdsPosts = async (page: any, isCallAfterRemoveAds: any) => {
		await api
			.get(`/customers/${_loginUserDetails?.id}?page=${page}&pageSize=${pageSize}&isCallAfterRemoveAds=${isCallAfterRemoveAds}`)
			.then(async (response: any) => {
				let _myAds = response?.data?.customer?.userAds
				let _totalMyAds = response?.data?.customer?.customer?.ads?.length
				if (page > 1) {
					if (!isCallAfterRemoveAds) {
						_myAds = myAdsList.concat(_myAds)
					}
				}
				setMyAdsList(_myAds)
				setMyAdsListFilter(_myAds)
				setCopyMyAdsList(_myAds)
				let _remainingFavAdsLength = _totalMyAds - page * pageSize
				setLoadMore(_remainingFavAdsLength > 0)
				setFilterStatus('all')
				setIsLoading(false)
				setSearch('')
			})
			.catch(function (error: any) {
				console.log(error)
				setIsLoading(false)
			})
	}

	useEffect(() => {
		checkFeaturedPostStatus()
	}, [])

	useEffect(() => {
		if (_loginUserDetails?.id) {
			getServerDate()
			setIsLoading(true)
			getMyAdsPosts(1, false)
		}
	}, [_loginUserDetails?.id])

	const goToPostDtails = (postId: any) => {
		_navigate({
			pathname: '/post-details',
			search: createSearchParams({
				id: postId
			}).toString()
		})
	}

	const deletePost = (postId: any) => {
		setIsLoading(true)
		api.delete(`/ads/${postId}`)
			.then((res) => {
				toast.success('Your ad is deleted successfully.')
				setDeleteAdsId('')
				setOpenAdsDeleteModal(false)
				getMyAdsPosts(page, true)
			})
			.catch((e) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setIsLoading(false)
				}
			})
	}

	const activateAndDeactivatePost = ({status, id}: any) => {
		setIsLoading(true)
		api.post(`ads/adsStatus/${id}`, {
			activate: status
		})
			.then((res) => {
				toast.success(`Your ad ${res?.data?.activate ? 'is activated' : 'is deactivated'} successfully.`)
				setDeactivateAdsId({status: '', id: ''})
				setOpenAdsDeactivateModal(false)
				getMyAdsPosts(page, true)
			})
			.catch((e) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setIsLoading(false)
				}
			})
	}

	const calculateRemainingDays = (expiryDate: any, serverDate: any) => {
		const targetDate = moment(expiryDate, 'DD-MM-YYYY HH:mm')
		const currentDate = moment(serverDate, 'DD-MM-YYYY HH:mm')
		const diffDuration = moment.duration(targetDate.diff(currentDate))
		const days = Math.ceil(diffDuration.asDays())
		return days - 1
	}

	const AdsSearch = async (label: any) => {
		setIsLoading(true)
		await api
			.post(`/ads/myAds_search`, {
				label: label
			})
			.then(async (response) => {
				if (label.trim().length > 0) {
					setMyAdsList(response?.data?.ads)
					setMyAdsListFilter(response?.data?.ads)
					setFilterStatus('all')
				} else {
					setMyAdsList(copyMyAdsList)
					setMyAdsListFilter(copyMyAdsList)
				}
				setIsLoading(false)
			})
			.catch(function (error) {
				console.log(error)
				setIsLoading(false)
			})
	}

	const getServerDate = async () => {
		await api
			.post(`/customers/server_date`)
			.then(async (response) => {
				setServerDate(response?.data)
			})
			.catch(function (error) {
				console.log(error)
			})
	}

	const loadMoreData = () => {
		setIsLoading(true)
		setPage(page + 1)
		getMyAdsPosts(page + 1, false)
	}

	return (
		<>
			<MainWrapper>
				<Row>
					<Col>
						<Spacer height={1} />
					</Col>
					<Col>
						<SearchWrapper>
							<InputField
								type="search"
								value={search}
								placeholder="Search by Ad Title"
								handleChange={(e: any) => {
									AdsSearch(e)
									setSearch(e)
								}}
							/>
						</SearchWrapper>
						<Spacer height={2} />
						<Flexed direction="row" align="center" justify={'space-between'} flexWrap="wrap" gap={1} margin="0rem 0rem 0rem 0rem">
							<Heading textTransform="normal" level={4} fontWeight={700} isDarkTheme={_isDarkTheme} margin={'0rem 0rem 1rem 0rem'}>
								Manage and view your Ads
							</Heading>
							<Flexed direction="row" align="center" flexWrap="wrap" gap={1} margin="0rem 0rem 2rem 0rem">
								<FilterButtons
									active={filterStatus === 'all'}
									isDarkTheme={_isDarkTheme}
									onClick={() => {
										setFilterStatus('all')
										setMyAdsListFilter(myAdsList)
									}}>
									All Ads ({myAdsList?.length})
								</FilterButtons>
								<FilterButtons
									active={filterStatus === 'active'}
									isDarkTheme={_isDarkTheme}
									onClick={() => {
										setFilterStatus('active')
										setMyAdsListFilter(myAdsList.filter((ad: any) => ad.activate && !ad.sold && !ad.isBlockedAd))
									}}>
									Active Ads ({myAdsList?.filter((ad: any) => ad.activate && !ad.sold && !ad.isBlockedAd)?.length})
								</FilterButtons>
								<FilterButtons
									active={filterStatus === 'Inactive'}
									isDarkTheme={_isDarkTheme}
									onClick={() => {
										setFilterStatus('Inactive')
										setMyAdsListFilter(myAdsList.filter((ad: any) => !ad.activate))
									}}>
									Inactive Ads ({myAdsList?.filter((ad: any) => !ad.activate)?.length})
								</FilterButtons>
							</Flexed>
						</Flexed>
					</Col>
					<List>
						{myAdsListFilter?.length > 0 && (
							<>
								{myAdsListFilter?.map((val: any) => {
									return (
										<Col>
											<Wrapper isDarkTheme={_isDarkTheme}>
												<Flexed direction="row" gap={0.5} align="center">
													<Cover
														onClick={() => {
															goToPostDtails(val?.id)
														}}>
														<Image src={process.env.REACT_APP_IMAGE_URL + val?.photos[0]} />
													</Cover>

													<Title
														pointer
														gap={0.2}
														onClick={() => {
															goToPostDtails(val?.id)
														}}>
														<Heading level={6} fontWeight={700} isDarkTheme={_isDarkTheme}>
															{val?.title}
														</Heading>
													</Title>

													<Price gap={0.1}>
														<Text type="small" isDarkTheme={_isDarkTheme}>
															${val?.typeCategory != 'Cars on Installments' ? val?.price : val?.downPayment?.toLocaleString()}
														</Text>
														{val?.typeCategory === 'Cars on Installments' && (
															<Text fontWeight={500} type="xsmall" lineHeight={0.65} isDarkTheme={_isDarkTheme}>
																Down payment
															</Text>
														)}
													</Price>

													{val?.isBlockedAd ? (
														<Status direction="row" gap={1}>
															<Button label="Blocked" type="danger" small />
														</Status>
													) : val?.sold ? (
														<Status direction="row" gap={1}>
															<Button label="Sold" type="cancel" small />
														</Status>
													) : (
														<Status gap={0.2}>
															{!val?.isFeatured && val?.activate && <Button label="Active" type="active" small />}
															{!val?.activate && <Button label="Disabled" type="cancel" small />}
															{val?.isFeatured && val?.activate && <Button label="Featured" type="featured" small />}
														</Status>
													)}

													{val?.isBlockedAd ? (
														<Detail gap={0.2}>
															<Text type="small" isDarkTheme={_isDarkTheme}>
																Your ad is blocked by admin, contact admin for more info.
															</Text>
														</Detail>
													) : val?.sold ? (
														<Detail gap={0.2}>
															<Text type="small" isDarkTheme={_isDarkTheme}>
																This ad was sold.
															</Text>
														</Detail>
													) : (
														<Detail gap={0.2}>
															{!val?.activate && (
																<Text type="small" isDarkTheme={_isDarkTheme}>
																	This ad is disabled.
																</Text>
															)}
															{!val?.isFeatured && val?.activate && (
																<Text type="small" isDarkTheme={_isDarkTheme}>
																	This ad is live.
																</Text>
															)}
															{val?.isFeatured &&
																val?.activate &&
																(calculateRemainingDays(val?.featureAds[0]?.expiryDate, serverDate) > 0 ? (
																	<Text type="small" isDarkTheme={_isDarkTheme}>
																		This ad is live & featured for {val?.featureAds[0]?.validFor} days.
																		<br /> Remaining days {calculateRemainingDays(val?.featureAds[0]?.expiryDate, serverDate)}
																	</Text>
																) : calculateRemainingDays(val?.featureAds[0]?.expiryDate, serverDate) == 0 ? (
																	<Text type="small" isDarkTheme={_isDarkTheme}>
																		Featured ad will expire today.
																	</Text>
																) : (
																	calculateRemainingDays(val?.featureAds[0]?.expiryDate, serverDate) < 0 && (
																		<Text type="small" isDarkTheme={_isDarkTheme}>
																			Featured ad is expired.
																		</Text>
																	)
																))}
														</Detail>
													)}
													<Action direction="row" gap={0.5} align="center" justify="flex-end">
														<HiDotsHorizontal fontSize="2rem" color={_isDarkTheme ? palette.light_gray : palette.dark_gray} />
														<DropContent isDarkTheme={_isDarkTheme}>
															{val?.activate && !val?.sold && !val?.isBlockedAd && (
																<DropMenu
																	isDarkTheme={_isDarkTheme}
																	onClick={() => {
																		_navigate({
																			pathname: '/edit-post',
																			search: createSearchParams({
																				id: val?.id
																			}).toString()
																		})
																	}}>
																	Edit now
																</DropMenu>
															)}
															<DropMenu
																isDarkTheme={_isDarkTheme}
																onClick={() => {
																	setDeleteAdsId(val?.id)
																	setOpenAdsDeleteModal(true)
																}}>
																Remove
															</DropMenu>
															{!val?.sold && !val?.isBlockedAd && (
																<DropMenu
																	isDarkTheme={_isDarkTheme}
																	onClick={() => {
																		setDeactivateAdsId({status: !val?.activate, id: val?.id})
																		val?.activate ? setOpenAdsDeactivateModal(true) : val?.activate ? setOpenAdsDeactivateModal(true) : activateAndDeactivatePost({status: !val?.activate, id: val?.id})
																	}}>
																	{!val?.activate ? 'Republish Ad' : 'Deactivate'}
																</DropMenu>
															)}
															{val?.activate && !val?.sold && !val?.isBlockedAd && (
																<DropMenu
																	isDarkTheme={_isDarkTheme}
																	onClick={() => {
																		setSoldAdsIdAdsInfo(val)
																		setOpenAdsSoldModal(true)
																	}}>
																	Mark as sold
																</DropMenu>
															)}
														</DropContent>
													</Action>
												</Flexed>

												<Divider margin="1rem 0rem" />

												<Flexed direction="row" gap={0.7} align="center">
													<Flexed direction="row" gap={0.3} align="center">
														<AiOutlineEye color={_isDarkTheme ? palette.light_gray : palette.dark_gray} />
														<Text type="xsmall" fontWeight={700} isDarkTheme={_isDarkTheme}>
															Views: {val?.views}
														</Text>
													</Flexed>
													<Flexed direction="row" gap={0.3} align="center">
														<AiOutlineEye color={_isDarkTheme ? palette.light_gray : palette.dark_gray} />
														<Text type="xsmall" fontWeight={700} isDarkTheme={_isDarkTheme}>
															Estimated Views: {parseInt(val?.estimatedViews) ? formatNumber(val?.estimatedViews) : 0}
														</Text>
													</Flexed>

													<Flexed direction="row" gap={0.3} align="center">
														<FiPhone color={_isDarkTheme ? palette.light_gray : palette.dark_gray} />
														<Text type="xsmall" fontWeight={700} isDarkTheme={_isDarkTheme}>
															Tel: {val?.tellUsers}
														</Text>
													</Flexed>

													<Flexed direction="row" gap={0.3} align="center">
														<AiFillHeart color={_isDarkTheme ? palette.light_gray : palette.dark_gray} />
														<Text type="xsmall" fontWeight={700} isDarkTheme={_isDarkTheme}>
															Likes: {val?.likes}
														</Text>
													</Flexed>

													<Flexed direction="row" gap={0.3} align="center">
														<RiChat1Line color={_isDarkTheme ? palette.light_gray : palette.dark_gray} />
														<Text type="xsmall" fontWeight={700} isDarkTheme={_isDarkTheme}>
															Chats: {val?.chatUsers}
														</Text>
													</Flexed>
												</Flexed>
											</Wrapper>
										</Col>
									)
								})}
							</>
						)}
					</List>

					{myAdsList?.length > 0 && loadMore && filterStatus === 'all' && search.trim().length === 0 && (
						<Col>
							<Flexed direction="row" justify="center" margin="1rem 0rem 2rem 0rem">
								<Button label="Load More" type="red" ifClicked={() => loadMoreData()} />
							</Flexed>
						</Col>
					)}
					{myAdsListFilter?.length === 0 && (
						<Col>
							<Spacer height={5} />
							{!isLoading ? (
								<Text type="small" isCentered isDarkTheme={_isDarkTheme}>
									No post available.
								</Text>
							) : (
								<Text type="small" isCentered isDarkTheme={_isDarkTheme}>
									The post is loading...
								</Text>
							)}
						</Col>
					)}
				</Row>
				<Spacer height="5" />
			</MainWrapper>
			<DeleteAdsModal
				open={openAdsDeleteModal}
				onCloseModal={() => {
					setDeleteAdsId('')
					setOpenAdsDeleteModal(false)
				}}
				deletePost={() => deletePost(deleteAdsId)}
			/>
			{openAdsSoldModal && (
				<ItemSoldModal
					open={openAdsSoldModal}
					soldAdsInfo={soldAdsInfo}
					setIsLoading={setIsLoading}
					onCloseModal={() => {
						setOpenAdsSoldModal(false)
					}}
					getMyAdsPosts={() => {
						getMyAdsPosts(page, true)
					}}
				/>
			)}
			<AdDeactivateModal
				open={openAdsDeactivateModal}
				onCloseModal={() => {
					setDeactivateAdsId('')
					setOpenAdsDeactivateModal(false)
				}}
				deactivatePost={() => activateAndDeactivatePost(deactivateAdsId)}
			/>
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</>
	)
}

const MainWrapper = styled(Container)`
	min-height: calc(100vh - 70px);
	${media.md`min-height: calc(100vh - 136px);`}
`
const List = styled.div`
	width: 100%;
	${media.xs`
	overflow: auto;
	`}
	${media.sm`
	overflow: auto;
	`}
	${media.md`
	overflow: visible;
	`}
`

const Wrapper = styled.div<any>`
	margin: 0 0 0.5rem 0;
	width: 38rem;
	background: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	box-shadow: ${palette.shadow};
	padding: 0.5rem;
	cursor: pointer;
	border-radius: 0.39rem;
	transition: all ease 0.25s;
	position: relative;
	&:hover {
		box-shadow: ${palette.shadowHover};
	}
	${media.sm`
	width: 42rem;
	`}
	${media.md`
	width: 100%;
	`}
`

const Cover = styled.div`
	cursor: pointer;
`

const Image = styled.img`
	width: 4rem;
	height: 4rem;
	object-fit: cover;
	border-radius: 0.39rem;
	${media.xs`
	width: 2.7rem;
	height: 2.7rem;
	`}
	${media.sm`
	width: 3rem;
	height: 3rem;`}
	${media.md`
	width: 4rem;
	height: 4rem;
	`}
`

const Title = styled(Flexed)`
	width: 20%;
	& div {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow-wrap: anywhere;
	}
	${media.xs`& div {
		font-size: 0.75rem !important;
	}`}
	${media.sm`& div {
		font-size: 0.75rem !important;
	}`}
	${media.md`& div {
		font-size: 1rem !important;
	}`}
`

const Price = styled(Flexed)`
	width: 15%;
	${media.xs`& div {
		font-size: 0.675rem !important;
	}`}
	${media.sm`& div {
		font-size: 0.75rem !important;
	}`}
	${media.md`& div {
		font-size: 0.875rem !important;
	}`}
`

const Status = styled(Flexed)`
	width: 15%;
	& button {
		height: 1.5rem;
		padding: 0.2rem 1rem;
		border-radius: 1rem;
		font-size: 0.75rem;
	}
	${media.xs`& button {
		font-size:0.5rem;
		min-width: 100% !important;
		padding: 0.2rem 0.4rem;
	}`}
	${media.sm`& button {
		font-size:0.5rem;
		min-width: 100% !important;
		padding: 0.2rem 0.4rem;
	}`}
	${media.md`& button {
		font-size:0.75rem;
		min-width: 6rem !important;
	}`}
`

const Detail = styled(Flexed)`
	width: 30%;
	${media.xs`& div {
		font-size: 0.675rem !important;
	}`}
	${media.sm`& div {
		font-size: 0.75rem !important;
	}`}
	${media.md`& div {
		font-size: 0.875rem !important;
	}`}
`

const DropContent = styled.div<any>`
	display: none;
	margin-top: 0.3rem;
	top: 1.5rem;
	position: absolute;
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	min-width: max-content;
	right: ${({right}) => (right ? `${right}rem` : 'auto')};
	box-shadow: ${palette.shadow};
	z-index: 1;
	border-radius: 0.5rem;
	overflow: hidden;
`

const Action = styled(Flexed)`
	position: relative;
	width: 10%;
	&:hover ${DropContent} {
		display: block;
	}
`

const DropMenu = styled.div<any>`
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
	font-size: 0.875rem;
	padding: 0.425rem 1rem 0.425rem 0.8rem;
	text-decoration: none;
	display: block;
	font-weight: bold;
	&:hover {
		background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.red : palette.red)};
		color: ${palette.white};
	}
`

const SearchWrapper = styled.div``

const FilterButtons = styled.div<any>`
	font-weight: 600;
	border: 1px solid ${({active, isDarkTheme}) => (active ? palette.red : isDarkTheme ? palette.light_silver : palette.silver)};
	color: ${({active, isDarkTheme}) => (active ? palette.white : isDarkTheme ? palette.white : palette.dark_gray)};
	background-color: ${({active}) => (active ? palette.red : '')};
	padding: 0.3rem 1rem;
	border-radius: 2rem;
	cursor: pointer;
	font-size: 14px;
	&:hover {
		background-color: ${palette.red};
		color: ${palette.white};
		border: 1px solid ${palette.red};
	}
`

export default MyPosts
