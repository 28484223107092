/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CreatePost Component
 *
 * This component provides a UI for users to post advertisements. It includes a multi-step form
 * where users can select main categories, type categories, and sub-type categories for their post.
 * The component also handles the interaction with the API to fetch and display categories.
 *
 * @component
 */

import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {Heading, Spacer, Flexed, Loading, Text} from '../../styled/shared'
import {useSelector} from 'react-redux'
import {api} from '../../components/api/callAxios'
import {palette} from '../../styled/colors'
import {FaAngleRight} from 'react-icons/fa'
import CreatePostForm from './CreatePostForm'

const CreatePost = () => {
	const _isDarkTheme = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const [isLoading, setIsLoading] = useState(false)
	const [viewCeatePostForm, setViewCeatePostForm] = useState(false)

	const [mainCategories, setMainCategories] = useState<any>([])
	const [mainCategoriesIndex, setMainCategoriesIndex] = useState<any>(null)
	const [typeCategoriesIndex, setTypeCategoriesIndex] = useState<any>(null)

	const [seletedMainCategory, setSeletedMainCategory] = useState<any>(null)
	const [seletedTypeCategory, setSeletedTypeCategory] = useState<any>(null)
	const [seletedSubTypeCategory, setSeletedSubTypeCategory] = useState<any>(null)

	const getCategories = async () => {
		setIsLoading(true)
		await api
			.get(`/business-type`)
			.then(async (response) => {
				if (response?.data?.length > 0) {
					console.log('=====response?.data', response?.data)
					setMainCategories(response?.data)
				}

				setIsLoading(false)
			})
			.catch(function (error) {
				console.log(error)
				setIsLoading(false)
			})
	}

	useEffect(() => {
		getCategories()
	}, [])

	const getCategoriesIcons = (type: any) => {
		switch (type) {
			case 'Property for Sale':
				return '/images/icons/property_sale.svg'
			case 'Property for Rent':
				return '/images/icons/property_rent.svg'
			case 'Vehicles':
				return '/images/icons/vehicle.svg'
			case 'Mobiles':
				return '/images/icons/mobile.svg'
			default:
				return '/images/icons/property_sale.svg'
		}
	}

	const checkFirstStepChild = (index: any) => {
		if (mainCategories[index]?.typeCategory.length) {
			setViewCeatePostForm(false)
		} else {
			setViewCeatePostForm(true)
		}
	}

	const checkSecondStepChild = (index: any) => {
		if (mainCategories[mainCategoriesIndex]?.typeCategory[index]?.subCategory?.length) {
			setViewCeatePostForm(false)
		} else {
			setViewCeatePostForm(true)
		}
	}

	const reset = () => {
		setViewCeatePostForm(false)
		setMainCategoriesIndex(null)
		setTypeCategoriesIndex(null)
		setSeletedMainCategory(null)
		setSeletedTypeCategory(null)
		setSeletedSubTypeCategory(null)
		setMainCategories([])
		getCategories()
	}

	return (
		<>
			<MainWrapper>
				<Container>
					<Row>
						<Col>
							<Spacer height={2} />
						</Col>
						<Col>
							<Heading textTransform="uppercase" level={4} isCentered fontWeight={700} isDarkTheme={_isDarkTheme} margin={'0rem 0rem 1rem 0rem'}>
								POST YOUR AD
							</Heading>
						</Col>
						<Col>
							{!viewCeatePostForm ? (
								<Wrapper isLoading={isLoading} isDarkTheme={_isDarkTheme}>
									<Row>
										{mainCategories?.length > 0 && (
											<Custom padding="0px 0px 0px 15px" md={4} sm={4} xs={12} isDarkTheme={_isDarkTheme}>
												{mainCategories?.map((val: any, index: any) => {
													return (
														<MainCategoriesList
															isDarkTheme={_isDarkTheme}
															key={index + 'mainCategory'}
															active={index == mainCategoriesIndex}
															direction="row"
															align="center"
															justify="space-between"
															onClick={() => {
																setMainCategoriesIndex(index)
																setSeletedMainCategory(val)
																checkFirstStepChild(index)
															}}>
															<Flexed direction="row" align="center" gap={0.5}>
																<img src={getCategoriesIcons(val?.title)} alt="property_sale" />
																<Text type="small" isDarkTheme={_isDarkTheme} fontWeight="500">
																	{val?.title}
																</Text>
															</Flexed>
															<Arrow isDarkTheme={_isDarkTheme} />
														</MainCategoriesList>
													)
												})}
											</Custom>
										)}
										{mainCategories?.length > 0 && (
											<CustomCol md={4} sm={4} xs={12} isDarkTheme={_isDarkTheme} activeXSmall={mainCategoriesIndex != null && mainCategories[mainCategoriesIndex]?.typeCategory?.length > 0 ? true : false}>
												{mainCategoriesIndex != null &&
													mainCategories[mainCategoriesIndex]?.typeCategory?.map((type: any, index: any) => {
														return (
															<MainCategoriesList
																isDarkTheme={_isDarkTheme}
																key={index + 'typeCategory'}
																active={type?.title == seletedTypeCategory?.title}
																direction="row"
																align="center"
																justify="space-between"
																onClick={() => {
																	setTypeCategoriesIndex(index)
																	setSeletedTypeCategory(type)
																	checkSecondStepChild(index)
																}}>
																<Flexed direction="row" align="center" gap={0.5}>
																	<Text type="small" isDarkTheme={_isDarkTheme}>
																		{type?.title}
																	</Text>
																</Flexed>
																{type?.subCategory.length > 0 && <Arrow isDarkTheme={_isDarkTheme} />}
															</MainCategoriesList>
														)
													})}
											</CustomCol>
										)}
										{mainCategories?.length > 0 && (
											<CustomLast
												md={4}
												sm={4}
												xs={12}
												padding="0px 15px 0px 0px"
												isDarkTheme={_isDarkTheme}
												activeXSmall={typeCategoriesIndex != null && mainCategories[mainCategoriesIndex]?.typeCategory[typeCategoriesIndex]?.subCategory?.length > 0 ? true : false}>
												{typeCategoriesIndex != null &&
													mainCategories[mainCategoriesIndex]?.typeCategory[typeCategoriesIndex]?.subCategory?.map((subType: any, index: any) => {
														return (
															<MainCategoriesList
																isDarkTheme={_isDarkTheme}
																key={index + 'typeCategory'}
																active={subType?.title == seletedSubTypeCategory?.title}
																direction="row"
																align="center"
																justify="space-between"
																onClick={() => {
																	setSeletedSubTypeCategory(subType)
																	setViewCeatePostForm(true)
																}}>
																<Flexed direction="row" align="center" gap={0.5}>
																	<Text type="small" isDarkTheme={_isDarkTheme}>
																		{subType?.title}
																	</Text>
																</Flexed>
															</MainCategoriesList>
														)
													})}
											</CustomLast>
										)}
										<Col>
											{mainCategories?.length === 0 && isLoading === false && (
												<Text margin="2rem 0rem" type="small" isCentered isDarkTheme={_isDarkTheme}>
													No categories found{' '}
												</Text>
											)}
										</Col>
									</Row>
								</Wrapper>
							) : (
								<CreatePostForm setIsLoading={setIsLoading} seletedMainCategory={seletedMainCategory} seletedTypeCategory={seletedTypeCategory} seletedSubTypeCategory={seletedSubTypeCategory} clear={reset} />
							)}
						</Col>
					</Row>
				</Container>
			</MainWrapper>
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</>
	)
}

const MainWrapper = styled(Container)`
	min-height: calc(100vh - 70px);
`

const Wrapper = styled(Container)<any>`
	border-radius: 0.3rem;
	padding: 0;
	margin-bottom: 1rem;
	opacity: ${({isLoading}) => (isLoading ? 0 : 1)};
	@media screen and (min-width: 576px) and (max-width: 9999px) {
		border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.silver)};
	}
`

const Custom = styled(Col)<any>`
	@media screen and (min-width: 576px) and (max-width: 9999px) {
		padding: ${({padding}) => padding};
	}
	@media screen and (min-width: 0px) and (max-width: 575px) {
		border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.silver)};
		margin-bottom: 1rem;
		padding: 0;
	}
`

const CustomCol = styled(Col)<any>`
	@media screen and (min-width: 576px) and (max-width: 9999px) {
		border-left: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.silver)};
		border-right: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.silver)};
		padding: 0;
	}
	@media screen and (min-width: 0px) and (max-width: 575px) {
		border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.silver)};
		margin-bottom: 1rem;
		padding: 0;
		opacity: ${({activeXSmall}) => (activeXSmall ? '1' : '0')};
	}
`

const CustomLast = styled(Col)<any>`
	@media screen and (min-width: 576px) and (max-width: 9999px) {
		padding: ${({padding}) => padding};
	}
	@media screen and (min-width: 0px) and (max-width: 575px) {
		border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.silver)};
		margin-bottom: 1rem;
		padding: 0;
		opacity: ${({activeXSmall}) => (activeXSmall ? '1' : '0')};
	}
`

const Arrow = styled(FaAngleRight)<any>`
	font-size: 1.2rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.black)};
`

const MainCategoriesList = styled(Flexed)<any>`
	font-size: 1.2rem;
	border-bottom: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.silver)};
	padding: 0.5rem;
	cursor: pointer;
	min-height: 49px;
	background: ${({active}) => (active ? palette.red : '')};
	& div,
	& svg {
		color: ${({active, isDarkTheme}) => (active ? palette.white : isDarkTheme ? palette.light_gray : palette.dark_gray)};
	}
	& img {
		filter: ${({active, isDarkTheme}) => (active || isDarkTheme ? 'invert(100%) sepia(100%) saturate(7%) hue-rotate(137deg) brightness(102%) contrast(102%);' : '')};
	}
	&:hover {
		background: ${palette.red};
		color: ${({active, isDarkTheme}) => (active ? palette.white : isDarkTheme ? palette.white : palette.back)};
	}
	&:hover div,
	&:hover svg {
		color: ${({active}) => (active ? palette.white : palette.white)};
	}
	&:hover img {
		filter: invert(100%) sepia(100%) saturate(7%) hue-rotate(137deg) brightness(102%) contrast(102%);
	}
`

export default CreatePost
