/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * PhoneModal Component
 *
 * @component
 * @description Modal for handling phone calls and SMS.
 *
 * @param {Object} props - Component props.
 * @param {boolean} props.open - Flag indicating whether the modal is open.
 * @param {function} props.onCloseModal - Callback function to handle modal close.
 * @param {boolean} props.isPhone - Flag indicating whether it's a phone call.
 * @param {boolean} props.isSms - Flag indicating whether it's an SMS.
 * @param {string} props.number - Phone number.
 */
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {Text, Spacer, Flexed} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {BsXLg} from 'react-icons/bs'
import {useSelector} from 'react-redux'
import {media} from 'styled-bootstrap-grid'

import axios from 'axios'
import {FaSms} from 'react-icons/fa'
import {IoMdCall} from 'react-icons/io'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

interface IProps {
	isDarkTheme: boolean
}

const PhoneModal = ({open, onCloseModal, isPhone, isSms, number}: any) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)

	const handleCall = () => {
		if (isPhone) {
			const telLink = `tel:${number}`
			window.location.href = telLink
		} else if (isSms) {
			const smsLink = `sms:${number}`
			window.location.href = smsLink
		}
	}

	return (
		<>
			<Modal
				open={open}
				center
				onClose={() => {
					onCloseModal()
				}}
				classNames={{
					overlay: 'customOverlay',
					modal: 'blockModal'
				}}
				showCloseIcon={false}
				// @ts-ignore
				styles={{zIndex: '2'}}>
				<ModalWrapper isDarkTheme={_isDarkTheme}>
					<Head direction="row" align="center" justify="space-between">
						<div></div>
						<Text type="large" isCentered lineHeight="1.438" fontWeight="700" isDarkTheme={_isDarkTheme}>
							Phone
						</Text>
						<CrossIcon
							isDarkTheme={_isDarkTheme}
							onClick={() => {
								onCloseModal()
							}}
						/>
					</Head>
					<Body style={{width: '25rem'}}>
						<Spacer height={1} />
						<Text isCentered isDarkTheme={_isDarkTheme} onClick={handleCall} pointer>
							<div style={{display: 'flex', justifyContent: 'center'}}>
								{isPhone ? <CallIcon isDarkTheme={_isDarkTheme} /> : <SmsIcon isDarkTheme={_isDarkTheme} />} <Number>{number}</Number>
							</div>
						</Text>

						<Spacer height={1.5} />
					</Body>
				</ModalWrapper>
			</Modal>
		</>
	)
}

const ModalWrapper = styled.div<IProps>`
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	padding: 1rem;
`

const Head = styled(Flexed)`
	padding: 1rem;
	${media.sm`
    width: 400px;
	`}
	${media.md`
    width: 400px;
	`}
`

const Body = styled.div`
	padding: 0rem 1rem 1rem;
`

const CrossIcon = styled(BsXLg)<IProps>`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 1.3rem;
`

const CallIcon = styled(IoMdCall)<IProps>`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 1.5rem;
	rotate: 10%;
`

const SmsIcon = styled(FaSms)<IProps>`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 1.5rem;
	rotate: 10%;
`

const Number = styled.div`
	color: blue;
	font-weight: 600;
	text-decoration: underline;
`

const Block = styled.div<any>`
	background: ${({isDarkTheme}) => (isDarkTheme ? palette.dark_gray : palette.black)};
	border-radius: 0.3rem;
	padding: 0.5rem 0.8rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	justify-content: center;
	font-size: 1rem;
	font-weight: bold;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.white : palette.white)};
	cursor: pointer;
	width: 100%;
`

const Cancel = styled(Block)<any>`
	border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
	background: ${palette.white};
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.dark_gray : palette.dark_gray)};
	&:hover {
		border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.white : palette.black)};
	}
`

export default PhoneModal
