/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * React component for displaying and managing all posts based on various filters and search criteria.
 *
 * This component renders a list of posts, allows users to apply filters, and supports both grid and list views.
 * It handles search functionality, pagination, and provides a user-friendly interface for exploring classified ads.
 *
 * @component
 *
 * @param {Function} clearFilter - Function to clear applied filters.
 * @param {string} searchKeyword - Keyword for search functionality.
 * @param {string} searchLocation - Location for search functionality.
 * @param {Object} sideFilters - Filters for refining search results.
 * @param {Function} setSideFilters - Function to update side filters.
 * @param {Object} priceRange - Price range for filtering results.
 * @param {Function} setPriceRange - Function to update the price range.
 * @param {string} businessType - Type of business for filtering results.
 * @param {Function} setBusniessType - Function to update the business type.
 * @param {string} typeCategory - Category for filtering results.
 * @param {Function} setTypeCategory - Function to update the type category.
 * @param {string} subCategory - Sub-category for filtering results.
 * @param {Function} setSubCategory - Function to update the sub-category.
 * @param {number} page - Current page number.
 * @param {Function} setPage - Function to update the current page.
 * @param {number} pageNoSearch - Page number for search results.
 * @param {Function} setPageNoSearch - Function to update the page number for search results.
 * @param {Array} copyAdsSearchResults - Copy of search results.
 * @param {Function} setCopyAdsSearchResults - Function to update the copy of search results.
 *
 */

import React, {useEffect, useState, forwardRef, useImperativeHandle} from 'react'
import styled from 'styled-components'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {Spacer, Flexed, Loading, Text, Divider} from '../../styled/shared'
import ProductCard from '../../components/products/ProductCard'
import FilterSideBar from '../../components/common/FilterSideBar'
import {useSelector, useDispatch} from 'react-redux'
import Button from '../../components/common/Button'
import {api, checkAdvertisementStatus, checkFeaturedPostStatus} from '../../components/api/callAxios'
import {calculatePriceRange, verifyJSON} from '../../constants/commonFunctions'
import {toast} from 'react-toastify'
import {palette} from '../../styled/colors'
import {FiGrid} from 'react-icons/fi'
import {PiListBold} from 'react-icons/pi'
import useWindowSize from '../../hooks/useWindowSize'
import {handleHomeBanerSearch, isImageSearch, saveSearchImage} from '../../actions/authActions'
import {doGenerateAiResponse} from '../../components/api/apis'

const AllPosts = forwardRef(
	(
		{
			clearFilter,
			searchKeyword,
			searchLocation,
			sideFilters,
			setSideFilters,
			priceRange,
			setPriceRange,
			businessType,
			setBusniessType,
			typeCategory,
			setTypeCategory,
			subCategory,
			setSubCategory,
			page,
			setPage,
			pageNoSearch,
			setPageNoSearch,
			copyAdsSearchResults,
			setCopyAdsSearchResults
		}: any,
		ref
	) => {
		const mainAppSearch = async (keyWord: any, location: any, sideFilters: any, priceRange: any, businessType: any, typeCategory: any, subCategory: any, pageNoSearch: any) => {
			setIsLoading(true)
			setAIResponse(null)
			keyWord = keyWord.charAt(0).toUpperCase() + keyWord.slice(1)
			await api
				.post(`/ads/search_ads`, {
					page: pageNoSearch,
					pageSize: 15,
					location: location,
					keywords: keyWord,
					businessType: businessType,
					typeCategory: typeCategory,
					subCategory: subCategory,
					price: sideFilters.price,
					condition: sideFilters?.condition,
					type: sideFilters?.type,
					deviceType: sideFilters?.deviceType,
					brand: sideFilters?.brand,
					model: sideFilters?.model,
					fuel: sideFilters?.fuel,
					registrationCity: [],
					carDocuments: sideFilters?.carDocuments,
					assembly: sideFilters?.assembly,
					transmission: sideFilters?.transmission,
					downPayment: sideFilters?.downPayment,
					monthlyInstallment: sideFilters?.monthlyInstallment,
					installmentPlan: sideFilters?.installmentPlan,
					areaUnit: sideFilters?.areaUnit,
					area: sideFilters?.area,
					registered: sideFilters?.registered,
					year: sideFilters?.year,
					kmDriven: sideFilters?.kmDriven,
					furnished: sideFilters?.furnished,
					bedRooms: sideFilters?.bedRooms,
					bathRooms: sideFilters?.bathRooms,
					constructionState: sideFilters?.constructionState,
					floorLevel: sideFilters?.floorLevel,
					storeys: sideFilters?.storeys,
					features: sideFilters?.features,
					userId: _loginUserDetails?.id
				})
				.then(async (response) => {
					setAIResponse(null)

					if (response?.data?.data?.length == 0 && keyWord?.length >= 2) {
						await generateImageAiResponse(_searchedImage && [_searchedImage], keyWord)
						setAds([])
						setLoadMoreForSearch(false)
						setLoadMore(response?.data?.data?.remaining > 0 ? true : false)
					} else if (
						response?.data?.data?.length == 0 &&
						keyWord?.length == 0 &&
						location?.length == 0 &&
						(sideFilters?.brand?.length == 0 || sideFilters?.brand?.length == undefined) &&
						(sideFilters?.condition?.length == 0 || sideFilters?.condition?.length == undefined) &&
						(sideFilters?.constructionState?.length == 0 || sideFilters?.constructionState?.length == undefined) &&
						(sideFilters?.deviceType?.length == 0 || sideFilters?.deviceType?.length == undefined) &&
						(sideFilters?.features?.length == 0 || sideFilters?.features?.length == undefined) &&
						(sideFilters?.fuel?.length == 0 || sideFilters?.fuel?.length == undefined) &&
						(sideFilters?.furnished?.length == 0 || sideFilters?.furnished?.length == undefined) &&
						(sideFilters?.installmentPlan?.length == 0 || sideFilters?.installmentPlan?.length == undefined) &&
						(sideFilters?.registered?.length == 0 || sideFilters?.registered?.length == undefined) &&
						(sideFilters?.registrationCity?.length == 0 || sideFilters?.registrationCity?.length == undefined) &&
						(sideFilters?.type?.length == 0 || sideFilters?.type?.length == undefined) &&
						(sideFilters?.price?.length == 0 || sideFilters?.price?.length == undefined) &&
						(sideFilters?.area?.length == 0 || sideFilters?.area?.length == undefined)
					) {
						if (copyAdsResults?.length != 0) {
							setAds(copyAdsResults)
							setLoadMoreForSearch(false)
							setLoadMore(response?.data?.data?.remaining > 0 ? true : false)
						} else {
							if (_searchFromHome === '' || _searchFromHome === null) {
								setPage(1)
								setAIResponse(null)
								getAllPost(1, true)
							}
						}
					} else {
						if (
							(response?.data?.data?.length != 0 && keyWord?.length != 0 && keyWord == response?.data?.filters?.keywords) ||
							(response?.data?.data?.length != 0 && sideFilters?.area?.length != 0 && sideFilters?.area == response?.data?.filters?.area) ||
							(response?.data?.data?.length != 0 && sideFilters?.price?.length != 0 && sideFilters?.price == response?.data?.filters?.price)
						) {
							let searchAdsData = response?.data?.data
							if (pageNoSearch > 1) {
								searchAdsData = copyAdsSearchResults.concat(searchAdsData)
							}
							setAds(searchAdsData)
							setCopyAdsSearchResults(searchAdsData)
							setLoadMore(false)
							setLoadMoreForSearch(response?.data?.data?.remaining > 0 ? true : false)
						} else {
							let searchAdsData = response?.data?.data
							if (pageNoSearch > 1) {
								searchAdsData = copyAdsSearchResults.concat(searchAdsData)
							}
							setAds(searchAdsData)
							setCopyAdsSearchResults(searchAdsData)
							setLoadMore(false)
							setLoadMoreForSearch(response?.data?.data?.remaining > 0 ? true : false)
						}
					}
					setIsLoading(false)
				})
				.catch(function (error: any) {
					console.log(error)
					setIsLoading(false)
				})
		}
		useImperativeHandle(ref, () => ({
			mainAppSearch
		}))
		const windowSize = useWindowSize()
		let _dispatch = useDispatch()
		const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
		const _searchedImage: any = useSelector<any>((state: any) => state.auth.saveSearchImage)
		const _isDarkTheme = useSelector<any>((state: any) => state.auth.isDarkTheme)
		const _searchFromHome: any = useSelector<any>((state: any) => state.auth.search)
		const [openFiterMenu, setOpenFilterMenu] = useState(false)
		const [isLoading, setIsLoading] = useState(false)
		const [ads, setAds] = useState([])
		const [copyAdsResults, setCopyAdsResults] = useState([])
		const [filterMaxPriceVal, setFilterMaxPriceVal] = useState<any>({lowestPrice: 0, highestPrice: 0})
		const [view, setView] = useState<any>('block')
		const [loadMore, setLoadMore] = useState(false)
		const [loadMoreForSearch, setLoadMoreForSearch] = useState(false)
		const [loading, setLoading] = useState(true)
		const [aiResponse, setAIResponse] = useState<any>('')

		const getAllPost = async (page: any, resetPrice: any, _loading = true) => {
			setIsLoading(_loading)
			setAIResponse(null)
			await api
				.post(`/ads/all_ads`, {
					page: page,
					pageSize: 15,
					userId: _loginUserDetails?.id ? _loginUserDetails?.id : '',
					businessType: businessType,
					typeCategory: typeCategory,
					subCategory: subCategory
				})
				.then(async (response) => {
					setAIResponse(null)
					let postData = response?.data?.ads
					if (page > 1) {
						postData = ads.concat(postData)
					}
					if (_loading == true) {
						setAds(postData)
					}

					setCopyAdsResults(postData)
					setLoadMore(response?.data?.remaining > 0 ? true : false)
					setLoadMoreForSearch(false)
					setIsLoading(false)
					if (resetPrice) {
						const priceRange = calculatePriceRange(postData)
						setFilterMaxPriceVal(priceRange)
					}
					setLoading(false)
				})
				.catch(function (error) {
					if (error?.response) {
						toast.error(error?.response?.data?.message)
					}
					setIsLoading(false)
					setLoading(false)
				})
		}

		useEffect(() => {
			checkFeaturedPostStatus()
			checkAdvertisementStatus()
		}, [])

		useEffect(() => {
			if (_searchFromHome === '' || _searchFromHome === null) {
				setPage(1)
				getAllPost(1, true)
			}
			_dispatch(handleHomeBanerSearch(null))
		}, [businessType, typeCategory, subCategory])

		const loadMoreData = () => {
			setPage(page + 1)
			getAllPost(page + 1, true)
		}
		const loadMoreSearchData = () => {
			setPageNoSearch(pageNoSearch + 1)
			mainAppSearch(searchKeyword, searchLocation, sideFilters, priceRange, businessType, typeCategory, subCategory, pageNoSearch + 1)
		}

		useEffect(() => {
			if (windowSize?.width < 576) {
				setView('block')
			}
		}, [windowSize?.width])

		const generateImageAiResponse = async (images?: any, text?: any) => {
			try {
				setIsLoading(true)
				const _text = `Generate relevant website links based on the provided ${
					text ? 'text' + text : 'image'
				}, excluding contact information in descriptions. If no results found in listings, provide alternative website links with a message indicating that. Ensure details are valid and accurate. Give Response in html tags and title and h1 always should be 'unfortunately, no results were found. However, you can find the same ad on these sites.'.`
				let res: any = await doGenerateAiResponse(_text, images)
				if (res) {
					console.log('ressss', res)

					setLoading(false)
					setIsLoading(false)
					_dispatch(isImageSearch(false))
					_dispatch(saveSearchImage(''))
					if (res?.match(/```html([\s\S]*?)```/)) {
						const stringWithEmbeddedJSON = res

						const jsonMatch = stringWithEmbeddedJSON.match(/```html([\s\S]*?)```/)

						if (jsonMatch && jsonMatch[1]) {
							const extractedJSON = jsonMatch[1].trim()

							try {
								setAIResponse(extractedJSON)
								console.log('parsedObject:', extractedJSON)
							} catch (error) {
								console.error('Error parsing JSON:', error)
							}
						} else {
							console.error('No JSON found in the string.')
						}
					} else {
						setAIResponse(res)
					}
				}
			} catch (error) {
				setLoading(false)
				setIsLoading(false)
				console.log('error', error)
			}
		}

		return (
			<>
				<Container>
					<Wrapper>
						<Filter open={openFiterMenu}>
							<FilterSideBar
								clearFilter={clearFilter}
								onClose={() => setOpenFilterMenu(false)}
								businessType={businessType}
								setBusniessType={setBusniessType}
								typeCategory={typeCategory}
								setTypeCategory={setTypeCategory}
								subCategory={subCategory}
								setSubCategory={setSubCategory}
								filterMaxPriceVal={filterMaxPriceVal}
								setSideFilters={(filter: any) => {
									setSideFilters(filter)
									setPageNoSearch(1)
									setCopyAdsSearchResults([])
									mainAppSearch(searchKeyword, searchLocation, filter, priceRange, businessType, typeCategory, subCategory, 1)
								}}
							/>
						</Filter>
						<InnerWrapper fluid>
							<FilterContent>
								<Col>
									<Spacer height={0.3} />
								</Col>
								<Col>
									<Button
										small
										type="red"
										label="Open Filter"
										ifClicked={() => {
											setOpenFilterMenu(!openFiterMenu)
										}}
									/>
									<Spacer height={0.3} />
								</Col>
							</FilterContent>
							<Spacer height={0.3} />
							<Row>
								<Col>
									<Flexed direction="row" align="center" gap={0.5} margin={'0rem 0rem 0.5rem 0rem'}>
										<Text
											pointer
											type={windowSize?.width < 575 ? 'xsmall' : 'normal'}
											fontWeight={700}
											isDarkTheme={_isDarkTheme}
											onClick={() => {
												clearFilter()
											}}>
											All Posts
										</Text>
										{businessType && (
											<>
												<Text fontWeight={700} isDarkTheme={_isDarkTheme}>
													/
												</Text>
												<Text
													pointer
													type={windowSize?.width < 575 ? 'xsmall' : 'normal'}
													fontWeight={700}
													color="red"
													onClick={() => {
														setTypeCategory('')
														setSubCategory('')
													}}>
													{businessType}
												</Text>
											</>
										)}
										{typeCategory && (
											<>
												<Text fontWeight={700} isDarkTheme={_isDarkTheme}>
													/
												</Text>
												<Text
													pointer
													type={windowSize?.width < 575 ? 'xsmall' : 'normal'}
													fontWeight={700}
													color="red"
													onClick={() => {
														setSubCategory('')
													}}>
													{typeCategory}
												</Text>
											</>
										)}
										{subCategory && (
											<>
												<Text fontWeight={700} isDarkTheme={_isDarkTheme}>
													/
												</Text>
												<Text pointer type="normal" fontWeight={700} color="red">
													{subCategory}
												</Text>
											</>
										)}
									</Flexed>
								</Col>
								{ads?.length > 0 && (
									<Col>
										<Flexed direction="row" justify="space-between" align="center" gap={1}>
											<PostCounterTag>{ads?.length > 9999 ? '10,000+' : ads?.length} ads</PostCounterTag>
											<ViewFlex direction="row" justify="space-between" align="center" gap={0.5}>
												<Text isDarkTheme={_isDarkTheme} fontWeight={700} textTransform="uppercase">
													View
												</Text>
												<ViewWrapper
													active={view === 'list'}
													onClick={() => {
														setView('list')
													}}
													isDarkTheme={_isDarkTheme}>
													<PiListBold />
												</ViewWrapper>
												<ViewWrapper
													active={view === 'block'}
													onClick={() => {
														setView('block')
													}}
													isDarkTheme={_isDarkTheme}>
													<FiGrid />
												</ViewWrapper>
											</ViewFlex>
										</Flexed>
										<Divider margin="0.92rem 0rem" />
									</Col>
								)}

								{ads?.map((post: any, key: number) => {
									return (
										<Col xxl={view === 'list' ? 12 : 4} xl={view === 'list' ? 12 : 4} lg={view === 'list' ? 12 : 6} md={view === 'list' ? 12 : 6} sm={view === 'list' ? 12 : 6} xs={12}>
											<ProductCard
												view={view === 'list' ? true : false}
												key={key}
												post={post}
												favAd={(res: any) => {
													let data = copyAdsResults.filter((e: any) => e.id == res.id && e.isFav)
													getAllPost(page, true, false)
												}}
											/>
										</Col>
									)
								})}
								{ads?.length > 0 && !isLoading && loadMore && !loadMoreForSearch && (
									<Col>
										<Flexed direction="row" justify="center" margin="0rem 0rem 1rem 0rem">
											<Button label="Load More" type="red" ifClicked={() => loadMoreData()} />
										</Flexed>
									</Col>
								)}
								{ads?.length > 0 && !isLoading && !loadMore && loadMoreForSearch && (
									<Col>
										<Flexed direction="row" justify="center" margin="0rem 0rem 1rem 0rem">
											<Button label="Load More" type="red" ifClicked={() => loadMoreSearchData()} />
										</Flexed>
									</Col>
								)}
								{ads?.length === 0 && (
									<Col>
										<Spacer height={5} />
										{loading == false ? (
											aiResponse ? (
												<div style={{padding: '0 1rem'}} dangerouslySetInnerHTML={{__html: aiResponse}} />
											) : (
												<Text type="small" isCentered isDarkTheme={_isDarkTheme}>
													No post available.
												</Text>
											)
										) : (
											<Text type="small" isCentered isDarkTheme={_isDarkTheme}>
												The post is loading...
											</Text>
										)}
									</Col>
								)}
							</Row>
						</InnerWrapper>
					</Wrapper>
				</Container>
				{isLoading && (
					<Loading>
						<div className="dots-loading"></div>
					</Loading>
				)}
			</>
		)
	}
)

const Wrapper = styled.div`
	display: flex;
	margin-top: 0.5rem;
`

const InnerWrapper = styled(Container)`
	min-height: calc(100vh - 202px);
`

const Filter = styled.div<any>`
	display: ${({open}) => (open ? 'block' : 'none')};
	${media.lg`
    display:block;
`}
`

const FilterContent = styled(Row)`
	display: flex;
	${media.lg`display:none;`}
`

const PostCounterTag = styled.div`
	font-weight: 700;
	font-size: 14px;
	padding: 0.2rem 0.4rem;
	background: ${palette.red};
	color: ${palette.white};
	width: fit-content;
	border-radius: 0.3rem;
`

const ViewWrapper = styled.div<any>`
	padding: 0.35rem;
	background: ${({active}) => (active ? palette.red : '')};
	color: ${({active, isDarkTheme}) => (active ? palette.white : isDarkTheme ? palette.light_gray : palette.black)};
	border-radius: 3rem;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	font-size: 20px;
`

const ViewFlex = styled(Flexed)`
	opacity: 0;
	${media.sm`
		opacity:1;
	`}
`

export default AllPosts
