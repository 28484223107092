/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * MyFavourites Component
 *
 * This component displays a list of user's favorite ads. Users can view and interact with
 * the ads they have marked as favorites. It fetches the favorite ads from the API and
 * provides options to load more ads.
 *
 * @component
 */

import React, {useEffect, useState} from 'react'
import {Heading, Spacer, Loading, Text, Flexed} from '../../styled/shared'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {useSelector} from 'react-redux'
import 'react-multi-carousel/lib/styles.css'
import {api, checkFeaturedPostStatus} from '../../components/api/callAxios'
import PropertyCardContent from '../../components/products/PropertyCardContent'
import styled from 'styled-components'
import Button from '../../components/common/Button'

const MyFavourites = () => {
	const [isLoading, setIsLoading] = useState<any>(false)
	const [favoriteAdsList, setFavoriteAdsList] = useState<any>([])
	const _isDarkTheme = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const [page, setPage] = useState(1)
	const [pageSize, setPageSize] = useState(15)
	const [loadMore, setLoadMore] = useState(false)
	const [processMessages, setProcessMessages] = useState('The post is loading...')

	const getMyFavouritesPosts = async (page: any, isCallAfterUnfavorite: any) => {
		setProcessMessages('The post is loading...')
		setIsLoading(true)
		await api
			.post(`/favoriteAds/myFav_ads`, {
				page: page,
				pageSize: pageSize,
				isCallAfterUnfavorite: isCallAfterUnfavorite
			})
			.then(async (response: any) => {
				let _allFavAds = response?.data?.allFavouriteAds
				let _totalFavAds = response?.data?.totalFavAds
				if (page > 1) {
					if (!isCallAfterUnfavorite) {
						_allFavAds = favoriteAdsList.concat(_allFavAds)
					}
				}
				setFavoriteAdsList(_allFavAds)
				let _remainingFavAdsLength = _totalFavAds - page * pageSize
				setLoadMore(_remainingFavAdsLength > 0)
				setIsLoading(false)
				if (_totalFavAds === 0) {
					setProcessMessages('You haven’t added any post in Favourite list.')
				}
			})
			.catch(function (error: any) {
				console.log(error)
				setIsLoading(false)
			})
	}

	useEffect(() => {
		checkFeaturedPostStatus()
		getMyFavouritesPosts(1, false)
	}, [])

	const loadMoreData = () => {
		setPage(page + 1)
		getMyFavouritesPosts(page + 1, false)
	}

	return (
		<>
			<Wrapper>
				<Row>
					<Col>
						<Spacer height={1} />
					</Col>
					<Col>
						<Heading textTransform="uppercase" level={4} fontWeight={700} isDarkTheme={_isDarkTheme} margin={'0rem 0rem 1rem 0rem'}>
							My Favourites ADs
						</Heading>
					</Col>
					{favoriteAdsList?.length > 0 && (
						<>
							{favoriteAdsList?.map((val: any) => {
								return (
									<Col xl={4} lg={4} md={6} sm={6}>
										<PropertyCardContent
											post={val}
											getMyFavouritesPosts={() => {
												getMyFavouritesPosts(page, true)
											}}
										/>
									</Col>
								)
							})}
						</>
					)}
					{favoriteAdsList?.length > 0 && loadMore && (
						<Col>
							<Flexed direction="row" justify="center" margin="0rem 0rem 1rem 0rem">
								<Button label="Load More" ifClicked={() => loadMoreData()} />
							</Flexed>
						</Col>
					)}
					{favoriteAdsList?.length === 0 && (
						<Col>
							<Spacer height={5} />
							<Text type="small" isCentered isDarkTheme={_isDarkTheme}>
								{processMessages}
							</Text>
						</Col>
					)}
				</Row>
				<Spacer height="5" />
			</Wrapper>

			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</>
	)
}

const Wrapper = styled(Container)`
	min-height: calc(100vh - 70px);
	${media.md`min-height: calc(100vh - 136px);`}
`

export default MyFavourites
