/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * AdsReport Component
 *
 * @component
 * @description Modal for reporting ads with specific reasons.
 *
 * @param {Object} props - Component props.
 * @param {boolean} props.open - Flag to control the visibility of the modal.
 * @param {function} props.onCloseModal - Callback function to handle modal close.
 * @param {function} props.setIsLoading - Callback function to set loading state.
 * @param {any} props.postOwnerId - ID of the owner of the reported post.
 * @param {boolean} props.isDarkTheme - Flag indicating whether the app is in dark theme.
 */
import React, {useState} from 'react'
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {Text, Spacer, Flexed} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {BsXLg} from 'react-icons/bs'
import {useSelector} from 'react-redux'
import {media} from 'styled-bootstrap-grid'
import InputField from '../common/InputField'
import {api} from '../api/callAxios'
import useRouter from '../common/UserRouterHook'
import {toast} from 'react-toastify'

interface ICategoriesModalProps {
	open: boolean
	onCloseModal: any
	setIsLoading?: any
	postOwnerId?: any
}

interface IProps {
	isDarkTheme: boolean
}

const AdsReport = ({open, onCloseModal, setIsLoading, postOwnerId}: ICategoriesModalProps) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const [report, setReport] = useState('')
	const router = useRouter()
	const [comment, setComment] = useState('')
	const [reportError, setReportError] = useState(false)
	const [commentError, setCommentError] = useState(false)

	const handleOnSelect = (reportType: string) => {
		setReport(reportType)
	}

	const adsReport = async () => {
		if (router.query?.id) {
			setIsLoading(true)
			await api
				.post(`/reportAds/report_ads`, {reason: report, comment: comment, adsId: router.query?.id, userId: postOwnerId})
				.then(async (response) => {
					if (response) {
						toast.success('This ad has been reported')
						onCloseModal()
						setIsLoading(false)
					}
				})
				.catch(function (error) {
					console.log(error?.response?.data)
					if (error?.response?.data?.message) {
						toast.error(error?.response?.data?.message)
					}
					onCloseModal()
					setIsLoading(false)
				})
		}
	}

	return (
		<>
			<Modal
				open={open}
				center
				onClose={() => {
					onCloseModal()
				}}
				classNames={{
					overlay: 'customOverlay',
					modal: 'blockModal'
				}}
				showCloseIcon={false}
				// @ts-ignore
				styles={{zIndex: '2'}}>
				<ModalWrapper isDarkTheme={_isDarkTheme}>
					<Head direction="row" align="center" justify="space-between">
						<Text type="large" lineHeight="1.438" fontWeight="700" isDarkTheme={_isDarkTheme}>
							Item report
						</Text>
						<CrossIcon
							isDarkTheme={_isDarkTheme}
							onClick={() => {
								onCloseModal()
								setCommentError(false)
								setReport('')
								setComment('')
								setReportError(false)
							}}
						/>
					</Head>
					<Body>
						<Spacer height={1} />
						<Flexed direction="row" align="center" gap={1}>
							<input id="offensiveContent" name={'report'} type="radio" checked={'offensiveContent' == report} onClick={() => handleOnSelect('offensiveContent')} />
							<Label htmlFor="offensiveContent" isDarkTheme={_isDarkTheme}>
								Offensive content
							</Label>
						</Flexed>
						<Spacer height={0.5} />
						<Flexed direction="row" align="center" gap={1}>
							<input id="fraud" name={'report'} type="radio" checked={'fraud' == report} onClick={() => handleOnSelect('fraud')} />
							<Label htmlFor="fraud" isDarkTheme={_isDarkTheme}>
								Fraud
							</Label>
						</Flexed>
						<Spacer height={0.5} />
						<Flexed direction="row" align="center" gap={1}>
							<input id="duplicateAd" name={'report'} type="radio" checked={'duplicateAd' == report} onClick={() => handleOnSelect('duplicateAd')} />
							<Label htmlFor="duplicateAd" isDarkTheme={_isDarkTheme}>
								Duplicate ad
							</Label>
						</Flexed>
						<Spacer height={0.5} />
						<Flexed direction="row" align="center" gap={1}>
							<input id="productAlreadySold" name={'report'} type="radio" checked={'productAlreadySold' == report} onClick={() => handleOnSelect('productAlreadySold')} />
							<Label htmlFor="productAlreadySold" isDarkTheme={_isDarkTheme}>
								Product already sold
							</Label>
						</Flexed>
						<Spacer height={0.5} />
						<Flexed direction="row" align="center" gap={1}>
							<input id="wrongCategory" name={'report'} type="radio" checked={'wrongCategory' == report} onClick={() => handleOnSelect('wrongCategory')} />
							<Label htmlFor="wrongCategory" isDarkTheme={_isDarkTheme}>
								Wrong category
							</Label>
						</Flexed>
						<Spacer height={0.5} />
						<Flexed direction="row" align="center" gap={1}>
							<input id="productUnavailable" name={'report'} type="radio" checked={'productUnavailable' == report} onClick={() => handleOnSelect('productUnavailable')} />
							<Label htmlFor="productUnavailable" isDarkTheme={_isDarkTheme}>
								Product unavailable
							</Label>
						</Flexed>
						<Spacer height={0.5} />
						<Flexed direction="row" align="center" gap={1}>
							<input id="fakeProduct" name={'report'} type="radio" checked={'fakeProduct' == report} onClick={() => handleOnSelect('fakeProduct')} />
							<Label htmlFor="fakeProduct" isDarkTheme={_isDarkTheme}>
								Fake product
							</Label>
						</Flexed>
						<Spacer height={0.5} />
						<Flexed direction="row" align="center" gap={1}>
							<input id="indecent" name={'report'} type="radio" checked={'indecent' == report} onClick={() => handleOnSelect('indecent')} />
							<Label htmlFor="indecent" isDarkTheme={_isDarkTheme}>
								Indecent
							</Label>
						</Flexed>
						<Spacer height={0.5} />
						<Flexed direction="row" align="center" gap={1}>
							<input id="other" name={'report'} type="radio" checked={'other' == report} onClick={() => handleOnSelect('other')} />
							<Label htmlFor="other" isDarkTheme={_isDarkTheme}>
								Other
							</Label>
						</Flexed>

						<Text fontSize={0.7} type="small" color="danger">
							{report ? '' : reportError ? 'Please select a report reason' : ''}
						</Text>

						<Spacer height={1.5} />

						<InputField
							label="Comment"
							type="textarea"
							value={comment}
							handleChange={(e: any) => {
								if (e?.length <= 500) {
									setComment(e)
								}
							}}
						/>
						{!commentError && (
							<Flexed direction="row" justify="space-between" gap={1}>
								<Text isDarkTheme={_isDarkTheme} type="xsmall">
									{comment?.length}/500
								</Text>
							</Flexed>
						)}
						<Text fontSize={0.7} type="small" color="danger">
							{comment?.trim().length > 0 ? '' : commentError ? `Please add a comment to help us understand what's wrong with this user.` : ''}
						</Text>

						<Spacer height={1.5} />

						<Spacer height={1.5} />

						<Flexed direction="row" align="center" justify="space-between" gap={1}>
							<Block
								isDarkTheme={_isDarkTheme}
								onClick={() => {
									if (report && comment.trim().length > 0) {
										adsReport()
									} else {
										setCommentError(comment.trim().length == 0 ? true : false)
										setReportError(report.trim().length == 0 ? true : false)
									}
								}}>
								Send complaint
							</Block>
						</Flexed>
					</Body>
				</ModalWrapper>
			</Modal>
		</>
	)
}

const ModalWrapper = styled.div<IProps>`
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	padding: 1rem;
`

const Head = styled(Flexed)`
	padding: 1rem;
	width: 250px;
	${media.sm`
    width: 400px;
	`}
	${media.md`
    width: 400px;
	`}
`

const Body = styled.div`
	padding: 0rem 1rem 1rem;
`

const CrossIcon = styled(BsXLg)<IProps>`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 1.3rem;
`

const Block = styled.div<any>`
	background: ${({isDarkTheme}) => (isDarkTheme ? palette.dark_gray : palette.black)};
	border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
	border-radius: 0.3rem;
	padding: 0.5rem 0.8rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	justify-content: center;
	font-size: 1rem;
	font-weight: bold;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.white : palette.white)};
	cursor: pointer;
	width: 100%;
	&:hover {
		background: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.dark_gray)};
	}
`

const Label = styled.label<any>`
	margin: 0;
	cursor: pointer;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
`

export default AdsReport
