/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * SafetyTipsModal Component
 *
 * @component
 * @description Modal providing safety tips for user interactions.
 *
 * @param {Object} props - Component props.
 * @param {boolean} props.open - Flag indicating whether the modal is open.
 * @param {function} props.onCloseModal - Callback function to handle modal close.
 * @param {function} props.onClick - Callback function to handle user action after viewing safety tips.
 * @param {boolean} props.isBlocked - Flag indicating whether the user is already blocked.
 */

import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {Text, Spacer, Flexed} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {BsFlag} from 'react-icons/bs'
import {useSelector} from 'react-redux'
import {media} from 'styled-bootstrap-grid'

import useRouter from '../common/UserRouterHook'
import {GiReceiveMoney} from 'react-icons/gi'
import {BiMessageRounded} from 'react-icons/bi'

interface ICategoriesModalProps {
	open: boolean
	onCloseModal: any
	onClick?: any
	isBlocked?: any
}

interface IProps {
	isDarkTheme: boolean
}

const SafetyTipsModal = ({open, onCloseModal, onClick, isBlocked}: ICategoriesModalProps) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const router = useRouter()
	return (
		<>
			<Modal
				open={open}
				center
				onClose={() => {
					onCloseModal()
				}}
				classNames={{
					overlay: 'customOverlay',
					modal: 'blockModal'
				}}
				showCloseIcon={false}
				// @ts-ignore
				styles={{zIndex: '2'}}>
				<ModalWrapper isDarkTheme={_isDarkTheme}>
					<Head direction="row" align="center" justify="center">
						<Text type="large" lineHeight="1.438" fontWeight="700" color={_isDarkTheme ? 'black' : 'black'} onClick={() => router.navigate('/')} pointer>
							Listing App
						</Text>
					</Head>
					<Body>
						<Spacer height={1} />
						<Flexed direction="row" align="center" gap={1}>
							<FlagIcon isDarkTheme={_isDarkTheme} />
							<Label htmlFor="spam" isDarkTheme={_isDarkTheme}>
								We strongly urge you not to meet amid the lockdown. Close the negotiations on chat and complete the final transaction once lockdown ends
							</Label>
						</Flexed>
						<Spacer height={1} border />
						<Spacer height={0.5} />
						<Flexed direction="row" align="center" gap={1}>
							<Money isDarkTheme={_isDarkTheme} />
							<Label htmlFor="fraud" isDarkTheme={_isDarkTheme}>
								Never give money or product in advance
							</Label>
						</Flexed>
						<Spacer height={1} border />
						<Spacer height={0.5} />
						<Flexed direction="row" align="center" gap={1}>
							<Message isDarkTheme={_isDarkTheme} />
							<Label htmlFor="inappropriateProfilePicture" isDarkTheme={_isDarkTheme}>
								Do not reply to messages from International Numbers
							</Label>
						</Flexed>
						<Spacer height={1} border />
						<Spacer height={0.5} />
						<Flexed direction="row" align="center" gap={1}>
							<ReportIcon isDarkTheme={_isDarkTheme} />
							<Label htmlFor="thisUserIsThreateningMe" isDarkTheme={_isDarkTheme}>
								Report suspicious users to Listing App
							</Label>
						</Flexed>
						<Spacer height={1.5} />

						<Flexed direction="row" align="center" justify="space-between" gap={1}>
							<Block
								isDarkTheme={_isDarkTheme}
								onClick={() => {
									onClick()
								}}>
								Continue Chat
							</Block>
						</Flexed>
					</Body>
				</ModalWrapper>
			</Modal>
		</>
	)
}

const ModalWrapper = styled.div<IProps>`
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	/* padding: 1rem 0; */
`

const FlagIcon = styled(BsFlag)<any>`
	cursor: pointer;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 4rem;
	/* margin: 0px 1.5rem; */
`

const Money = styled(GiReceiveMoney)<any>`
	cursor: pointer;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 1.5rem;
	/* margin: 0px 1.5rem; */
`

const Message = styled(BiMessageRounded)<any>`
	cursor: pointer;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 1.5rem;
	/* margin: 0px 1.5rem; */
`

const ReportIcon = styled(BsFlag)<any>`
	cursor: pointer;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 1.2rem;
	/* margin: 0px 1.5rem; */
`

const Head = styled(Flexed)`
	padding: 1rem;
	${media.sm`
    width: 400px;
	`}
	${media.md`
    width: 400px;
	`}
	background-color: #ebeeef;
`

const Body = styled.div`
	width: 25rem;
	padding: 0rem 1rem 1rem;
`

const Block = styled.div<any>`
	background: ${({isDarkTheme}) => (isDarkTheme ? palette.dark_gray : palette.black)};
	border-radius: 0.3rem;
	padding: 0.5rem 0.8rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	justify-content: center;
	font-size: 1rem;
	font-weight: bold;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.white : palette.white)};
	cursor: pointer;
	width: 100%;
`

const Label = styled.label<any>`
	margin: 0;
	cursor: pointer;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
`

export default SafetyTipsModal
