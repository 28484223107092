/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * BusinessOTPVerification Component
 *
 * This component handles the verification of a user's account using an OTP code.
 * It allows users to input the OTP received via email, verifies it, and performs necessary actions
 * such as logging in the user or redirecting to the homepage.
 *
 * @component
 *
 * @param {string} phone - The user's phone number for OTP verification.
 * @param {Object} dataForSignIn - The user's data used for sign-in (e.g., email, password).
 * @param {Function} setIsLoading - A function to set the loading state.
 * @param {boolean} socialVerification - A flag indicating whether it's a social media account verification.
 */

import React, {useState} from 'react'
import styled from 'styled-components'
import {Flexed, Heading, Loading, Spacer, Text} from '../../styled/shared'
import {Row, Col} from 'styled-bootstrap-grid'
import Button from '../../components/common/Button'
import {useNavigate} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {toast} from 'react-toastify'
import {loginUserDetailsAction} from '../../actions/authActions'
import VerificationInput from 'react-verification-input'
import {api} from '../../components/api/callAxios'

const BusinessOTPVerification = ({phone, dataForSignIn, setIsLoading, socialVerification}: any) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	let _navigate = useNavigate()
	const _dispatch = useDispatch()
	const [otpCode, setOtpCode] = useState('')
	const [otpCodeError, setOtpCodeError] = useState('')

	const checkVerification = async () => {
		setIsLoading(true)
		await api
			.post(`/customers/verify_account`, {
				phone: phone,
				otp: parseInt(otpCode)
			})
			.then((res: any) => {
				if (res?.data?.status) {
					toast.success(res?.data?.message)
					if (socialVerification) {
						setIsLoading(false)
						localStorage.setItem('authorization', res?.data?.data?.accessToken)
						localStorage.setItem('userStatus', res?.data?.data?.user_login)
						_dispatch(loginUserDetailsAction(res?.data?.data?.user))
						_navigate('/')
					} else {
						api.post(`/auth/login`, {email: dataForSignIn?.email, password: dataForSignIn?.password, role: 'customer'})
							.then((res) => {
								if (res?.data?.status) {
									setIsLoading(false)
									localStorage.setItem('authorization', res?.data?.data?.accessToken)
									localStorage.setItem('userStatus', res?.data?.data?.user_login)
									_dispatch(loginUserDetailsAction(res?.data?.data?.user))

									_navigate('/')
								}
							})
							.catch((e) => {
								if (e?.response) {
									toast.error(e?.response?.data?.message)
									setIsLoading(false)
								}
							})
					}
				} else {
					setOtpCodeError(res?.data?.message)
					setIsLoading(false)
				}
			})
			.catch((error) => {
				setIsLoading(false)
				toast.error(error.message)
				console.error(error)
			})
	}

	const resendOTP = async () => {
		setIsLoading(true)
		await api
			.post(`customers/resend_otp`, {
				phone: phone
			})
			.then((res: any) => {
				if (res?.data?.status) {
					setOtpCode('')
					toast.success(res?.data?.message)
				}
				setIsLoading(false)
			})
			.catch((error) => {
				console.error(error)
				setIsLoading(false)
			})
	}

	return (
		<Row justifyContent="center">
			<Col xxl={3} xl={5} lg={5} md={6}>
				<Spacer height={2} />
				<Heading level={2} isCentered isDarkTheme={_isDarkTheme}>
					Verification Code
				</Heading>
				<Spacer height={2} />
				<Text type="normal" isCentered fontWeight={500} color="dark_black">
					Please enter the validation code that was sent to your email.
				</Text>
				<Spacer height={2} />
				<Flexed direction="row" justify="center">
					<VerificationInput
						autoFocus={true}
						validChars="0-9"
						classNames={{
							character: 'character'
						}}
						onChange={(e: any) => {
							setOtpCodeError('')
							setOtpCode(e)
						}}
						value={otpCode}
					/>
				</Flexed>
				<div>
					<Spacer height={0.5} />
					<Text isCentered fontSize={0.7} type="small" color="danger">
						{otpCodeError}
					</Text>
					<Spacer height={1} />
				</div>

				<Flexed direction="row" justify="center">
					<Button
						textTransformation
						width="100%"
						type="red"
						disabled={otpCode.length < 6}
						label="Verify Now"
						ifClicked={() => {
							checkVerification()
						}}
					/>
				</Flexed>
				<ResendCode
					pointer
					type="normal"
					margin="0.625rem 0rem 0rem 0rem"
					textDecoration="underline"
					fontWeight={700}
					color="red"
					onClick={() => {
						resendOTP()
					}}>
					Resend Code
				</ResendCode>
			</Col>
		</Row>
	)
}

const ResendCode = styled(Text)`
	text-align: end;
`

export default BusinessOTPVerification
