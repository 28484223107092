/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * ItemSoldModal Component
 *
 * @component
 * @description Modal for marking an item as sold.
 *
 * @param {Object} props - Component props.
 * @param {boolean} props.open - Flag indicating whether the modal is open.
 * @param {function} props.onCloseModal - Callback function to handle modal close.
 * @param {function} props.setIsLoading - Callback function to set loading state.
 * @param {function} props.getMyAdsPosts - Callback function to get user's ads posts.
 * @param {Object} props.soldAdsInfo - Information about the sold ad.
 */

import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {Modal} from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {Text, Spacer, Flexed} from '../../styled/shared'
import {palette} from '../../styled/colors'
import {BsXLg} from 'react-icons/bs'
import {useSelector} from 'react-redux'
import {media} from 'styled-bootstrap-grid'

import axios from 'axios'
import {api} from '../api/callAxios'
import {toast} from 'react-toastify'
import InputField from '../common/InputField'
import Button from '../common/Button'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

interface ICategoriesModalProps {
	open: boolean
	onCloseModal: any
	setIsLoading: any
	getMyAdsPosts: any
	soldAdsInfo: any
}

interface IProps {
	isDarkTheme: boolean
}

const ItemSoldModal = ({open, onCloseModal, soldAdsInfo, setIsLoading, getMyAdsPosts}: ICategoriesModalProps) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	const [soldOutside, setSoldOutside] = useState(false)
	const [soldHere, setSoldHere] = useState(false)
	const [outSidePlatform, setOutSidePlatform] = useState('')
	const [chatUsers, setChatUsers] = useState<any>([])
	const [searchQuery, setSearchQuery] = useState('')
	const [chatUserIds, setChatUsersIds] = useState<any>([])
	const [selectedUser, setSelectedUser] = useState<any>()

	const [error, setError] = useState('')

	const markPostSold = (postId: any) => {
		if (soldHere && !selectedUser) {
			setError('Please select at least one user.')
		} else if (soldHere || soldOutside) {
			setIsLoading(true)
			api.post(`/ads/mark_sold/${postId}`, {
				sold: true,
				soldOutside: soldOutside,
				soldHere: soldHere,
				outSidePlatform: outSidePlatform,
				soldTo: selectedUser
			})
				.then((res) => {
					setIsLoading(false)
					onCloseModal()
					getMyAdsPosts()
				})
				.catch((e) => {
					if (e?.response) {
						toast.error(e?.response?.data?.message)
						setIsLoading(false)
					}
				})
		} else {
			setError('Please select at least one checkbox.')
		}
	}

	useEffect(() => {
		if (_loginUserDetails?.conversationId?.length && soldHere) {
			let users: any[] = []
			for (let i = 0; i < _loginUserDetails?.conversationId?.length; i++) {
				let ids = _loginUserDetails?.conversationId[i]?.createdId?.split('_')
				if (ids?.length >= 3) {
					users.push(ids[2])
				}
			}
			setChatUsersIds(users)
			if (users?.length) {
				users = users.splice(_loginUserDetails?.id, 1)
				getChatUsers(users)
			}
		}
	}, [_loginUserDetails, soldHere])

	const getChatUsers = async (userIds: any) => {
		try {
			await api
				.post(`customers/view_all_user`, {userIds})
				.then(async (response) => {
					setChatUsers(response?.data)
				})
				.catch(function (error) {
					console.log(error)
				})
		} catch (error) {
			console.log('===error', error)
		}
	}

	const searchUsers = async (query?: any, userIds?: any) => {
		try {
			await api
				.post(`customers/search_all_user`, {name: query, userIds})
				.then(async (response) => {
					setChatUsers(response?.data)
				})
				.catch(function (error) {
					console.log(error)
				})
		} catch (error) {
			console.log('===error', error)
		}
	}

	return (
		<>
			<Modal
				open={open}
				center
				onClose={() => {
					onCloseModal()
				}}
				classNames={{
					overlay: 'customOverlay',
					modal: 'blockModal'
				}}
				showCloseIcon={false}
				// @ts-ignore
				styles={{zIndex: '2'}}>
				<ModalWrapper isDarkTheme={_isDarkTheme}>
					<Head direction="row" align="center" justify="space-between">
						<Text type="large" lineHeight="1.438" fontWeight="700" isDarkTheme={_isDarkTheme}>
							Mark as sold
						</Text>
						<CrossIcon
							isDarkTheme={_isDarkTheme}
							onClick={() => {
								onCloseModal()
							}}
						/>
					</Head>
					<Body>
						<Spacer height={1} />

						<Flexed direction="row" align="center" gap={1}>
							<div>
								<Image src={process.env.REACT_APP_IMAGE_URL + soldAdsInfo?.photos[0]} />
							</div>
							<Flexed gap={0.2}>
								<Text type="small" isDarkTheme={_isDarkTheme}>
									${soldAdsInfo?.price}
								</Text>
								<Text type="small" fontWeight={600} isDarkTheme={_isDarkTheme}>
									{soldAdsInfo?.title}
								</Text>
							</Flexed>
						</Flexed>

						<Spacer height={1} />

						<Text isDarkTheme={_isDarkTheme} fontWeight={600}>
							Who bought your Ad?
						</Text>

						<Spacer height={0.5} />

						<Flexed
							pointer
							gap={0.5}
							direction="row"
							align="center"
							onClick={() => {
								setSoldOutside(true)
								setSoldHere(false)
								setError('')
							}}>
							<DefaultIcon src={soldOutside ? '/images/icons/icon_CheckMarkBlue.svg' : '/images/icons/icon_Profile.png'} alt="icon_Profile" />
							<Text type="small" fontWeight={600} isDarkTheme={_isDarkTheme}>
								Sold outside Our Platform
							</Text>
						</Flexed>

						<Spacer height={0.5} />

						<Flexed
							pointer
							gap={0.5}
							direction="row"
							align="center"
							onClick={() => {
								setSoldOutside(false)
								setSoldHere(true)
								setOutSidePlatform('')
								setError('')
							}}>
							<DefaultIcon src={soldHere ? '/images/icons/icon_CheckMarkBlue.svg' : '/images/icons/icon_Profile.png'} alt="icon_Profile" />
							<Text type="small" fontWeight={600} isDarkTheme={_isDarkTheme}>
								Sold on Our Platform
							</Text>
						</Flexed>

						{error && (
							<Text fontSize={0.7} type="small" color="danger">
								{error}
							</Text>
						)}

						{soldOutside && <Spacer height={1} />}
						{soldOutside && <InputField value={outSidePlatform} handleChange={(e: any) => setOutSidePlatform(e)} placeholder="Platform name (optional)" />}

						<Spacer height={1.5} />
						{soldHere && (
							<InputField
								value={searchQuery}
								handleChange={(e: any) => {
									setSearchQuery(e ? e.trimStart() : e)
									searchUsers(e ? e.trimStart() : e, [...chatUserIds, _loginUserDetails?.id])
								}}
								placeholder="Search users"
							/>
						)}
						<Spacer height={0.5} />
						{chatUsers.length > 0 && soldHere ? (
							<div>
								<ul style={{listStyleType: 'none'}}>
									{chatUsers.map((user: any) => (
										<li key={user.id} onClick={() => setSelectedUser(user.id)} style={{cursor: 'pointer', gap: '0.5rem', display: 'flex', marginBottom: '0.5rem', alignItems: 'center'}}>
											<DefaultIcon src={selectedUser == user.id ? '/images/icons/icon_CheckMarkBlue.svg' : '/images/icons/icon_CheckMarkempty.svg'} alt="icon_Profile" />
											<DefaultIcon src={user?.photo ? process.env.REACT_APP_IMAGE_URL + user?.photo : '/images/icons/icon_Profile.png'} alt="icon_Profile" />{' '}
											<Text type="small" color={_isDarkTheme ? 'white' : 'black'} pointer>
												{user.fullname}
											</Text>
										</li>
									))}
								</ul>
							</div>
						) : (
							soldHere && (
								<>
									<Spacer height={0.5} />
									<Text isDarkTheme={_isDarkTheme} fontWeight={500} isCentered>
										No user found
									</Text>
									<Spacer height={1.5} />
								</>
							)
						)}

						<Flexed direction="row" align="center" justify="space-between" gap={1}>
							<Cancel
								onClick={() => {
									onCloseModal()
								}}>
								Cancel
							</Cancel>
							<Button
								label=" Mark as sold"
								type="red"
								small
								width="100%"
								isDarkTheme={_isDarkTheme}
								ifClicked={() => {
									markPostSold(soldAdsInfo?.id)
								}}
							/>
						</Flexed>
					</Body>
				</ModalWrapper>
			</Modal>
		</>
	)
}

const ModalWrapper = styled.div<IProps>`
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	padding: 1rem;
`

const Head = styled(Flexed)`
	padding: 1rem;
	${media.sm`
    width: 400px;
	`}
	${media.md`
    width: 400px;
	`}
`

const Body = styled.div`
	padding: 0rem 1rem 1rem;
`

const CrossIcon = styled(BsXLg)<IProps>`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 1.3rem;
`

const Image = styled.img`
	width: 3.5rem;
	height: 3.5rem;
	object-fit: cover;
	border-radius: 0.39rem;
`

export const DefaultIcon = styled.img`
	width: 2rem;
	height: 2rem;
	object-fit: cover;
	border-radius: 0.39rem;
`

const Cancel = styled.div<any>`
	background: ${({isDarkTheme}) => (isDarkTheme ? palette.dark_gray : palette.black)};
	border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
	border-radius: 0.3rem;
	padding: 0.4rem 0.8rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	justify-content: center;
	font-size: 0.75rem;
	font-weight: bold;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.white : palette.white)};
	cursor: pointer;
	width: 100%;
	text-transform: uppercase;
	&:hover {
		background: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.dark_gray)};
		border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.white : palette.black)};
	}
`

export default ItemSoldModal
