/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CustomerProfile component represents the user profile page for customers.
 * It allows users to view and edit their personal information, upload an image,
 * disconnect social accounts, and delete their account.
 *
 * @component
 */

import React, {useState, useEffect} from 'react'
import {Spacer, Flexed, Text, Loading, Divider} from '../../styled/shared'
import {Container, Row, Col} from 'styled-bootstrap-grid'
import {palette} from '../../styled/colors'
import styled from 'styled-components'
import InputField from '../../components/common/InputField'
import Button from '../../components/common/Button'
import {MdOutlineCloudUpload} from 'react-icons/md'
import {useSelector, useDispatch} from 'react-redux'
import {api} from '../../components/api/callAxios'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import {useJsApiLoader} from '@react-google-maps/api'
import axios from 'axios'
import {loginBusinessDetailsAction, loginUserDetailsAction} from '../../actions/authActions'
import InputPhoneNo from '../../components/common/InputPhoneNo'
import DeleteAccountModal from '../../components/modals/DeleteAccountModal'
import InputLocationForCreateForm from '../../components/common/InputLocationForCreateForm'
import DisconnectSocialAccounts from '../../components/modals/DisconnectSocialAccounts'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const CustomerProfile = () => {
	const _isDarkTheme = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	let _dispatch = useDispatch()
	const _navigate = useNavigate()
	const [userId, setUserId] = useState('')
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [email, setEmail] = useState('')
	const [image, setImage] = useState('')
	const [newImage, setNewImage] = useState('')
	const [phone, setPhone] = useState('')
	const [address, setAddress] = useState('')
	const [gender, setGender] = useState('')
	const [deleteAccount, setDeleteAccount] = useState(false)
	const [openDisconnectAccountModal, setOpenDisconnectAccountModal] = useState(false)
	const [showAddressInput, setShowAddressInput] = useState(false)
	const [socialProviderName, setSocialProviderName] = useState('')
	const [socialProviderId, setSocialProviderId] = useState('')
	const [googleSocialId, setGoogleSocialId] = useState('')
	const [facebookSocialId, setFacebookSocialId] = useState('')

	const [isLoading, setIsLoading] = useState(false)
	const [clat, setCLat] = useState<any>('')
	const [clng, setCLng] = useState<any>('')

	const [firstNameError, setFirstNameError] = useState('')
	const [lastNameError, setLastNameError] = useState('')
	const [imageError, setImageError] = useState('')
	const [emailError, setEmailError] = useState('')
	const [phoneError, setPhoneError] = useState('')
	const [addressError, setAddressError] = useState('')
	const [genderError, setGenderError] = useState('')

	useEffect(() => {
		if (_loginUserDetails?.id) {
			getUserDetails()
		}
	}, [_loginUserDetails])

	const handleCapture = ({target}: any) => {
		const reader: any = new FileReader()
		reader.readAsDataURL(target.files[0])
		reader.onload = () => {
			if (reader.readyState === 2) {
				setImageError('')
				setImage(reader?.result)
				setNewImage(reader?.result)
			}
		}
	}

	const getUserDetails = () => {
		setIsLoading(true)
		api.get(`/customers/${_loginUserDetails?.id}?page=${1}&pageSize=${15}`)
			.then((res) => {
				if (res?.data) {
					console.log('res?.data', res?.data)
					setUserId(res?.data?.customer?.customer?.id)
					setFirstName(res?.data?.customer?.customer?.firstname)
					setLastName(res?.data?.customer?.customer?.lastname)
					setImage(res?.data?.customer?.customer?.photo ? process.env.REACT_APP_IMAGE_URL + res?.data?.customer?.customer?.photo : '')
					setEmail(res?.data?.customer?.customer?.email)
					setPhone(res?.data?.customer?.customer?.phone)
					setGender(res?.data?.customer?.customer?.gender)
					setAddress(res?.data?.customer?.customer?.address)
					setIsLoading(false)
					setShowAddressInput(true)
					setFacebookSocialId(res?.data?.customer?.customer?.facebookSocialId)
					setGoogleSocialId(res?.data?.customer?.customer?.googleSocialId)
				}
			})
			.catch((e) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setIsLoading(false)
				}
			})
	}

	const editUserDetails = () => {
		if (formValidation()) {
			setIsLoading(true)
			api.put(`/customers/${userId}`, {
				firstname: firstName,
				lastname: lastName,
				photo: newImage ? newImage : _loginUserDetails?.photo,
				email: email,
				phone: phone,
				address: address,
				gender: gender
			})
				.then((res) => {
					if (res?.data) {
						setIsLoading(false)
						toast.success('User updated successfully')
						_dispatch(loginUserDetailsAction(res.data.data))
					}
				})
				.catch((e) => {
					if (e?.response) {
						toast.error(e?.response?.data?.message)
						setIsLoading(false)
					}
				})
		}
	}

	const formValidation = () => {
		let isValid = true
		if (firstName === '') {
			setFirstNameError('First Name is required')
			isValid = false
		}
		if (lastName === '') {
			setLastNameError('Last Name is required')
			isValid = false
		}
		if (email === '') {
			setEmailError('Email is required')
			isValid = false
		}
		if (phone === '' || phone === null) {
			setPhoneError('Phone number is required')
			isValid = false
		}
		if (address === '') {
			setAddressError('Address is required')
			isValid = false
		}
		if (image === '') {
			setImageError('Image is required')
			isValid = false
		}
		if (gender === '' || gender === null || gender === undefined) {
			setGenderError('Select gender')
			isValid = false
		}
		return isValid
	}

	const containerStyle = {
		width: '100%',
		height: '14.74rem'
	}

	const center = {
		lat: clat ? clat : 38.892708,
		lng: clng ? clng : -94.6426741,
		zoom: 15
	}

	const {isLoaded} = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: 'AIzaSyAiSEHvjCeth6Gx8YDltGHUhzBl5ongDRk'
	})

	const getCurrentLatLng = async (lat: any, lng: any) => {
		navigator.geolocation.getCurrentPosition(
			async (position) => {
				setCLat(lat)
				setCLng(lng)
				await getCurrentAddress(lat, lng)
			},
			function (error) {
				// eslint-disable-next-line eqeqeq
				if (error.code == error.PERMISSION_DENIED) {
				} else {
				}
			},
			{timeout: 5000, enableHighAccuracy: true}
		)
	}

	const getCurrentAddress = async (lat: any, lng: any) => {
		setCLat(lat)
		setCLng(lng)
		await axios
			.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${parseFloat(lat)},${parseFloat(lng)}&sensor=true&key=${process.env.REACT_APP_MAP_API_KEY}`)
			.then((response) => {})
			.catch((error) => {})
	}

	const doDeleteAccount = () => {
		setIsLoading(true)
		api.delete(`/customers/${_loginUserDetails?.id}`)
			.then((res) => {
				setIsLoading(false)
				setDeleteAccount(false)
				localStorage.removeItem('authorization')
				localStorage.removeItem('userStatus')
				_dispatch(loginUserDetailsAction({}))
				_dispatch(loginBusinessDetailsAction({}))
				_navigate('/')
				toast.success('Account deleted successfully.')
			})
			.catch((e) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setIsLoading(false)
				}
			})
	}

	const doConnectAndDisconnectAccount = (type: any, socialProviderName: any, socialProviderId: any) => {
		setIsLoading(true)
		api.post(`/customers/update_socialId`, {
			type: type,
			provider: socialProviderName,
			socialId: socialProviderId
		})
			.then((res: any) => {
				setFacebookSocialId(res?.data?.data?.facebookSocialId)
				setGoogleSocialId(res?.data?.data?.googleSocialId)
				setSocialProviderName('')
				setIsLoading(false)
				setOpenDisconnectAccountModal(false)
				if (res?.data?.message === 'Customer Deleted Successfully') {
					localStorage.removeItem('authorization')
					localStorage.removeItem('userStatus')
					_dispatch(loginUserDetailsAction({}))
					_dispatch(loginBusinessDetailsAction({}))
					_navigate('/')
				}
			})
			.catch((e) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setIsLoading(false)
				}
			})
	}

	return (
		<>
			<Container>
				<Spacer height={1} />
				<Row justifyContent="center">
					<Col lg={7} md={10} sm={12}>
						<DeleteAccountContainer>
							<Wrapper>
								<Row>
									<Col>
										<Flexed direction="row" justify="center">
											<ProfileImgCover htmlFor="faceImage">
												<ProfileImg isDarkTheme={_isDarkTheme} url={image} />
												{(image === '' || image === null || image === undefined) && (
													<UploadImageText isDarkTheme={_isDarkTheme}>
														<MdOutlineCloudUpload color={_isDarkTheme ? palette.light_gray : palette.gray} fontSize={24} />
														Upload Image
													</UploadImageText>
												)}
											</ProfileImgCover>
										</Flexed>
										<Flexed direction="row" justify="center">
											<input accept="image/*" style={{display: 'none'}} id="faceImage" type="file" onChange={handleCapture} />
										</Flexed>
										<Flexed direction="row" justify="center" margin="0rem 0rem 0.19rem 0rem">
											<Text fontSize={0.7} type="small" color="danger">
												{imageError}
											</Text>
										</Flexed>
										<Spacer height={1} />
									</Col>

									<Col lg={6} md={6}>
										<InputField
											error={firstNameError}
											label="First Name"
											required
											value={firstName}
											handleChange={(e: any) => {
												setFirstNameError('')
												if (e == '') {
													setFirstNameError('name is required')
												}
												setFirstName(e)
											}}
										/>
										<Spacer height={1} />
									</Col>
									<Col lg={6} md={6}>
										<InputField
											error={lastNameError}
											label="Last Name"
											value={lastName}
											required
											handleChange={(e: any) => {
												setLastNameError('')
												if (e == '') {
													setLastNameError('last name is required')
												}
												setLastName(e)
											}}
										/>
										<Spacer height={1} />
									</Col>
									{showAddressInput && (
										<Col lg={12}>
											<InputLocationForCreateForm
												label="Address"
												required
												address={address}
												isEdit={true}
												setAddress={(address: any) => {
													setAddressError('')
													if (address == '') {
														setAddressError('address is required')
													}
													setAddress(address)
												}}
												error={addressError}
											/>
											<Spacer height={1} />
										</Col>
									)}

									<Col lg={6} md={6}>
										<InputField
											disabled
											error={emailError}
											label="Email"
											value={email}
											required
											handleChange={(e: any) => {
												const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i
												setEmailError('')
												if (e === '') {
													setEmailError('email is required')
												} else if (!regex.test(e.value)) {
													setEmailError('invalid email')
												}
												setEmail(e)
											}}
										/>
										<Spacer height={1} />
									</Col>

									<Col lg={6} md={6}>
										<InputPhoneNo
											error={phoneError}
											label="Phone"
											required
											value={phone}
											handleChange={(e: any) => {
												setPhoneError('')
												if (e?.value == '' && !isLoading) {
													setPhoneError('phone number is required')
												}
												setPhone(e?.value)
											}}
										/>

										<Spacer height={1} />
									</Col>
									<Col>
										<Flexed direction="row" gap={2}>
											<Text type="normal" fontWeight={500} isDarkTheme={_isDarkTheme}>
												Gender:
											</Text>
											<Flexed direction="row" gap={2}>
												<Flexed direction="row" align="center" gap={0.5}>
													<input
														name="male"
														type="radio"
														checked={gender === 'male'}
														onChange={() => {
															setGender('male')
															setGenderError('')
														}}
													/>
													<Label htmlFor="gender" isDarkTheme={_isDarkTheme}>
														Male
													</Label>
												</Flexed>
												<Flexed direction="row" align="center" gap={0.5}>
													<input
														name="female"
														type="radio"
														checked={gender === 'female'}
														onChange={() => {
															setGender('female')
															setGenderError('')
														}}
													/>
													<Label htmlFor="gender" isDarkTheme={_isDarkTheme}>
														Female
													</Label>
												</Flexed>
											</Flexed>
										</Flexed>
										{genderError && (
											<Text fontSize={0.7} type="small" color="danger">
												{genderError}
											</Text>
										)}
									</Col>

									<Col>
										<Divider margin="1.2rem 0rem 1rem 0rem" />
										<Text type="normal" margin="1rem 0rem" isDarkTheme={_isDarkTheme} fontWeight={700}>
											Optional information
										</Text>

										<SocialConnectGroup>
											<div>
												<Text type="normal" fontWeight={700} margin="0rem 0rem 0.5rem 0rem" isDarkTheme={_isDarkTheme}>
													Facebook
												</Text>
												<Text isDarkTheme={_isDarkTheme}>Sign in with Facebook and discover your trusted connections to buyers</Text>
											</div>
											<div>
												{facebookSocialId ? (
													<Button
														medium
														type="cancel"
														width="100%"
														label="Disconnect"
														ifClicked={() => {
															setSocialProviderName('facebook')
															setSocialProviderId(facebookSocialId)
															setOpenDisconnectAccountModal(true)
														}}
													/>
												) : (
													<Button
														medium
														type="red"
														width="100%"
														label="Connect"
														ifClicked={() => {
															doConnectAndDisconnectAccount('connect', 'facebook', '098765432109876543210')
														}}
													/>
												)}
											</div>
										</SocialConnectGroup>
										<SocialConnectGroup>
											<div>
												<Text type="normal" fontWeight={700} margin="0rem 0rem 0.5rem 0rem" isDarkTheme={_isDarkTheme}>
													Google
												</Text>
												<Text isDarkTheme={_isDarkTheme}>Connect your Listing account to your Google account for simplicity and ease</Text>
											</div>
											<div>
												{googleSocialId ? (
													<Button
														medium
														type="cancel"
														width="100%"
														label="Disconnect"
														ifClicked={() => {
															setSocialProviderName('google')
															setSocialProviderId(googleSocialId)
															setOpenDisconnectAccountModal(true)
														}}
													/>
												) : (
													<Button
														medium
														type="red"
														width="100%"
														label="Connect"
														ifClicked={() => {
															doConnectAndDisconnectAccount('connect', 'google', '123456789012345678901')
														}}
													/>
												)}
											</div>
										</SocialConnectGroup>
									</Col>
								</Row>

								<Row>
									<Col>
										<Spacer height={1.5} />
										<Flexed direction="row" justify="center" gap={1}>
											<Button
												medium
												width="100%"
												label="Cancel"
												type="cancel"
												ifClicked={() => {
													_navigate('/')
												}}
											/>
											<Button medium type="red" width="100%" label="Save" ifClicked={editUserDetails} />
										</Flexed>
										<Spacer height={1} />
									</Col>
								</Row>
							</Wrapper>
						</DeleteAccountContainer>
						<Spacer height={1} />
						<DeleteAccountContainer>
							<DeleteAccountWrapper>
								<Text type="medium" margin="0rem 0rem 1rem 0rem" fontWeight={700} isDarkTheme={_isDarkTheme}>
									Delete this account
								</Text>
							</DeleteAccountWrapper>
							<Text type="normal" margin="0rem 0rem 1rem 0rem" fontWeight={500} isDarkTheme={_isDarkTheme}>
								Are you sure you want to delete your account?
							</Text>
							<DeleteButton onClick={() => setDeleteAccount(true)}>
								<DeleteButtonText>Yes, delete my account</DeleteButtonText>
							</DeleteButton>

							<Spacer height={1} />
						</DeleteAccountContainer>
						<Spacer height={1} />
					</Col>
				</Row>
			</Container>
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
			{openDisconnectAccountModal && (
				<DisconnectSocialAccounts
					open={openDisconnectAccountModal}
					onCloseModal={() => setOpenDisconnectAccountModal(false)}
					unlink={() => doConnectAndDisconnectAccount('discounect', socialProviderName, null)}
					unlinkAndRemoveData={() => doConnectAndDisconnectAccount('disconnectAndRemoveData', socialProviderName, null)}
				/>
			)}
			{deleteAccount && <DeleteAccountModal open={deleteAccount} onCloseModal={() => setDeleteAccount(false)} deleteAction={() => doDeleteAccount()} />}
		</>
	)
}

const SocialConnectGroup = styled.div`
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;
`

const DeleteAccountContainer = styled.div`
	padding: 20px;
	border: 1px solid #ccc;
	border-radius: 8px;
	margin: 0px;
`

const DeleteAccountWrapper = styled.div`
	margin-bottom: 15px;
	border-bottom: 1px solid #ccc;
`

const DeleteButton = styled.button<any>`
	background-color: ${palette.danger};
	padding: 10px 15px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
`

const DeleteButtonText = styled.span<any>`
	font-size: 16px;
	color: ${palette.white};
	font-weight: 500;
`

const MoreInfoText = styled(Text)`
	text-decoration: underline;
`
const Wrapper = styled(Container)`
	padding: 0;
`

const ProfileImgCover = styled.label<any>`
	position: relative;
	cursor: pointer;
`

const ProfileImg = styled.div<any>`
	background: ${({url, defaultImage, isDarkTheme}) => (url ? `url(${url})` : defaultImage ? '' : isDarkTheme ? palette.light_silver : palette.silver)};
	background-repeat: no-repeat;
	background-size: cover;
	border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.silver)};
	width: 10rem;
	height: 10rem;
	border-radius: 100%;
	cursor: pointer;
`

const UploadImageText = styled(Text)<any>`
	position: absolute;
	text-align: center;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	gap: 0.2rem;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: auto;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.gray)};
`

const Label = styled.label<any>`
	margin: 0;
	cursor: pointer;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
	font-weight: 500;
`

const Mandatory = styled.span`
	color: ${palette.danger};
`

export default CustomerProfile
