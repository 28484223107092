/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * RightAdvertisement Component
 * Description: This component displays advertisements on the right side, either as a carousel or a single advertisement.
 * The appearance is dynamically adjusted based on the dark or light theme. Advertisements are clickable, opening their respective URLs in new tabs.
 *
 * Props:
 * - paidAdvertisement: array - An array of paid advertisements to be displayed. Each advertisement object should have the following structure:
 *   - position: string - The position of the advertisement ('right' in this case).
 *   - url: string - The URL to navigate to when the advertisement is clicked.
 *   - image: string - The image URL for the advertisement.
 *
 */
import React from 'react'
import styled from 'styled-components'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {Carousel} from 'react-responsive-carousel'
import {useSelector} from 'react-redux'
import {palette} from '../../styled/colors'

const RightAdvertisement = ({paidAdvertisement}: any) => {
	const _isDarkTheme = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const OpenLink = (index: any) => {
		window.open(paidAdvertisement?.filter((ad: any) => ad?.position === 'right')[index]?.url, '_blank')
	}
	return (
		<Main isDarkTheme={_isDarkTheme} active={paidAdvertisement?.filter((ad: any) => ad?.position === 'right')?.length > 0}>
			{paidAdvertisement?.filter((ad: any) => ad?.position === 'right')?.length > 1 ? (
				<Carousel showStatus={false} showThumbs={false} autoPlay={true} infiniteLoop={true} stopOnHover={true} swipeable={false} onClickItem={(e: any) => OpenLink(e)} dynamicHeight={true}>
					{paidAdvertisement
						?.filter((ad: any) => ad?.position === 'right')
						?.map((val: any, index: any) => {
							return (
								<a href={val?.url} target="_blank" key={index + 'right'} rel="noreferrer">
									<AdvertisementRight src={process.env.REACT_APP_IMAGE_URL + val?.image} alt="Advertisement" />
								</a>
							)
						})}
				</Carousel>
			) : paidAdvertisement?.filter((ad: any) => ad?.position === 'right')?.length > 0 ? (
				paidAdvertisement
					?.filter((ad: any) => ad?.position === 'right')
					?.map((val: any, index: any) => {
						return (
							<a href={val?.url} target="_blank" key={index + 'AdvertisementRight'} rel="noreferrer">
								<AdvertisementRight src={process.env.REACT_APP_IMAGE_URL + val?.image} alt="Advertisement" />
							</a>
						)
					})
			) : null}
		</Main>
	)
}

const Main = styled.div<any>`
	width: 100%;
	margin-bottom: ${({active}) => (active ? `1rem` : '0rem')};
	display: ${({active}) => (active ? `block` : 'none')};
	border-radius: 0.5rem;
	border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? `gray` : palette.light_gray)};
	overflow: hidden;
	/* background: ${palette.blue}; */
	cursor: pointer;
`

const AdvertisementRight = styled.img`
	width: 100%;
	border-radius: 0.5rem;
	cursor: pointer;
	object-fit: cover;
	height: auto;
`

export default RightAdvertisement
