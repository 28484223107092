/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * InputField Component
 * Description: Reuseable input field component with various configurations like password visibility,
 * input restrictions (numbers, text, etc.), and support for AI-generated responses.
 *
 * @component
 *
 * @param {Object} props - The properties of the component.
 * @param {string} props.label - The label for the input field.
 * @param {string} props.value - The value of the input field.
 * @param {boolean} props.required - Indicates whether the input is required.
 * @param {string} props.type - The type of the input field.
 * @param {string} props.placeholder - The placeholder for the input field.
 * @param {Function} props.handleChange - Function to handle input change.
 * @param {boolean} props.disabled - Indicates whether the input field is disabled.
 * @param {boolean} props.error - Indicates whether there is an error.
 * @param {string} props.errorMsg - The error message to be displayed.
 * @param {boolean} props.bgTransparent - Indicates whether the background is transparent.
 * @param {string} props.name - The name attribute of the input field.
 * @param {boolean} props.allowNumber - Allows only numbers in the input field.
 * @param {boolean} props.allowPhoneNumberOnly - Allows only phone numbers in the input field.
 * @param {boolean} props.allowText - Allows only alphabetic characters in the input field.
 * @param {boolean} props.allowOnlyNumbers - Allows only numeric characters in the input field.
 * @param {boolean} props.allowOnlyNumbersAndDecimal - Allows only numeric characters and a decimal point in the input field.
 * @param {string} props.info - Additional information or description for the input field.
 * @param {Function} props.onBlur - Function to handle the blur event.
 * @param {Function} props.onFocus - Function to handle the focus event.
 * @param {boolean} props.useAI - Indicates whether AI-generated response is used.
 * @param {Function} props.handleAiGeneratedResponse - Function to handle AI-generated response.
 * @param {string} props.AiGenerateTitle - Title for generating AI response.
 * @param {Function} props.onKeyPress - Function to handle key press event.
 *
 */

import React, {useState} from 'react'
import styled from 'styled-components'
import {palette} from '../../styled/colors'
import {Flexed, Text} from '../../styled/shared'
import {BsFillEyeFill, BsFillEyeSlashFill} from 'react-icons/bs'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {CustomDiv} from '../../pages/posts/CreatePostForm'

const InputField = ({
	label,
	value,
	required,
	type,
	placeholder,
	handleChange,
	disabled,
	error,
	errorMsg,
	bgTransparent,
	name,
	allowNumber,
	allowPhoneNumberOnly,
	allowText,
	allowOnlyNumbers,
	allowOnlyNumbersAndDecimal,
	info,
	onBlur,
	onFocus,
	useAI,
	handleAiGeneratedResponse,
	AiGenerateTitle,
	onKeyPress
}: any) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const [visible, setVisible] = useState(false)
	const [keyValue, setKeyValue] = useState<any>('')

	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (allowNumber) {
			const re = /^[0-9 ]+/
			let val = event.target.value.replace(' ', '')
			if (re.test(val) || val?.length == 0) {
				if (handleChange) handleChange(event.target.value.replace(/[\s]/g, ''))
			}
		} else if (allowPhoneNumberOnly) {
			const re = /^[0-9 -]+$/
			let val = event.target.value.replace(' ', '')
			if (re.test(val) || val?.length == 0) {
				if (handleChange) handleChange(event.target.value.replace(/[\s]/g, ''))
			}
		} else if (allowText) {
			const re = /^[a-z]+$/
			let val = event.target.value
			if (re.test(val) || val?.length == 0) {
				if (handleChange) handleChange(event.target.value)
			}
		} else if (allowOnlyNumbers) {
			const re = /^\d+$/
			let val = event.target.value.replace(' ', '')
			if (keyValue == 'Backspace' || keyValue == 'Delete') {
				handleChange(event.target.value)
			} else {
				if (re.test(val) || val?.length == 0) {
					if (handleChange) handleChange(event.target.value.replace(/[\s]/g, ''))
				}
			}
		} else if (allowOnlyNumbersAndDecimal) {
			const re = /^[0-9][0-9]*[.]?[0-9]{0,2}$/
			let val = event.target.value.replace(' ', '')
			if (keyValue == 'Backspace' || keyValue == 'Delete') {
				handleChange(event.target.value)
			} else {
				if (re.test(val) || val?.length == 0) {
					if (handleChange) handleChange(event.target.value.replace(/[\s]/g, ''))
				}
			}
		} else {
			if (handleChange) handleChange(event.target.value)
		}
	}

	const onKeyDown = (e: any) => {
		e.preventDefault()
	}

	return (
		<>
			{label && (
				<CustomDiv isInput={true}>
					<Label type="normal" margin="0rem 0rem 0.19rem 0rem" isDarkTheme={_isDarkTheme}>
						{label} {required ? <Mandatory>*</Mandatory> : ''}
					</Label>
					{useAI && (
						<Link to="" onClick={() => handleAiGeneratedResponse && handleAiGeneratedResponse()}>
							{AiGenerateTitle ? AiGenerateTitle : ''}
						</Link>
					)}
				</CustomDiv>
			)}
			{type !== 'textarea' ? (
				<>
					<InputWrapper id="inputWrapper">
						<TextInput
							value={value}
							type={`${type && !visible ? (type === 'search' ? 'text' : type) : 'text'}`}
							disabled={disabled}
							placeholder={placeholder}
							error={error}
							name={name}
							onChange={(event: any) => onChange(event)}
							bgTransparent={bgTransparent}
							isDarkTheme={_isDarkTheme}
							onBlur={onBlur}
							onFocus={onFocus}
							onKeyDown={(e: any) => {
								if (type == 'date') {
									type == 'date' && onKeyDown(e)
								} else {
									setKeyValue(e.key)
									onFocus && onFocus()
									onKeyPress && onKeyPress(e)
								}
							}}
						/>
						{type === 'password' && (
							<Icon direction="row" align="center" justify="center" isDarkTheme={_isDarkTheme}>
								{visible ? (
									<Eye
										onClick={() => {
											setVisible(false)
										}}
										isDarkTheme={_isDarkTheme}
									/>
								) : (
									<CloseEye
										onClick={() => {
											setVisible(true)
										}}
										isDarkTheme={_isDarkTheme}
									/>
								)}
							</Icon>
						)}
						{type === 'search' && (
							<Icon isDarkTheme={_isDarkTheme} direction="row" align="center" justify="center">
								<Search isDarkTheme={_isDarkTheme} src="/images/icons/search.svg" />
							</Icon>
						)}
					</InputWrapper>
					{required && error && !disabled && (
						<Text fontSize={0.7} type="small" color="danger">
							{error?.message ? error?.message : error}
						</Text>
					)}
				</>
			) : (
				<>
					<TextArea isDarkTheme={_isDarkTheme} bgTransparent={bgTransparent} rows={4} value={value} placeholder={placeholder} error={error} onChange={(event: any) => handleChange(event.target.value)} />
					{required && error && !disabled && (
						<Text fontSize={0.7} type="small" color="danger">
							{error}
						</Text>
					)}
				</>
			)}
		</>
	)
}

const Label = styled(Text)`
	font-weight: 500;
	text-transform: capitalize;
`

export const Mandatory = styled.span`
	color: ${palette.danger};
`

const InputWrapper = styled.div`
	position: relative;
`

const TextInput = styled.input<any>`
	font-family: 'Roboto';
	width: 100%;
	line-height: 2rem;
	outline: none;
	font-weight: 400;
	text-align: left;
	font-size: 0.875rem;
	border-radius: 0.375rem;
	padding: 0.5rem 0.8rem;
	border: 1px solid ${({error, disabled, isDarkTheme}) => (disabled ? `${palette.silver}` : error ? `${palette.danger}` : isDarkTheme ? `${palette.light_silver}` : `${palette.silver}`)};
	color: ${({disabled, isDarkTheme}) => (disabled ? (isDarkTheme ? palette.light_gray : palette.gray_400) : isDarkTheme ? `${palette.light_gray}` : `${palette.dark_gray}`)};
	width: 100%;
	background: ${({disabled, bgTransparent, isDarkTheme}) => (bgTransparent ? 'transparent' : disabled ? `` : isDarkTheme ? `${palette.black}` : `${palette.white}`)};

	&:focus {
		border: 1px solid ${({error, disabled}) => (disabled ? 'none' : error ? `${palette.danger}` : `${palette.red}`)};
	}
	&::placeholder {
		color: ${({disabled, isDarkTheme}) => (disabled ? (isDarkTheme ? palette.silver : palette.light_silver) : isDarkTheme ? palette.silver : palette.black)};
		opacity: 0.5; /* Firefox */
	}

	&:-ms-input-placeholder {
		color: ${({disabled, isDarkTheme}) => (disabled ? (isDarkTheme ? palette.silver : palette.light_silver) : isDarkTheme ? palette.silver : palette.black)};
	}

	&::-ms-input-placeholder {
	}
`

const Icon = styled(Flexed)<any>`
	position: absolute;
	top: 0.063rem;
	bottom: 0.063rem;
	margin: auto;
	right: 0.063rem;
	width: 2.5rem;
	background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.black}` : `${palette.white}`)};
	border-bottom-right-radius: 0.375rem;
	border-top-right-radius: 0.375rem;
`

const Eye = styled(BsFillEyeFill)<any>`
	font-size: 1.25rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.silver}` : `${palette.gray}`)};
	opacity: 0.5;
	cursor: pointer;
`
const CloseEye = styled(BsFillEyeSlashFill)<any>`
	font-size: 1.25rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? `${palette.silver}` : `${palette.gray}`)};
	opacity: 0.5;
	cursor: pointer;
`

const TextArea = styled.textarea<any>`
	font-family: 'Roboto';
	width: 100%;
	outline: none;
	font-weight: 400;
	text-align: left;
	font-size: 0.875rem;
	border-radius: 0.375rem;
	padding: 0.5rem 0.8rem;
	border: 1px solid ${({error, disabled, isDarkTheme}) => (disabled ? `${palette.silver}` : error ? `${palette.danger}` : isDarkTheme ? `${palette.light_silver}` : `${palette.silver}`)};
	color: ${({disabled, isDarkTheme}) => (disabled ? (isDarkTheme ? palette.light_gray : palette.gray_400) : isDarkTheme ? `${palette.light_gray}` : `${palette.dark_gray}`)};
	width: 100%;

	background: ${({disabled, bgTransparent, isDarkTheme}) => (bgTransparent ? 'transparent' : disabled ? `${palette.silver}` : isDarkTheme ? `${palette.black}` : `${palette.white}`)};

	&:focus {
		border: 1px solid ${({error, disabled}) => (disabled ? 'none' : error ? `${palette.danger}` : `${palette.red}`)};
	}
	&::placeholder {
		color: ${({disabled, isDarkTheme}) => (disabled || isDarkTheme ? `${palette.silver}` : `${palette.black}`)};
		opacity: 0.5; /* Firefox */
	}

	&:-ms-input-placeholder {
		color: ${({disabled, isDarkTheme}) => (disabled || isDarkTheme ? `${palette.silver}` : `${palette.black}`)};
	}

	&::-ms-input-placeholder {
	}
`

const Search = styled.img<any>`
	width: 1rem;
	filter: ${({isDarkTheme}) => (isDarkTheme ? 'invert(100%) sepia(100%) saturate(7%) hue-rotate(137deg) brightness(102%) contrast(102%);' : '')};
`

export default InputField
