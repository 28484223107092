/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * LogInFaceBook Component
 * @description Component for Facebook login button.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {Function} props.setIsLoading - Callback function to set the loading state.
 * @param {Function} props.setShowPhoneInput - Callback function to set the phone input visibility.
 * @param {Function} props.setUserId - Callback function to set the user ID.
 * @param {Function} props.setShowOtpInput - Callback function to set the OTP input visibility.
 * @param {Function} props.setPhone - Callback function to set the phone number.
 *
 */

import React, {useRef} from 'react'
import styled from 'styled-components'
import {Text} from '../../styled/shared'
import {useSelector} from 'react-redux'
import {palette} from '../../styled/colors'
import {api} from '../api/callAxios'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import {loginUserDetailsAction} from '../../actions/authActions'
import {useDispatch} from 'react-redux'
import {FaFacebook} from 'react-icons/fa'

const LogInFaceBook = ({setIsLoading, setShowPhoneInput, setUserId, setShowOtpInput, setPhone}: any) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	let _navigate = useNavigate()
	const _dispatch = useDispatch()
	const responseFaceBook = async (response: any) => {
		setIsLoading(true)
		await api
			.post(`/customers/social_login`, {
				provider: 'facebook',
				socialId: '098765432109876543210',
				fcmToken: '',
				name: 'facebook',
				email: 'facebook@gmail.com'
			})
			.then(async (response) => {
				setIsLoading(false)
				if (response?.data?.data?.user?.user?.isVerified) {
					localStorage.setItem('authorization', response?.data?.data?.user?.accessToken)
					localStorage.setItem('userStatus', response?.data?.data?.user?.user_login)
					_dispatch(loginUserDetailsAction(response?.data?.data?.user?.user))
					_navigate('/post')
				} else if (response?.data?.data?.user?.user?.phone) {
					setPhone(response?.data?.data?.user?.user?.phone)
					setShowOtpInput(true)
					setShowPhoneInput(true)
				} else {
					setUserId(response?.data?.data?.user?.user?.id)
					setShowPhoneInput(true)
				}
			})
			.catch(function (error) {
				setIsLoading(false)
				if (error?.response) {
					toast.error(error?.response?.data?.message)
				}
			})
	}

	return (
		<>
			<Button
				onClick={() => {
					responseFaceBook('C')
				}}
				justify="center"
				isDarkTheme={_isDarkTheme}>
				<FaFacebook fontSize={'1.55rem'} color={palette.blue} />
				<Text type="normal" isDarkTheme={_isDarkTheme}>
					Continue with Facebook
				</Text>
			</Button>
		</>
	)
}
const Button = styled.button<any>`
	align-items: center;
	padding: 0.563rem 0rem;
	font-weight: 600;
	width: 100%;
	cursor: pointer;
	white-space: nowrap;
	font-size: 0.875rem;
	position: relative;
	display: flex;
	gap: 0.6rem;
	justify-content: ${({justify}) => justify};
	transition: all ease 0.25s;
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	border: 0.063rem solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.silver)};
	border-radius: 0.375rem;
`
export default LogInFaceBook
