/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * GoogleGenerativeAI component
 *
 * Description: This component interacts with the Google Generative AI library to generate AI responses.
 * It provides a function for generating AI responses based on text and optional images.
 */

import {GoogleGenerativeAI} from '@google/generative-ai'
import {toast} from 'react-toastify'
const genAI = new GoogleGenerativeAI(process.env.REACT_APP_AI_API_KEY ? process.env.REACT_APP_AI_API_KEY : 'AIzaSyA_1opl_8mbmqBU_pDWk74TvXgtq0EvX-s')

/**
 * doGenerateAiResponse Function
 *
 * @param {string} _text - The text to generate AI response for.
 * @param {Array<string>} images - Optional array of images to be used in the generation process.
 * @returns {Promise<string | null>} - A promise that resolves to the generated AI response or null if an error occurs.
 */
export const doGenerateAiResponse = async (_text: any, images?: any) => {
	try {
		if (_text) {
			const model = genAI.getGenerativeModel({model: images?.length || images ? 'gemini-pro-vision' : 'gemini-pro'})

			const result = await model.generateContent(images?.length ? [_text, ...images] : images ? [_text, images] : _text)
			const response = await result.response
			const text = response.text()
			return text
		}
	} catch (error: any) {
		if (error?.message == '[GoogleGenerativeAI Error]: Candidate was blocked due to RECITATION') {
			toast.error('Please try searching again by choosing a different image.')
		} else {
			toast.error(error?.message)
		}
		console.log('error', error)
	}
}

/**
 * extractPriceFromResponse Function
 *
 * @param {string} text - The text to extract price information from.
 * @returns {string | null} - Extracted price or null if not found.
 */
export const extractPriceFromResponse = (text: any) => {
	const priceRegex = /\$\d+(?:\.\d{1,2})?/g
	const priceKeywords = ['price', 'Price', 'cost', 'charge', 'fee', 'value', 'recommend', 'pkr', 'PKR', 'Rs.']
	for (const keyword of priceKeywords) {
		const index = text.indexOf(keyword)
		if (index !== -1) {
			const potentialPrice = text.substring(index + keyword.length).trim()
			const priceMatch = potentialPrice.match(priceRegex)
			if (priceMatch) {
				return priceMatch[0]
			}
		}
	}
	return null
}

/**
 * adAllFields Array
 *
 * Description: An array defining all the fields associated with advertisements.
 * Used for various purposes such as validation and mapping.
 */
export let adAllFields = [
	'title',
	'businessType',
	'typeCategory',
	'description',
	'furnished',
	'bedRooms',
	'bathRooms',
	'floorLevel',
	'storeys',
	'constructionState',
	'type',
	'features',
	'areaUnit',
	'area',
	'price',
	'location',
	'userName',
	'phone',
	'showNoInAds',
	'year',
	'kmDriven',
	'brand',
	'condition',
	'model',
	'fuel',
	'registrationCity',
	'carDocuments',
	'assemble',
	'transmission',
	'registered',
	'downPayment',
	'monthlyInstallment',
	'installmentPlan',
	'deviceType'
]
