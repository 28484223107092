/**
 * Listing - Web App

 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * PropertyRelatedAds Component
 *
 * @component
 * @description Represents a section for displaying related property ads.
 *
 * @param {Object} props - Component props.
 * @param {Array} props.relatedAds - Array of related property ads.
 */

import {Heading, Text} from '../../styled/shared'
import {Col} from 'styled-bootstrap-grid'
import {useSelector} from 'react-redux'
import Slider from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import PropertyCardContent from './PropertyCardContent'

const PropertyRelatedAds = ({relatedAds}: any) => {
	const _isDarkTheme = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const responsive = {
		superLargeDesktop: {
			breakpoint: {max: 4000, min: 3000},
			items: 2,
			slidesToSlide: 1
		},
		desktop: {
			breakpoint: {max: 3000, min: 1024},
			items: 2,
			slidesToSlide: 1
		},
		tablet: {
			breakpoint: {max: 1024, min: 600},
			items: 2,
			slidesToSlide: 1
		},
		mobile: {
			breakpoint: {max: 600, min: 0},
			items: 1,
			slidesToSlide: 1
		}
	}
	return (
		<>
			<Heading level={3} fontWeight={700} isDarkTheme={_isDarkTheme} margin={'0rem 0rem 1rem 0rem'}>
				Related ads
			</Heading>
			<div id="home">
				<Slider responsive={responsive}>
					{relatedAds?.map((post: any) => {
						return (
							<Col>
								<PropertyCardContent post={post} />
							</Col>
						)
					})}
				</Slider>
			</div>
		</>
	)
}

export default PropertyRelatedAds
